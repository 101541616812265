/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Account } from '../models/account';
import { Accounts } from '../models/accounts';

@Injectable({
  providedIn: 'root',
})
export class AccountsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAccounts
   */
  static readonly GetAccountsPath = '/api/v1/Accounts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAccounts$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccounts$Plain$Response(params?: {
    count?: number;
    page?: number;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Accounts>> {

    const rb = new RequestBuilder(this.rootUrl, AccountsService.GetAccountsPath, 'get');
    if (params) {
      rb.query('count', params.count, {});
      rb.query('page', params.page, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Accounts>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAccounts$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccounts$Plain(params?: {
    count?: number;
    page?: number;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Accounts> {

    return this.getAccounts$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Accounts>) => r.body as Accounts)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAccounts()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccounts$Response(params?: {
    count?: number;
    page?: number;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Accounts>> {

    const rb = new RequestBuilder(this.rootUrl, AccountsService.GetAccountsPath, 'get');
    if (params) {
      rb.query('count', params.count, {});
      rb.query('page', params.page, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Accounts>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAccounts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccounts(params?: {
    count?: number;
    page?: number;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Accounts> {

    return this.getAccounts$Response(params,context).pipe(
      map((r: StrictHttpResponse<Accounts>) => r.body as Accounts)
    );
  }

  /**
   * Path part for operation addAccount
   */
  static readonly AddAccountPath = '/api/v1/Accounts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addAccount$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addAccount$Plain$Response(params?: {
    body?: Account
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Account>> {

    const rb = new RequestBuilder(this.rootUrl, AccountsService.AddAccountPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Account>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addAccount$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addAccount$Plain(params?: {
    body?: Account
  },
  context?: HttpContext

): Observable<Account> {

    return this.addAccount$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Account>) => r.body as Account)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addAccount()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addAccount$Response(params?: {
    body?: Account
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Account>> {

    const rb = new RequestBuilder(this.rootUrl, AccountsService.AddAccountPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Account>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addAccount$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addAccount(params?: {
    body?: Account
  },
  context?: HttpContext

): Observable<Account> {

    return this.addAccount$Response(params,context).pipe(
      map((r: StrictHttpResponse<Account>) => r.body as Account)
    );
  }

  /**
   * Path part for operation getAccount
   */
  static readonly GetAccountPath = '/api/v1/Accounts/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAccount$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccount$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Account>> {

    const rb = new RequestBuilder(this.rootUrl, AccountsService.GetAccountPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Account>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAccount$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccount$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Account> {

    return this.getAccount$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Account>) => r.body as Account)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAccount()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccount$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Account>> {

    const rb = new RequestBuilder(this.rootUrl, AccountsService.GetAccountPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Account>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAccount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccount(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Account> {

    return this.getAccount$Response(params,context).pipe(
      map((r: StrictHttpResponse<Account>) => r.body as Account)
    );
  }

  /**
   * Path part for operation updateAccount
   */
  static readonly UpdateAccountPath = '/api/v1/Accounts/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAccount$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateAccount$Plain$Response(params: {
    id: string;
    body?: Account
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Account>> {

    const rb = new RequestBuilder(this.rootUrl, AccountsService.UpdateAccountPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Account>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateAccount$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateAccount$Plain(params: {
    id: string;
    body?: Account
  },
  context?: HttpContext

): Observable<Account> {

    return this.updateAccount$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Account>) => r.body as Account)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAccount()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateAccount$Response(params: {
    id: string;
    body?: Account
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Account>> {

    const rb = new RequestBuilder(this.rootUrl, AccountsService.UpdateAccountPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Account>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateAccount$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateAccount(params: {
    id: string;
    body?: Account
  },
  context?: HttpContext

): Observable<Account> {

    return this.updateAccount$Response(params,context).pipe(
      map((r: StrictHttpResponse<Account>) => r.body as Account)
    );
  }

  /**
   * Path part for operation deleteAccount
   */
  static readonly DeleteAccountPath = '/api/v1/Accounts/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAccount$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAccount$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Accounts>> {

    const rb = new RequestBuilder(this.rootUrl, AccountsService.DeleteAccountPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Accounts>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteAccount$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAccount$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Accounts> {

    return this.deleteAccount$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Accounts>) => r.body as Accounts)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteAccount()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAccount$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Accounts>> {

    const rb = new RequestBuilder(this.rootUrl, AccountsService.DeleteAccountPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Accounts>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteAccount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteAccount(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Accounts> {

    return this.deleteAccount$Response(params,context).pipe(
      map((r: StrictHttpResponse<Accounts>) => r.body as Accounts)
    );
  }

  /**
   * Path part for operation getAccountByPersonId
   */
  static readonly GetAccountByPersonIdPath = '/api/v1/Accounts/Person/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAccountByPersonId$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccountByPersonId$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Account>> {

    const rb = new RequestBuilder(this.rootUrl, AccountsService.GetAccountByPersonIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Account>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAccountByPersonId$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccountByPersonId$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Account> {

    return this.getAccountByPersonId$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Account>) => r.body as Account)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAccountByPersonId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccountByPersonId$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Account>> {

    const rb = new RequestBuilder(this.rootUrl, AccountsService.GetAccountByPersonIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Account>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAccountByPersonId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAccountByPersonId(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Account> {

    return this.getAccountByPersonId$Response(params,context).pipe(
      map((r: StrictHttpResponse<Account>) => r.body as Account)
    );
  }

  /**
   * Path part for operation resendVerificationEmail
   */
  static readonly ResendVerificationEmailPath = '/api/v1/Accounts/{id}/ResendVerificationEmail';

  /**
   * Resends the verification email for the account.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resendVerificationEmail()` instead.
   *
   * This method doesn't expect any request body.
   */
  resendVerificationEmail$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AccountsService.ResendVerificationEmailPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Resends the verification email for the account.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `resendVerificationEmail$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  resendVerificationEmail(params: {
    id: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.resendVerificationEmail$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation sendAccountPasswordResetEmail
   */
  static readonly SendAccountPasswordResetEmailPath = '/api/v1/Accounts/{id}/SendPasswordResetEmail';

  /**
   * Sends out a password reset email for the account.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendAccountPasswordResetEmail()` instead.
   *
   * This method doesn't expect any request body.
   */
  sendAccountPasswordResetEmail$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AccountsService.SendAccountPasswordResetEmailPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Sends out a password reset email for the account.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendAccountPasswordResetEmail$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sendAccountPasswordResetEmail(params: {
    id: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.sendAccountPasswordResetEmail$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation verifyUser
   */
  static readonly VerifyUserPath = '/api/v1/Accounts/{id}/Verify';

  /**
   * Verifies the emailaddress of the account.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `verifyUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  verifyUser$Response(params: {

    /**
     * account id
     */
    id: string;

    /**
     * The email of the account
     */
    email?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, AccountsService.VerifyUserPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('email', params.email, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Verifies the emailaddress of the account.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `verifyUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  verifyUser(params: {

    /**
     * account id
     */
    id: string;

    /**
     * The email of the account
     */
    email?: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.verifyUser$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
