import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { PortalService } from './portal.service';

@Injectable()
export class PortalGuard implements CanActivate {

  constructor(private router: Router, private portalService: PortalService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.portalService.hasPortal) {
      return true;
    } else {
      this.router.navigate(['home'], { replaceUrl: true });
      return false;
    }
  }
}
