import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { StingrayAuthenticationService } from '../stingray-authentication.service';

@Injectable()
export class AdminGuard implements CanActivate {

  constructor(private router: Router, private stingrayAuthenticationService: StingrayAuthenticationService) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.stingrayAuthenticationService.user.administrator) {
      return true;
    } else {
      this.router.navigate(['home'], { replaceUrl: true });
      return false;
    }
  }
}
