import { Injectable } from '@angular/core';
import { DocumentType, Customer, Shareholder, Member } from '@app/core/stingray-api/models';
import { CompaniesService, CollaborationsService } from '@app/core/stingray-api/services';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class FilesService {

    constructor(private companiesService: CompaniesService,
        private collaborationsService: CollaborationsService) {

    }

    /**
     * Check if the given associationType is a customer
     * @param associationType selected associationType
     */
    public isCustomer(associationType: 'Building' | 'Mortgage' | 'Customer' | 'Person' | 'Company' | 'Collaboration') {
        if (associationType === 'Customer' ||
            associationType === 'Person' ||
            associationType === 'Company' ||
            associationType === 'Collaboration') {
            return true;
        } else {
            return false;
        }
    }

    /**
     * Get the related customers
     * @param customer selected customer
     */
    public getRelatedCustomers(customer: Customer): Observable<Customer[]> {
        switch (customer.type) {
            case 'Person': {
                return of([customer]);
            }
            case 'Company': {
                return this.companiesService.getCompanyShareholdersById({ id: customer.id }).pipe(map((shareholders: Shareholder[]) => {
                    const customers = shareholders.map(shareholder => <Customer>{
                        id: shareholder.customerId,
                        type: 'Person', name: shareholder.name
                    });
                    customers.unshift(customer);

                    return customers;
                }));
            }
            case 'Collaboration': {
                return this.collaborationsService.getCollaborationMembers({ id: customer.id }).pipe(map((members: Member[]) => {
                    const customers = members.map(member => <Customer>{
                        id: member.customerId,
                        type: member.type,
                        name: member.name
                    });
                    customers.unshift(customer);

                    return customers;
                }));
            }
        }
    }

    /**
     * Remove the inrelevant document types and sort the types on the id column
     * e.g. a customer with the type 'person' doesn't need to see document types with the association 'company'
     * @param customer selected customer
     * @param documentTypes list with all document types
     */
    public removeInrelevantDocumentTypes(customer: Customer, documentTypes: DocumentType[]): DocumentType[] {
        return documentTypes.filter((documentType: DocumentType) => {
            if (documentType.associationType === 'Building' ||
                documentType.associationType === 'Mortgage' ||
                documentType.associationType === 'Customer') {
                return true;
            }

            if (customer.type === 'Collaboration') {
                return true;
            }

            if (customer.type === 'Company' &&
                (documentType.associationType === 'Company' || documentType.associationType === 'Person')) {
                return true;
            }

            if (customer.type === 'Person' && documentType.associationType === 'Person') {
                return true;
            }

            return false;
        }).sort((documentTypeA, documentTypeB) => (+documentTypeA.id < +documentTypeB.id ? -1 : 1));
    }
}
