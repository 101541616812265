/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BuildingInfo } from '../models/building-info';
import { BuildingOwners } from '../models/building-owners';
import { PointsRent } from '../models/points-rent';

@Injectable({
  providedIn: 'root',
})
export class BuildingInfoService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation calculatePointsRent
   */
  static readonly CalculatePointsRentPath = '/api/v1/BuildingInfo/PointsRent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `calculatePointsRent$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  calculatePointsRent$Plain$Response(params?: {
    constructionYear?: number;
    energyIndex?: number;
    energyLabel?: 'A4Plus' | 'A3Plus' | 'A2Plus' | 'A1Plus' | 'A' | 'B' | 'C' | 'D' | 'E' | 'F' | 'G';
    energyPerformanceRegistrationDate?: string;
    estimatedValueByMunicipality?: number;
    roomRental?: boolean;
    surface?: number;
    buildingType?: 'Apartment' | 'Business' | 'Cafe' | 'Combination' | 'Garage' | 'Hotel' | 'House' | 'Office' | 'Other' | 'Parking' | 'PracticeSpace' | 'Recreation' | 'Restaurant' | 'Room' | 'Store' | 'Studio';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PointsRent>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingInfoService.CalculatePointsRentPath, 'get');
    if (params) {
      rb.query('constructionYear', params.constructionYear, {});
      rb.query('energyIndex', params.energyIndex, {});
      rb.query('energyLabel', params.energyLabel, {});
      rb.query('energyPerformanceRegistrationDate', params.energyPerformanceRegistrationDate, {});
      rb.query('estimatedValueByMunicipality', params.estimatedValueByMunicipality, {});
      rb.query('roomRental', params.roomRental, {});
      rb.query('surface', params.surface, {});
      rb.query('buildingType', params.buildingType, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PointsRent>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `calculatePointsRent$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  calculatePointsRent$Plain(params?: {
    constructionYear?: number;
    energyIndex?: number;
    energyLabel?: 'A4Plus' | 'A3Plus' | 'A2Plus' | 'A1Plus' | 'A' | 'B' | 'C' | 'D' | 'E' | 'F' | 'G';
    energyPerformanceRegistrationDate?: string;
    estimatedValueByMunicipality?: number;
    roomRental?: boolean;
    surface?: number;
    buildingType?: 'Apartment' | 'Business' | 'Cafe' | 'Combination' | 'Garage' | 'Hotel' | 'House' | 'Office' | 'Other' | 'Parking' | 'PracticeSpace' | 'Recreation' | 'Restaurant' | 'Room' | 'Store' | 'Studio';
  },
  context?: HttpContext

): Observable<PointsRent> {

    return this.calculatePointsRent$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<PointsRent>) => r.body as PointsRent)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `calculatePointsRent()` instead.
   *
   * This method doesn't expect any request body.
   */
  calculatePointsRent$Response(params?: {
    constructionYear?: number;
    energyIndex?: number;
    energyLabel?: 'A4Plus' | 'A3Plus' | 'A2Plus' | 'A1Plus' | 'A' | 'B' | 'C' | 'D' | 'E' | 'F' | 'G';
    energyPerformanceRegistrationDate?: string;
    estimatedValueByMunicipality?: number;
    roomRental?: boolean;
    surface?: number;
    buildingType?: 'Apartment' | 'Business' | 'Cafe' | 'Combination' | 'Garage' | 'Hotel' | 'House' | 'Office' | 'Other' | 'Parking' | 'PracticeSpace' | 'Recreation' | 'Restaurant' | 'Room' | 'Store' | 'Studio';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PointsRent>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingInfoService.CalculatePointsRentPath, 'get');
    if (params) {
      rb.query('constructionYear', params.constructionYear, {});
      rb.query('energyIndex', params.energyIndex, {});
      rb.query('energyLabel', params.energyLabel, {});
      rb.query('energyPerformanceRegistrationDate', params.energyPerformanceRegistrationDate, {});
      rb.query('estimatedValueByMunicipality', params.estimatedValueByMunicipality, {});
      rb.query('roomRental', params.roomRental, {});
      rb.query('surface', params.surface, {});
      rb.query('buildingType', params.buildingType, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PointsRent>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `calculatePointsRent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  calculatePointsRent(params?: {
    constructionYear?: number;
    energyIndex?: number;
    energyLabel?: 'A4Plus' | 'A3Plus' | 'A2Plus' | 'A1Plus' | 'A' | 'B' | 'C' | 'D' | 'E' | 'F' | 'G';
    energyPerformanceRegistrationDate?: string;
    estimatedValueByMunicipality?: number;
    roomRental?: boolean;
    surface?: number;
    buildingType?: 'Apartment' | 'Business' | 'Cafe' | 'Combination' | 'Garage' | 'Hotel' | 'House' | 'Office' | 'Other' | 'Parking' | 'PracticeSpace' | 'Recreation' | 'Restaurant' | 'Room' | 'Store' | 'Studio';
  },
  context?: HttpContext

): Observable<PointsRent> {

    return this.calculatePointsRent$Response(params,context).pipe(
      map((r: StrictHttpResponse<PointsRent>) => r.body as PointsRent)
    );
  }

  /**
   * Path part for operation getBuildingInfo
   */
  static readonly GetBuildingInfoPath = '/api/v1/BuildingInfo/Info';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBuildingInfo$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBuildingInfo$Plain$Response(params?: {
    postalCode?: string;
    houseNumber?: number;
    houseNumberAddition?: string;
    includeEnergy?: boolean;
    includeValue?: boolean;
    includeRegistration?: boolean;
    includeMarketRent?: boolean;
    includePurchaseProtectionAct?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<BuildingInfo>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingInfoService.GetBuildingInfoPath, 'get');
    if (params) {
      rb.query('postalCode', params.postalCode, {});
      rb.query('houseNumber', params.houseNumber, {});
      rb.query('houseNumberAddition', params.houseNumberAddition, {});
      rb.query('includeEnergy', params.includeEnergy, {});
      rb.query('includeValue', params.includeValue, {});
      rb.query('includeRegistration', params.includeRegistration, {});
      rb.query('includeMarketRent', params.includeMarketRent, {});
      rb.query('includePurchaseProtectionAct', params.includePurchaseProtectionAct, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BuildingInfo>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBuildingInfo$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBuildingInfo$Plain(params?: {
    postalCode?: string;
    houseNumber?: number;
    houseNumberAddition?: string;
    includeEnergy?: boolean;
    includeValue?: boolean;
    includeRegistration?: boolean;
    includeMarketRent?: boolean;
    includePurchaseProtectionAct?: boolean;
  },
  context?: HttpContext

): Observable<BuildingInfo> {

    return this.getBuildingInfo$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<BuildingInfo>) => r.body as BuildingInfo)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBuildingInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBuildingInfo$Response(params?: {
    postalCode?: string;
    houseNumber?: number;
    houseNumberAddition?: string;
    includeEnergy?: boolean;
    includeValue?: boolean;
    includeRegistration?: boolean;
    includeMarketRent?: boolean;
    includePurchaseProtectionAct?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<BuildingInfo>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingInfoService.GetBuildingInfoPath, 'get');
    if (params) {
      rb.query('postalCode', params.postalCode, {});
      rb.query('houseNumber', params.houseNumber, {});
      rb.query('houseNumberAddition', params.houseNumberAddition, {});
      rb.query('includeEnergy', params.includeEnergy, {});
      rb.query('includeValue', params.includeValue, {});
      rb.query('includeRegistration', params.includeRegistration, {});
      rb.query('includeMarketRent', params.includeMarketRent, {});
      rb.query('includePurchaseProtectionAct', params.includePurchaseProtectionAct, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BuildingInfo>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBuildingInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBuildingInfo(params?: {
    postalCode?: string;
    houseNumber?: number;
    houseNumberAddition?: string;
    includeEnergy?: boolean;
    includeValue?: boolean;
    includeRegistration?: boolean;
    includeMarketRent?: boolean;
    includePurchaseProtectionAct?: boolean;
  },
  context?: HttpContext

): Observable<BuildingInfo> {

    return this.getBuildingInfo$Response(params,context).pipe(
      map((r: StrictHttpResponse<BuildingInfo>) => r.body as BuildingInfo)
    );
  }

  /**
   * Path part for operation getBuildingOwnerInfo
   */
  static readonly GetBuildingOwnerInfoPath = '/api/v1/BuildingInfo/Owner';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBuildingOwnerInfo$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBuildingOwnerInfo$Plain$Response(params?: {
    postalCode?: string;
    houseNumber?: number;
    houseNumberAddition?: string;
    includeAddressRegistrations?: boolean;
    createCustomerIfNotExisting?: boolean;
    filterCadastralId?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<BuildingOwners>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingInfoService.GetBuildingOwnerInfoPath, 'get');
    if (params) {
      rb.query('postalCode', params.postalCode, {});
      rb.query('houseNumber', params.houseNumber, {});
      rb.query('houseNumberAddition', params.houseNumberAddition, {});
      rb.query('includeAddressRegistrations', params.includeAddressRegistrations, {});
      rb.query('createCustomerIfNotExisting', params.createCustomerIfNotExisting, {});
      rb.query('filterCadastralId', params.filterCadastralId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BuildingOwners>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBuildingOwnerInfo$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBuildingOwnerInfo$Plain(params?: {
    postalCode?: string;
    houseNumber?: number;
    houseNumberAddition?: string;
    includeAddressRegistrations?: boolean;
    createCustomerIfNotExisting?: boolean;
    filterCadastralId?: string;
  },
  context?: HttpContext

): Observable<BuildingOwners> {

    return this.getBuildingOwnerInfo$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<BuildingOwners>) => r.body as BuildingOwners)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBuildingOwnerInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBuildingOwnerInfo$Response(params?: {
    postalCode?: string;
    houseNumber?: number;
    houseNumberAddition?: string;
    includeAddressRegistrations?: boolean;
    createCustomerIfNotExisting?: boolean;
    filterCadastralId?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<BuildingOwners>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingInfoService.GetBuildingOwnerInfoPath, 'get');
    if (params) {
      rb.query('postalCode', params.postalCode, {});
      rb.query('houseNumber', params.houseNumber, {});
      rb.query('houseNumberAddition', params.houseNumberAddition, {});
      rb.query('includeAddressRegistrations', params.includeAddressRegistrations, {});
      rb.query('createCustomerIfNotExisting', params.createCustomerIfNotExisting, {});
      rb.query('filterCadastralId', params.filterCadastralId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BuildingOwners>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBuildingOwnerInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBuildingOwnerInfo(params?: {
    postalCode?: string;
    houseNumber?: number;
    houseNumberAddition?: string;
    includeAddressRegistrations?: boolean;
    createCustomerIfNotExisting?: boolean;
    filterCadastralId?: string;
  },
  context?: HttpContext

): Observable<BuildingOwners> {

    return this.getBuildingOwnerInfo$Response(params,context).pipe(
      map((r: StrictHttpResponse<BuildingOwners>) => r.body as BuildingOwners)
    );
  }

}
