/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Building } from '../models/building';
import { Collaboration } from '../models/collaboration';
import { Company } from '../models/company';
import { Mortgage } from '../models/mortgage';
import { NewVirSession } from '../models/new-vir-session';
import { Person } from '../models/person';
import { PublicAccess } from '../models/public-access';
import { VirAddressSeizures } from '../models/vir-address-seizures';
import { VirCollaboration } from '../models/vir-collaboration';
import { VirCompletedSession } from '../models/vir-completed-session';
import { VirDocuments } from '../models/vir-documents';
import { VirEstimatedCosts } from '../models/vir-estimated-costs';
import { VirNewBuilding } from '../models/vir-new-building';
import { VirNewMortgage } from '../models/vir-new-mortgage';
import { VirRemovedMortgage } from '../models/vir-removed-mortgage';
import { VirSession } from '../models/vir-session';
import { VirUnmatchedRegistration } from '../models/vir-unmatched-registration';
import { VirUpdatedBuilding } from '../models/vir-updated-building';

@Injectable({
  providedIn: 'root',
})
export class VirService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getSession
   */
  static readonly GetSessionPath = '/api/v1/Vir/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSession$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSession$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<VirSession>> {

    const rb = new RequestBuilder(this.rootUrl, VirService.GetSessionPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<VirSession>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSession$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSession$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<VirSession> {

    return this.getSession$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<VirSession>) => r.body as VirSession)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSession()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSession$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<VirSession>> {

    const rb = new RequestBuilder(this.rootUrl, VirService.GetSessionPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<VirSession>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSession$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSession(params: {
    id: string;
  },
  context?: HttpContext

): Observable<VirSession> {

    return this.getSession$Response(params,context).pipe(
      map((r: StrictHttpResponse<VirSession>) => r.body as VirSession)
    );
  }

  /**
   * Path part for operation cancelSession
   */
  static readonly CancelSessionPath = '/api/v1/Vir/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cancelSession()` instead.
   *
   * This method doesn't expect any request body.
   */
  cancelSession$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, VirService.CancelSessionPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `cancelSession$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  cancelSession(params: {
    id: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.cancelSession$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation startSession
   */
  static readonly StartSessionPath = '/api/v1/Vir';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `startSession$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  startSession$Plain$Response(params?: {
    body?: NewVirSession
  },
  context?: HttpContext

): Observable<StrictHttpResponse<VirSession>> {

    const rb = new RequestBuilder(this.rootUrl, VirService.StartSessionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<VirSession>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `startSession$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  startSession$Plain(params?: {
    body?: NewVirSession
  },
  context?: HttpContext

): Observable<VirSession> {

    return this.startSession$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<VirSession>) => r.body as VirSession)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `startSession()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  startSession$Response(params?: {
    body?: NewVirSession
  },
  context?: HttpContext

): Observable<StrictHttpResponse<VirSession>> {

    const rb = new RequestBuilder(this.rootUrl, VirService.StartSessionPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<VirSession>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `startSession$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  startSession(params?: {
    body?: NewVirSession
  },
  context?: HttpContext

): Observable<VirSession> {

    return this.startSession$Response(params,context).pipe(
      map((r: StrictHttpResponse<VirSession>) => r.body as VirSession)
    );
  }

  /**
   * Path part for operation getCompletedSession
   */
  static readonly GetCompletedSessionPath = '/api/v1/Vir/{id}/completed';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompletedSession$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompletedSession$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<VirCompletedSession>> {

    const rb = new RequestBuilder(this.rootUrl, VirService.GetCompletedSessionPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<VirCompletedSession>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompletedSession$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompletedSession$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<VirCompletedSession> {

    return this.getCompletedSession$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<VirCompletedSession>) => r.body as VirCompletedSession)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompletedSession()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompletedSession$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<VirCompletedSession>> {

    const rb = new RequestBuilder(this.rootUrl, VirService.GetCompletedSessionPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<VirCompletedSession>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompletedSession$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompletedSession(params: {
    id: string;
  },
  context?: HttpContext

): Observable<VirCompletedSession> {

    return this.getCompletedSession$Response(params,context).pipe(
      map((r: StrictHttpResponse<VirCompletedSession>) => r.body as VirCompletedSession)
    );
  }

  /**
   * Path part for operation getEstimatedCosts
   */
  static readonly GetEstimatedCostsPath = '/api/v1/Vir/{id}/costs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEstimatedCosts$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEstimatedCosts$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<VirEstimatedCosts>> {

    const rb = new RequestBuilder(this.rootUrl, VirService.GetEstimatedCostsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<VirEstimatedCosts>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEstimatedCosts$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEstimatedCosts$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<VirEstimatedCosts> {

    return this.getEstimatedCosts$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<VirEstimatedCosts>) => r.body as VirEstimatedCosts)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEstimatedCosts()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEstimatedCosts$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<VirEstimatedCosts>> {

    const rb = new RequestBuilder(this.rootUrl, VirService.GetEstimatedCostsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<VirEstimatedCosts>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEstimatedCosts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEstimatedCosts(params: {
    id: string;
  },
  context?: HttpContext

): Observable<VirEstimatedCosts> {

    return this.getEstimatedCosts$Response(params,context).pipe(
      map((r: StrictHttpResponse<VirEstimatedCosts>) => r.body as VirEstimatedCosts)
    );
  }

  /**
   * Path part for operation getSeizures
   */
  static readonly GetSeizuresPath = '/api/v1/Vir/{id}/seizures';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSeizures$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSeizures$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<VirAddressSeizures>>> {

    const rb = new RequestBuilder(this.rootUrl, VirService.GetSeizuresPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<VirAddressSeizures>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSeizures$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSeizures$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<VirAddressSeizures>> {

    return this.getSeizures$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<VirAddressSeizures>>) => r.body as Array<VirAddressSeizures>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSeizures()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSeizures$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<VirAddressSeizures>>> {

    const rb = new RequestBuilder(this.rootUrl, VirService.GetSeizuresPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<VirAddressSeizures>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSeizures$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSeizures(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<VirAddressSeizures>> {

    return this.getSeizures$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<VirAddressSeizures>>) => r.body as Array<VirAddressSeizures>)
    );
  }

  /**
   * Path part for operation getUnmatchedDesignations
   */
  static readonly GetUnmatchedDesignationsPath = '/api/v1/Vir/{id}/unmatcheddesignations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUnmatchedDesignations$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUnmatchedDesignations$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<VirUnmatchedRegistration>>> {

    const rb = new RequestBuilder(this.rootUrl, VirService.GetUnmatchedDesignationsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<VirUnmatchedRegistration>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUnmatchedDesignations$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUnmatchedDesignations$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<VirUnmatchedRegistration>> {

    return this.getUnmatchedDesignations$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<VirUnmatchedRegistration>>) => r.body as Array<VirUnmatchedRegistration>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUnmatchedDesignations()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUnmatchedDesignations$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<VirUnmatchedRegistration>>> {

    const rb = new RequestBuilder(this.rootUrl, VirService.GetUnmatchedDesignationsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<VirUnmatchedRegistration>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUnmatchedDesignations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUnmatchedDesignations(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<VirUnmatchedRegistration>> {

    return this.getUnmatchedDesignations$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<VirUnmatchedRegistration>>) => r.body as Array<VirUnmatchedRegistration>)
    );
  }

  /**
   * Path part for operation getSubjectPerson
   */
  static readonly GetSubjectPersonPath = '/api/v1/Vir/{id}/subject/person';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSubjectPerson$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSubjectPerson$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Person>> {

    const rb = new RequestBuilder(this.rootUrl, VirService.GetSubjectPersonPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Person>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSubjectPerson$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSubjectPerson$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Person> {

    return this.getSubjectPerson$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Person>) => r.body as Person)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSubjectPerson()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSubjectPerson$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Person>> {

    const rb = new RequestBuilder(this.rootUrl, VirService.GetSubjectPersonPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Person>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSubjectPerson$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSubjectPerson(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Person> {

    return this.getSubjectPerson$Response(params,context).pipe(
      map((r: StrictHttpResponse<Person>) => r.body as Person)
    );
  }

  /**
   * Path part for operation getSubjectCompany
   */
  static readonly GetSubjectCompanyPath = '/api/v1/Vir/{id}/subject/company';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSubjectCompany$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSubjectCompany$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Company>> {

    const rb = new RequestBuilder(this.rootUrl, VirService.GetSubjectCompanyPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Company>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSubjectCompany$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSubjectCompany$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Company> {

    return this.getSubjectCompany$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Company>) => r.body as Company)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSubjectCompany()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSubjectCompany$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Company>> {

    const rb = new RequestBuilder(this.rootUrl, VirService.GetSubjectCompanyPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Company>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSubjectCompany$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSubjectCompany(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Company> {

    return this.getSubjectCompany$Response(params,context).pipe(
      map((r: StrictHttpResponse<Company>) => r.body as Company)
    );
  }

  /**
   * Path part for operation getNewMortgages
   */
  static readonly GetNewMortgagesPath = '/api/v1/Vir/{id}/mortgages/new';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNewMortgages$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNewMortgages$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<VirNewMortgage>>> {

    const rb = new RequestBuilder(this.rootUrl, VirService.GetNewMortgagesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<VirNewMortgage>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getNewMortgages$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNewMortgages$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<VirNewMortgage>> {

    return this.getNewMortgages$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<VirNewMortgage>>) => r.body as Array<VirNewMortgage>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNewMortgages()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNewMortgages$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<VirNewMortgage>>> {

    const rb = new RequestBuilder(this.rootUrl, VirService.GetNewMortgagesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<VirNewMortgage>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getNewMortgages$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNewMortgages(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<VirNewMortgage>> {

    return this.getNewMortgages$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<VirNewMortgage>>) => r.body as Array<VirNewMortgage>)
    );
  }

  /**
   * Path part for operation getRemovedMortgages
   */
  static readonly GetRemovedMortgagesPath = '/api/v1/Vir/{id}/mortgages/removed';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRemovedMortgages$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRemovedMortgages$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<VirRemovedMortgage>>> {

    const rb = new RequestBuilder(this.rootUrl, VirService.GetRemovedMortgagesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<VirRemovedMortgage>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRemovedMortgages$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRemovedMortgages$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<VirRemovedMortgage>> {

    return this.getRemovedMortgages$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<VirRemovedMortgage>>) => r.body as Array<VirRemovedMortgage>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRemovedMortgages()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRemovedMortgages$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<VirRemovedMortgage>>> {

    const rb = new RequestBuilder(this.rootUrl, VirService.GetRemovedMortgagesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<VirRemovedMortgage>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRemovedMortgages$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRemovedMortgages(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<VirRemovedMortgage>> {

    return this.getRemovedMortgages$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<VirRemovedMortgage>>) => r.body as Array<VirRemovedMortgage>)
    );
  }

  /**
   * Path part for operation processMortgage
   */
  static readonly ProcessMortgagePath = '/api/v1/Vir/{id}/mortgages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `processMortgage$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  processMortgage$Plain$Response(params: {
    id: string;
    body?: Mortgage
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Mortgage>> {

    const rb = new RequestBuilder(this.rootUrl, VirService.ProcessMortgagePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Mortgage>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `processMortgage$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  processMortgage$Plain(params: {
    id: string;
    body?: Mortgage
  },
  context?: HttpContext

): Observable<Mortgage> {

    return this.processMortgage$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Mortgage>) => r.body as Mortgage)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `processMortgage()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  processMortgage$Response(params: {
    id: string;
    body?: Mortgage
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Mortgage>> {

    const rb = new RequestBuilder(this.rootUrl, VirService.ProcessMortgagePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Mortgage>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `processMortgage$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  processMortgage(params: {
    id: string;
    body?: Mortgage
  },
  context?: HttpContext

): Observable<Mortgage> {

    return this.processMortgage$Response(params,context).pipe(
      map((r: StrictHttpResponse<Mortgage>) => r.body as Mortgage)
    );
  }

  /**
   * Path part for operation getNewBuildings
   */
  static readonly GetNewBuildingsPath = '/api/v1/Vir/{id}/buildings/new';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNewBuildings$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNewBuildings$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<VirNewBuilding>>> {

    const rb = new RequestBuilder(this.rootUrl, VirService.GetNewBuildingsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<VirNewBuilding>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getNewBuildings$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNewBuildings$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<VirNewBuilding>> {

    return this.getNewBuildings$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<VirNewBuilding>>) => r.body as Array<VirNewBuilding>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getNewBuildings()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNewBuildings$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<VirNewBuilding>>> {

    const rb = new RequestBuilder(this.rootUrl, VirService.GetNewBuildingsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<VirNewBuilding>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getNewBuildings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getNewBuildings(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<VirNewBuilding>> {

    return this.getNewBuildings$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<VirNewBuilding>>) => r.body as Array<VirNewBuilding>)
    );
  }

  /**
   * Path part for operation getUpdatedBuildings
   */
  static readonly GetUpdatedBuildingsPath = '/api/v1/Vir/{id}/buildings/updates';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUpdatedBuildings$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUpdatedBuildings$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<VirUpdatedBuilding>>> {

    const rb = new RequestBuilder(this.rootUrl, VirService.GetUpdatedBuildingsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<VirUpdatedBuilding>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUpdatedBuildings$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUpdatedBuildings$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<VirUpdatedBuilding>> {

    return this.getUpdatedBuildings$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<VirUpdatedBuilding>>) => r.body as Array<VirUpdatedBuilding>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUpdatedBuildings()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUpdatedBuildings$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<VirUpdatedBuilding>>> {

    const rb = new RequestBuilder(this.rootUrl, VirService.GetUpdatedBuildingsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<VirUpdatedBuilding>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUpdatedBuildings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUpdatedBuildings(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<VirUpdatedBuilding>> {

    return this.getUpdatedBuildings$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<VirUpdatedBuilding>>) => r.body as Array<VirUpdatedBuilding>)
    );
  }

  /**
   * Path part for operation getRemovedBuildings
   */
  static readonly GetRemovedBuildingsPath = '/api/v1/Vir/{id}/buildings/removed';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRemovedBuildings$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRemovedBuildings$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Building>>> {

    const rb = new RequestBuilder(this.rootUrl, VirService.GetRemovedBuildingsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Building>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRemovedBuildings$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRemovedBuildings$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<Building>> {

    return this.getRemovedBuildings$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Building>>) => r.body as Array<Building>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRemovedBuildings()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRemovedBuildings$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Building>>> {

    const rb = new RequestBuilder(this.rootUrl, VirService.GetRemovedBuildingsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Building>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRemovedBuildings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRemovedBuildings(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<Building>> {

    return this.getRemovedBuildings$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Building>>) => r.body as Array<Building>)
    );
  }

  /**
   * Path part for operation getCombinationBuildings
   */
  static readonly GetCombinationBuildingsPath = '/api/v1/Vir/{id}/buildings/combinations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCombinationBuildings$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCombinationBuildings$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Array<Building>>>> {

    const rb = new RequestBuilder(this.rootUrl, VirService.GetCombinationBuildingsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Array<Building>>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCombinationBuildings$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCombinationBuildings$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<Array<Building>>> {

    return this.getCombinationBuildings$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Array<Building>>>) => r.body as Array<Array<Building>>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCombinationBuildings()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCombinationBuildings$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Array<Building>>>> {

    const rb = new RequestBuilder(this.rootUrl, VirService.GetCombinationBuildingsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Array<Building>>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCombinationBuildings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCombinationBuildings(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<Array<Building>>> {

    return this.getCombinationBuildings$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Array<Building>>>) => r.body as Array<Array<Building>>)
    );
  }

  /**
   * Path part for operation combineBuildings
   */
  static readonly CombineBuildingsPath = '/api/v1/Vir/{id}/buildings/combinations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `combineBuildings()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  combineBuildings$Response(params: {
    id: string;
    body?: Array<string>
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, VirService.CombineBuildingsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `combineBuildings$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  combineBuildings(params: {
    id: string;
    body?: Array<string>
  },
  context?: HttpContext

): Observable<void> {

    return this.combineBuildings$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getCollaborations
   */
  static readonly GetCollaborationsPath = '/api/v1/Vir/{id}/collaborations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCollaborations$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCollaborations$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<VirCollaboration>>> {

    const rb = new RequestBuilder(this.rootUrl, VirService.GetCollaborationsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<VirCollaboration>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCollaborations$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCollaborations$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<VirCollaboration>> {

    return this.getCollaborations$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<VirCollaboration>>) => r.body as Array<VirCollaboration>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCollaborations()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCollaborations$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<VirCollaboration>>> {

    const rb = new RequestBuilder(this.rootUrl, VirService.GetCollaborationsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<VirCollaboration>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCollaborations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCollaborations(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<VirCollaboration>> {

    return this.getCollaborations$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<VirCollaboration>>) => r.body as Array<VirCollaboration>)
    );
  }

  /**
   * Path part for operation processCollaboration
   */
  static readonly ProcessCollaborationPath = '/api/v1/Vir/{id}/collaborations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `processCollaboration$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  processCollaboration$Plain$Response(params: {
    id: string;
    virCollaborationId?: string;
    virAction?: 'CREATE' | 'UPDATE' | 'IGNORE';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Collaboration>> {

    const rb = new RequestBuilder(this.rootUrl, VirService.ProcessCollaborationPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('virCollaborationId', params.virCollaborationId, {});
      rb.query('virAction', params.virAction, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Collaboration>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `processCollaboration$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  processCollaboration$Plain(params: {
    id: string;
    virCollaborationId?: string;
    virAction?: 'CREATE' | 'UPDATE' | 'IGNORE';
  },
  context?: HttpContext

): Observable<Collaboration> {

    return this.processCollaboration$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Collaboration>) => r.body as Collaboration)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `processCollaboration()` instead.
   *
   * This method doesn't expect any request body.
   */
  processCollaboration$Response(params: {
    id: string;
    virCollaborationId?: string;
    virAction?: 'CREATE' | 'UPDATE' | 'IGNORE';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Collaboration>> {

    const rb = new RequestBuilder(this.rootUrl, VirService.ProcessCollaborationPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('virCollaborationId', params.virCollaborationId, {});
      rb.query('virAction', params.virAction, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Collaboration>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `processCollaboration$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  processCollaboration(params: {
    id: string;
    virCollaborationId?: string;
    virAction?: 'CREATE' | 'UPDATE' | 'IGNORE';
  },
  context?: HttpContext

): Observable<Collaboration> {

    return this.processCollaboration$Response(params,context).pipe(
      map((r: StrictHttpResponse<Collaboration>) => r.body as Collaboration)
    );
  }

  /**
   * Path part for operation processCollaborationByAddress
   */
  static readonly ProcessCollaborationByAddressPath = '/api/v1/Vir/{id}/collaborations/address';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `processCollaborationByAddress$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  processCollaborationByAddress$Plain$Response(params: {
    postalCode?: string;
    houseNumber?: number;
    houseNumberAddition?: string;
    adviserId?: string;
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Collaboration>> {

    const rb = new RequestBuilder(this.rootUrl, VirService.ProcessCollaborationByAddressPath, 'post');
    if (params) {
      rb.query('postalCode', params.postalCode, {});
      rb.query('houseNumber', params.houseNumber, {});
      rb.query('houseNumberAddition', params.houseNumberAddition, {});
      rb.query('adviserId', params.adviserId, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Collaboration>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `processCollaborationByAddress$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  processCollaborationByAddress$Plain(params: {
    postalCode?: string;
    houseNumber?: number;
    houseNumberAddition?: string;
    adviserId?: string;
    id: string;
  },
  context?: HttpContext

): Observable<Collaboration> {

    return this.processCollaborationByAddress$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Collaboration>) => r.body as Collaboration)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `processCollaborationByAddress()` instead.
   *
   * This method doesn't expect any request body.
   */
  processCollaborationByAddress$Response(params: {
    postalCode?: string;
    houseNumber?: number;
    houseNumberAddition?: string;
    adviserId?: string;
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Collaboration>> {

    const rb = new RequestBuilder(this.rootUrl, VirService.ProcessCollaborationByAddressPath, 'post');
    if (params) {
      rb.query('postalCode', params.postalCode, {});
      rb.query('houseNumber', params.houseNumber, {});
      rb.query('houseNumberAddition', params.houseNumberAddition, {});
      rb.query('adviserId', params.adviserId, {});
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Collaboration>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `processCollaborationByAddress$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  processCollaborationByAddress(params: {
    postalCode?: string;
    houseNumber?: number;
    houseNumberAddition?: string;
    adviserId?: string;
    id: string;
  },
  context?: HttpContext

): Observable<Collaboration> {

    return this.processCollaborationByAddress$Response(params,context).pipe(
      map((r: StrictHttpResponse<Collaboration>) => r.body as Collaboration)
    );
  }

  /**
   * Path part for operation getVirDocuments
   */
  static readonly GetVirDocumentsPath = '/api/v1/Vir/{id}/documents';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVirDocuments$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVirDocuments$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<VirDocuments>> {

    const rb = new RequestBuilder(this.rootUrl, VirService.GetVirDocumentsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<VirDocuments>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getVirDocuments$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVirDocuments$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<VirDocuments> {

    return this.getVirDocuments$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<VirDocuments>) => r.body as VirDocuments)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVirDocuments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVirDocuments$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<VirDocuments>> {

    const rb = new RequestBuilder(this.rootUrl, VirService.GetVirDocumentsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<VirDocuments>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getVirDocuments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVirDocuments(params: {
    id: string;
  },
  context?: HttpContext

): Observable<VirDocuments> {

    return this.getVirDocuments$Response(params,context).pipe(
      map((r: StrictHttpResponse<VirDocuments>) => r.body as VirDocuments)
    );
  }

  /**
   * Path part for operation processDocuments
   */
  static readonly ProcessDocumentsPath = '/api/v1/Vir/{id}/documents';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `processDocuments()` instead.
   *
   * This method doesn't expect any request body.
   */
  processDocuments$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, VirService.ProcessDocumentsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `processDocuments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  processDocuments(params: {
    id: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.processDocuments$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getDocumentUri
   */
  static readonly GetDocumentUriPath = '/api/v1/Vir/{id}/documents/open';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocumentUri$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentUri$Plain$Response(params: {
    id: string;
    description?: string;
    originalFileName?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PublicAccess>> {

    const rb = new RequestBuilder(this.rootUrl, VirService.GetDocumentUriPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('description', params.description, {});
      rb.query('originalFileName', params.originalFileName, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PublicAccess>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDocumentUri$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentUri$Plain(params: {
    id: string;
    description?: string;
    originalFileName?: string;
  },
  context?: HttpContext

): Observable<PublicAccess> {

    return this.getDocumentUri$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<PublicAccess>) => r.body as PublicAccess)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocumentUri()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentUri$Response(params: {
    id: string;
    description?: string;
    originalFileName?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PublicAccess>> {

    const rb = new RequestBuilder(this.rootUrl, VirService.GetDocumentUriPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('description', params.description, {});
      rb.query('originalFileName', params.originalFileName, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PublicAccess>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDocumentUri$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentUri(params: {
    id: string;
    description?: string;
    originalFileName?: string;
  },
  context?: HttpContext

): Observable<PublicAccess> {

    return this.getDocumentUri$Response(params,context).pipe(
      map((r: StrictHttpResponse<PublicAccess>) => r.body as PublicAccess)
    );
  }

}
