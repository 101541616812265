import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class VersionCheckService {

  interval: number;
  currentHash: string;
  currentVersion: string;

  constructor(private translateService: TranslateService, private httpClient: HttpClient) {
    this.currentVersion = '{{POST_BUILD_ENTERS_VERSION_HERE}}';
  }

  /**
     * Checks in every set frequency the version of frontend application
     */
  public initVersionCheck(url: string, frequency: number = 1000 * 60 * 1) {
    this.interval = window.setInterval(() => {
      this.checkVersion(url);
    }, frequency);
  }




  /**
     * Will do the call and check if the hash has changed or not
     */
  private checkVersion(url: string) {
    // timestamp these requests to invalidate caches
    this.httpClient.get(url + '?t=' + new Date().getTime())
      .subscribe(
        (response: any) => {
          try {
            const newHash = response.hash;
            const newVersion = response.version;
            // If new version, do something
            if (this.hasVersionChanged(newVersion)) {
              window.clearInterval(this.interval);
              if (window.confirm(this.translateService.instant('There is an update available. Do you want to update?'))) {
                location.reload();
              }
            }
            // Store the new hash so we wouldn't trigger versionChange again
            // only necessary in case you did not force refresh
            this.currentHash = newHash;
            this.currentVersion = newVersion;
          } catch (error) {
            console.log(error);
          }
        },
        (err) => {
          console.log(err, 'Could not get the version');
        }
      );
  }

  private hasVersionChanged(newVersion: string): boolean {
    console.debug('Comparing version', this.currentVersion, newVersion, this.currentVersion !== newVersion);
    return this.currentVersion !== newVersion;
  }
}
