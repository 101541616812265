/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AddApplicationsResult } from '../models/add-applications-result';
import { Application } from '../models/application';
import { ApplicationTotals } from '../models/application-totals';
import { ApplicationType } from '../models/application-type';
import { Applications } from '../models/applications';
import { Document } from '../models/document';
import { Documents } from '../models/documents';
import { GuidId } from '../models/guid-id';
import { IntId } from '../models/int-id';
import { JsonPatchDocument } from '../models/json-patch-document';
import { NewApplicationFromCustom } from '../models/new-application-from-custom';
import { NewApplicationFromProposal } from '../models/new-application-from-proposal';
import { NewRequiredDocument } from '../models/new-required-document';
import { PublicAccess } from '../models/public-access';
import { RegisterDocument } from '../models/register-document';
import { RequestRequiredDocuments } from '../models/request-required-documents';
import { RequiredDocument } from '../models/required-document';
import { RequiredDocuments } from '../models/required-documents';
import { SubmitData } from '../models/submit-data';
import { SubmitOption } from '../models/submit-option';
import { SubmitResult } from '../models/submit-result';
import { UpdateApplication } from '../models/update-application';

@Injectable({
  providedIn: 'root',
})
export class ApplicationsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getApplications
   */
  static readonly GetApplicationsPath = '/api/v1/Applications';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApplications()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplications$Response(params?: {
    count?: number;
    page?: number;
    statuses?: Array<'Consult' | 'CollectingInformation' | 'Writing' | 'TermSheetRequested' | 'TermSheetReceived' | 'TermSheetApproved' | 'CollectingAdditionalInformation' | 'AligningConditions' | 'WritingAdvisoryReport' | 'LoanAgreementRequested' | 'LoanAgreementReceived' | 'LoanAgreementApproved' | 'DocumentsAtTheNotary' | 'MortgagePassed' | 'Rejected' | 'Closed' | 'Archived'>;
    terms?: string;
    adviserId?: string;
    lenderId?: string;
    onlyWithActionNeed?: boolean;
    beforeExecution?: boolean;
    hasPipedriveDeal?: boolean;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Applications>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.GetApplicationsPath, 'get');
    if (params) {
      rb.query('count', params.count, {});
      rb.query('page', params.page, {});
      rb.query('statuses', params.statuses, {});
      rb.query('terms', params.terms, {});
      rb.query('adviserId', params.adviserId, {});
      rb.query('lenderId', params.lenderId, {});
      rb.query('onlyWithActionNeed', params.onlyWithActionNeed, {});
      rb.query('beforeExecution', params.beforeExecution, {});
      rb.query('hasPipedriveDeal', params.hasPipedriveDeal, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Applications>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getApplications$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplications(params?: {
    count?: number;
    page?: number;
    statuses?: Array<'Consult' | 'CollectingInformation' | 'Writing' | 'TermSheetRequested' | 'TermSheetReceived' | 'TermSheetApproved' | 'CollectingAdditionalInformation' | 'AligningConditions' | 'WritingAdvisoryReport' | 'LoanAgreementRequested' | 'LoanAgreementReceived' | 'LoanAgreementApproved' | 'DocumentsAtTheNotary' | 'MortgagePassed' | 'Rejected' | 'Closed' | 'Archived'>;
    terms?: string;
    adviserId?: string;
    lenderId?: string;
    onlyWithActionNeed?: boolean;
    beforeExecution?: boolean;
    hasPipedriveDeal?: boolean;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Applications> {

    return this.getApplications$Response(params,context).pipe(
      map((r: StrictHttpResponse<Applications>) => r.body as Applications)
    );
  }

  /**
   * Path part for operation addApplications
   */
  static readonly AddApplicationsPath = '/api/v1/Applications';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addApplications()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addApplications$Response(params?: {
    body?: (NewApplicationFromProposal | NewApplicationFromCustom)
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AddApplicationsResult>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.AddApplicationsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AddApplicationsResult>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addApplications$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addApplications(params?: {
    body?: (NewApplicationFromProposal | NewApplicationFromCustom)
  },
  context?: HttpContext

): Observable<AddApplicationsResult> {

    return this.addApplications$Response(params,context).pipe(
      map((r: StrictHttpResponse<AddApplicationsResult>) => r.body as AddApplicationsResult)
    );
  }

  /**
   * Path part for operation getApplicationById
   */
  static readonly GetApplicationByIdPath = '/api/v1/Applications/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApplicationById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationById$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Application>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.GetApplicationByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Application>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getApplicationById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationById(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Application> {

    return this.getApplicationById$Response(params,context).pipe(
      map((r: StrictHttpResponse<Application>) => r.body as Application)
    );
  }

  /**
   * Path part for operation updateApplication
   */
  static readonly UpdateApplicationPath = '/api/v1/Applications/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateApplication()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateApplication$Response(params: {
    id: string;
    body?: UpdateApplication
  },
  context?: HttpContext

): Observable<StrictHttpResponse<GuidId>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.UpdateApplicationPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GuidId>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateApplication$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateApplication(params: {
    id: string;
    body?: UpdateApplication
  },
  context?: HttpContext

): Observable<GuidId> {

    return this.updateApplication$Response(params,context).pipe(
      map((r: StrictHttpResponse<GuidId>) => r.body as GuidId)
    );
  }

  /**
   * Path part for operation deleteApplicationById
   */
  static readonly DeleteApplicationByIdPath = '/api/v1/Applications/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteApplicationById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteApplicationById$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.DeleteApplicationByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteApplicationById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteApplicationById(params: {
    id: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.deleteApplicationById$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation patchApplication
   */
  static readonly PatchApplicationPath = '/api/v1/Applications/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchApplication()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchApplication$Response(params: {
    id: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Application>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.PatchApplicationPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json-patch+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Application>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchApplication$Response()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchApplication(params: {
    id: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<Application> {

    return this.patchApplication$Response(params,context).pipe(
      map((r: StrictHttpResponse<Application>) => r.body as Application)
    );
  }

  /**
   * Path part for operation getApplicationByExternalLenderId
   */
  static readonly GetApplicationByExternalLenderIdPath = '/api/v1/Applications/ExternalLenderId/{externalLenderId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApplicationByExternalLenderId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationByExternalLenderId$Response(params: {
    externalLenderId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Application>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.GetApplicationByExternalLenderIdPath, 'get');
    if (params) {
      rb.path('externalLenderId', params.externalLenderId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Application>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getApplicationByExternalLenderId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationByExternalLenderId(params: {
    externalLenderId: string;
  },
  context?: HttpContext

): Observable<Application> {

    return this.getApplicationByExternalLenderId$Response(params,context).pipe(
      map((r: StrictHttpResponse<Application>) => r.body as Application)
    );
  }

  /**
   * Path part for operation closeApplication
   */
  static readonly CloseApplicationPath = '/api/v1/Applications/{id}/Close';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `closeApplication()` instead.
   *
   * This method doesn't expect any request body.
   */
  closeApplication$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Application>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.CloseApplicationPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Application>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `closeApplication$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  closeApplication(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Application> {

    return this.closeApplication$Response(params,context).pipe(
      map((r: StrictHttpResponse<Application>) => r.body as Application)
    );
  }

  /**
   * Path part for operation getBorrowerAdvisoryReport
   */
  static readonly GetBorrowerAdvisoryReportPath = '/api/v1/Applications/{id}/Borrowers/{borrowerId}/AdvisoryReport';

  /**
   * Returns a advisory report for the specific borrower in pdf format can be downloaded.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBorrowerAdvisoryReport()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBorrowerAdvisoryReport$Response(params: {
    id: string;
    borrowerId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PublicAccess>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.GetBorrowerAdvisoryReportPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('borrowerId', params.borrowerId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PublicAccess>;
      })
    );
  }

  /**
   * Returns a advisory report for the specific borrower in pdf format can be downloaded.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBorrowerAdvisoryReport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBorrowerAdvisoryReport(params: {
    id: string;
    borrowerId: string;
  },
  context?: HttpContext

): Observable<PublicAccess> {

    return this.getBorrowerAdvisoryReport$Response(params,context).pipe(
      map((r: StrictHttpResponse<PublicAccess>) => r.body as PublicAccess)
    );
  }

  /**
   * Path part for operation matchApplicationDocuments
   */
  static readonly MatchApplicationDocumentsPath = '/api/v1/Applications/{id}/MatchedDocuments';

  /**
   * Looks for matching documents connected to the applicatíon's entitites based on the provided parameters.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `matchApplicationDocuments()` instead.
   *
   * This method doesn't expect any request body.
   */
  matchApplicationDocuments$Response(params: {

    /**
     * The id of the application's data to look in
     */
    id: string;

    /**
     * Additional search term to filter out documents
     */
    term?: string;

    /**
     * The document type name
     */
    documentTypeName?: string;

    /**
     * The description of the customer (e.g. name)
     */
    customerName?: string;

    /**
     * The description of the building (e.g. address)
     */
    buildingName?: string;

    /**
     * The number of results that should be returned
     */
    count?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Documents>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.MatchApplicationDocumentsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('term', params.term, {});
      rb.query('documentTypeName', params.documentTypeName, {});
      rb.query('customerName', params.customerName, {});
      rb.query('buildingName', params.buildingName, {});
      rb.query('count', params.count, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Documents>;
      })
    );
  }

  /**
   * Looks for matching documents connected to the applicatíon's entitites based on the provided parameters.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `matchApplicationDocuments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  matchApplicationDocuments(params: {

    /**
     * The id of the application's data to look in
     */
    id: string;

    /**
     * Additional search term to filter out documents
     */
    term?: string;

    /**
     * The document type name
     */
    documentTypeName?: string;

    /**
     * The description of the customer (e.g. name)
     */
    customerName?: string;

    /**
     * The description of the building (e.g. address)
     */
    buildingName?: string;

    /**
     * The number of results that should be returned
     */
    count?: number;
  },
  context?: HttpContext

): Observable<Documents> {

    return this.matchApplicationDocuments$Response(params,context).pipe(
      map((r: StrictHttpResponse<Documents>) => r.body as Documents)
    );
  }

  /**
   * Path part for operation getApplicationDocuments
   */
  static readonly GetApplicationDocumentsPath = '/api/v1/Applications/{id}/Documents';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApplicationDocuments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationDocuments$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Document>>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.GetApplicationDocumentsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Document>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getApplicationDocuments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationDocuments(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<Document>> {

    return this.getApplicationDocuments$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Document>>) => r.body as Array<Document>)
    );
  }

  /**
   * Path part for operation putApplicationDocument
   */
  static readonly PutApplicationDocumentPath = '/api/v1/Applications/{id}/Documents/{documentId}';

  /**
   * Associates a document with this application.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putApplicationDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  putApplicationDocument$Response(params: {
    id: string;
    documentId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Document>>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.PutApplicationDocumentPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('documentId', params.documentId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Document>>;
      })
    );
  }

  /**
   * Associates a document with this application.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `putApplicationDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  putApplicationDocument(params: {
    id: string;
    documentId: string;
  },
  context?: HttpContext

): Observable<Array<Document>> {

    return this.putApplicationDocument$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Document>>) => r.body as Array<Document>)
    );
  }

  /**
   * Path part for operation deleteApplicationDocument
   */
  static readonly DeleteApplicationDocumentPath = '/api/v1/Applications/{id}/Documents/{documentId}';

  /**
   * Removes the assocation of a document with this application.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteApplicationDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteApplicationDocument$Response(params: {
    id: string;
    documentId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Document>>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.DeleteApplicationDocumentPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('documentId', params.documentId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Document>>;
      })
    );
  }

  /**
   * Removes the assocation of a document with this application.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteApplicationDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteApplicationDocument(params: {
    id: string;
    documentId: string;
  },
  context?: HttpContext

): Observable<Array<Document>> {

    return this.deleteApplicationDocument$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Document>>) => r.body as Array<Document>)
    );
  }

  /**
   * Path part for operation getRequiredDocuments
   */
  static readonly GetRequiredDocumentsPath = '/api/v1/Applications/{id}/RequiredDocuments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRequiredDocuments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRequiredDocuments$Response(params: {
    id: string;
    count?: number;
    page?: number;
    phases?: Array<'TermSheet' | 'LoanAgreement' | 'Closing' | 'MoneyTransfer' | 'MortgageDeedDraft' | 'Finalizing'>;
    statuses?: Array<'Open' | 'Requested' | 'Received' | 'Accepted' | 'Rejected' | 'Matched' | 'ReceivedByLender' | 'AcceptedByLender' | 'RejectedByLender'>;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<RequiredDocuments>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.GetRequiredDocumentsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('count', params.count, {});
      rb.query('page', params.page, {});
      rb.query('phases', params.phases, {});
      rb.query('statuses', params.statuses, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RequiredDocuments>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRequiredDocuments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRequiredDocuments(params: {
    id: string;
    count?: number;
    page?: number;
    phases?: Array<'TermSheet' | 'LoanAgreement' | 'Closing' | 'MoneyTransfer' | 'MortgageDeedDraft' | 'Finalizing'>;
    statuses?: Array<'Open' | 'Requested' | 'Received' | 'Accepted' | 'Rejected' | 'Matched' | 'ReceivedByLender' | 'AcceptedByLender' | 'RejectedByLender'>;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<RequiredDocuments> {

    return this.getRequiredDocuments$Response(params,context).pipe(
      map((r: StrictHttpResponse<RequiredDocuments>) => r.body as RequiredDocuments)
    );
  }

  /**
   * Path part for operation addRequiredDocument
   */
  static readonly AddRequiredDocumentPath = '/api/v1/Applications/{id}/RequiredDocuments';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addRequiredDocument()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addRequiredDocument$Response(params: {
    id: string;
    body?: NewRequiredDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<RequiredDocument>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.AddRequiredDocumentPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RequiredDocument>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addRequiredDocument$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addRequiredDocument(params: {
    id: string;
    body?: NewRequiredDocument
  },
  context?: HttpContext

): Observable<RequiredDocument> {

    return this.addRequiredDocument$Response(params,context).pipe(
      map((r: StrictHttpResponse<RequiredDocument>) => r.body as RequiredDocument)
    );
  }

  /**
   * Path part for operation requestRequiredDocuments
   */
  static readonly RequestRequiredDocumentsPath = '/api/v1/Applications/{id}/RequiredDocuments/Request';

  /**
   * Request the required documents.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `requestRequiredDocuments()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  requestRequiredDocuments$Response(params: {
    id: string;
    body?: RequestRequiredDocuments
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.RequestRequiredDocumentsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Request the required documents.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `requestRequiredDocuments$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  requestRequiredDocuments(params: {
    id: string;
    body?: RequestRequiredDocuments
  },
  context?: HttpContext

): Observable<void> {

    return this.requestRequiredDocuments$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation zipRequiredDocuments
   */
  static readonly ZipRequiredDocumentsPath = '/api/v1/Applications/{id}/RequiredDocuments/Zip';

  /**
   * Zips the uploaded required documents.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `zipRequiredDocuments()` instead.
   *
   * This method doesn't expect any request body.
   */
  zipRequiredDocuments$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PublicAccess>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.ZipRequiredDocumentsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PublicAccess>;
      })
    );
  }

  /**
   * Zips the uploaded required documents.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `zipRequiredDocuments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  zipRequiredDocuments(params: {
    id: string;
  },
  context?: HttpContext

): Observable<PublicAccess> {

    return this.zipRequiredDocuments$Response(params,context).pipe(
      map((r: StrictHttpResponse<PublicAccess>) => r.body as PublicAccess)
    );
  }

  /**
   * Path part for operation deleteRequiredDocument
   */
  static readonly DeleteRequiredDocumentPath = '/api/v1/Applications/{id}/RequiredDocuments/{requiredDocumentId}';

  /**
   * Deletes a document.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteRequiredDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteRequiredDocument$Response(params: {
    id: string;
    requiredDocumentId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.DeleteRequiredDocumentPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('requiredDocumentId', params.requiredDocumentId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Deletes a document.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteRequiredDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteRequiredDocument(params: {
    id: string;
    requiredDocumentId: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.deleteRequiredDocument$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation patchRequiredDocument
   */
  static readonly PatchRequiredDocumentPath = '/api/v1/Applications/{id}/RequiredDocuments/{requiredDocumentId}';

  /**
   * Patch a document to change properties.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchRequiredDocument()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchRequiredDocument$Response(params: {

    /**
     * Id of the application
     */
    id: string;

    /**
     * The id of the required document to patch
     */
    requiredDocumentId: string;

    /**
     * Details of the operation in JSON patch format
     */
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<RequiredDocument>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.PatchRequiredDocumentPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('requiredDocumentId', params.requiredDocumentId, {});
      rb.body(params.body, 'application/json-patch+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RequiredDocument>;
      })
    );
  }

  /**
   * Patch a document to change properties.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchRequiredDocument$Response()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchRequiredDocument(params: {

    /**
     * Id of the application
     */
    id: string;

    /**
     * The id of the required document to patch
     */
    requiredDocumentId: string;

    /**
     * Details of the operation in JSON patch format
     */
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<RequiredDocument> {

    return this.patchRequiredDocument$Response(params,context).pipe(
      map((r: StrictHttpResponse<RequiredDocument>) => r.body as RequiredDocument)
    );
  }

  /**
   * Path part for operation acceptRequiredDocument
   */
  static readonly AcceptRequiredDocumentPath = '/api/v1/Applications/{id}/RequiredDocuments/{requiredDocumentId}/Accept';

  /**
   * Accepts the content of the document.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `acceptRequiredDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  acceptRequiredDocument$Response(params: {

    /**
     * The id of the application
     */
    id: string;

    /**
     * The id of the required document to patch
     */
    requiredDocumentId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<RequiredDocument>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.AcceptRequiredDocumentPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('requiredDocumentId', params.requiredDocumentId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RequiredDocument>;
      })
    );
  }

  /**
   * Accepts the content of the document.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `acceptRequiredDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  acceptRequiredDocument(params: {

    /**
     * The id of the application
     */
    id: string;

    /**
     * The id of the required document to patch
     */
    requiredDocumentId: string;
  },
  context?: HttpContext

): Observable<RequiredDocument> {

    return this.acceptRequiredDocument$Response(params,context).pipe(
      map((r: StrictHttpResponse<RequiredDocument>) => r.body as RequiredDocument)
    );
  }

  /**
   * Path part for operation registerRequiredDocument
   */
  static readonly RegisterRequiredDocumentPath = '/api/v1/Applications/{id}/RequiredDocuments/{requiredDocumentId}/Register';

  /**
   * Registers a document that has been uploaded to the document storage into the organizations document collection.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registerRequiredDocument()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  registerRequiredDocument$Response(params: {

    /**
     * The id of the application
     */
    id: string;

    /**
     * The id of the required document
     */
    requiredDocumentId: string;

    /**
     * The document details to be registered
     */
    body?: RegisterDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<RequiredDocument>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.RegisterRequiredDocumentPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('requiredDocumentId', params.requiredDocumentId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RequiredDocument>;
      })
    );
  }

  /**
   * Registers a document that has been uploaded to the document storage into the organizations document collection.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `registerRequiredDocument$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  registerRequiredDocument(params: {

    /**
     * The id of the application
     */
    id: string;

    /**
     * The id of the required document
     */
    requiredDocumentId: string;

    /**
     * The document details to be registered
     */
    body?: RegisterDocument
  },
  context?: HttpContext

): Observable<RequiredDocument> {

    return this.registerRequiredDocument$Response(params,context).pipe(
      map((r: StrictHttpResponse<RequiredDocument>) => r.body as RequiredDocument)
    );
  }

  /**
   * Path part for operation setRequiredDocumentDocument
   */
  static readonly SetRequiredDocumentDocumentPath = '/api/v1/Applications/{id}/RequiredDocuments/{requiredDocumentId}/Document';

  /**
   * Sets a document that has been found from the current document system.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setRequiredDocumentDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  setRequiredDocumentDocument$Response(params: {

    /**
     * The id of the application
     */
    id: string;

    /**
     * The id of the required document
     */
    requiredDocumentId: string;

    /**
     * The document id to be set
     */
    documentId?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<RequiredDocument>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.SetRequiredDocumentDocumentPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('requiredDocumentId', params.requiredDocumentId, {});
      rb.query('documentId', params.documentId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RequiredDocument>;
      })
    );
  }

  /**
   * Sets a document that has been found from the current document system.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setRequiredDocumentDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  setRequiredDocumentDocument(params: {

    /**
     * The id of the application
     */
    id: string;

    /**
     * The id of the required document
     */
    requiredDocumentId: string;

    /**
     * The document id to be set
     */
    documentId?: string;
  },
  context?: HttpContext

): Observable<RequiredDocument> {

    return this.setRequiredDocumentDocument$Response(params,context).pipe(
      map((r: StrictHttpResponse<RequiredDocument>) => r.body as RequiredDocument)
    );
  }

  /**
   * Path part for operation rejectRequiredDocument
   */
  static readonly RejectRequiredDocumentPath = '/api/v1/Applications/{id}/RequiredDocuments/{requiredDocumentId}/Reject';

  /**
   * Rejects the content of the document.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `rejectRequiredDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  rejectRequiredDocument$Response(params: {

    /**
     * The id of the application
     */
    id: string;

    /**
     * The id of the document to patch
     */
    requiredDocumentId: string;

    /**
     * The reason for rejection
     */
    reason?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<RequiredDocument>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.RejectRequiredDocumentPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('requiredDocumentId', params.requiredDocumentId, {});
      rb.query('reason', params.reason, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RequiredDocument>;
      })
    );
  }

  /**
   * Rejects the content of the document.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `rejectRequiredDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  rejectRequiredDocument(params: {

    /**
     * The id of the application
     */
    id: string;

    /**
     * The id of the document to patch
     */
    requiredDocumentId: string;

    /**
     * The reason for rejection
     */
    reason?: string;
  },
  context?: HttpContext

): Observable<RequiredDocument> {

    return this.rejectRequiredDocument$Response(params,context).pipe(
      map((r: StrictHttpResponse<RequiredDocument>) => r.body as RequiredDocument)
    );
  }

  /**
   * Path part for operation getApplicationSubmitOptions
   */
  static readonly GetApplicationSubmitOptionsPath = '/api/v1/Applications/{id}/Submit';

  /**
   * Retrieves a list of possible submit options for this application.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApplicationSubmitOptions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationSubmitOptions$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<SubmitOption>>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.GetApplicationSubmitOptionsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SubmitOption>>;
      })
    );
  }

  /**
   * Retrieves a list of possible submit options for this application.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getApplicationSubmitOptions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationSubmitOptions(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<SubmitOption>> {

    return this.getApplicationSubmitOptions$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<SubmitOption>>) => r.body as Array<SubmitOption>)
    );
  }

  /**
   * Path part for operation submitApplication
   */
  static readonly SubmitApplicationPath = '/api/v1/Applications/{id}/Submit';

  /**
   * Submits the application based on the provided option.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `submitApplication()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  submitApplication$Response(params: {
    id: string;
    body?: SubmitData
  },
  context?: HttpContext

): Observable<StrictHttpResponse<SubmitResult>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.SubmitApplicationPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SubmitResult>;
      })
    );
  }

  /**
   * Submits the application based on the provided option.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `submitApplication$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  submitApplication(params: {
    id: string;
    body?: SubmitData
  },
  context?: HttpContext

): Observable<SubmitResult> {

    return this.submitApplication$Response(params,context).pipe(
      map((r: StrictHttpResponse<SubmitResult>) => r.body as SubmitResult)
    );
  }

  /**
   * Path part for operation getApplicationTotals
   */
  static readonly GetApplicationTotalsPath = '/api/v1/ApplicationTotals';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApplicationTotals()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationTotals$Response(params?: {
    statuses?: Array<'Consult' | 'CollectingInformation' | 'Writing' | 'TermSheetRequested' | 'TermSheetReceived' | 'TermSheetApproved' | 'CollectingAdditionalInformation' | 'AligningConditions' | 'WritingAdvisoryReport' | 'LoanAgreementRequested' | 'LoanAgreementReceived' | 'LoanAgreementApproved' | 'DocumentsAtTheNotary' | 'MortgagePassed' | 'Rejected' | 'Closed' | 'Archived'>;
    adviserId?: string;
    lenderId?: string;
    hasPipedriveDeal?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ApplicationTotals>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.GetApplicationTotalsPath, 'get');
    if (params) {
      rb.query('statuses', params.statuses, {});
      rb.query('adviserId', params.adviserId, {});
      rb.query('lenderId', params.lenderId, {});
      rb.query('hasPipedriveDeal', params.hasPipedriveDeal, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationTotals>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getApplicationTotals$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationTotals(params?: {
    statuses?: Array<'Consult' | 'CollectingInformation' | 'Writing' | 'TermSheetRequested' | 'TermSheetReceived' | 'TermSheetApproved' | 'CollectingAdditionalInformation' | 'AligningConditions' | 'WritingAdvisoryReport' | 'LoanAgreementRequested' | 'LoanAgreementReceived' | 'LoanAgreementApproved' | 'DocumentsAtTheNotary' | 'MortgagePassed' | 'Rejected' | 'Closed' | 'Archived'>;
    adviserId?: string;
    lenderId?: string;
    hasPipedriveDeal?: boolean;
  },
  context?: HttpContext

): Observable<ApplicationTotals> {

    return this.getApplicationTotals$Response(params,context).pipe(
      map((r: StrictHttpResponse<ApplicationTotals>) => r.body as ApplicationTotals)
    );
  }

  /**
   * Path part for operation getApplicationTypes
   */
  static readonly GetApplicationTypesPath = '/api/v1/ApplicationTypes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApplicationTypes$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationTypes$Plain$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ApplicationType>>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.GetApplicationTypesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ApplicationType>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getApplicationTypes$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationTypes$Plain(params?: {
  },
  context?: HttpContext

): Observable<Array<ApplicationType>> {

    return this.getApplicationTypes$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ApplicationType>>) => r.body as Array<ApplicationType>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApplicationTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationTypes$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ApplicationType>>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.GetApplicationTypesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ApplicationType>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getApplicationTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationTypes(params?: {
  },
  context?: HttpContext

): Observable<Array<ApplicationType>> {

    return this.getApplicationTypes$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ApplicationType>>) => r.body as Array<ApplicationType>)
    );
  }

  /**
   * Path part for operation addApplicationType
   */
  static readonly AddApplicationTypePath = '/api/v1/ApplicationTypes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addApplicationType$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addApplicationType$Plain$Response(params?: {
    body?: ApplicationType
  },
  context?: HttpContext

): Observable<StrictHttpResponse<IntId>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.AddApplicationTypePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IntId>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addApplicationType$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addApplicationType$Plain(params?: {
    body?: ApplicationType
  },
  context?: HttpContext

): Observable<IntId> {

    return this.addApplicationType$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<IntId>) => r.body as IntId)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addApplicationType()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addApplicationType$Response(params?: {
    body?: ApplicationType
  },
  context?: HttpContext

): Observable<StrictHttpResponse<IntId>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.AddApplicationTypePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IntId>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addApplicationType$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addApplicationType(params?: {
    body?: ApplicationType
  },
  context?: HttpContext

): Observable<IntId> {

    return this.addApplicationType$Response(params,context).pipe(
      map((r: StrictHttpResponse<IntId>) => r.body as IntId)
    );
  }

}
