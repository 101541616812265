import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '@app/material.module';

import { HeaderComponent } from './header/header.component';
import { ShellGuard } from './shell.guard';
import { SharedModule } from '@app/shared/shared.module';
import { ShellComponent } from './shell.component';
import { ShellSidenavService } from './shell-sidenav.service';
import { ShellLoaderService } from './shell-loader.service';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FlexLayoutModule,
    MaterialModule,
    SharedModule,
    RouterModule
  ],
  declarations: [
    HeaderComponent,
    ShellComponent
  ], providers: [
    ShellSidenavService,
    ShellLoaderService,
    ShellGuard,
  ]
})
export class ShellModule {
  static forRoot(): ModuleWithProviders<ShellModule> {
    return {
      ngModule: ShellModule,
      providers: [
        ShellSidenavService,
        ShellLoaderService,
      ]
    };
  }
}
