<mat-form-field fxFlex [appearance]="appearance" [class]="class"
[ngClass]="{ 'mat-form-field-invalid' : showValidationError(), 'ng-invalid' : showValidationError() , 'nationality-input-none': appearance === 'none' }">
<mat-label *ngIf="label">{{label}}</mat-label>
  <mat-spinner *ngIf="isLoadingNationalities === true" matSuffix diameter="14" class="mr1"></mat-spinner>
  <span class="placeholder" *ngIf="showPlaceholder">
    {{control.value != null ? control.value.name : "..."}}
  </span>
  <input [formControl]="control" type="text" matInput [matAutocomplete]="nationalityAutoComplete"
     [attr.cdkFocusInitial]="focus !== false ? 'true' : null" autocomplete="off">
  <mat-autocomplete autoActiveFirstOption #nationalityAutoComplete="matAutocomplete"
    [displayWith]="displayWithNationality">
    <mat-option [value]="null">
      <span translate>None</span>
    </mat-option>
    <mat-option *ngFor="let nationality of nationalitiesFiltered" [value]="nationality">
      {{nationality.name}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>