import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { StingrayAuthenticationService } from '@app/core/authentication/stingray-authentication.service';
import { I18nService } from '@app/core/i18n.service';
import { Customer } from '@app/core/stingray-api/models';
import { CreateDialogComponent } from '@app/shared/create-customer/create-dialog/create-dialog.component';
import { ShellSidenavService } from '../shell-sidenav.service';
import { Shell } from '../shell.service';
import { Router } from '@angular/router';
import { PortalService } from '@app/core/portal/portal.service';

@Component({
  selector: 'unite-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  hasLeftSidenav = false;
  hasPortal = false;
  subscription = '';
  isProfessional = false;

  isCombinedProposalsEnabledAccount = false;
  userIsAdministrator = false;

  constructor(
    private router: Router,
    private matDialog: MatDialog,
    private i18nService: I18nService,
    private shellSidenavService: ShellSidenavService,
    private portalService: PortalService,
    private stingrayAuthenticationService: StingrayAuthenticationService) { }

  get currentLanguage(): string {
    return this.i18nService.language;
  }

  get languages(): string[] {
    return this.i18nService.supportedLanguages;
  }

  get loginName(): string {
    const user = this.stingrayAuthenticationService.user;
    const name = this.stingrayAuthenticationService.user ? `${user.initials} ${user.middleName} ${user.surname}` : '';
    return name;
  }

  ngOnInit(): void {
    this.shellSidenavService.getHasLeftSidenav().subscribe((hasLeftSidenav: boolean) => {
      this.hasLeftSidenav = hasLeftSidenav;
    });

    this.subscription = this.stingrayAuthenticationService.organization.subscription;
    this.isProfessional = this.stingrayAuthenticationService.organization.subscription === 'Professional';
    this.hasPortal = this.portalService.hasPortal && this.stingrayAuthenticationService.organization.subscription === 'Professional';
    this.isCombinedProposalsEnabledAccount = this.stingrayAuthenticationService.isCombinedProposalsEnabledAccount();
    this.userIsAdministrator = this.stingrayAuthenticationService.user.administrator;
  }

  logout(): void {
    this.stingrayAuthenticationService.logout();
  }

  setLanguage(language: string): void {
    this.i18nService.language = language;
  }

  openCreateCustomerDialog(): void {
    Shell.addDialog<CreateDialogComponent, null, Customer>(this.matDialog, CreateDialogComponent, null)
      .subscribe((customer: Customer) => {
        if (customer) {
          this.router.navigate(['customers', customer.id, 'detail']);
        }
      });
  }

  toggleGeneralSideNav(): void {
    this.shellSidenavService.toggleGeneralSidenav();
  }

  toggleLeftSideNav(): void {
    this.shellSidenavService.toggleLeftSidenav();
  }

}
