/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { AccountsService } from './services/accounts.service';
import { AddressesService } from './services/addresses.service';
import { ApplicationsService } from './services/applications.service';
import { AuditLogsService } from './services/audit-logs.service';
import { BuildingInfoService } from './services/building-info.service';
import { BuildingsService } from './services/buildings.service';
import { CollaborationsService } from './services/collaborations.service';
import { CompaniesService } from './services/companies.service';
import { CompanyInfoService } from './services/company-info.service';
import { CountriesService } from './services/countries.service';
import { CustomersService } from './services/customers.service';
import { DocumentsService } from './services/documents.service';
import { FinancingsService } from './services/financings.service';
import { LendersService } from './services/lenders.service';
import { MortgageInfoService } from './services/mortgage-info.service';
import { MortgagesService } from './services/mortgages.service';
import { NationalitiesService } from './services/nationalities.service';
import { NotariesService } from './services/notaries.service';
import { OrganizationsService } from './services/organizations.service';
import { PersonsService } from './services/persons.service';
import { ProspectsService } from './services/prospects.service';
import { PublicAccessService } from './services/public-access.service';
import { RatesService } from './services/rates.service';
import { RentalContractsService } from './services/rental-contracts.service';
import { ReportsService } from './services/reports.service';
import { SearchService } from './services/search.service';
import { TasksService } from './services/tasks.service';
import { TenantsService } from './services/tenants.service';
import { ValuationsService } from './services/valuations.service';
import { ValuatorsService } from './services/valuators.service';
import { VirService } from './services/vir.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    AccountsService,
    AddressesService,
    ApplicationsService,
    AuditLogsService,
    BuildingInfoService,
    BuildingsService,
    CollaborationsService,
    CompaniesService,
    CompanyInfoService,
    CountriesService,
    CustomersService,
    DocumentsService,
    FinancingsService,
    LendersService,
    MortgageInfoService,
    MortgagesService,
    NationalitiesService,
    NotariesService,
    OrganizationsService,
    PersonsService,
    ProspectsService,
    PublicAccessService,
    RatesService,
    RentalContractsService,
    ReportsService,
    SearchService,
    TasksService,
    TenantsService,
    ValuationsService,
    ValuatorsService,
    VirService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
