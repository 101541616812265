import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { StingrayAuthenticationService } from '@app/core/authentication/stingray-authentication.service';
import { BusinessUnit } from '@app/core/stingray-api/models';
import { OrganizationsService } from '@app/core/stingray-api/services';

@Component({
  selector: 'unite-businessunit-input',
  templateUrl: './businessunit-input.component.html',
  styleUrls: ['./businessunit-input.component.scss']
})
export class BusinessUnitInputComponent implements OnInit {

  @Input() control: FormControl;

  businessUnits: BusinessUnit[] = [];

  constructor(private stingrayAuthenticationService: StingrayAuthenticationService) { }

  ngOnInit(): void {
    this.businessUnits = this.stingrayAuthenticationService.organization.businessUnits;
  }
}
