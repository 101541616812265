import { Injectable } from '@angular/core';
import { Rate } from '@app/core/stingray-api/models';
import { RatesService } from '@app/core/stingray-api/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class RateAutoCompleteService {

  constructor(private ratesService: RatesService) { }

  /**
   * Clears the session storage so any subsequent call
   * forces to retrieve the lender list from the backend
   */
  clearCache() {
    sessionStorage.removeItem('rateAutoComplete');
  }

  getRates(): Observable<Rate[]> {
    return this.ratesService.getRates().pipe(
      map((rates: Rate[]) => {
        return rates;
      })
    );
    //}
  }

}
