import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Country } from '@app/core/stingray-api/models';
import { CountriesService } from '@app/core/stingray-api/services';


@Injectable()
export class CountryAutoCompleteService {

  constructor(private countriesService: CountriesService) { }

  getCountries(): Observable<Country[]> {
    const cache = sessionStorage.getItem('countryAutoComplete');
    if (cache) {
      return of(JSON.parse(cache));
    } else {
      return this.countriesService.getCountries().pipe(map((countries: Country[]) => {
        sessionStorage.setItem('countryAutoComplete', JSON.stringify(countries));
        return countries;
      }));
    }
  }

}
