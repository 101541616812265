<div fxLayout="row">
  <mat-icon [ngSwitch]="toast.type">
    <ng-container *ngSwitchCase="toastType.info">
      info
    </ng-container>
    <ng-container *ngSwitchCase="toastType.success">
      check
    </ng-container>
    <ng-container *ngSwitchCase="toastType.warning">
      warning
    </ng-container>
    <ng-container *ngSwitchCase="toastType.error">
      error
    </ng-container>
  </mat-icon>
  <span class="mat-body-1 bold ml1">{{toast.message}}</span>
</div>