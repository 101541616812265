/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Building } from '../models/building';
import { Buildings } from '../models/buildings';
import { Customers } from '../models/customers';
import { Guarantors } from '../models/guarantors';
import { Limits } from '../models/limits';
import { LoanPart } from '../models/loan-part';
import { LoanParts } from '../models/loan-parts';
import { Mortgage } from '../models/mortgage';
import { Terms } from '../models/terms';

@Injectable({
  providedIn: 'root',
})
export class MortgagesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getMortgageById
   */
  static readonly GetMortgageByIdPath = '/api/v1/Mortgages/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMortgageById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageById$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Mortgage>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.GetMortgageByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Mortgage>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMortgageById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageById$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Mortgage> {

    return this.getMortgageById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Mortgage>) => r.body as Mortgage)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMortgageById$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageById$Json$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Mortgage>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.GetMortgageByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Mortgage>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMortgageById$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageById$Json(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Mortgage> {

    return this.getMortgageById$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Mortgage>) => r.body as Mortgage)
    );
  }

  /**
   * Path part for operation getMortgageBorrowers
   */
  static readonly GetMortgageBorrowersPath = '/api/v1/Mortgages/{id}/Borrowers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMortgageBorrowers$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageBorrowers$Plain$Response(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Customers>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.GetMortgageBorrowersPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Customers>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMortgageBorrowers$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageBorrowers$Plain(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Customers> {

    return this.getMortgageBorrowers$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Customers>) => r.body as Customers)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMortgageBorrowers$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageBorrowers$Json$Response(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Customers>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.GetMortgageBorrowersPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Customers>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMortgageBorrowers$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageBorrowers$Json(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Customers> {

    return this.getMortgageBorrowers$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Customers>) => r.body as Customers)
    );
  }

  /**
   * Path part for operation getMortgageBuildings
   */
  static readonly GetMortgageBuildingsPath = '/api/v1/Mortgages/{id}/Buildings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMortgageBuildings$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageBuildings$Plain$Response(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Buildings>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.GetMortgageBuildingsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Buildings>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMortgageBuildings$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageBuildings$Plain(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Buildings> {

    return this.getMortgageBuildings$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Buildings>) => r.body as Buildings)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMortgageBuildings$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageBuildings$Json$Response(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Buildings>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.GetMortgageBuildingsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Buildings>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMortgageBuildings$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageBuildings$Json(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Buildings> {

    return this.getMortgageBuildings$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Buildings>) => r.body as Buildings)
    );
  }

  /**
   * Path part for operation getMortgageBuildingById
   */
  static readonly GetMortgageBuildingByIdPath = '/api/v1/Mortgages/{id}/Buildings/{buildingId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMortgageBuildingById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageBuildingById$Plain$Response(params: {
    id: string;
    buildingId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Building>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.GetMortgageBuildingByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('buildingId', params.buildingId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Building>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMortgageBuildingById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageBuildingById$Plain(params: {
    id: string;
    buildingId: string;
  },
  context?: HttpContext

): Observable<Building> {

    return this.getMortgageBuildingById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Building>) => r.body as Building)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMortgageBuildingById$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageBuildingById$Json$Response(params: {
    id: string;
    buildingId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Building>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.GetMortgageBuildingByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('buildingId', params.buildingId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Building>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMortgageBuildingById$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageBuildingById$Json(params: {
    id: string;
    buildingId: string;
  },
  context?: HttpContext

): Observable<Building> {

    return this.getMortgageBuildingById$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Building>) => r.body as Building)
    );
  }

  /**
   * Path part for operation getMortgageGuarantors
   */
  static readonly GetMortgageGuarantorsPath = '/api/v1/Mortgages/{id}/Guarantors';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMortgageGuarantors$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageGuarantors$Plain$Response(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Guarantors>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.GetMortgageGuarantorsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Guarantors>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMortgageGuarantors$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageGuarantors$Plain(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Guarantors> {

    return this.getMortgageGuarantors$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Guarantors>) => r.body as Guarantors)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMortgageGuarantors$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageGuarantors$Json$Response(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Guarantors>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.GetMortgageGuarantorsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Guarantors>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMortgageGuarantors$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageGuarantors$Json(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Guarantors> {

    return this.getMortgageGuarantors$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Guarantors>) => r.body as Guarantors)
    );
  }

  /**
   * Path part for operation getMortgageLoanParts
   */
  static readonly GetMortgageLoanPartsPath = '/api/v1/Mortgages/{id}/LoanParts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMortgageLoanParts$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageLoanParts$Plain$Response(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<LoanParts>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.GetMortgageLoanPartsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoanParts>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMortgageLoanParts$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageLoanParts$Plain(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<LoanParts> {

    return this.getMortgageLoanParts$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<LoanParts>) => r.body as LoanParts)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMortgageLoanParts$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageLoanParts$Json$Response(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<LoanParts>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.GetMortgageLoanPartsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoanParts>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMortgageLoanParts$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageLoanParts$Json(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<LoanParts> {

    return this.getMortgageLoanParts$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<LoanParts>) => r.body as LoanParts)
    );
  }

  /**
   * Path part for operation getMortgageLoanPartById
   */
  static readonly GetMortgageLoanPartByIdPath = '/api/v1/Mortgages/{id}/LoanParts/{loanPartId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMortgageLoanPartById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageLoanPartById$Plain$Response(params: {
    id: string;
    loanPartId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<LoanPart>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.GetMortgageLoanPartByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('loanPartId', params.loanPartId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoanPart>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMortgageLoanPartById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageLoanPartById$Plain(params: {
    id: string;
    loanPartId: string;
  },
  context?: HttpContext

): Observable<LoanPart> {

    return this.getMortgageLoanPartById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<LoanPart>) => r.body as LoanPart)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMortgageLoanPartById$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageLoanPartById$Json$Response(params: {
    id: string;
    loanPartId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<LoanPart>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.GetMortgageLoanPartByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('loanPartId', params.loanPartId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoanPart>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMortgageLoanPartById$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageLoanPartById$Json(params: {
    id: string;
    loanPartId: string;
  },
  context?: HttpContext

): Observable<LoanPart> {

    return this.getMortgageLoanPartById$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<LoanPart>) => r.body as LoanPart)
    );
  }

  /**
   * Path part for operation getMortgageLoanPartBuildingsById
   */
  static readonly GetMortgageLoanPartBuildingsByIdPath = '/api/v1/Mortgages/{id}/LoanParts/{loanPartId}/Buildings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMortgageLoanPartBuildingsById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageLoanPartBuildingsById$Plain$Response(params: {
    id: string;
    loanPartId: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Buildings>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.GetMortgageLoanPartBuildingsByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('loanPartId', params.loanPartId, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Buildings>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMortgageLoanPartBuildingsById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageLoanPartBuildingsById$Plain(params: {
    id: string;
    loanPartId: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Buildings> {

    return this.getMortgageLoanPartBuildingsById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Buildings>) => r.body as Buildings)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMortgageLoanPartBuildingsById$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageLoanPartBuildingsById$Json$Response(params: {
    id: string;
    loanPartId: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Buildings>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.GetMortgageLoanPartBuildingsByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('loanPartId', params.loanPartId, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Buildings>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMortgageLoanPartBuildingsById$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageLoanPartBuildingsById$Json(params: {
    id: string;
    loanPartId: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Buildings> {

    return this.getMortgageLoanPartBuildingsById$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Buildings>) => r.body as Buildings)
    );
  }

  /**
   * Path part for operation getMortgageLimits
   */
  static readonly GetMortgageLimitsPath = '/api/v1/Mortgages/{id}/Limits';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMortgageLimits$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageLimits$Plain$Response(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Limits>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.GetMortgageLimitsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Limits>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMortgageLimits$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageLimits$Plain(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Limits> {

    return this.getMortgageLimits$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Limits>) => r.body as Limits)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMortgageLimits$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageLimits$Json$Response(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Limits>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.GetMortgageLimitsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Limits>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMortgageLimits$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageLimits$Json(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Limits> {

    return this.getMortgageLimits$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Limits>) => r.body as Limits)
    );
  }

  /**
   * Path part for operation getMortgageTerms
   */
  static readonly GetMortgageTermsPath = '/api/v1/Mortgages/{id}/Terms';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMortgageTerms$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageTerms$Plain$Response(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Terms>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.GetMortgageTermsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Terms>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMortgageTerms$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageTerms$Plain(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Terms> {

    return this.getMortgageTerms$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Terms>) => r.body as Terms)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMortgageTerms$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageTerms$Json$Response(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Terms>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.GetMortgageTermsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Terms>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMortgageTerms$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageTerms$Json(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Terms> {

    return this.getMortgageTerms$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Terms>) => r.body as Terms)
    );
  }

}
