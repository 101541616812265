import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { StingrayAuthenticationService } from '@app/core/authentication/stingray-authentication.service';
import { I18nService } from '@app/core/i18n.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'unite-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  error: string;
  loginForm: UntypedFormGroup;
  isLoading = false;

  constructor(
    private title: Title,
    private translateService: TranslateService,
    private i18nService: I18nService,
    private stingrayAuthenticationService: StingrayAuthenticationService) {
    this.title.setTitle('Unite - ' + this.translateService.instant('Login'));
  }

  get currentLanguage(): string {
    return this.i18nService.language;
  }

  get languages(): string[] {
    return this.i18nService.supportedLanguages;
  }

  login(): void {
    this.isLoading = true;
    this.stingrayAuthenticationService.login();
  }

  setLanguage(language: string): void {
    this.i18nService.language = language;
  }
}
