import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';

export interface Toast {
  type: ToastType;
  message: string;
}

export enum ToastType {
  info = 'info',
  success = 'success',
  warning = 'warning',
  error = 'error',
}

@Injectable()
export class ToasterService {

  private subject = new Subject<Toast>();

  constructor(private readonly translate: TranslateService) {

  }

  getToast(): Observable<Toast> {
    return this.subject.asObservable();
  }

  closed(message: string) {
    this.toast(message + ' ' + this.translate.instant('is closed!'), ToastType.success);
  }

  created(message: string) {
    this.toast(message + ' ' + this.translate.instant('is created!'), ToastType.success);
  }

  updated(message: string) {
    this.toast(message + ' ' + this.translate.instant('is updated!'), ToastType.success);
  }

  deleted(message: string) {
    this.toast(message + ' ' + this.translate.instant('is deleted!'), ToastType.success);
  }

  opened(message: string) {
    this.toast(message + ' ' + this.translate.instant('is opened!'), ToastType.success);
  }

  info(message: string) {
    this.toast(message, ToastType.info);
  }

  success(message: string) {
    this.toast(message, ToastType.success);
  }

  warning(message: string) {
    this.toast(message, ToastType.warning);
  }

  error(message: string) {
    this.toast(message, ToastType.error);
  }

  toast(message: string, type: ToastType = ToastType.info) {
    this.subject.next(<Toast>{ message: message, type: type });
  }

  /**
     * Clear toasts
     */
  clear() {
    this.subject.next(null);
  }

}
