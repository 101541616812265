/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Address } from '../models/address';

@Injectable({
  providedIn: 'root',
})
export class AddressesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAddresses
   */
  static readonly GetAddressesPath = '/api/v1/Addresses';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAddresses$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAddresses$Plain$Response(params?: {
    postalCode?: string;
    houseNumber?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Address>>> {

    const rb = new RequestBuilder(this.rootUrl, AddressesService.GetAddressesPath, 'get');
    if (params) {
      rb.query('postalCode', params.postalCode, {});
      rb.query('houseNumber', params.houseNumber, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Address>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAddresses$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAddresses$Plain(params?: {
    postalCode?: string;
    houseNumber?: number;
  },
  context?: HttpContext

): Observable<Array<Address>> {

    return this.getAddresses$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Address>>) => r.body as Array<Address>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAddresses()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAddresses$Response(params?: {
    postalCode?: string;
    houseNumber?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Address>>> {

    const rb = new RequestBuilder(this.rootUrl, AddressesService.GetAddressesPath, 'get');
    if (params) {
      rb.query('postalCode', params.postalCode, {});
      rb.query('houseNumber', params.houseNumber, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Address>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAddresses$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAddresses(params?: {
    postalCode?: string;
    houseNumber?: number;
  },
  context?: HttpContext

): Observable<Array<Address>> {

    return this.getAddresses$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Address>>) => r.body as Array<Address>)
    );
  }

  /**
   * Path part for operation queryAddresses
   */
  static readonly QueryAddressesPath = '/api/v1/Addresses';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `queryAddresses$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  queryAddresses$Plain$Response(params?: {
    query?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Address>>> {

    const rb = new RequestBuilder(this.rootUrl, AddressesService.QueryAddressesPath, 'post');
    if (params) {
      rb.query('query', params.query, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Address>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `queryAddresses$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  queryAddresses$Plain(params?: {
    query?: string;
  },
  context?: HttpContext

): Observable<Array<Address>> {

    return this.queryAddresses$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Address>>) => r.body as Array<Address>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `queryAddresses()` instead.
   *
   * This method doesn't expect any request body.
   */
  queryAddresses$Response(params?: {
    query?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Address>>> {

    const rb = new RequestBuilder(this.rootUrl, AddressesService.QueryAddressesPath, 'post');
    if (params) {
      rb.query('query', params.query, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Address>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `queryAddresses$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  queryAddresses(params?: {
    query?: string;
  },
  context?: HttpContext

): Observable<Array<Address>> {

    return this.queryAddresses$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Address>>) => r.body as Array<Address>)
    );
  }

}
