<mat-form-field fxFlex appearance="outline">
  <mat-label>{{label}}</mat-label>
  <button *ngIf="isLoadingUsers === false" (click)="refreshUsers(); $event.stopPropagation();" matSuffix mat-icon-button
    color="primary">
    <mat-icon>refresh</mat-icon>
  </button>
  <mat-spinner *ngIf="isLoadingUsers === true" matSuffix diameter="14" class="mr1"></mat-spinner>
  <input [formControl]="control" type="text" matInput [matAutocomplete]="userAutoComplete"
    [required]="required !== false" [attr.cdkFocusInitial]="focus !== false ? 'true' : null" autocomplete="off">
  <mat-autocomplete autoActiveFirstOption #userAutoComplete="matAutocomplete" [displayWith]="displayWithUser">
    <mat-option *ngIf="required === false" [value]="null">
      <span translate>None</span>
    </mat-option>
    <mat-option *ngFor="let user of usersFiltered" [value]="user">
      {{user.initials}}
      <ng-container *ngIf="user.middleName !== null && user.middleName.trim().length > 0">
        {{user.middleName}}</ng-container>
      {{user.surname}}</mat-option>
  </mat-autocomplete>
</mat-form-field>