<h3 class="mat-title mt2" fxLayout="row" fxLayoutAlign="start center">
  <mat-icon class="icon-small mr1">assignment_ind</mat-icon>
  <span translate>Settings</span>
</h3>

<div class="mb1">
  <mat-checkbox color="primary" [formControl]="existingControl" ><p translate>Existing client</p></mat-checkbox>
</div>

<unite-businessunit-input [control]="businessunitControl" *ngIf="businessunitControl">
</unite-businessunit-input>

<unite-user-autocomplete [label]="'Client adviser' | translate" required [control]="userControl">
</unite-user-autocomplete>
