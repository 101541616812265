import { FormBuilder, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms"
import { housenumberValidator } from "@app/validators/housenumber-validator";
import { postalcodeValidator } from "@app/validators/postalcode-validator";

export function createAddressFormGroup(formBuilder: UntypedFormBuilder): UntypedFormGroup {

    return formBuilder.group({
        street: ['', [Validators.required]],
        houseNumber: ['', [Validators.required, housenumberValidator]],
        houseNumberAddition: [],
        postalCode: ['', [Validators.required, postalcodeValidator]],
        city: ['', [Validators.required]],
        addressLine1: [''],
        province: [],
        country: [],
        municipality: [],
        district: [],
        waterAuthority: [],
        latitude: [],
        longitude: [],
        cadastralDesignations: [],
        municipalityRegisterResidenceId: [],
        municipalityRegisterPublicSpaceId: [],
        municipalityRegisterAddressId: []
    });
}

export const createAddressFormGroupTyped = (formBuilder: FormBuilder) =>
    formBuilder.group({
        street: ['', [Validators.required]],
        houseNumber: ['', [Validators.required, housenumberValidator]],
        houseNumberAddition: [],
        postalCode: ['', [Validators.required, postalcodeValidator]],
        city: ['', [Validators.required]],
        addressLine1: [''],
        province: [],
        country: [],
        municipality: [],
        district: [],
        waterAuthority: [],
        latitude: [],
        longitude: [],
        cadastralDesignations: [],
        municipalityRegisterResidenceId: [],
        municipalityRegisterPublicSpaceId: [],
        municipalityRegisterAddressId: []
    });


