import { Component, OnInit, ViewChild } from '@angular/core';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { MatSidenav } from '@angular/material/sidenav';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Event, Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { ShellLoaderService } from './shell-loader.service';
import { ShellSidenavService } from './shell-sidenav.service';
import { StingrayAuthenticationService } from '@app/core/authentication/stingray-authentication.service';

@UntilDestroy()
@Component({
  selector: 'unite-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss']
})
export class ShellComponent implements OnInit {

  @ViewChild('sidenav', { static: true }) sidenav!: MatSidenav;

  mobileQuery: MediaQueryList;
  loading = false;
  requestLoader: boolean;
  isProfessional = false;

  constructor(
    private media: MediaObserver,
    private router: Router,
    private shellLoaderService: ShellLoaderService,
    private shellSidenavService: ShellSidenavService,
    private stingrayAuthenticationService: StingrayAuthenticationService) {
  }

  ngOnInit(): void {
    this.shellSidenavService.setGeneralSidenav(this.sidenav);

    // Automatically close sidenavas on screens > sm breakpoint
    this.media.asObservable().pipe(
      untilDestroyed(this)
    ).subscribe((changes: MediaChange[]) => {
      if (changes.some(change => change.mqAlias !== 'xs' && change.mqAlias !== 'sm')) {
        this.shellSidenavService.closeGeneralSidenav();
      }

      this.shellSidenavService.openLeftSidenav();
    });

    this.router.events.pipe(
      untilDestroyed(this)
    ).subscribe((routerEvent: Event) => {
      this.checkRouterEvent(routerEvent);
    });

    this.shellLoaderService.getRequestLoader().subscribe((requestLoader: boolean) => {
      this.requestLoader = requestLoader;
    });

    this.isProfessional = this.stingrayAuthenticationService.organization.subscription === 'Professional';
  }

  checkRouterEvent(routerEvent: Event): void {
    // Navigation comes to an end
    if (routerEvent instanceof NavigationStart) {
      if (this.shellSidenavService.getLeftSidenav() !== null) {
        this.shellLoaderService.setCustomerContentLoader(true);
      } else {
        this.loading = true;
      }
    }

    // Navigation comes to an end
    if (routerEvent instanceof NavigationEnd ||
      routerEvent instanceof NavigationCancel ||
      routerEvent instanceof NavigationError) {
      if (this.shellSidenavService.getLeftSidenav() !== null) {
        this.shellLoaderService.setCustomerContentLoader(false);
      } else {
        this.loading = false;
      }
    }
  }
}
