/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Document } from '../models/document';
import { DocumentType } from '../models/document-type';
import { Documents } from '../models/documents';
import { JsonPatchDocument } from '../models/json-patch-document';
import { RegisterDocument } from '../models/register-document';

@Injectable({
  providedIn: 'root',
})
export class DocumentsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getDocument
   */
  static readonly GetDocumentPath = '/api/v1/Documents/{id}';

  /**
   * Returns a single document.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocument$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocument$Plain$Response(params: {

    /**
     * The id of the document to be fetched
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Document>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentsService.GetDocumentPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Document>;
      })
    );
  }

  /**
   * Returns a single document.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDocument$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocument$Plain(params: {

    /**
     * The id of the document to be fetched
     */
    id: string;
  },
  context?: HttpContext

): Observable<Document> {

    return this.getDocument$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Document>) => r.body as Document)
    );
  }

  /**
   * Returns a single document.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocument$Response(params: {

    /**
     * The id of the document to be fetched
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Document>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentsService.GetDocumentPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Document>;
      })
    );
  }

  /**
   * Returns a single document.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocument(params: {

    /**
     * The id of the document to be fetched
     */
    id: string;
  },
  context?: HttpContext

): Observable<Document> {

    return this.getDocument$Response(params,context).pipe(
      map((r: StrictHttpResponse<Document>) => r.body as Document)
    );
  }

  /**
   * Path part for operation patchDocument
   */
  static readonly PatchDocumentPath = '/api/v1/Documents/{id}';

  /**
   * Patch a document to change basic properties.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchDocument$Plain()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchDocument$Plain$Response(params: {

    /**
     * The id of the document to patch
     */
    id: string;

    /**
     * Details of the operation in JSON patch format
     */
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Document>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentsService.PatchDocumentPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json-patch+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Document>;
      })
    );
  }

  /**
   * Patch a document to change basic properties.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchDocument$Plain$Response()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchDocument$Plain(params: {

    /**
     * The id of the document to patch
     */
    id: string;

    /**
     * Details of the operation in JSON patch format
     */
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<Document> {

    return this.patchDocument$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Document>) => r.body as Document)
    );
  }

  /**
   * Patch a document to change basic properties.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchDocument()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchDocument$Response(params: {

    /**
     * The id of the document to patch
     */
    id: string;

    /**
     * Details of the operation in JSON patch format
     */
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Document>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentsService.PatchDocumentPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json-patch+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Document>;
      })
    );
  }

  /**
   * Patch a document to change basic properties.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchDocument$Response()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchDocument(params: {

    /**
     * The id of the document to patch
     */
    id: string;

    /**
     * Details of the operation in JSON patch format
     */
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<Document> {

    return this.patchDocument$Response(params,context).pipe(
      map((r: StrictHttpResponse<Document>) => r.body as Document)
    );
  }

  /**
   * Path part for operation getDocuments
   */
  static readonly GetDocumentsPath = '/api/v1/Documents';

  /**
   * Gets the documents for the current organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocuments$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocuments$Plain$Response(params?: {

    /**
     * Mandatory: the customerId for which to retrieve the documents
     */
    customerId?: string;

    /**
     * The number of documents to be retrieved
     */
    count?: number;

    /**
     * The page that's used to offset the documents based on the count
     */
    page?: number;

    /**
     * Only retrieve documents of type that associated with a building
     */
    associatedWithBuilding?: boolean;

    /**
     * Only retrieve documents of type that associated with a mortgage
     */
    associatedWithMortgage?: boolean;

    /**
     * Only retrieve documents of type that associated with an application
     */
    associatedWithApplication?: boolean;

    /**
     * Only retrieve documents associated with a customer
     */
    associatedWithCustomer?: boolean;

    /**
     * Only retrieve documents of a particular documentType
     */
    documentTypeId?: string;

    /**
     * Only retrieve documents that have a document type or not (leave empty to get all)
     */
    hasDocumentType?: boolean;

    /**
     * Only retrieve documents uploaded in this month
     */
    uploadMonth?: number;

    /**
     * Only retrieve documents uploaded in this year
     */
    uploadYear?: number;

    /**
     * Only retrieve documents associated with this building
     */
    buildingId?: string;

    /**
     * Only retrieve documents associated with this application
     */
    applicationId?: string;

    /**
     * Only retrieve documents associated with this mortgage
     */
    mortgageId?: string;

    /**
     * Only retrieve documents associated with this task
     */
    taskId?: string;

    /**
     * Only retrieve documents that are user uploaded or non-user uploaded
     */
    isUserUploaded?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Documents>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentsService.GetDocumentsPath, 'get');
    if (params) {
      rb.query('customerId', params.customerId, {});
      rb.query('count', params.count, {});
      rb.query('page', params.page, {});
      rb.query('associatedWithBuilding', params.associatedWithBuilding, {});
      rb.query('associatedWithMortgage', params.associatedWithMortgage, {});
      rb.query('associatedWithApplication', params.associatedWithApplication, {});
      rb.query('associatedWithCustomer', params.associatedWithCustomer, {});
      rb.query('documentTypeId', params.documentTypeId, {});
      rb.query('hasDocumentType', params.hasDocumentType, {});
      rb.query('uploadMonth', params.uploadMonth, {});
      rb.query('uploadYear', params.uploadYear, {});
      rb.query('buildingId', params.buildingId, {});
      rb.query('applicationId', params.applicationId, {});
      rb.query('mortgageId', params.mortgageId, {});
      rb.query('taskId', params.taskId, {});
      rb.query('isUserUploaded', params.isUserUploaded, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Documents>;
      })
    );
  }

  /**
   * Gets the documents for the current organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDocuments$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocuments$Plain(params?: {

    /**
     * Mandatory: the customerId for which to retrieve the documents
     */
    customerId?: string;

    /**
     * The number of documents to be retrieved
     */
    count?: number;

    /**
     * The page that's used to offset the documents based on the count
     */
    page?: number;

    /**
     * Only retrieve documents of type that associated with a building
     */
    associatedWithBuilding?: boolean;

    /**
     * Only retrieve documents of type that associated with a mortgage
     */
    associatedWithMortgage?: boolean;

    /**
     * Only retrieve documents of type that associated with an application
     */
    associatedWithApplication?: boolean;

    /**
     * Only retrieve documents associated with a customer
     */
    associatedWithCustomer?: boolean;

    /**
     * Only retrieve documents of a particular documentType
     */
    documentTypeId?: string;

    /**
     * Only retrieve documents that have a document type or not (leave empty to get all)
     */
    hasDocumentType?: boolean;

    /**
     * Only retrieve documents uploaded in this month
     */
    uploadMonth?: number;

    /**
     * Only retrieve documents uploaded in this year
     */
    uploadYear?: number;

    /**
     * Only retrieve documents associated with this building
     */
    buildingId?: string;

    /**
     * Only retrieve documents associated with this application
     */
    applicationId?: string;

    /**
     * Only retrieve documents associated with this mortgage
     */
    mortgageId?: string;

    /**
     * Only retrieve documents associated with this task
     */
    taskId?: string;

    /**
     * Only retrieve documents that are user uploaded or non-user uploaded
     */
    isUserUploaded?: boolean;
  },
  context?: HttpContext

): Observable<Documents> {

    return this.getDocuments$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Documents>) => r.body as Documents)
    );
  }

  /**
   * Gets the documents for the current organization.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocuments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocuments$Response(params?: {

    /**
     * Mandatory: the customerId for which to retrieve the documents
     */
    customerId?: string;

    /**
     * The number of documents to be retrieved
     */
    count?: number;

    /**
     * The page that's used to offset the documents based on the count
     */
    page?: number;

    /**
     * Only retrieve documents of type that associated with a building
     */
    associatedWithBuilding?: boolean;

    /**
     * Only retrieve documents of type that associated with a mortgage
     */
    associatedWithMortgage?: boolean;

    /**
     * Only retrieve documents of type that associated with an application
     */
    associatedWithApplication?: boolean;

    /**
     * Only retrieve documents associated with a customer
     */
    associatedWithCustomer?: boolean;

    /**
     * Only retrieve documents of a particular documentType
     */
    documentTypeId?: string;

    /**
     * Only retrieve documents that have a document type or not (leave empty to get all)
     */
    hasDocumentType?: boolean;

    /**
     * Only retrieve documents uploaded in this month
     */
    uploadMonth?: number;

    /**
     * Only retrieve documents uploaded in this year
     */
    uploadYear?: number;

    /**
     * Only retrieve documents associated with this building
     */
    buildingId?: string;

    /**
     * Only retrieve documents associated with this application
     */
    applicationId?: string;

    /**
     * Only retrieve documents associated with this mortgage
     */
    mortgageId?: string;

    /**
     * Only retrieve documents associated with this task
     */
    taskId?: string;

    /**
     * Only retrieve documents that are user uploaded or non-user uploaded
     */
    isUserUploaded?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Documents>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentsService.GetDocumentsPath, 'get');
    if (params) {
      rb.query('customerId', params.customerId, {});
      rb.query('count', params.count, {});
      rb.query('page', params.page, {});
      rb.query('associatedWithBuilding', params.associatedWithBuilding, {});
      rb.query('associatedWithMortgage', params.associatedWithMortgage, {});
      rb.query('associatedWithApplication', params.associatedWithApplication, {});
      rb.query('associatedWithCustomer', params.associatedWithCustomer, {});
      rb.query('documentTypeId', params.documentTypeId, {});
      rb.query('hasDocumentType', params.hasDocumentType, {});
      rb.query('uploadMonth', params.uploadMonth, {});
      rb.query('uploadYear', params.uploadYear, {});
      rb.query('buildingId', params.buildingId, {});
      rb.query('applicationId', params.applicationId, {});
      rb.query('mortgageId', params.mortgageId, {});
      rb.query('taskId', params.taskId, {});
      rb.query('isUserUploaded', params.isUserUploaded, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Documents>;
      })
    );
  }

  /**
   * Gets the documents for the current organization.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDocuments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocuments(params?: {

    /**
     * Mandatory: the customerId for which to retrieve the documents
     */
    customerId?: string;

    /**
     * The number of documents to be retrieved
     */
    count?: number;

    /**
     * The page that's used to offset the documents based on the count
     */
    page?: number;

    /**
     * Only retrieve documents of type that associated with a building
     */
    associatedWithBuilding?: boolean;

    /**
     * Only retrieve documents of type that associated with a mortgage
     */
    associatedWithMortgage?: boolean;

    /**
     * Only retrieve documents of type that associated with an application
     */
    associatedWithApplication?: boolean;

    /**
     * Only retrieve documents associated with a customer
     */
    associatedWithCustomer?: boolean;

    /**
     * Only retrieve documents of a particular documentType
     */
    documentTypeId?: string;

    /**
     * Only retrieve documents that have a document type or not (leave empty to get all)
     */
    hasDocumentType?: boolean;

    /**
     * Only retrieve documents uploaded in this month
     */
    uploadMonth?: number;

    /**
     * Only retrieve documents uploaded in this year
     */
    uploadYear?: number;

    /**
     * Only retrieve documents associated with this building
     */
    buildingId?: string;

    /**
     * Only retrieve documents associated with this application
     */
    applicationId?: string;

    /**
     * Only retrieve documents associated with this mortgage
     */
    mortgageId?: string;

    /**
     * Only retrieve documents associated with this task
     */
    taskId?: string;

    /**
     * Only retrieve documents that are user uploaded or non-user uploaded
     */
    isUserUploaded?: boolean;
  },
  context?: HttpContext

): Observable<Documents> {

    return this.getDocuments$Response(params,context).pipe(
      map((r: StrictHttpResponse<Documents>) => r.body as Documents)
    );
  }

  /**
   * Path part for operation registerDocument
   */
  static readonly RegisterDocumentPath = '/api/v1/Documents';

  /**
   * Registers a document that has been uploaded to the document storage into the organizations document collection.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registerDocument$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  registerDocument$Plain$Response(params?: {

    /**
     * The document details to be registered
     */
    body?: RegisterDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Document>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentsService.RegisterDocumentPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Document>;
      })
    );
  }

  /**
   * Registers a document that has been uploaded to the document storage into the organizations document collection.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `registerDocument$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  registerDocument$Plain(params?: {

    /**
     * The document details to be registered
     */
    body?: RegisterDocument
  },
  context?: HttpContext

): Observable<Document> {

    return this.registerDocument$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Document>) => r.body as Document)
    );
  }

  /**
   * Registers a document that has been uploaded to the document storage into the organizations document collection.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registerDocument()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  registerDocument$Response(params?: {

    /**
     * The document details to be registered
     */
    body?: RegisterDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Document>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentsService.RegisterDocumentPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Document>;
      })
    );
  }

  /**
   * Registers a document that has been uploaded to the document storage into the organizations document collection.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `registerDocument$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  registerDocument(params?: {

    /**
     * The document details to be registered
     */
    body?: RegisterDocument
  },
  context?: HttpContext

): Observable<Document> {

    return this.registerDocument$Response(params,context).pipe(
      map((r: StrictHttpResponse<Document>) => r.body as Document)
    );
  }

  /**
   * Path part for operation deleteDocument
   */
  static readonly DeleteDocumentPath = '/api/v1/Documents';

  /**
   * Deletes a document from the organizations collection.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDocument$Response(params?: {

    /**
     * the id of the document
     */
    id?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentsService.DeleteDocumentPath, 'delete');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Deletes a document from the organizations collection.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDocument(params?: {

    /**
     * the id of the document
     */
    id?: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.deleteDocument$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getDocumentTypes
   */
  static readonly GetDocumentTypesPath = '/api/v1/DocumentTypes';

  /**
   * Returns a list of available document types.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocumentTypes$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentTypes$Plain$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<DocumentType>>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentsService.GetDocumentTypesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DocumentType>>;
      })
    );
  }

  /**
   * Returns a list of available document types.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDocumentTypes$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentTypes$Plain(params?: {
  },
  context?: HttpContext

): Observable<Array<DocumentType>> {

    return this.getDocumentTypes$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<DocumentType>>) => r.body as Array<DocumentType>)
    );
  }

  /**
   * Returns a list of available document types.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocumentTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentTypes$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<DocumentType>>> {

    const rb = new RequestBuilder(this.rootUrl, DocumentsService.GetDocumentTypesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<DocumentType>>;
      })
    );
  }

  /**
   * Returns a list of available document types.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDocumentTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocumentTypes(params?: {
  },
  context?: HttpContext

): Observable<Array<DocumentType>> {

    return this.getDocumentTypes$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<DocumentType>>) => r.body as Array<DocumentType>)
    );
  }

}
