/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Action } from '../models/action';
import { Building } from '../models/building';
import { BuildingMortgage } from '../models/building-mortgage';
import { BuildingValuation } from '../models/building-valuation';
import { BuildingValueOption } from '../models/building-value-option';
import { Buildings } from '../models/buildings';
import { Dashboard } from '../models/dashboard';
import { Document } from '../models/document';
import { EstimatedBuildingValue } from '../models/estimated-building-value';
import { JsonPatchDocument } from '../models/json-patch-document';
import { NewBuilding } from '../models/new-building';
import { NewRoom } from '../models/new-room';
import { NewUnit } from '../models/new-unit';
import { Proposals } from '../models/proposals';
import { Renovation } from '../models/renovation';
import { RentalContract } from '../models/rental-contract';
import { Room } from '../models/room';
import { SplitBuilding } from '../models/split-building';
import { Tenant } from '../models/tenant';
import { Unit } from '../models/unit';

@Injectable({
  providedIn: 'root',
})
export class BuildingsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getBuildings
   */
  static readonly GetBuildingsPath = '/api/v1/Buildings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBuildings$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBuildings$Plain$Response(params?: {
    splitCombinations?: boolean;
    count?: number;
    page?: number;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Buildings>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.GetBuildingsPath, 'get');
    if (params) {
      rb.query('splitCombinations', params.splitCombinations, {});
      rb.query('count', params.count, {});
      rb.query('page', params.page, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Buildings>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBuildings$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBuildings$Plain(params?: {
    splitCombinations?: boolean;
    count?: number;
    page?: number;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Buildings> {

    return this.getBuildings$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Buildings>) => r.body as Buildings)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBuildings()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBuildings$Response(params?: {
    splitCombinations?: boolean;
    count?: number;
    page?: number;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Buildings>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.GetBuildingsPath, 'get');
    if (params) {
      rb.query('splitCombinations', params.splitCombinations, {});
      rb.query('count', params.count, {});
      rb.query('page', params.page, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Buildings>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBuildings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBuildings(params?: {
    splitCombinations?: boolean;
    count?: number;
    page?: number;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Buildings> {

    return this.getBuildings$Response(params,context).pipe(
      map((r: StrictHttpResponse<Buildings>) => r.body as Buildings)
    );
  }

  /**
   * Path part for operation addBuildings
   */
  static readonly AddBuildingsPath = '/api/v1/Buildings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addBuildings$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addBuildings$Plain$Response(params?: {
    body?: Array<NewBuilding>
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Building>>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.AddBuildingsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Building>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addBuildings$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addBuildings$Plain(params?: {
    body?: Array<NewBuilding>
  },
  context?: HttpContext

): Observable<Array<Building>> {

    return this.addBuildings$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Building>>) => r.body as Array<Building>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addBuildings()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addBuildings$Response(params?: {
    body?: Array<NewBuilding>
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Building>>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.AddBuildingsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Building>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addBuildings$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addBuildings(params?: {
    body?: Array<NewBuilding>
  },
  context?: HttpContext

): Observable<Array<Building>> {

    return this.addBuildings$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Building>>) => r.body as Array<Building>)
    );
  }

  /**
   * Path part for operation getBuildingById
   */
  static readonly GetBuildingByIdPath = '/api/v1/Buildings/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBuildingById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBuildingById$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Building>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.GetBuildingByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Building>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBuildingById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBuildingById$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Building> {

    return this.getBuildingById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Building>) => r.body as Building)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBuildingById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBuildingById$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Building>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.GetBuildingByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Building>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBuildingById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBuildingById(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Building> {

    return this.getBuildingById$Response(params,context).pipe(
      map((r: StrictHttpResponse<Building>) => r.body as Building)
    );
  }

  /**
   * Path part for operation updateBuilding
   */
  static readonly UpdateBuildingPath = '/api/v1/Buildings/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateBuilding$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateBuilding$Plain$Response(params: {
    id: string;
    body?: Building
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Building>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.UpdateBuildingPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Building>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateBuilding$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateBuilding$Plain(params: {
    id: string;
    body?: Building
  },
  context?: HttpContext

): Observable<Building> {

    return this.updateBuilding$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Building>) => r.body as Building)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateBuilding()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateBuilding$Response(params: {
    id: string;
    body?: Building
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Building>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.UpdateBuildingPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Building>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateBuilding$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateBuilding(params: {
    id: string;
    body?: Building
  },
  context?: HttpContext

): Observable<Building> {

    return this.updateBuilding$Response(params,context).pipe(
      map((r: StrictHttpResponse<Building>) => r.body as Building)
    );
  }

  /**
   * Path part for operation deleteBuildingById
   */
  static readonly DeleteBuildingByIdPath = '/api/v1/Buildings/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteBuildingById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteBuildingById$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.DeleteBuildingByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteBuildingById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteBuildingById(params: {
    id: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.deleteBuildingById$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation patchBuilding
   */
  static readonly PatchBuildingPath = '/api/v1/Buildings/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchBuilding$Plain()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchBuilding$Plain$Response(params: {
    id: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Building>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.PatchBuildingPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json-patch+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Building>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchBuilding$Plain$Response()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchBuilding$Plain(params: {
    id: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<Building> {

    return this.patchBuilding$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Building>) => r.body as Building)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchBuilding()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchBuilding$Response(params: {
    id: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Building>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.PatchBuildingPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json-patch+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Building>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchBuilding$Response()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchBuilding(params: {
    id: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<Building> {

    return this.patchBuilding$Response(params,context).pipe(
      map((r: StrictHttpResponse<Building>) => r.body as Building)
    );
  }

  /**
   * Path part for operation getBuildingActionsById
   */
  static readonly GetBuildingActionsByIdPath = '/api/v1/Buildings/{id}/Actions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBuildingActionsById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBuildingActionsById$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Action>>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.GetBuildingActionsByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Action>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBuildingActionsById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBuildingActionsById$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<Action>> {

    return this.getBuildingActionsById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Action>>) => r.body as Array<Action>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBuildingActionsById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBuildingActionsById$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Action>>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.GetBuildingActionsByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Action>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBuildingActionsById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBuildingActionsById(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<Action>> {

    return this.getBuildingActionsById$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Action>>) => r.body as Array<Action>)
    );
  }

  /**
   * Path part for operation splitBuilding
   */
  static readonly SplitBuildingPath = '/api/v1/Buildings/{id}/Split';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `splitBuilding$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  splitBuilding$Plain$Response(params: {
    id: string;
    body?: SplitBuilding
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Building>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.SplitBuildingPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Building>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `splitBuilding$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  splitBuilding$Plain(params: {
    id: string;
    body?: SplitBuilding
  },
  context?: HttpContext

): Observable<Building> {

    return this.splitBuilding$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Building>) => r.body as Building)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `splitBuilding()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  splitBuilding$Response(params: {
    id: string;
    body?: SplitBuilding
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Building>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.SplitBuildingPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Building>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `splitBuilding$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  splitBuilding(params: {
    id: string;
    body?: SplitBuilding
  },
  context?: HttpContext

): Observable<Building> {

    return this.splitBuilding$Response(params,context).pipe(
      map((r: StrictHttpResponse<Building>) => r.body as Building)
    );
  }

  /**
   * Path part for operation getBuildingDashboardById
   */
  static readonly GetBuildingDashboardByIdPath = '/api/v1/Buildings/{id}/Dashboard';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBuildingDashboardById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBuildingDashboardById$Plain$Response(params: {
    id: string;
    referenceDate?: string;
    includeVacancy?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Dashboard>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.GetBuildingDashboardByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('referenceDate', params.referenceDate, {});
      rb.query('includeVacancy', params.includeVacancy, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Dashboard>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBuildingDashboardById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBuildingDashboardById$Plain(params: {
    id: string;
    referenceDate?: string;
    includeVacancy?: boolean;
  },
  context?: HttpContext

): Observable<Dashboard> {

    return this.getBuildingDashboardById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Dashboard>) => r.body as Dashboard)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBuildingDashboardById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBuildingDashboardById$Response(params: {
    id: string;
    referenceDate?: string;
    includeVacancy?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Dashboard>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.GetBuildingDashboardByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('referenceDate', params.referenceDate, {});
      rb.query('includeVacancy', params.includeVacancy, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Dashboard>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBuildingDashboardById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBuildingDashboardById(params: {
    id: string;
    referenceDate?: string;
    includeVacancy?: boolean;
  },
  context?: HttpContext

): Observable<Dashboard> {

    return this.getBuildingDashboardById$Response(params,context).pipe(
      map((r: StrictHttpResponse<Dashboard>) => r.body as Dashboard)
    );
  }

  /**
   * Path part for operation getEstimatedValues
   */
  static readonly GetEstimatedValuesPath = '/api/v1/Buildings/{id}/EstimatedValues';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEstimatedValues$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEstimatedValues$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<EstimatedBuildingValue>>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.GetEstimatedValuesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EstimatedBuildingValue>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEstimatedValues$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEstimatedValues$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<EstimatedBuildingValue>> {

    return this.getEstimatedValues$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<EstimatedBuildingValue>>) => r.body as Array<EstimatedBuildingValue>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEstimatedValues()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEstimatedValues$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<EstimatedBuildingValue>>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.GetEstimatedValuesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EstimatedBuildingValue>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEstimatedValues$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEstimatedValues(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<EstimatedBuildingValue>> {

    return this.getEstimatedValues$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<EstimatedBuildingValue>>) => r.body as Array<EstimatedBuildingValue>)
    );
  }

  /**
   * Path part for operation addEstimatedValueById
   */
  static readonly AddEstimatedValueByIdPath = '/api/v1/Buildings/{id}/EstimatedValues';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addEstimatedValueById$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addEstimatedValueById$Plain$Response(params: {
    id: string;
    body?: EstimatedBuildingValue
  },
  context?: HttpContext

): Observable<StrictHttpResponse<EstimatedBuildingValue>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.AddEstimatedValueByIdPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EstimatedBuildingValue>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addEstimatedValueById$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addEstimatedValueById$Plain(params: {
    id: string;
    body?: EstimatedBuildingValue
  },
  context?: HttpContext

): Observable<EstimatedBuildingValue> {

    return this.addEstimatedValueById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<EstimatedBuildingValue>) => r.body as EstimatedBuildingValue)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addEstimatedValueById()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addEstimatedValueById$Response(params: {
    id: string;
    body?: EstimatedBuildingValue
  },
  context?: HttpContext

): Observable<StrictHttpResponse<EstimatedBuildingValue>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.AddEstimatedValueByIdPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EstimatedBuildingValue>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addEstimatedValueById$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addEstimatedValueById(params: {
    id: string;
    body?: EstimatedBuildingValue
  },
  context?: HttpContext

): Observable<EstimatedBuildingValue> {

    return this.addEstimatedValueById$Response(params,context).pipe(
      map((r: StrictHttpResponse<EstimatedBuildingValue>) => r.body as EstimatedBuildingValue)
    );
  }

  /**
   * Path part for operation getEstimatedValueById
   */
  static readonly GetEstimatedValueByIdPath = '/api/v1/Buildings/{id}/EstimatedValues/{estimatedValueId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEstimatedValueById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEstimatedValueById$Plain$Response(params: {
    id: string;
    estimatedValueId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<EstimatedBuildingValue>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.GetEstimatedValueByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('estimatedValueId', params.estimatedValueId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EstimatedBuildingValue>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEstimatedValueById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEstimatedValueById$Plain(params: {
    id: string;
    estimatedValueId: string;
  },
  context?: HttpContext

): Observable<EstimatedBuildingValue> {

    return this.getEstimatedValueById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<EstimatedBuildingValue>) => r.body as EstimatedBuildingValue)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEstimatedValueById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEstimatedValueById$Response(params: {
    id: string;
    estimatedValueId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<EstimatedBuildingValue>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.GetEstimatedValueByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('estimatedValueId', params.estimatedValueId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EstimatedBuildingValue>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEstimatedValueById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEstimatedValueById(params: {
    id: string;
    estimatedValueId: string;
  },
  context?: HttpContext

): Observable<EstimatedBuildingValue> {

    return this.getEstimatedValueById$Response(params,context).pipe(
      map((r: StrictHttpResponse<EstimatedBuildingValue>) => r.body as EstimatedBuildingValue)
    );
  }

  /**
   * Path part for operation putEstimatedValueById
   */
  static readonly PutEstimatedValueByIdPath = '/api/v1/Buildings/{id}/EstimatedValues/{estimatedValueId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putEstimatedValueById$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  putEstimatedValueById$Plain$Response(params: {
    id: string;
    estimatedValueId: string;
    body?: EstimatedBuildingValue
  },
  context?: HttpContext

): Observable<StrictHttpResponse<EstimatedBuildingValue>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.PutEstimatedValueByIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('estimatedValueId', params.estimatedValueId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EstimatedBuildingValue>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `putEstimatedValueById$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  putEstimatedValueById$Plain(params: {
    id: string;
    estimatedValueId: string;
    body?: EstimatedBuildingValue
  },
  context?: HttpContext

): Observable<EstimatedBuildingValue> {

    return this.putEstimatedValueById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<EstimatedBuildingValue>) => r.body as EstimatedBuildingValue)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putEstimatedValueById()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  putEstimatedValueById$Response(params: {
    id: string;
    estimatedValueId: string;
    body?: EstimatedBuildingValue
  },
  context?: HttpContext

): Observable<StrictHttpResponse<EstimatedBuildingValue>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.PutEstimatedValueByIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('estimatedValueId', params.estimatedValueId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EstimatedBuildingValue>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `putEstimatedValueById$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  putEstimatedValueById(params: {
    id: string;
    estimatedValueId: string;
    body?: EstimatedBuildingValue
  },
  context?: HttpContext

): Observable<EstimatedBuildingValue> {

    return this.putEstimatedValueById$Response(params,context).pipe(
      map((r: StrictHttpResponse<EstimatedBuildingValue>) => r.body as EstimatedBuildingValue)
    );
  }

  /**
   * Path part for operation deleteEstimatedValueById
   */
  static readonly DeleteEstimatedValueByIdPath = '/api/v1/Buildings/{id}/EstimatedValues/{estimatedValueId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteEstimatedValueById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteEstimatedValueById$Response(params: {
    id: string;
    estimatedValueId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.DeleteEstimatedValueByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('estimatedValueId', params.estimatedValueId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteEstimatedValueById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteEstimatedValueById(params: {
    id: string;
    estimatedValueId: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.deleteEstimatedValueById$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation patchEstimatedValue
   */
  static readonly PatchEstimatedValuePath = '/api/v1/Buildings/{id}/EstimatedValues/{estimatedValueId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchEstimatedValue$Plain()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchEstimatedValue$Plain$Response(params: {
    id: string;
    estimatedValueId: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<EstimatedBuildingValue>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.PatchEstimatedValuePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('estimatedValueId', params.estimatedValueId, {});
      rb.body(params.body, 'application/json-patch+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EstimatedBuildingValue>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchEstimatedValue$Plain$Response()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchEstimatedValue$Plain(params: {
    id: string;
    estimatedValueId: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<EstimatedBuildingValue> {

    return this.patchEstimatedValue$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<EstimatedBuildingValue>) => r.body as EstimatedBuildingValue)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchEstimatedValue()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchEstimatedValue$Response(params: {
    id: string;
    estimatedValueId: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<EstimatedBuildingValue>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.PatchEstimatedValuePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('estimatedValueId', params.estimatedValueId, {});
      rb.body(params.body, 'application/json-patch+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<EstimatedBuildingValue>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchEstimatedValue$Response()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchEstimatedValue(params: {
    id: string;
    estimatedValueId: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<EstimatedBuildingValue> {

    return this.patchEstimatedValue$Response(params,context).pipe(
      map((r: StrictHttpResponse<EstimatedBuildingValue>) => r.body as EstimatedBuildingValue)
    );
  }

  /**
   * Path part for operation getBuildingFinancingOptionsById
   */
  static readonly GetBuildingFinancingOptionsByIdPath = '/api/v1/Buildings/{id}/FinancingOptions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBuildingFinancingOptionsById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBuildingFinancingOptionsById$Plain$Response(params: {
    id: string;
    style?: 'Featured' | 'Top3';
    sort?: 'AverageTotalCostsPerMonth' | 'ContributionOwnResources' | 'Loan' | 'LoanToValue' | 'InterestRate' | 'TotalCosts' | 'MonthlyCharges' | 'FreeCashFlow' | 'ReturnOnEquity' | 'ReturnOnInvestment' | 'Quality';
    count?: number;
    page?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Proposals>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.GetBuildingFinancingOptionsByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('style', params.style, {});
      rb.query('sort', params.sort, {});
      rb.query('count', params.count, {});
      rb.query('page', params.page, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Proposals>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBuildingFinancingOptionsById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBuildingFinancingOptionsById$Plain(params: {
    id: string;
    style?: 'Featured' | 'Top3';
    sort?: 'AverageTotalCostsPerMonth' | 'ContributionOwnResources' | 'Loan' | 'LoanToValue' | 'InterestRate' | 'TotalCosts' | 'MonthlyCharges' | 'FreeCashFlow' | 'ReturnOnEquity' | 'ReturnOnInvestment' | 'Quality';
    count?: number;
    page?: number;
  },
  context?: HttpContext

): Observable<Proposals> {

    return this.getBuildingFinancingOptionsById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Proposals>) => r.body as Proposals)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBuildingFinancingOptionsById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBuildingFinancingOptionsById$Response(params: {
    id: string;
    style?: 'Featured' | 'Top3';
    sort?: 'AverageTotalCostsPerMonth' | 'ContributionOwnResources' | 'Loan' | 'LoanToValue' | 'InterestRate' | 'TotalCosts' | 'MonthlyCharges' | 'FreeCashFlow' | 'ReturnOnEquity' | 'ReturnOnInvestment' | 'Quality';
    count?: number;
    page?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Proposals>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.GetBuildingFinancingOptionsByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('style', params.style, {});
      rb.query('sort', params.sort, {});
      rb.query('count', params.count, {});
      rb.query('page', params.page, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Proposals>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBuildingFinancingOptionsById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBuildingFinancingOptionsById(params: {
    id: string;
    style?: 'Featured' | 'Top3';
    sort?: 'AverageTotalCostsPerMonth' | 'ContributionOwnResources' | 'Loan' | 'LoanToValue' | 'InterestRate' | 'TotalCosts' | 'MonthlyCharges' | 'FreeCashFlow' | 'ReturnOnEquity' | 'ReturnOnInvestment' | 'Quality';
    count?: number;
    page?: number;
  },
  context?: HttpContext

): Observable<Proposals> {

    return this.getBuildingFinancingOptionsById$Response(params,context).pipe(
      map((r: StrictHttpResponse<Proposals>) => r.body as Proposals)
    );
  }

  /**
   * Path part for operation getBuildingMortgagesById
   */
  static readonly GetBuildingMortgagesByIdPath = '/api/v1/Buildings/{id}/Mortgages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBuildingMortgagesById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBuildingMortgagesById$Plain$Response(params: {
    id: string;
    statuses?: Array<'Concept' | 'Active' | 'Repayed'>;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<BuildingMortgage>>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.GetBuildingMortgagesByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('statuses', params.statuses, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BuildingMortgage>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBuildingMortgagesById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBuildingMortgagesById$Plain(params: {
    id: string;
    statuses?: Array<'Concept' | 'Active' | 'Repayed'>;
  },
  context?: HttpContext

): Observable<Array<BuildingMortgage>> {

    return this.getBuildingMortgagesById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<BuildingMortgage>>) => r.body as Array<BuildingMortgage>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBuildingMortgagesById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBuildingMortgagesById$Response(params: {
    id: string;
    statuses?: Array<'Concept' | 'Active' | 'Repayed'>;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<BuildingMortgage>>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.GetBuildingMortgagesByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('statuses', params.statuses, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BuildingMortgage>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBuildingMortgagesById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBuildingMortgagesById(params: {
    id: string;
    statuses?: Array<'Concept' | 'Active' | 'Repayed'>;
  },
  context?: HttpContext

): Observable<Array<BuildingMortgage>> {

    return this.getBuildingMortgagesById$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<BuildingMortgage>>) => r.body as Array<BuildingMortgage>)
    );
  }

  /**
   * Path part for operation addBuildingMortgagesById
   */
  static readonly AddBuildingMortgagesByIdPath = '/api/v1/Buildings/{id}/Mortgages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addBuildingMortgagesById()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addBuildingMortgagesById$Response(params: {
    id: string;
    body?: Array<BuildingMortgage>
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.AddBuildingMortgagesByIdPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addBuildingMortgagesById$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addBuildingMortgagesById(params: {
    id: string;
    body?: Array<BuildingMortgage>
  },
  context?: HttpContext

): Observable<void> {

    return this.addBuildingMortgagesById$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation updateBuildingMortgageById
   */
  static readonly UpdateBuildingMortgageByIdPath = '/api/v1/Buildings/{id}/Mortgages/{mortgageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateBuildingMortgageById()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateBuildingMortgageById$Response(params: {
    id: string;
    mortgageId: string;
    body?: BuildingMortgage
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.UpdateBuildingMortgageByIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('mortgageId', params.mortgageId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateBuildingMortgageById$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateBuildingMortgageById(params: {
    id: string;
    mortgageId: string;
    body?: BuildingMortgage
  },
  context?: HttpContext

): Observable<void> {

    return this.updateBuildingMortgageById$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation deleteBuildingMortgageById
   */
  static readonly DeleteBuildingMortgageByIdPath = '/api/v1/Buildings/{id}/Mortgages/{mortgageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteBuildingMortgageById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteBuildingMortgageById$Response(params: {
    id: string;
    mortgageId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.DeleteBuildingMortgageByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('mortgageId', params.mortgageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteBuildingMortgageById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteBuildingMortgageById(params: {
    id: string;
    mortgageId: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.deleteBuildingMortgageById$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation patchBuildingMortgage
   */
  static readonly PatchBuildingMortgagePath = '/api/v1/Buildings/{id}/Mortgages/{mortgageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchBuildingMortgage$Plain()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchBuildingMortgage$Plain$Response(params: {
    id: string;
    mortgageId: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<BuildingMortgage>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.PatchBuildingMortgagePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('mortgageId', params.mortgageId, {});
      rb.body(params.body, 'application/json-patch+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BuildingMortgage>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchBuildingMortgage$Plain$Response()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchBuildingMortgage$Plain(params: {
    id: string;
    mortgageId: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<BuildingMortgage> {

    return this.patchBuildingMortgage$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<BuildingMortgage>) => r.body as BuildingMortgage)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchBuildingMortgage()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchBuildingMortgage$Response(params: {
    id: string;
    mortgageId: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<BuildingMortgage>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.PatchBuildingMortgagePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('mortgageId', params.mortgageId, {});
      rb.body(params.body, 'application/json-patch+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BuildingMortgage>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchBuildingMortgage$Response()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchBuildingMortgage(params: {
    id: string;
    mortgageId: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<BuildingMortgage> {

    return this.patchBuildingMortgage$Response(params,context).pipe(
      map((r: StrictHttpResponse<BuildingMortgage>) => r.body as BuildingMortgage)
    );
  }

  /**
   * Path part for operation getBuildingRentalContracts
   */
  static readonly GetBuildingRentalContractsPath = '/api/v1/Buildings/{id}/RentalContracts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBuildingRentalContracts$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBuildingRentalContracts$Plain$Response(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<RentalContract>>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.GetBuildingRentalContractsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RentalContract>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBuildingRentalContracts$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBuildingRentalContracts$Plain(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Array<RentalContract>> {

    return this.getBuildingRentalContracts$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<RentalContract>>) => r.body as Array<RentalContract>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBuildingRentalContracts()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBuildingRentalContracts$Response(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<RentalContract>>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.GetBuildingRentalContractsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RentalContract>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBuildingRentalContracts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBuildingRentalContracts(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Array<RentalContract>> {

    return this.getBuildingRentalContracts$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<RentalContract>>) => r.body as Array<RentalContract>)
    );
  }

  /**
   * Path part for operation addRentalContract
   */
  static readonly AddRentalContractPath = '/api/v1/Buildings/{id}/RentalContracts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addRentalContract$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addRentalContract$Plain$Response(params: {
    id: string;
    body?: RentalContract
  },
  context?: HttpContext

): Observable<StrictHttpResponse<RentalContract>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.AddRentalContractPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RentalContract>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addRentalContract$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addRentalContract$Plain(params: {
    id: string;
    body?: RentalContract
  },
  context?: HttpContext

): Observable<RentalContract> {

    return this.addRentalContract$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<RentalContract>) => r.body as RentalContract)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addRentalContract()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addRentalContract$Response(params: {
    id: string;
    body?: RentalContract
  },
  context?: HttpContext

): Observable<StrictHttpResponse<RentalContract>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.AddRentalContractPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RentalContract>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addRentalContract$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addRentalContract(params: {
    id: string;
    body?: RentalContract
  },
  context?: HttpContext

): Observable<RentalContract> {

    return this.addRentalContract$Response(params,context).pipe(
      map((r: StrictHttpResponse<RentalContract>) => r.body as RentalContract)
    );
  }

  /**
   * Path part for operation getTenants
   */
  static readonly GetTenantsPath = '/api/v1/Buildings/{id}/RentalContracts/{rentalContractId}/Tenants';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTenants$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTenants$Plain$Response(params: {
    id: string;
    rentalContractId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Tenant>>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.GetTenantsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('rentalContractId', params.rentalContractId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Tenant>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTenants$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTenants$Plain(params: {
    id: string;
    rentalContractId: string;
  },
  context?: HttpContext

): Observable<Array<Tenant>> {

    return this.getTenants$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Tenant>>) => r.body as Array<Tenant>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTenants()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTenants$Response(params: {
    id: string;
    rentalContractId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Tenant>>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.GetTenantsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('rentalContractId', params.rentalContractId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Tenant>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTenants$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTenants(params: {
    id: string;
    rentalContractId: string;
  },
  context?: HttpContext

): Observable<Array<Tenant>> {

    return this.getTenants$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Tenant>>) => r.body as Array<Tenant>)
    );
  }

  /**
   * Path part for operation getRenovations
   */
  static readonly GetRenovationsPath = '/api/v1/Buildings/{id}/Renovations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRenovations$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRenovations$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Renovation>>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.GetRenovationsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Renovation>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRenovations$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRenovations$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<Renovation>> {

    return this.getRenovations$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Renovation>>) => r.body as Array<Renovation>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRenovations()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRenovations$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Renovation>>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.GetRenovationsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Renovation>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRenovations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRenovations(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<Renovation>> {

    return this.getRenovations$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Renovation>>) => r.body as Array<Renovation>)
    );
  }

  /**
   * Path part for operation addRenovationById
   */
  static readonly AddRenovationByIdPath = '/api/v1/Buildings/{id}/Renovations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addRenovationById$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addRenovationById$Plain$Response(params: {
    id: string;
    body?: Renovation
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Renovation>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.AddRenovationByIdPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Renovation>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addRenovationById$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addRenovationById$Plain(params: {
    id: string;
    body?: Renovation
  },
  context?: HttpContext

): Observable<Renovation> {

    return this.addRenovationById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Renovation>) => r.body as Renovation)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addRenovationById()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addRenovationById$Response(params: {
    id: string;
    body?: Renovation
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Renovation>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.AddRenovationByIdPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Renovation>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addRenovationById$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addRenovationById(params: {
    id: string;
    body?: Renovation
  },
  context?: HttpContext

): Observable<Renovation> {

    return this.addRenovationById$Response(params,context).pipe(
      map((r: StrictHttpResponse<Renovation>) => r.body as Renovation)
    );
  }

  /**
   * Path part for operation getRenovationById
   */
  static readonly GetRenovationByIdPath = '/api/v1/Buildings/{id}/Renovations/{renovationId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRenovationById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRenovationById$Plain$Response(params: {
    id: string;
    renovationId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Renovation>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.GetRenovationByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('renovationId', params.renovationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Renovation>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRenovationById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRenovationById$Plain(params: {
    id: string;
    renovationId: string;
  },
  context?: HttpContext

): Observable<Renovation> {

    return this.getRenovationById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Renovation>) => r.body as Renovation)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRenovationById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRenovationById$Response(params: {
    id: string;
    renovationId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Renovation>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.GetRenovationByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('renovationId', params.renovationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Renovation>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRenovationById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRenovationById(params: {
    id: string;
    renovationId: string;
  },
  context?: HttpContext

): Observable<Renovation> {

    return this.getRenovationById$Response(params,context).pipe(
      map((r: StrictHttpResponse<Renovation>) => r.body as Renovation)
    );
  }

  /**
   * Path part for operation putRenovationById
   */
  static readonly PutRenovationByIdPath = '/api/v1/Buildings/{id}/Renovations/{renovationId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putRenovationById$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  putRenovationById$Plain$Response(params: {
    id: string;
    renovationId: string;
    body?: Renovation
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Renovation>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.PutRenovationByIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('renovationId', params.renovationId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Renovation>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `putRenovationById$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  putRenovationById$Plain(params: {
    id: string;
    renovationId: string;
    body?: Renovation
  },
  context?: HttpContext

): Observable<Renovation> {

    return this.putRenovationById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Renovation>) => r.body as Renovation)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putRenovationById()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  putRenovationById$Response(params: {
    id: string;
    renovationId: string;
    body?: Renovation
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Renovation>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.PutRenovationByIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('renovationId', params.renovationId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Renovation>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `putRenovationById$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  putRenovationById(params: {
    id: string;
    renovationId: string;
    body?: Renovation
  },
  context?: HttpContext

): Observable<Renovation> {

    return this.putRenovationById$Response(params,context).pipe(
      map((r: StrictHttpResponse<Renovation>) => r.body as Renovation)
    );
  }

  /**
   * Path part for operation deleteRenovationById
   */
  static readonly DeleteRenovationByIdPath = '/api/v1/Buildings/{id}/Renovations/{renovationId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteRenovationById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteRenovationById$Response(params: {
    id: string;
    renovationId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.DeleteRenovationByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('renovationId', params.renovationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteRenovationById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteRenovationById(params: {
    id: string;
    renovationId: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.deleteRenovationById$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation patchRenovation
   */
  static readonly PatchRenovationPath = '/api/v1/Buildings/{id}/Renovations/{renovationId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchRenovation$Plain()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchRenovation$Plain$Response(params: {
    id: string;
    renovationId: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Renovation>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.PatchRenovationPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('renovationId', params.renovationId, {});
      rb.body(params.body, 'application/json-patch+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Renovation>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchRenovation$Plain$Response()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchRenovation$Plain(params: {
    id: string;
    renovationId: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<Renovation> {

    return this.patchRenovation$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Renovation>) => r.body as Renovation)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchRenovation()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchRenovation$Response(params: {
    id: string;
    renovationId: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Renovation>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.PatchRenovationPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('renovationId', params.renovationId, {});
      rb.body(params.body, 'application/json-patch+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Renovation>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchRenovation$Response()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchRenovation(params: {
    id: string;
    renovationId: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<Renovation> {

    return this.patchRenovation$Response(params,context).pipe(
      map((r: StrictHttpResponse<Renovation>) => r.body as Renovation)
    );
  }

  /**
   * Path part for operation getBuildingValuations
   */
  static readonly GetBuildingValuationsPath = '/api/v1/Buildings/{id}/Valuations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBuildingValuations$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBuildingValuations$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<BuildingValuation>>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.GetBuildingValuationsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BuildingValuation>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBuildingValuations$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBuildingValuations$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<BuildingValuation>> {

    return this.getBuildingValuations$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<BuildingValuation>>) => r.body as Array<BuildingValuation>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBuildingValuations()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBuildingValuations$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<BuildingValuation>>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.GetBuildingValuationsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BuildingValuation>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBuildingValuations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBuildingValuations(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<BuildingValuation>> {

    return this.getBuildingValuations$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<BuildingValuation>>) => r.body as Array<BuildingValuation>)
    );
  }

  /**
   * Path part for operation addValuationById
   */
  static readonly AddValuationByIdPath = '/api/v1/Buildings/{id}/Valuations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addValuationById$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addValuationById$Plain$Response(params: {
    id: string;
    body?: BuildingValuation
  },
  context?: HttpContext

): Observable<StrictHttpResponse<BuildingValuation>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.AddValuationByIdPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BuildingValuation>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addValuationById$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addValuationById$Plain(params: {
    id: string;
    body?: BuildingValuation
  },
  context?: HttpContext

): Observable<BuildingValuation> {

    return this.addValuationById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<BuildingValuation>) => r.body as BuildingValuation)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addValuationById()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addValuationById$Response(params: {
    id: string;
    body?: BuildingValuation
  },
  context?: HttpContext

): Observable<StrictHttpResponse<BuildingValuation>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.AddValuationByIdPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BuildingValuation>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addValuationById$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addValuationById(params: {
    id: string;
    body?: BuildingValuation
  },
  context?: HttpContext

): Observable<BuildingValuation> {

    return this.addValuationById$Response(params,context).pipe(
      map((r: StrictHttpResponse<BuildingValuation>) => r.body as BuildingValuation)
    );
  }

  /**
   * Path part for operation getBuildingValuationById
   */
  static readonly GetBuildingValuationByIdPath = '/api/v1/Buildings/{id}/Valuations/{valuationId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBuildingValuationById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBuildingValuationById$Plain$Response(params: {
    id: string;
    valuationId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<BuildingValuation>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.GetBuildingValuationByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('valuationId', params.valuationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BuildingValuation>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBuildingValuationById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBuildingValuationById$Plain(params: {
    id: string;
    valuationId: string;
  },
  context?: HttpContext

): Observable<BuildingValuation> {

    return this.getBuildingValuationById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<BuildingValuation>) => r.body as BuildingValuation)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBuildingValuationById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBuildingValuationById$Response(params: {
    id: string;
    valuationId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<BuildingValuation>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.GetBuildingValuationByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('valuationId', params.valuationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BuildingValuation>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBuildingValuationById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBuildingValuationById(params: {
    id: string;
    valuationId: string;
  },
  context?: HttpContext

): Observable<BuildingValuation> {

    return this.getBuildingValuationById$Response(params,context).pipe(
      map((r: StrictHttpResponse<BuildingValuation>) => r.body as BuildingValuation)
    );
  }

  /**
   * Path part for operation putValuationById
   */
  static readonly PutValuationByIdPath = '/api/v1/Buildings/{id}/Valuations/{valuationId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putValuationById$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  putValuationById$Plain$Response(params: {
    id: string;
    valuationId: string;
    body?: BuildingValuation
  },
  context?: HttpContext

): Observable<StrictHttpResponse<BuildingValuation>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.PutValuationByIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('valuationId', params.valuationId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BuildingValuation>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `putValuationById$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  putValuationById$Plain(params: {
    id: string;
    valuationId: string;
    body?: BuildingValuation
  },
  context?: HttpContext

): Observable<BuildingValuation> {

    return this.putValuationById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<BuildingValuation>) => r.body as BuildingValuation)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putValuationById()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  putValuationById$Response(params: {
    id: string;
    valuationId: string;
    body?: BuildingValuation
  },
  context?: HttpContext

): Observable<StrictHttpResponse<BuildingValuation>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.PutValuationByIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('valuationId', params.valuationId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BuildingValuation>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `putValuationById$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  putValuationById(params: {
    id: string;
    valuationId: string;
    body?: BuildingValuation
  },
  context?: HttpContext

): Observable<BuildingValuation> {

    return this.putValuationById$Response(params,context).pipe(
      map((r: StrictHttpResponse<BuildingValuation>) => r.body as BuildingValuation)
    );
  }

  /**
   * Path part for operation deleteBuildingValuationById
   */
  static readonly DeleteBuildingValuationByIdPath = '/api/v1/Buildings/{id}/Valuations/{valuationId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteBuildingValuationById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteBuildingValuationById$Response(params: {
    id: string;
    valuationId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.DeleteBuildingValuationByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('valuationId', params.valuationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteBuildingValuationById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteBuildingValuationById(params: {
    id: string;
    valuationId: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.deleteBuildingValuationById$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation patchBuildingValuation
   */
  static readonly PatchBuildingValuationPath = '/api/v1/Buildings/{id}/Valuations/{valuationId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchBuildingValuation$Plain()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchBuildingValuation$Plain$Response(params: {
    id: string;
    valuationId: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<BuildingValuation>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.PatchBuildingValuationPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('valuationId', params.valuationId, {});
      rb.body(params.body, 'application/json-patch+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BuildingValuation>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchBuildingValuation$Plain$Response()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchBuildingValuation$Plain(params: {
    id: string;
    valuationId: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<BuildingValuation> {

    return this.patchBuildingValuation$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<BuildingValuation>) => r.body as BuildingValuation)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchBuildingValuation()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchBuildingValuation$Response(params: {
    id: string;
    valuationId: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<BuildingValuation>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.PatchBuildingValuationPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('valuationId', params.valuationId, {});
      rb.body(params.body, 'application/json-patch+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BuildingValuation>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchBuildingValuation$Response()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchBuildingValuation(params: {
    id: string;
    valuationId: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<BuildingValuation> {

    return this.patchBuildingValuation$Response(params,context).pipe(
      map((r: StrictHttpResponse<BuildingValuation>) => r.body as BuildingValuation)
    );
  }

  /**
   * Path part for operation putSelectedBuildingValue
   */
  static readonly PutSelectedBuildingValuePath = '/api/v1/Buildings/{id}/SelectedValue';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putSelectedBuildingValue$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  putSelectedBuildingValue$Plain$Response(params: {
    id: string;
    body?: BuildingValueOption
  },
  context?: HttpContext

): Observable<StrictHttpResponse<BuildingValueOption>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.PutSelectedBuildingValuePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BuildingValueOption>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `putSelectedBuildingValue$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  putSelectedBuildingValue$Plain(params: {
    id: string;
    body?: BuildingValueOption
  },
  context?: HttpContext

): Observable<BuildingValueOption> {

    return this.putSelectedBuildingValue$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<BuildingValueOption>) => r.body as BuildingValueOption)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putSelectedBuildingValue()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  putSelectedBuildingValue$Response(params: {
    id: string;
    body?: BuildingValueOption
  },
  context?: HttpContext

): Observable<StrictHttpResponse<BuildingValueOption>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.PutSelectedBuildingValuePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BuildingValueOption>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `putSelectedBuildingValue$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  putSelectedBuildingValue(params: {
    id: string;
    body?: BuildingValueOption
  },
  context?: HttpContext

): Observable<BuildingValueOption> {

    return this.putSelectedBuildingValue$Response(params,context).pipe(
      map((r: StrictHttpResponse<BuildingValueOption>) => r.body as BuildingValueOption)
    );
  }

  /**
   * Path part for operation getValueOptions
   */
  static readonly GetValueOptionsPath = '/api/v1/Buildings/{id}/ValueOptions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getValueOptions$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getValueOptions$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<BuildingValueOption>>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.GetValueOptionsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BuildingValueOption>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getValueOptions$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getValueOptions$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<BuildingValueOption>> {

    return this.getValueOptions$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<BuildingValueOption>>) => r.body as Array<BuildingValueOption>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getValueOptions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getValueOptions$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<BuildingValueOption>>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.GetValueOptionsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BuildingValueOption>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getValueOptions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getValueOptions(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<BuildingValueOption>> {

    return this.getValueOptions$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<BuildingValueOption>>) => r.body as Array<BuildingValueOption>)
    );
  }

  /**
   * Path part for operation getBuildingDocuments
   */
  static readonly GetBuildingDocumentsPath = '/api/v1/Buildings/{id}/Documents';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBuildingDocuments$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBuildingDocuments$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Document>>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.GetBuildingDocumentsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Document>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBuildingDocuments$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBuildingDocuments$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<Document>> {

    return this.getBuildingDocuments$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Document>>) => r.body as Array<Document>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getBuildingDocuments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBuildingDocuments$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Document>>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.GetBuildingDocumentsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Document>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getBuildingDocuments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getBuildingDocuments(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<Document>> {

    return this.getBuildingDocuments$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Document>>) => r.body as Array<Document>)
    );
  }

  /**
   * Path part for operation putBuildingDocument
   */
  static readonly PutBuildingDocumentPath = '/api/v1/Buildings/{id}/Documents/{documentId}';

  /**
   * Associates a document with this building.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putBuildingDocument$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  putBuildingDocument$Plain$Response(params: {
    id: string;
    documentId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Document>>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.PutBuildingDocumentPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('documentId', params.documentId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Document>>;
      })
    );
  }

  /**
   * Associates a document with this building.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `putBuildingDocument$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  putBuildingDocument$Plain(params: {
    id: string;
    documentId: string;
  },
  context?: HttpContext

): Observable<Array<Document>> {

    return this.putBuildingDocument$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Document>>) => r.body as Array<Document>)
    );
  }

  /**
   * Associates a document with this building.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putBuildingDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  putBuildingDocument$Response(params: {
    id: string;
    documentId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Document>>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.PutBuildingDocumentPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('documentId', params.documentId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Document>>;
      })
    );
  }

  /**
   * Associates a document with this building.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `putBuildingDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  putBuildingDocument(params: {
    id: string;
    documentId: string;
  },
  context?: HttpContext

): Observable<Array<Document>> {

    return this.putBuildingDocument$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Document>>) => r.body as Array<Document>)
    );
  }

  /**
   * Path part for operation deleteBuildingDocument
   */
  static readonly DeleteBuildingDocumentPath = '/api/v1/Buildings/{id}/Documents/{documentId}';

  /**
   * Removes the assocation of a document with this building.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteBuildingDocument$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteBuildingDocument$Plain$Response(params: {
    id: string;
    documentId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Document>>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.DeleteBuildingDocumentPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('documentId', params.documentId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Document>>;
      })
    );
  }

  /**
   * Removes the assocation of a document with this building.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteBuildingDocument$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteBuildingDocument$Plain(params: {
    id: string;
    documentId: string;
  },
  context?: HttpContext

): Observable<Array<Document>> {

    return this.deleteBuildingDocument$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Document>>) => r.body as Array<Document>)
    );
  }

  /**
   * Removes the assocation of a document with this building.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteBuildingDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteBuildingDocument$Response(params: {
    id: string;
    documentId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Document>>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.DeleteBuildingDocumentPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('documentId', params.documentId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Document>>;
      })
    );
  }

  /**
   * Removes the assocation of a document with this building.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteBuildingDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteBuildingDocument(params: {
    id: string;
    documentId: string;
  },
  context?: HttpContext

): Observable<Array<Document>> {

    return this.deleteBuildingDocument$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Document>>) => r.body as Array<Document>)
    );
  }

  /**
   * Path part for operation getRooms
   */
  static readonly GetRoomsPath = '/api/v1/Buildings/{id}/Rooms';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRooms$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRooms$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Room>>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.GetRoomsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Room>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRooms$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRooms$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<Room>> {

    return this.getRooms$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Room>>) => r.body as Array<Room>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRooms()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRooms$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Room>>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.GetRoomsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Room>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRooms$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRooms(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<Room>> {

    return this.getRooms$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Room>>) => r.body as Array<Room>)
    );
  }

  /**
   * Path part for operation addRoomById
   */
  static readonly AddRoomByIdPath = '/api/v1/Buildings/{id}/Rooms';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addRoomById$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addRoomById$Plain$Response(params: {
    id: string;
    body?: NewRoom
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Room>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.AddRoomByIdPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Room>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addRoomById$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addRoomById$Plain(params: {
    id: string;
    body?: NewRoom
  },
  context?: HttpContext

): Observable<Room> {

    return this.addRoomById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Room>) => r.body as Room)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addRoomById()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addRoomById$Response(params: {
    id: string;
    body?: NewRoom
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Room>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.AddRoomByIdPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Room>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addRoomById$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addRoomById(params: {
    id: string;
    body?: NewRoom
  },
  context?: HttpContext

): Observable<Room> {

    return this.addRoomById$Response(params,context).pipe(
      map((r: StrictHttpResponse<Room>) => r.body as Room)
    );
  }

  /**
   * Path part for operation deleteRoomById
   */
  static readonly DeleteRoomByIdPath = '/api/v1/Buildings/{id}/Rooms/{roomId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteRoomById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteRoomById$Response(params: {
    id: string;
    roomId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.DeleteRoomByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('roomId', params.roomId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteRoomById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteRoomById(params: {
    id: string;
    roomId: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.deleteRoomById$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getUnits
   */
  static readonly GetUnitsPath = '/api/v1/Buildings/{id}/Units';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUnits$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUnits$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Unit>>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.GetUnitsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Unit>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUnits$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUnits$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<Unit>> {

    return this.getUnits$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Unit>>) => r.body as Array<Unit>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUnits()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUnits$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Unit>>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.GetUnitsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Unit>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUnits$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUnits(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<Unit>> {

    return this.getUnits$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Unit>>) => r.body as Array<Unit>)
    );
  }

  /**
   * Path part for operation addUnitById
   */
  static readonly AddUnitByIdPath = '/api/v1/Buildings/{id}/Units';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addUnitById$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addUnitById$Plain$Response(params: {
    id: string;
    body?: NewUnit
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Building>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.AddUnitByIdPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Building>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addUnitById$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addUnitById$Plain(params: {
    id: string;
    body?: NewUnit
  },
  context?: HttpContext

): Observable<Building> {

    return this.addUnitById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Building>) => r.body as Building)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addUnitById()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addUnitById$Response(params: {
    id: string;
    body?: NewUnit
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Building>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.AddUnitByIdPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Building>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addUnitById$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addUnitById(params: {
    id: string;
    body?: NewUnit
  },
  context?: HttpContext

): Observable<Building> {

    return this.addUnitById$Response(params,context).pipe(
      map((r: StrictHttpResponse<Building>) => r.body as Building)
    );
  }

  /**
   * Path part for operation deleteUnitById
   */
  static readonly DeleteUnitByIdPath = '/api/v1/Buildings/{id}/Units/{unitId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteUnitById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUnitById$Response(params: {
    id: string;
    unitId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, BuildingsService.DeleteUnitByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('unitId', params.unitId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteUnitById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUnitById(params: {
    id: string;
    unitId: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.deleteUnitById$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
