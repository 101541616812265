import { Component, OnInit, Input, EventEmitter, Output, AfterViewInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CompanyProfile, CompanySearchResult } from '@app/core/stingray-api/models';
import { CompanyInfoService } from '@app/core/stingray-api/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'unite-company-autocomplete',
  templateUrl: './company-autocomplete.component.html',
  styleUrls: ['./company-autocomplete.component.scss']
})
export class CompanyAutocompleteComponent implements OnInit {

  @Input() control: FormControl;
  @Input() appearance: string = 'outline';
  @Input() label: string = 'Company name';
  @Input() class: string;

  @Output() companySelected = new EventEmitter<CompanyProfile>();

  isSearchingCompanies = false;
  companySearchResults: CompanySearchResult[] = [];

  constructor(
    private translatorService: TranslateService,
    private companyInfoService: CompanyInfoService) {
  }

  ngOnInit() {

    this.label = this.translatorService.instant(this.label);

    this.control.valueChanges
    .pipe(
      debounceTime(350),
      distinctUntilChanged(),
      untilDestroyed(this))
    .subscribe(value => {

      if (value ) {
        // Start a search
        if (typeof value === 'string' && value.trim() !== '') {
          this.isSearchingCompanies = true;
          this.companyInfoService.findCompanies({ tradeName: value, count: 10 })
            .pipe(finalize(() => this.isSearchingCompanies = false), untilDestroyed(this))
            .subscribe((companySearchResults) => {
              this.companySearchResults = companySearchResults;
            });
        } else {
          this.selectCompanySearchResult(value);
        }
      }
      
    });
  }

 
  
  selectCompanySearchResult(result: CompanySearchResult): void {
    if (result) {
      // Company info search result
      this.companyInfoService
      .getCompanyProfile({chamberOfCommerceNumber: result.chamberOfCommerceNumber})
      .subscribe(companyProfile => {
        this.companySelected.emit(companyProfile);
      });
    }
  }

  displayCompanySearchResult(result: any): string {
    if(typeof result === 'string' && result.trim() !== '' ) {
      return result;
    } else {
      return result?.name;
    }
  }
}
