import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ShellLoaderService } from '@app/shell/shell-loader.service';
import { ActivatedRoute } from '@angular/router';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

  constructor(
    private activatedRoute: ActivatedRoute,
    private shellLoaderService: ShellLoaderService) {

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.indexOf('octopus-broker-api-dev.azurewebsites.net') >= 0) {
      return next.handle(request);
    }

    if (request.method === 'PATCH' || request.method === 'PUT') {
      this.shellLoaderService.setRequestLoader(true);
    }

    return next.handle(request).pipe(
      finalize(() => {
        if (request.method === 'PATCH' || request.method === 'PUT') {
          this.shellLoaderService.setRequestLoader(false);
        }
      })
    );
  }
}
