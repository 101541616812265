/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Company } from '../models/company';
import { JsonPatchDocument } from '../models/json-patch-document';
import { NewCompany } from '../models/new-company';
import { Shareholder } from '../models/shareholder';

@Injectable({
  providedIn: 'root',
})
export class CompaniesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getCompanyById
   */
  static readonly GetCompanyByIdPath = '/api/v1/Companies/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompanyById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyById$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Company>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.GetCompanyByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Company>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompanyById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyById$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Company> {

    return this.getCompanyById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Company>) => r.body as Company)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompanyById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyById$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Company>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.GetCompanyByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Company>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompanyById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyById(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Company> {

    return this.getCompanyById$Response(params,context).pipe(
      map((r: StrictHttpResponse<Company>) => r.body as Company)
    );
  }

  /**
   * Path part for operation updateCompany
   */
  static readonly UpdateCompanyPath = '/api/v1/Companies/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCompany$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCompany$Plain$Response(params: {
    id: string;
    body?: Company
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Company>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.UpdateCompanyPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Company>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCompany$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCompany$Plain(params: {
    id: string;
    body?: Company
  },
  context?: HttpContext

): Observable<Company> {

    return this.updateCompany$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Company>) => r.body as Company)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCompany()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCompany$Response(params: {
    id: string;
    body?: Company
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Company>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.UpdateCompanyPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Company>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCompany$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCompany(params: {
    id: string;
    body?: Company
  },
  context?: HttpContext

): Observable<Company> {

    return this.updateCompany$Response(params,context).pipe(
      map((r: StrictHttpResponse<Company>) => r.body as Company)
    );
  }

  /**
   * Path part for operation deleteCompanyById
   */
  static readonly DeleteCompanyByIdPath = '/api/v1/Companies/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCompanyById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCompanyById$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.DeleteCompanyByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteCompanyById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCompanyById(params: {
    id: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.deleteCompanyById$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation patchCompany
   */
  static readonly PatchCompanyPath = '/api/v1/Companies/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchCompany$Plain()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchCompany$Plain$Response(params: {
    id: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Company>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.PatchCompanyPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json-patch+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Company>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchCompany$Plain$Response()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchCompany$Plain(params: {
    id: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<Company> {

    return this.patchCompany$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Company>) => r.body as Company)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchCompany()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchCompany$Response(params: {
    id: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Company>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.PatchCompanyPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json-patch+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Company>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchCompany$Response()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchCompany(params: {
    id: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<Company> {

    return this.patchCompany$Response(params,context).pipe(
      map((r: StrictHttpResponse<Company>) => r.body as Company)
    );
  }

  /**
   * Path part for operation addCompany
   */
  static readonly AddCompanyPath = '/api/v1/Companies';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addCompany$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addCompany$Plain$Response(params?: {
    body?: NewCompany
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Company>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.AddCompanyPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Company>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addCompany$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addCompany$Plain(params?: {
    body?: NewCompany
  },
  context?: HttpContext

): Observable<Company> {

    return this.addCompany$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Company>) => r.body as Company)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addCompany()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addCompany$Response(params?: {
    body?: NewCompany
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Company>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.AddCompanyPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Company>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addCompany$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addCompany(params?: {
    body?: NewCompany
  },
  context?: HttpContext

): Observable<Company> {

    return this.addCompany$Response(params,context).pipe(
      map((r: StrictHttpResponse<Company>) => r.body as Company)
    );
  }

  /**
   * Path part for operation addCompanyByChamberOfCommerceNumber
   */
  static readonly AddCompanyByChamberOfCommerceNumberPath = '/api/v1/Companies/{chamberOfCommerceNumber}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addCompanyByChamberOfCommerceNumber$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  addCompanyByChamberOfCommerceNumber$Plain$Response(params: {
    chamberOfCommerceNumber: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Company>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.AddCompanyByChamberOfCommerceNumberPath, 'post');
    if (params) {
      rb.path('chamberOfCommerceNumber', params.chamberOfCommerceNumber, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Company>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addCompanyByChamberOfCommerceNumber$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addCompanyByChamberOfCommerceNumber$Plain(params: {
    chamberOfCommerceNumber: string;
  },
  context?: HttpContext

): Observable<Company> {

    return this.addCompanyByChamberOfCommerceNumber$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Company>) => r.body as Company)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addCompanyByChamberOfCommerceNumber()` instead.
   *
   * This method doesn't expect any request body.
   */
  addCompanyByChamberOfCommerceNumber$Response(params: {
    chamberOfCommerceNumber: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Company>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.AddCompanyByChamberOfCommerceNumberPath, 'post');
    if (params) {
      rb.path('chamberOfCommerceNumber', params.chamberOfCommerceNumber, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Company>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addCompanyByChamberOfCommerceNumber$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addCompanyByChamberOfCommerceNumber(params: {
    chamberOfCommerceNumber: string;
  },
  context?: HttpContext

): Observable<Company> {

    return this.addCompanyByChamberOfCommerceNumber$Response(params,context).pipe(
      map((r: StrictHttpResponse<Company>) => r.body as Company)
    );
  }

  /**
   * Path part for operation getCompanyShareholdersById
   */
  static readonly GetCompanyShareholdersByIdPath = '/api/v1/Companies/{id}/Shareholders';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompanyShareholdersById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyShareholdersById$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Shareholder>>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.GetCompanyShareholdersByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Shareholder>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompanyShareholdersById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyShareholdersById$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<Shareholder>> {

    return this.getCompanyShareholdersById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Shareholder>>) => r.body as Array<Shareholder>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompanyShareholdersById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyShareholdersById$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Shareholder>>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.GetCompanyShareholdersByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Shareholder>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCompanyShareholdersById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyShareholdersById(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<Shareholder>> {

    return this.getCompanyShareholdersById$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Shareholder>>) => r.body as Array<Shareholder>)
    );
  }

  /**
   * Path part for operation updateCompanyShareholders
   */
  static readonly UpdateCompanyShareholdersPath = '/api/v1/Companies/{id}/Shareholders';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCompanyShareholders$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCompanyShareholders$Plain$Response(params: {
    id: string;
    body?: Array<Shareholder>
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Shareholder>>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.UpdateCompanyShareholdersPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Shareholder>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCompanyShareholders$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCompanyShareholders$Plain(params: {
    id: string;
    body?: Array<Shareholder>
  },
  context?: HttpContext

): Observable<Array<Shareholder>> {

    return this.updateCompanyShareholders$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Shareholder>>) => r.body as Array<Shareholder>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCompanyShareholders()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCompanyShareholders$Response(params: {
    id: string;
    body?: Array<Shareholder>
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Shareholder>>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.UpdateCompanyShareholdersPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Shareholder>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCompanyShareholders$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCompanyShareholders(params: {
    id: string;
    body?: Array<Shareholder>
  },
  context?: HttpContext

): Observable<Array<Shareholder>> {

    return this.updateCompanyShareholders$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Shareholder>>) => r.body as Array<Shareholder>)
    );
  }

  /**
   * Path part for operation addCompanyShareholders
   */
  static readonly AddCompanyShareholdersPath = '/api/v1/Companies/{id}/Shareholders';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addCompanyShareholders$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addCompanyShareholders$Plain$Response(params: {
    id: string;
    body?: Array<Shareholder>
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Shareholder>>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.AddCompanyShareholdersPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Shareholder>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addCompanyShareholders$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addCompanyShareholders$Plain(params: {
    id: string;
    body?: Array<Shareholder>
  },
  context?: HttpContext

): Observable<Array<Shareholder>> {

    return this.addCompanyShareholders$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Shareholder>>) => r.body as Array<Shareholder>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addCompanyShareholders()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addCompanyShareholders$Response(params: {
    id: string;
    body?: Array<Shareholder>
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Shareholder>>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.AddCompanyShareholdersPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Shareholder>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addCompanyShareholders$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addCompanyShareholders(params: {
    id: string;
    body?: Array<Shareholder>
  },
  context?: HttpContext

): Observable<Array<Shareholder>> {

    return this.addCompanyShareholders$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Shareholder>>) => r.body as Array<Shareholder>)
    );
  }

  /**
   * Path part for operation updateCompanyShareholder
   */
  static readonly UpdateCompanyShareholderPath = '/api/v1/Companies/{id}/Shareholders/{shareholderId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCompanyShareholder()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCompanyShareholder$Response(params: {
    id: string;
    shareholderId: string;
    body?: Shareholder
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.UpdateCompanyShareholderPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('shareholderId', params.shareholderId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCompanyShareholder$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCompanyShareholder(params: {
    id: string;
    shareholderId: string;
    body?: Shareholder
  },
  context?: HttpContext

): Observable<void> {

    return this.updateCompanyShareholder$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation deleteCompanyShareholderById
   */
  static readonly DeleteCompanyShareholderByIdPath = '/api/v1/Companies/{id}/Shareholders/{shareholderId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCompanyShareholderById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCompanyShareholderById$Response(params: {
    id: string;
    shareholderId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CompaniesService.DeleteCompanyShareholderByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('shareholderId', params.shareholderId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteCompanyShareholderById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCompanyShareholderById(params: {
    id: string;
    shareholderId: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.deleteCompanyShareholderById$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
