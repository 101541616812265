/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { SearchResults } from '../models/search-results';

@Injectable({
  providedIn: 'root',
})
export class SearchService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation searchByTerms
   */
  static readonly SearchByTermsPath = '/api/v1/Search';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchByTerms$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchByTerms$Plain$Response(params?: {
    count?: number;
    page?: number;
    terms?: string;
    customers?: boolean;
    mortgages?: boolean;
    buildings?: boolean;
    documents?: boolean;
    applications?: boolean;
    financings?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<SearchResults>> {

    const rb = new RequestBuilder(this.rootUrl, SearchService.SearchByTermsPath, 'get');
    if (params) {
      rb.query('count', params.count, {});
      rb.query('page', params.page, {});
      rb.query('terms', params.terms, {});
      rb.query('customers', params.customers, {});
      rb.query('mortgages', params.mortgages, {});
      rb.query('buildings', params.buildings, {});
      rb.query('documents', params.documents, {});
      rb.query('applications', params.applications, {});
      rb.query('financings', params.financings, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SearchResults>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchByTerms$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchByTerms$Plain(params?: {
    count?: number;
    page?: number;
    terms?: string;
    customers?: boolean;
    mortgages?: boolean;
    buildings?: boolean;
    documents?: boolean;
    applications?: boolean;
    financings?: boolean;
  },
  context?: HttpContext

): Observable<SearchResults> {

    return this.searchByTerms$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<SearchResults>) => r.body as SearchResults)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchByTerms()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchByTerms$Response(params?: {
    count?: number;
    page?: number;
    terms?: string;
    customers?: boolean;
    mortgages?: boolean;
    buildings?: boolean;
    documents?: boolean;
    applications?: boolean;
    financings?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<SearchResults>> {

    const rb = new RequestBuilder(this.rootUrl, SearchService.SearchByTermsPath, 'get');
    if (params) {
      rb.query('count', params.count, {});
      rb.query('page', params.page, {});
      rb.query('terms', params.terms, {});
      rb.query('customers', params.customers, {});
      rb.query('mortgages', params.mortgages, {});
      rb.query('buildings', params.buildings, {});
      rb.query('documents', params.documents, {});
      rb.query('applications', params.applications, {});
      rb.query('financings', params.financings, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SearchResults>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchByTerms$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchByTerms(params?: {
    count?: number;
    page?: number;
    terms?: string;
    customers?: boolean;
    mortgages?: boolean;
    buildings?: boolean;
    documents?: boolean;
    applications?: boolean;
    financings?: boolean;
  },
  context?: HttpContext

): Observable<SearchResults> {

    return this.searchByTerms$Response(params,context).pipe(
      map((r: StrictHttpResponse<SearchResults>) => r.body as SearchResults)
    );
  }

}
