export const environment = {
  app_name: 'Unite_dev',
  versionCheck: true,
  production: false,
  stingrayApiEndpoint: 'https://stingray-dev-api.azurewebsites.net',
  octopusApiEndpoint: 'https://octopus-dev-brokerapi.azurewebsites.net',
  defaultLanguage: 'nl-NL',
  supportedLanguages: ['en-US', 'nl-NL'],
  stingrayAuthentication: {
    clientID: 'eluZYkXo8sOPC3FNBqra_4UHrw_2RTur',
    domain: 'unite-nl.eu.auth0.com',
    scope: 'openid profile',
    baseUri: 'https://stingray-client-dev.unite.nl',
    redirectUri: 'https://stingray-client-dev.unite.nl/callback',
    returnUri: 'https://stingray-client-dev.unite.nl/login',
    audience: 'https://stingray-api.unite.nl',
    namespace: 'https://unite.nl/',
    logoutIdentityProviders: 'https://unite-nl.eu.auth0.com/v2/logout'
  },
  kadasterAuthentication: {
    scope: 'kikinzage',
    loginUri: 'https://authorization.kadaster.nl/auth/oauth/v2/authorize',
    redirectUri: 'https://stingray-client-dev.unite.nl/settings/kadaster/callback',
  }
};





