import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { ValuatorsService } from '@app/core/stingray-api/services';
import { Valuator } from '@app/core/stingray-api/models';


@Injectable()
export class ValuatorAutoCompleteService {

  constructor(private valuatorsService: ValuatorsService) { }

  getValuators(reload: boolean = false, ): Observable<Valuator[]> {
    //const cache = sessionStorage.getItem('valuatorAutoComplete');
    //if (reload === false && cache) {
    //  return of(JSON.parse(cache));
    //} else {
      return this.valuatorsService.getValuators().pipe(map((valuator: Valuator[]) => {
        sessionStorage.setItem('valuatorAutoComplete', JSON.stringify(valuator));
        return valuator;
      }));
    //}
  }

}
