import { Injectable } from '@angular/core';
import { Resolve, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { EMPTY, forkJoin, Observable, zip } from 'rxjs';
import { catchError, tap, switchMap, map } from 'rxjs/operators';
import { OrganizationsService } from '@app/core/stingray-api/services';
import { StingrayAuthenticationService } from '@app/core/authentication/stingray-authentication.service';
import { User, Organization } from '@app/core/stingray-api/models';
import { Angulartics2GoogleAnalytics } from 'angulartics2';
import { environment } from '@env/environment';
import { OctopusApiAuthenticationService } from '@app/core/authentication/octopus-api-authentication.service';
import { PortalService } from '@app/core/portal/portal.service';


@Injectable()
export class ShellGuard implements CanActivate {

  constructor(
    private angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics,
    private portalService: PortalService,
    private stingrayAuthenticationService: StingrayAuthenticationService,
    private octopusApiAuthenticationService: OctopusApiAuthenticationService,
    private organizationsService: OrganizationsService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.stingrayAuthenticationService.userProfile$.pipe(switchMap(() => {
      return forkJoin([this.organizationsService.getUserById({
        id: this.stingrayAuthenticationService.organizationId,
        userId: this.stingrayAuthenticationService.userId
      }), this.organizationsService.getOrganizationById({ id: this.stingrayAuthenticationService.organizationId })]).pipe(
        tap((shell: [User, Organization]) => {
          if (shell[0] === null) {
            alert('We could not find the user.');
            this.stingrayAuthenticationService.logout();
          } else if (shell[1] === null) {
            alert('We could not find your organization.');
            this.stingrayAuthenticationService.logout();
          } else { // Yes! We found the user and the organization :)
            this.stingrayAuthenticationService.user = shell[0];
            this.stingrayAuthenticationService.organization = shell[1];
            this.angulartics2GoogleAnalytics.setUsername(shell[0].id);

            this.portalService.setPortal(shell[1]);
            this.octopusApiAuthenticationService.renewToken(shell[1]);
          }
        }), map(() => true),
        catchError((e) => {
          console.error('We could not retrieve the user nor the organization.', e);
          alert('We could not retrieve the user nor the organization.');
          window.location.href = environment.stingrayAuthentication.logoutIdentityProviders;
          return EMPTY;
        })
      );
    }));
  }
}
