/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { AuditLogEntries } from '../models/audit-log-entries';

@Injectable({
  providedIn: 'root',
})
export class AuditLogsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getAuditLogEntityById
   */
  static readonly GetAuditLogEntityByIdPath = '/api/v1/AuditLogs/Entities/{entityId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAuditLogEntityById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAuditLogEntityById$Response(params: {
    entityId: string;
    entityShortName?: string;
    fieldName?: string;
    count?: number;
    page?: number;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AuditLogEntries>> {

    const rb = new RequestBuilder(this.rootUrl, AuditLogsService.GetAuditLogEntityByIdPath, 'get');
    if (params) {
      rb.path('entityId', params.entityId, {});
      rb.query('entityShortName', params.entityShortName, {});
      rb.query('fieldName', params.fieldName, {});
      rb.query('count', params.count, {});
      rb.query('page', params.page, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AuditLogEntries>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAuditLogEntityById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAuditLogEntityById(params: {
    entityId: string;
    entityShortName?: string;
    fieldName?: string;
    count?: number;
    page?: number;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<AuditLogEntries> {

    return this.getAuditLogEntityById$Response(params,context).pipe(
      map((r: StrictHttpResponse<AuditLogEntries>) => r.body as AuditLogEntries)
    );
  }

  /**
   * Path part for operation getAuditLogSessionById
   */
  static readonly GetAuditLogSessionByIdPath = '/api/v1/AuditLogs/Sessions/{sessionId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAuditLogSessionById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAuditLogSessionById$Response(params: {
    sessionId: string;
    count?: number;
    page?: number;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AuditLogEntries>> {

    const rb = new RequestBuilder(this.rootUrl, AuditLogsService.GetAuditLogSessionByIdPath, 'get');
    if (params) {
      rb.path('sessionId', params.sessionId, {});
      rb.query('count', params.count, {});
      rb.query('page', params.page, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AuditLogEntries>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAuditLogSessionById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAuditLogSessionById(params: {
    sessionId: string;
    count?: number;
    page?: number;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<AuditLogEntries> {

    return this.getAuditLogSessionById$Response(params,context).pipe(
      map((r: StrictHttpResponse<AuditLogEntries>) => r.body as AuditLogEntries)
    );
  }

  /**
   * Path part for operation getAuditLogEntries
   */
  static readonly GetAuditLogEntriesPath = '/api/v1/AuditLogs/Entries';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAuditLogEntries()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAuditLogEntries$Response(params?: {
    startDate?: string;
    endDate?: string;
    entityShortNames?: Array<string>;
    count?: number;
    page?: number;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<AuditLogEntries>> {

    const rb = new RequestBuilder(this.rootUrl, AuditLogsService.GetAuditLogEntriesPath, 'get');
    if (params) {
      rb.query('startDate', params.startDate, {});
      rb.query('endDate', params.endDate, {});
      rb.query('entityShortNames', params.entityShortNames, {});
      rb.query('count', params.count, {});
      rb.query('page', params.page, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<AuditLogEntries>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAuditLogEntries$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAuditLogEntries(params?: {
    startDate?: string;
    endDate?: string;
    entityShortNames?: Array<string>;
    count?: number;
    page?: number;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<AuditLogEntries> {

    return this.getAuditLogEntries$Response(params,context).pipe(
      map((r: StrictHttpResponse<AuditLogEntries>) => r.body as AuditLogEntries)
    );
  }

}
