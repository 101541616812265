/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { JsonPatchDocument } from '../models/json-patch-document';
import { NewValuation } from '../models/new-valuation';
import { Valuation } from '../models/valuation';
import { ValuationBuilding } from '../models/valuation-building';
import { Valuations } from '../models/valuations';

@Injectable({
  providedIn: 'root',
})
export class ValuationsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getValuations
   */
  static readonly GetValuationsPath = '/api/v1/Valuations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getValuations$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getValuations$Plain$Response(params?: {
    count?: number;
    page?: number;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Valuations>> {

    const rb = new RequestBuilder(this.rootUrl, ValuationsService.GetValuationsPath, 'get');
    if (params) {
      rb.query('count', params.count, {});
      rb.query('page', params.page, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Valuations>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getValuations$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getValuations$Plain(params?: {
    count?: number;
    page?: number;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Valuations> {

    return this.getValuations$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Valuations>) => r.body as Valuations)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getValuations()` instead.
   *
   * This method doesn't expect any request body.
   */
  getValuations$Response(params?: {
    count?: number;
    page?: number;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Valuations>> {

    const rb = new RequestBuilder(this.rootUrl, ValuationsService.GetValuationsPath, 'get');
    if (params) {
      rb.query('count', params.count, {});
      rb.query('page', params.page, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Valuations>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getValuations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getValuations(params?: {
    count?: number;
    page?: number;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Valuations> {

    return this.getValuations$Response(params,context).pipe(
      map((r: StrictHttpResponse<Valuations>) => r.body as Valuations)
    );
  }

  /**
   * Path part for operation addValuation
   */
  static readonly AddValuationPath = '/api/v1/Valuations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addValuation$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addValuation$Plain$Response(params?: {
    body?: NewValuation
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Valuation>> {

    const rb = new RequestBuilder(this.rootUrl, ValuationsService.AddValuationPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Valuation>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addValuation$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addValuation$Plain(params?: {
    body?: NewValuation
  },
  context?: HttpContext

): Observable<Valuation> {

    return this.addValuation$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Valuation>) => r.body as Valuation)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addValuation()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addValuation$Response(params?: {
    body?: NewValuation
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Valuation>> {

    const rb = new RequestBuilder(this.rootUrl, ValuationsService.AddValuationPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Valuation>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addValuation$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addValuation(params?: {
    body?: NewValuation
  },
  context?: HttpContext

): Observable<Valuation> {

    return this.addValuation$Response(params,context).pipe(
      map((r: StrictHttpResponse<Valuation>) => r.body as Valuation)
    );
  }

  /**
   * Path part for operation getValuationById
   */
  static readonly GetValuationByIdPath = '/api/v1/Valuations/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getValuationById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getValuationById$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Valuation>> {

    const rb = new RequestBuilder(this.rootUrl, ValuationsService.GetValuationByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Valuation>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getValuationById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getValuationById$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Valuation> {

    return this.getValuationById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Valuation>) => r.body as Valuation)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getValuationById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getValuationById$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Valuation>> {

    const rb = new RequestBuilder(this.rootUrl, ValuationsService.GetValuationByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Valuation>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getValuationById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getValuationById(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Valuation> {

    return this.getValuationById$Response(params,context).pipe(
      map((r: StrictHttpResponse<Valuation>) => r.body as Valuation)
    );
  }

  /**
   * Path part for operation updateValuation
   */
  static readonly UpdateValuationPath = '/api/v1/Valuations/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateValuation$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateValuation$Plain$Response(params: {
    id: string;
    body?: Valuation
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Valuation>> {

    const rb = new RequestBuilder(this.rootUrl, ValuationsService.UpdateValuationPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Valuation>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateValuation$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateValuation$Plain(params: {
    id: string;
    body?: Valuation
  },
  context?: HttpContext

): Observable<Valuation> {

    return this.updateValuation$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Valuation>) => r.body as Valuation)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateValuation()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateValuation$Response(params: {
    id: string;
    body?: Valuation
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Valuation>> {

    const rb = new RequestBuilder(this.rootUrl, ValuationsService.UpdateValuationPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Valuation>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateValuation$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateValuation(params: {
    id: string;
    body?: Valuation
  },
  context?: HttpContext

): Observable<Valuation> {

    return this.updateValuation$Response(params,context).pipe(
      map((r: StrictHttpResponse<Valuation>) => r.body as Valuation)
    );
  }

  /**
   * Path part for operation deleteValuationById
   */
  static readonly DeleteValuationByIdPath = '/api/v1/Valuations/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteValuationById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteValuationById$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ValuationsService.DeleteValuationByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteValuationById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteValuationById(params: {
    id: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.deleteValuationById$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation patchValuation
   */
  static readonly PatchValuationPath = '/api/v1/Valuations/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchValuation$Plain()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchValuation$Plain$Response(params: {
    id: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Valuation>> {

    const rb = new RequestBuilder(this.rootUrl, ValuationsService.PatchValuationPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json-patch+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Valuation>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchValuation$Plain$Response()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchValuation$Plain(params: {
    id: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<Valuation> {

    return this.patchValuation$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Valuation>) => r.body as Valuation)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchValuation()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchValuation$Response(params: {
    id: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Valuation>> {

    const rb = new RequestBuilder(this.rootUrl, ValuationsService.PatchValuationPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json-patch+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Valuation>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchValuation$Response()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchValuation(params: {
    id: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<Valuation> {

    return this.patchValuation$Response(params,context).pipe(
      map((r: StrictHttpResponse<Valuation>) => r.body as Valuation)
    );
  }

  /**
   * Path part for operation getValuationBuildings
   */
  static readonly GetValuationBuildingsPath = '/api/v1/Valuations/{id}/Buildings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getValuationBuildings$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getValuationBuildings$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ValuationBuilding>>> {

    const rb = new RequestBuilder(this.rootUrl, ValuationsService.GetValuationBuildingsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ValuationBuilding>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getValuationBuildings$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getValuationBuildings$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<ValuationBuilding>> {

    return this.getValuationBuildings$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ValuationBuilding>>) => r.body as Array<ValuationBuilding>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getValuationBuildings()` instead.
   *
   * This method doesn't expect any request body.
   */
  getValuationBuildings$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ValuationBuilding>>> {

    const rb = new RequestBuilder(this.rootUrl, ValuationsService.GetValuationBuildingsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ValuationBuilding>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getValuationBuildings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getValuationBuildings(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<ValuationBuilding>> {

    return this.getValuationBuildings$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ValuationBuilding>>) => r.body as Array<ValuationBuilding>)
    );
  }

  /**
   * Path part for operation addValuationBuildings
   */
  static readonly AddValuationBuildingsPath = '/api/v1/Valuations/{id}/Buildings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addValuationBuildings()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addValuationBuildings$Response(params: {
    id: string;
    body?: Array<ValuationBuilding>
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ValuationsService.AddValuationBuildingsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addValuationBuildings$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addValuationBuildings(params: {
    id: string;
    body?: Array<ValuationBuilding>
  },
  context?: HttpContext

): Observable<void> {

    return this.addValuationBuildings$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation updateValuationBuildingById
   */
  static readonly UpdateValuationBuildingByIdPath = '/api/v1/Valuations/{id}/Buildings/{buildingId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateValuationBuildingById$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateValuationBuildingById$Plain$Response(params: {
    id: string;
    buildingId: string;
    body?: ValuationBuilding
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ValuationBuilding>> {

    const rb = new RequestBuilder(this.rootUrl, ValuationsService.UpdateValuationBuildingByIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('buildingId', params.buildingId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ValuationBuilding>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateValuationBuildingById$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateValuationBuildingById$Plain(params: {
    id: string;
    buildingId: string;
    body?: ValuationBuilding
  },
  context?: HttpContext

): Observable<ValuationBuilding> {

    return this.updateValuationBuildingById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<ValuationBuilding>) => r.body as ValuationBuilding)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateValuationBuildingById()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateValuationBuildingById$Response(params: {
    id: string;
    buildingId: string;
    body?: ValuationBuilding
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ValuationBuilding>> {

    const rb = new RequestBuilder(this.rootUrl, ValuationsService.UpdateValuationBuildingByIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('buildingId', params.buildingId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ValuationBuilding>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateValuationBuildingById$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateValuationBuildingById(params: {
    id: string;
    buildingId: string;
    body?: ValuationBuilding
  },
  context?: HttpContext

): Observable<ValuationBuilding> {

    return this.updateValuationBuildingById$Response(params,context).pipe(
      map((r: StrictHttpResponse<ValuationBuilding>) => r.body as ValuationBuilding)
    );
  }

  /**
   * Path part for operation deleteValuationBuildingById
   */
  static readonly DeleteValuationBuildingByIdPath = '/api/v1/Valuations/{id}/Buildings/{buildingId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteValuationBuildingById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteValuationBuildingById$Response(params: {
    id: string;
    buildingId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ValuationsService.DeleteValuationBuildingByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('buildingId', params.buildingId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteValuationBuildingById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteValuationBuildingById(params: {
    id: string;
    buildingId: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.deleteValuationBuildingById$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation patchValuationBuildingById
   */
  static readonly PatchValuationBuildingByIdPath = '/api/v1/Valuations/{id}/Buildings/{buildingId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchValuationBuildingById$Plain()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchValuationBuildingById$Plain$Response(params: {
    id: string;
    buildingId: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ValuationBuilding>> {

    const rb = new RequestBuilder(this.rootUrl, ValuationsService.PatchValuationBuildingByIdPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('buildingId', params.buildingId, {});
      rb.body(params.body, 'application/json-patch+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ValuationBuilding>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchValuationBuildingById$Plain$Response()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchValuationBuildingById$Plain(params: {
    id: string;
    buildingId: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<ValuationBuilding> {

    return this.patchValuationBuildingById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<ValuationBuilding>) => r.body as ValuationBuilding)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchValuationBuildingById()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchValuationBuildingById$Response(params: {
    id: string;
    buildingId: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ValuationBuilding>> {

    const rb = new RequestBuilder(this.rootUrl, ValuationsService.PatchValuationBuildingByIdPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('buildingId', params.buildingId, {});
      rb.body(params.body, 'application/json-patch+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ValuationBuilding>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchValuationBuildingById$Response()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchValuationBuildingById(params: {
    id: string;
    buildingId: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<ValuationBuilding> {

    return this.patchValuationBuildingById$Response(params,context).pipe(
      map((r: StrictHttpResponse<ValuationBuilding>) => r.body as ValuationBuilding)
    );
  }

}
