<mat-form-field [appearance]="appearance" [class]="'small-field ' + class" [hideRequiredMarker]="hideRequiredMarker" [floatLabel]="label === '...' ? 'never' : 'auto'"
[ngClass]="{ 'mat-form-field-invalid' : showValidationError(), 'ng-invalid' : showValidationError() , 'maritalstatus-input-none': appearance === 'none' }">
  <mat-label *ngIf="label">{{label}}</mat-label>
  <span class="placeholder" *ngIf="showPlaceholder">
    {{control.value != null ? ( control.value | translate ) : "..."}}
  </span>
  <mat-select  [formControl]="control" >
    <mat-option [value]="null"><span translate>None</span></mat-option>
    <mat-option [value]="'Single'"><span translate>Single</span></mat-option>
    <mat-option [value]="'LivingTogetherWithCohabitationContract'"><span translate>LivingTogetherWithCohabitationContract</span></mat-option>
    <mat-option [value]="'LivingTogetherWithoutCohabitationContract'"><span translate>LivingTogetherWithoutCohabitationContract</span></mat-option>
    <mat-option [value]="'RegisteredPartnershipCommunityProperty'"><span translate>RegisteredPartnershipCommunityProperty</span></mat-option>
    <mat-option [value]="'RegisteredPartnershipPrenup'"><span translate>RegisteredPartnershipPrenup</span></mat-option>
    <mat-option [value]="'MarriedCommunityProperty'"><span translate>MarriedCommunityProperty</span></mat-option>
    <mat-option [value]="'MarriedPrenup'"><span translate>MarriedPrenup</span></mat-option>
    <mat-option [value]="'MarriedAccordingInternationalLaw'"><span translate>MarriedAccordingInternationalLaw</span></mat-option>
  </mat-select>
</mat-form-field>