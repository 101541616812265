import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { ProfessionalSubscriptionGuard } from './core/authentication/guards/professional-subscription.guard';
import { Shell } from './shell/shell.service';

const routes: Routes = [
  Shell.childRoutes(
    [
      { path: '', loadChildren: () => import('src/app/home/home.module').then(m => m.HomeModule) },
      { path: 'applications', loadChildren: () => import('src/app/applications/applications.module').then(m => m.ApplicationsModule) },
      { path: 'buildings', canActivate: [ProfessionalSubscriptionGuard], loadChildren: () => import('src/app/buildings/buildings.module').then(m => m.BuildingsModule) },
      { path: 'combined-proposals', canActivate: [ProfessionalSubscriptionGuard], loadChildren: () => import('src/app/combined-proposals/combined-proposals.module').then(m => m.CombinedProposalsModule) },
      { path: 'customers', canActivate: [ProfessionalSubscriptionGuard], loadChildren: () => import('src/app/customers/customers.module').then(m => m.CustomersModule) },
      { path: 'customers/:customerId', loadChildren: () => import('src/app/customer/customer.module').then(m => m.CustomerModule) },
      { path: 'financings', loadChildren: () => import('src/app/financings/financings.module').then(m => m.FinancingsModule) },
      { path: 'home', loadChildren: () => import('src/app/home/home.module').then(m => m.HomeModule) },
      { path: 'mortgages', canActivate: [ProfessionalSubscriptionGuard], loadChildren: () => import('src/app/mortgages/mortgages.module').then(m => m.MortgagesModule) },
      { path: 'portal', canActivate: [ProfessionalSubscriptionGuard], loadChildren: () => import('src/app/portal/portal.module').then(m => m.PortalModule) },
      { path: 'reports', loadChildren: () => import('src/app/reports/reports.module').then(m => m.ReportsModule) },
      { path: 'reports/:reportId', loadChildren: () => import('src/app/reports/reports.module').then(m => m.ReportsModule) },
      { path: 'settings', loadChildren: () => import('src/app/settings/settings.module').then(m => m.SettingsModule) },
      { path: 'tasks', canActivate: [ProfessionalSubscriptionGuard], loadChildren: () => import('src/app/tasks/tasks.module').then(m => m.TasksModule) }
    ]),
  // Fallback when no prior route is matched
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
