import { Injectable } from '@angular/core';
import { Organization } from '../stingray-api/models';
import { OrganizationsService } from '../stingray-api/services';

@Injectable()
export class OctopusApiAuthenticationService {

  // Constants
  private SS_ACCESS_TOKEN = 'octopus_access_token';

  constructor(
    private organizationService: OrganizationsService) {

  }

  /**
   * Remove token expiration from sessionStorage
   */
  clearToken(): void {
    sessionStorage.removeItem(this.SS_ACCESS_TOKEN);
  }

  getToken(): string {
    return sessionStorage.getItem(this.SS_ACCESS_TOKEN);
  }

  /**
   * Renews the token present in the session storage
   */
  renewToken(organization: Organization): void {

    if (!organization || !organization.octopusClientId) {
      console.warn('No Octopus Client Credentials found');
    } else {
      // Retrieve a new token from Stingray
      this.organizationService.getOctopusTokenById({ id: organization.id })
        .subscribe((token) => {
          sessionStorage.setItem(this.SS_ACCESS_TOKEN, token);
        });
    }
  }
}
