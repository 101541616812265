/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Account } from '../models/account';
import { Application } from '../models/application';
import { Building } from '../models/building';
import { Customer } from '../models/customer';
import { Customers } from '../models/customers';
import { Dashboard } from '../models/dashboard';
import { Financing } from '../models/financing';
import { FinancingCheckResult } from '../models/financing-check-result';
import { MergeBuildings } from '../models/merge-buildings';
import { Mortgage } from '../models/mortgage';
import { MoveBuilding } from '../models/move-building';
import { NewBuildingRegistration } from '../models/new-building-registration';
import { Note } from '../models/note';
import { Notes } from '../models/notes';
import { Proposals } from '../models/proposals';
import { PublicAccess } from '../models/public-access';
import { RelatedCustomer } from '../models/related-customer';
import { RentalBuilding } from '../models/rental-building';
import { RentalContract } from '../models/rental-contract';
import { Report } from '../models/report';
import { ValuationRecord } from '../models/valuation-record';

@Injectable({
  providedIn: 'root',
})
export class CustomersService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getCustomers
   */
  static readonly GetCustomersPath = '/api/v1/Customers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomers$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomers$Plain$Response(params?: {
    count?: number;
    page?: number;
    types?: Array<'Collaboration' | 'Company' | 'Person' | 'Prospect'>;
    name?: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Customers>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersService.GetCustomersPath, 'get');
    if (params) {
      rb.query('count', params.count, {});
      rb.query('page', params.page, {});
      rb.query('types', params.types, {});
      rb.query('name', params.name, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Customers>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomers$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomers$Plain(params?: {
    count?: number;
    page?: number;
    types?: Array<'Collaboration' | 'Company' | 'Person' | 'Prospect'>;
    name?: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Customers> {

    return this.getCustomers$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Customers>) => r.body as Customers)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomers$Response(params?: {
    count?: number;
    page?: number;
    types?: Array<'Collaboration' | 'Company' | 'Person' | 'Prospect'>;
    name?: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Customers>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersService.GetCustomersPath, 'get');
    if (params) {
      rb.query('count', params.count, {});
      rb.query('page', params.page, {});
      rb.query('types', params.types, {});
      rb.query('name', params.name, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Customers>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomers(params?: {
    count?: number;
    page?: number;
    types?: Array<'Collaboration' | 'Company' | 'Person' | 'Prospect'>;
    name?: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Customers> {

    return this.getCustomers$Response(params,context).pipe(
      map((r: StrictHttpResponse<Customers>) => r.body as Customers)
    );
  }

  /**
   * Path part for operation getCustomerCount
   */
  static readonly GetCustomerCountPath = '/api/v1/Customers/Count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerCount$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerCount$Plain$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersService.GetCustomerCountPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerCount$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerCount$Plain(params?: {
  },
  context?: HttpContext

): Observable<number> {

    return this.getCustomerCount$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerCount$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<number>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersService.GetCustomerCountPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerCount(params?: {
  },
  context?: HttpContext

): Observable<number> {

    return this.getCustomerCount$Response(params,context).pipe(
      map((r: StrictHttpResponse<number>) => r.body as number)
    );
  }

  /**
   * Path part for operation getCustomerFinancingCheck
   */
  static readonly GetCustomerFinancingCheckPath = '/api/v1/Customers/{id}/FinancingCheck';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerFinancingCheck$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerFinancingCheck$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<FinancingCheckResult>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersService.GetCustomerFinancingCheckPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FinancingCheckResult>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerFinancingCheck$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerFinancingCheck$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<FinancingCheckResult> {

    return this.getCustomerFinancingCheck$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<FinancingCheckResult>) => r.body as FinancingCheckResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerFinancingCheck()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerFinancingCheck$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<FinancingCheckResult>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersService.GetCustomerFinancingCheckPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FinancingCheckResult>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerFinancingCheck$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerFinancingCheck(params: {
    id: string;
  },
  context?: HttpContext

): Observable<FinancingCheckResult> {

    return this.getCustomerFinancingCheck$Response(params,context).pipe(
      map((r: StrictHttpResponse<FinancingCheckResult>) => r.body as FinancingCheckResult)
    );
  }

  /**
   * Path part for operation getCustomerById
   */
  static readonly GetCustomerByIdPath = '/api/v1/Customers/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerById$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Customer>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersService.GetCustomerByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Customer>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerById$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Customer> {

    return this.getCustomerById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Customer>) => r.body as Customer)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerById$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Customer>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersService.GetCustomerByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Customer>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerById(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Customer> {

    return this.getCustomerById$Response(params,context).pipe(
      map((r: StrictHttpResponse<Customer>) => r.body as Customer)
    );
  }

  /**
   * Path part for operation getCustomerAccountsById
   */
  static readonly GetCustomerAccountsByIdPath = '/api/v1/Customers/{id}/Accounts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerAccountsById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerAccountsById$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Account>>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersService.GetCustomerAccountsByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Account>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerAccountsById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerAccountsById$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<Account>> {

    return this.getCustomerAccountsById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Account>>) => r.body as Array<Account>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerAccountsById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerAccountsById$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Account>>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersService.GetCustomerAccountsByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Account>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerAccountsById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerAccountsById(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<Account>> {

    return this.getCustomerAccountsById$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Account>>) => r.body as Array<Account>)
    );
  }

  /**
   * Path part for operation getCustomerApplicationsById
   */
  static readonly GetCustomerApplicationsByIdPath = '/api/v1/Customers/{id}/Applications';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerApplicationsById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerApplicationsById$Plain$Response(params: {
    id: string;
    financingId?: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Application>>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersService.GetCustomerApplicationsByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('financingId', params.financingId, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Application>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerApplicationsById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerApplicationsById$Plain(params: {
    id: string;
    financingId?: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Array<Application>> {

    return this.getCustomerApplicationsById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Application>>) => r.body as Array<Application>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerApplicationsById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerApplicationsById$Response(params: {
    id: string;
    financingId?: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Application>>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersService.GetCustomerApplicationsByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('financingId', params.financingId, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Application>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerApplicationsById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerApplicationsById(params: {
    id: string;
    financingId?: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Array<Application>> {

    return this.getCustomerApplicationsById$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Application>>) => r.body as Array<Application>)
    );
  }

  /**
   * Path part for operation getCustomerNewBuildingRegistrations
   */
  static readonly GetCustomerNewBuildingRegistrationsPath = '/api/v1/Customers/{id}/NewBuildingRegistrations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerNewBuildingRegistrations$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerNewBuildingRegistrations$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<NewBuildingRegistration>>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersService.GetCustomerNewBuildingRegistrationsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<NewBuildingRegistration>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerNewBuildingRegistrations$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerNewBuildingRegistrations$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<NewBuildingRegistration>> {

    return this.getCustomerNewBuildingRegistrations$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<NewBuildingRegistration>>) => r.body as Array<NewBuildingRegistration>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerNewBuildingRegistrations()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerNewBuildingRegistrations$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<NewBuildingRegistration>>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersService.GetCustomerNewBuildingRegistrationsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<NewBuildingRegistration>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerNewBuildingRegistrations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerNewBuildingRegistrations(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<NewBuildingRegistration>> {

    return this.getCustomerNewBuildingRegistrations$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<NewBuildingRegistration>>) => r.body as Array<NewBuildingRegistration>)
    );
  }

  /**
   * Path part for operation getCustomerBuildingsById
   */
  static readonly GetCustomerBuildingsByIdPath = '/api/v1/Customers/{id}/Buildings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerBuildingsById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerBuildingsById$Plain$Response(params: {
    id: string;
    splitCombinations?: boolean;
    includeActions?: boolean;
    includeMortgages?: boolean;
    includeRenovations?: boolean;
    includeRentalContracts?: boolean;
    includeValuations?: boolean;
    includeUnits?: boolean;
    statuses?: Array<'Owned' | 'Purchase' | 'Sell' | 'Sold'>;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Building>>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersService.GetCustomerBuildingsByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('splitCombinations', params.splitCombinations, {});
      rb.query('includeActions', params.includeActions, {});
      rb.query('includeMortgages', params.includeMortgages, {});
      rb.query('includeRenovations', params.includeRenovations, {});
      rb.query('includeRentalContracts', params.includeRentalContracts, {});
      rb.query('includeValuations', params.includeValuations, {});
      rb.query('includeUnits', params.includeUnits, {});
      rb.query('statuses', params.statuses, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Building>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerBuildingsById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerBuildingsById$Plain(params: {
    id: string;
    splitCombinations?: boolean;
    includeActions?: boolean;
    includeMortgages?: boolean;
    includeRenovations?: boolean;
    includeRentalContracts?: boolean;
    includeValuations?: boolean;
    includeUnits?: boolean;
    statuses?: Array<'Owned' | 'Purchase' | 'Sell' | 'Sold'>;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Array<Building>> {

    return this.getCustomerBuildingsById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Building>>) => r.body as Array<Building>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerBuildingsById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerBuildingsById$Response(params: {
    id: string;
    splitCombinations?: boolean;
    includeActions?: boolean;
    includeMortgages?: boolean;
    includeRenovations?: boolean;
    includeRentalContracts?: boolean;
    includeValuations?: boolean;
    includeUnits?: boolean;
    statuses?: Array<'Owned' | 'Purchase' | 'Sell' | 'Sold'>;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Building>>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersService.GetCustomerBuildingsByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('splitCombinations', params.splitCombinations, {});
      rb.query('includeActions', params.includeActions, {});
      rb.query('includeMortgages', params.includeMortgages, {});
      rb.query('includeRenovations', params.includeRenovations, {});
      rb.query('includeRentalContracts', params.includeRentalContracts, {});
      rb.query('includeValuations', params.includeValuations, {});
      rb.query('includeUnits', params.includeUnits, {});
      rb.query('statuses', params.statuses, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Building>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerBuildingsById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerBuildingsById(params: {
    id: string;
    splitCombinations?: boolean;
    includeActions?: boolean;
    includeMortgages?: boolean;
    includeRenovations?: boolean;
    includeRentalContracts?: boolean;
    includeValuations?: boolean;
    includeUnits?: boolean;
    statuses?: Array<'Owned' | 'Purchase' | 'Sell' | 'Sold'>;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Array<Building>> {

    return this.getCustomerBuildingsById$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Building>>) => r.body as Array<Building>)
    );
  }

  /**
   * Path part for operation moveCustomerBuildings
   */
  static readonly MoveCustomerBuildingsPath = '/api/v1/Customers/{id}/Buildings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `moveCustomerBuildings()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  moveCustomerBuildings$Response(params: {
    id: string;
    body?: Array<MoveBuilding>
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersService.MoveCustomerBuildingsPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `moveCustomerBuildings$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  moveCustomerBuildings(params: {
    id: string;
    body?: Array<MoveBuilding>
  },
  context?: HttpContext

): Observable<void> {

    return this.moveCustomerBuildings$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation mergeCustomerBuildings
   */
  static readonly MergeCustomerBuildingsPath = '/api/v1/Customers/{id}/Buildings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mergeCustomerBuildings$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  mergeCustomerBuildings$Plain$Response(params: {
    id: string;
    body?: MergeBuildings
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Building>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersService.MergeCustomerBuildingsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Building>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `mergeCustomerBuildings$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  mergeCustomerBuildings$Plain(params: {
    id: string;
    body?: MergeBuildings
  },
  context?: HttpContext

): Observable<Building> {

    return this.mergeCustomerBuildings$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Building>) => r.body as Building)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mergeCustomerBuildings()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  mergeCustomerBuildings$Response(params: {
    id: string;
    body?: MergeBuildings
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Building>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersService.MergeCustomerBuildingsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Building>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `mergeCustomerBuildings$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  mergeCustomerBuildings(params: {
    id: string;
    body?: MergeBuildings
  },
  context?: HttpContext

): Observable<Building> {

    return this.mergeCustomerBuildings$Response(params,context).pipe(
      map((r: StrictHttpResponse<Building>) => r.body as Building)
    );
  }

  /**
   * Path part for operation getCustomerBuildingList
   */
  static readonly GetCustomerBuildingListPath = '/api/v1/Customers/{id}/BuildingList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerBuildingList$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerBuildingList$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PublicAccess>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersService.GetCustomerBuildingListPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PublicAccess>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerBuildingList$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerBuildingList$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<PublicAccess> {

    return this.getCustomerBuildingList$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<PublicAccess>) => r.body as PublicAccess)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerBuildingList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerBuildingList$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PublicAccess>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersService.GetCustomerBuildingListPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PublicAccess>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerBuildingList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerBuildingList(params: {
    id: string;
  },
  context?: HttpContext

): Observable<PublicAccess> {

    return this.getCustomerBuildingList$Response(params,context).pipe(
      map((r: StrictHttpResponse<PublicAccess>) => r.body as PublicAccess)
    );
  }

  /**
   * Path part for operation getCustomerBuildingRentalList
   */
  static readonly GetCustomerBuildingRentalListPath = '/api/v1/Customers/{id}/BuildingRentalList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerBuildingRentalList$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerBuildingRentalList$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PublicAccess>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersService.GetCustomerBuildingRentalListPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PublicAccess>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerBuildingRentalList$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerBuildingRentalList$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<PublicAccess> {

    return this.getCustomerBuildingRentalList$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<PublicAccess>) => r.body as PublicAccess)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerBuildingRentalList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerBuildingRentalList$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PublicAccess>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersService.GetCustomerBuildingRentalListPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PublicAccess>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerBuildingRentalList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerBuildingRentalList(params: {
    id: string;
  },
  context?: HttpContext

): Observable<PublicAccess> {

    return this.getCustomerBuildingRentalList$Response(params,context).pipe(
      map((r: StrictHttpResponse<PublicAccess>) => r.body as PublicAccess)
    );
  }

  /**
   * Path part for operation getCustomerDashboardById
   */
  static readonly GetCustomerDashboardByIdPath = '/api/v1/Customers/{id}/Dashboard';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerDashboardById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerDashboardById$Plain$Response(params: {
    id: string;
    referenceDate?: string;
    purposes?: Array<'Rent' | 'Sell' | 'Split' | 'OwnUse'>;
    statuses?: Array<'Owned' | 'Purchase' | 'Sell' | 'Sold'>;
    rentalPriceIndex?: number;
    operatingCostsIndex?: number;
    valueIncreasePercentage?: number;
    buildingIds?: Array<string>;
    includeVacancy?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Dashboard>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersService.GetCustomerDashboardByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('referenceDate', params.referenceDate, {});
      rb.query('purposes', params.purposes, {});
      rb.query('statuses', params.statuses, {});
      rb.query('rentalPriceIndex', params.rentalPriceIndex, {});
      rb.query('operatingCostsIndex', params.operatingCostsIndex, {});
      rb.query('valueIncreasePercentage', params.valueIncreasePercentage, {});
      rb.query('buildingIds', params.buildingIds, {});
      rb.query('includeVacancy', params.includeVacancy, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Dashboard>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerDashboardById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerDashboardById$Plain(params: {
    id: string;
    referenceDate?: string;
    purposes?: Array<'Rent' | 'Sell' | 'Split' | 'OwnUse'>;
    statuses?: Array<'Owned' | 'Purchase' | 'Sell' | 'Sold'>;
    rentalPriceIndex?: number;
    operatingCostsIndex?: number;
    valueIncreasePercentage?: number;
    buildingIds?: Array<string>;
    includeVacancy?: boolean;
  },
  context?: HttpContext

): Observable<Dashboard> {

    return this.getCustomerDashboardById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Dashboard>) => r.body as Dashboard)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerDashboardById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerDashboardById$Response(params: {
    id: string;
    referenceDate?: string;
    purposes?: Array<'Rent' | 'Sell' | 'Split' | 'OwnUse'>;
    statuses?: Array<'Owned' | 'Purchase' | 'Sell' | 'Sold'>;
    rentalPriceIndex?: number;
    operatingCostsIndex?: number;
    valueIncreasePercentage?: number;
    buildingIds?: Array<string>;
    includeVacancy?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Dashboard>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersService.GetCustomerDashboardByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('referenceDate', params.referenceDate, {});
      rb.query('purposes', params.purposes, {});
      rb.query('statuses', params.statuses, {});
      rb.query('rentalPriceIndex', params.rentalPriceIndex, {});
      rb.query('operatingCostsIndex', params.operatingCostsIndex, {});
      rb.query('valueIncreasePercentage', params.valueIncreasePercentage, {});
      rb.query('buildingIds', params.buildingIds, {});
      rb.query('includeVacancy', params.includeVacancy, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Dashboard>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerDashboardById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerDashboardById(params: {
    id: string;
    referenceDate?: string;
    purposes?: Array<'Rent' | 'Sell' | 'Split' | 'OwnUse'>;
    statuses?: Array<'Owned' | 'Purchase' | 'Sell' | 'Sold'>;
    rentalPriceIndex?: number;
    operatingCostsIndex?: number;
    valueIncreasePercentage?: number;
    buildingIds?: Array<string>;
    includeVacancy?: boolean;
  },
  context?: HttpContext

): Observable<Dashboard> {

    return this.getCustomerDashboardById$Response(params,context).pipe(
      map((r: StrictHttpResponse<Dashboard>) => r.body as Dashboard)
    );
  }

  /**
   * Path part for operation getCustomerFinancingOptions
   */
  static readonly GetCustomerFinancingOptionsPath = '/api/v1/Customers/{id}/FinancingOptions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerFinancingOptions$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerFinancingOptions$Plain$Response(params: {
    id: string;
    style?: 'Featured' | 'Top3';
    sort?: 'AverageTotalCostsPerMonth' | 'ContributionOwnResources' | 'Loan' | 'LoanToValue' | 'InterestRate' | 'TotalCosts' | 'MonthlyCharges' | 'FreeCashFlow' | 'ReturnOnEquity' | 'ReturnOnInvestment' | 'Quality';
    purposes?: Array<'Rent' | 'Sell' | 'Split' | 'OwnUse'>;
    statuses?: Array<'Owned' | 'Purchase' | 'Sell' | 'Sold'>;
    buildingIds?: Array<string>;
    count?: number;
    page?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Proposals>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersService.GetCustomerFinancingOptionsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('style', params.style, {});
      rb.query('sort', params.sort, {});
      rb.query('purposes', params.purposes, {});
      rb.query('statuses', params.statuses, {});
      rb.query('buildingIds', params.buildingIds, {});
      rb.query('count', params.count, {});
      rb.query('page', params.page, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Proposals>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerFinancingOptions$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerFinancingOptions$Plain(params: {
    id: string;
    style?: 'Featured' | 'Top3';
    sort?: 'AverageTotalCostsPerMonth' | 'ContributionOwnResources' | 'Loan' | 'LoanToValue' | 'InterestRate' | 'TotalCosts' | 'MonthlyCharges' | 'FreeCashFlow' | 'ReturnOnEquity' | 'ReturnOnInvestment' | 'Quality';
    purposes?: Array<'Rent' | 'Sell' | 'Split' | 'OwnUse'>;
    statuses?: Array<'Owned' | 'Purchase' | 'Sell' | 'Sold'>;
    buildingIds?: Array<string>;
    count?: number;
    page?: number;
  },
  context?: HttpContext

): Observable<Proposals> {

    return this.getCustomerFinancingOptions$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Proposals>) => r.body as Proposals)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerFinancingOptions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerFinancingOptions$Response(params: {
    id: string;
    style?: 'Featured' | 'Top3';
    sort?: 'AverageTotalCostsPerMonth' | 'ContributionOwnResources' | 'Loan' | 'LoanToValue' | 'InterestRate' | 'TotalCosts' | 'MonthlyCharges' | 'FreeCashFlow' | 'ReturnOnEquity' | 'ReturnOnInvestment' | 'Quality';
    purposes?: Array<'Rent' | 'Sell' | 'Split' | 'OwnUse'>;
    statuses?: Array<'Owned' | 'Purchase' | 'Sell' | 'Sold'>;
    buildingIds?: Array<string>;
    count?: number;
    page?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Proposals>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersService.GetCustomerFinancingOptionsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('style', params.style, {});
      rb.query('sort', params.sort, {});
      rb.query('purposes', params.purposes, {});
      rb.query('statuses', params.statuses, {});
      rb.query('buildingIds', params.buildingIds, {});
      rb.query('count', params.count, {});
      rb.query('page', params.page, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Proposals>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerFinancingOptions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerFinancingOptions(params: {
    id: string;
    style?: 'Featured' | 'Top3';
    sort?: 'AverageTotalCostsPerMonth' | 'ContributionOwnResources' | 'Loan' | 'LoanToValue' | 'InterestRate' | 'TotalCosts' | 'MonthlyCharges' | 'FreeCashFlow' | 'ReturnOnEquity' | 'ReturnOnInvestment' | 'Quality';
    purposes?: Array<'Rent' | 'Sell' | 'Split' | 'OwnUse'>;
    statuses?: Array<'Owned' | 'Purchase' | 'Sell' | 'Sold'>;
    buildingIds?: Array<string>;
    count?: number;
    page?: number;
  },
  context?: HttpContext

): Observable<Proposals> {

    return this.getCustomerFinancingOptions$Response(params,context).pipe(
      map((r: StrictHttpResponse<Proposals>) => r.body as Proposals)
    );
  }

  /**
   * Path part for operation getCustomerPerformanceOverview
   */
  static readonly GetCustomerPerformanceOverviewPath = '/api/v1/Customers/{id}/PerformanceOverview';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerPerformanceOverview$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerPerformanceOverview$Plain$Response(params: {
    id: string;
    buildingIds?: Array<string>;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PublicAccess>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersService.GetCustomerPerformanceOverviewPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('buildingIds', params.buildingIds, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PublicAccess>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerPerformanceOverview$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerPerformanceOverview$Plain(params: {
    id: string;
    buildingIds?: Array<string>;
  },
  context?: HttpContext

): Observable<PublicAccess> {

    return this.getCustomerPerformanceOverview$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<PublicAccess>) => r.body as PublicAccess)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerPerformanceOverview()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerPerformanceOverview$Response(params: {
    id: string;
    buildingIds?: Array<string>;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PublicAccess>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersService.GetCustomerPerformanceOverviewPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('buildingIds', params.buildingIds, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PublicAccess>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerPerformanceOverview$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerPerformanceOverview(params: {
    id: string;
    buildingIds?: Array<string>;
  },
  context?: HttpContext

): Observable<PublicAccess> {

    return this.getCustomerPerformanceOverview$Response(params,context).pipe(
      map((r: StrictHttpResponse<PublicAccess>) => r.body as PublicAccess)
    );
  }

  /**
   * Path part for operation getCustomerFinancingsById
   */
  static readonly GetCustomerFinancingsByIdPath = '/api/v1/Customers/{id}/Financings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerFinancingsById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerFinancingsById$Plain$Response(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Financing>>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersService.GetCustomerFinancingsByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Financing>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerFinancingsById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerFinancingsById$Plain(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Array<Financing>> {

    return this.getCustomerFinancingsById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Financing>>) => r.body as Array<Financing>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerFinancingsById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerFinancingsById$Response(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Financing>>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersService.GetCustomerFinancingsByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Financing>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerFinancingsById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerFinancingsById(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Array<Financing>> {

    return this.getCustomerFinancingsById$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Financing>>) => r.body as Array<Financing>)
    );
  }

  /**
   * Path part for operation getCustomerMortgagesById
   */
  static readonly GetCustomerMortgagesByIdPath = '/api/v1/Customers/{id}/Mortgages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerMortgagesById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerMortgagesById$Plain$Response(params: {
    id: string;
    statuses?: Array<'Concept' | 'Active' | 'Repayed'>;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Mortgage>>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersService.GetCustomerMortgagesByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('statuses', params.statuses, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Mortgage>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerMortgagesById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerMortgagesById$Plain(params: {
    id: string;
    statuses?: Array<'Concept' | 'Active' | 'Repayed'>;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Array<Mortgage>> {

    return this.getCustomerMortgagesById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Mortgage>>) => r.body as Array<Mortgage>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerMortgagesById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerMortgagesById$Response(params: {
    id: string;
    statuses?: Array<'Concept' | 'Active' | 'Repayed'>;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Mortgage>>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersService.GetCustomerMortgagesByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('statuses', params.statuses, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Mortgage>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerMortgagesById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerMortgagesById(params: {
    id: string;
    statuses?: Array<'Concept' | 'Active' | 'Repayed'>;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Array<Mortgage>> {

    return this.getCustomerMortgagesById$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Mortgage>>) => r.body as Array<Mortgage>)
    );
  }

  /**
   * Path part for operation getCustomerReportsById
   */
  static readonly GetCustomerReportsByIdPath = '/api/v1/Customers/{id}/Reports';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerReportsById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerReportsById$Plain$Response(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Report>>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersService.GetCustomerReportsByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Report>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerReportsById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerReportsById$Plain(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Array<Report>> {

    return this.getCustomerReportsById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Report>>) => r.body as Array<Report>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerReportsById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerReportsById$Response(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Report>>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersService.GetCustomerReportsByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Report>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerReportsById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerReportsById(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Array<Report>> {

    return this.getCustomerReportsById$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Report>>) => r.body as Array<Report>)
    );
  }

  /**
   * Path part for operation getRelatedCustomersById
   */
  static readonly GetRelatedCustomersByIdPath = '/api/v1/Customers/{id}/RelatedCustomers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRelatedCustomersById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRelatedCustomersById$Plain$Response(params: {
    id: string;
    includingChildren?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<RelatedCustomer>>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersService.GetRelatedCustomersByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('includingChildren', params.includingChildren, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RelatedCustomer>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRelatedCustomersById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRelatedCustomersById$Plain(params: {
    id: string;
    includingChildren?: boolean;
  },
  context?: HttpContext

): Observable<Array<RelatedCustomer>> {

    return this.getRelatedCustomersById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<RelatedCustomer>>) => r.body as Array<RelatedCustomer>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRelatedCustomersById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRelatedCustomersById$Response(params: {
    id: string;
    includingChildren?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<RelatedCustomer>>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersService.GetRelatedCustomersByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('includingChildren', params.includingChildren, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RelatedCustomer>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRelatedCustomersById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRelatedCustomersById(params: {
    id: string;
    includingChildren?: boolean;
  },
  context?: HttpContext

): Observable<Array<RelatedCustomer>> {

    return this.getRelatedCustomersById$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<RelatedCustomer>>) => r.body as Array<RelatedCustomer>)
    );
  }

  /**
   * Path part for operation getCustomerRentalBuildingsById
   */
  static readonly GetCustomerRentalBuildingsByIdPath = '/api/v1/Customers/{id}/RentalBuildings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerRentalBuildingsById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerRentalBuildingsById$Plain$Response(params: {
    id: string;
    storageFileName?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<RentalBuilding>>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersService.GetCustomerRentalBuildingsByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('storageFileName', params.storageFileName, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RentalBuilding>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerRentalBuildingsById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerRentalBuildingsById$Plain(params: {
    id: string;
    storageFileName?: string;
  },
  context?: HttpContext

): Observable<Array<RentalBuilding>> {

    return this.getCustomerRentalBuildingsById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<RentalBuilding>>) => r.body as Array<RentalBuilding>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerRentalBuildingsById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerRentalBuildingsById$Response(params: {
    id: string;
    storageFileName?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<RentalBuilding>>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersService.GetCustomerRentalBuildingsByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('storageFileName', params.storageFileName, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RentalBuilding>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerRentalBuildingsById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerRentalBuildingsById(params: {
    id: string;
    storageFileName?: string;
  },
  context?: HttpContext

): Observable<Array<RentalBuilding>> {

    return this.getCustomerRentalBuildingsById$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<RentalBuilding>>) => r.body as Array<RentalBuilding>)
    );
  }

  /**
   * Path part for operation updateCustomerRentalBuildingsById
   */
  static readonly UpdateCustomerRentalBuildingsByIdPath = '/api/v1/Customers/{id}/RentalBuildings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCustomerRentalBuildingsById$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCustomerRentalBuildingsById$Plain$Response(params: {
    id: string;
    body?: Array<RentalBuilding>
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<RentalBuilding>>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersService.UpdateCustomerRentalBuildingsByIdPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RentalBuilding>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCustomerRentalBuildingsById$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCustomerRentalBuildingsById$Plain(params: {
    id: string;
    body?: Array<RentalBuilding>
  },
  context?: HttpContext

): Observable<Array<RentalBuilding>> {

    return this.updateCustomerRentalBuildingsById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<RentalBuilding>>) => r.body as Array<RentalBuilding>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCustomerRentalBuildingsById()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCustomerRentalBuildingsById$Response(params: {
    id: string;
    body?: Array<RentalBuilding>
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<RentalBuilding>>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersService.UpdateCustomerRentalBuildingsByIdPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RentalBuilding>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCustomerRentalBuildingsById$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCustomerRentalBuildingsById(params: {
    id: string;
    body?: Array<RentalBuilding>
  },
  context?: HttpContext

): Observable<Array<RentalBuilding>> {

    return this.updateCustomerRentalBuildingsById$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<RentalBuilding>>) => r.body as Array<RentalBuilding>)
    );
  }

  /**
   * Path part for operation getCustomerRentalContracts
   */
  static readonly GetCustomerRentalContractsPath = '/api/v1/Customers/{id}/RentalContracts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerRentalContracts$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerRentalContracts$Plain$Response(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<RentalContract>>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersService.GetCustomerRentalContractsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RentalContract>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerRentalContracts$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerRentalContracts$Plain(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Array<RentalContract>> {

    return this.getCustomerRentalContracts$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<RentalContract>>) => r.body as Array<RentalContract>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerRentalContracts()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerRentalContracts$Response(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<RentalContract>>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersService.GetCustomerRentalContractsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RentalContract>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerRentalContracts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerRentalContracts(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Array<RentalContract>> {

    return this.getCustomerRentalContracts$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<RentalContract>>) => r.body as Array<RentalContract>)
    );
  }

  /**
   * Path part for operation getRentalContractList
   */
  static readonly GetRentalContractListPath = '/api/v1/Customers/{id}/RentalContractList';

  /**
   * Returns a file location where a rental contract list in Excel format can be downloaded.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRentalContractList$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRentalContractList$Plain$Response(params: {

    /**
     * The customer id
     */
    id: string;

    /**
     * Indicates wether person identifying data for tenants should be anonymized
     */
    anonymized?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PublicAccess>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersService.GetRentalContractListPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('anonymized', params.anonymized, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PublicAccess>;
      })
    );
  }

  /**
   * Returns a file location where a rental contract list in Excel format can be downloaded.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRentalContractList$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRentalContractList$Plain(params: {

    /**
     * The customer id
     */
    id: string;

    /**
     * Indicates wether person identifying data for tenants should be anonymized
     */
    anonymized?: boolean;
  },
  context?: HttpContext

): Observable<PublicAccess> {

    return this.getRentalContractList$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<PublicAccess>) => r.body as PublicAccess)
    );
  }

  /**
   * Returns a file location where a rental contract list in Excel format can be downloaded.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRentalContractList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRentalContractList$Response(params: {

    /**
     * The customer id
     */
    id: string;

    /**
     * Indicates wether person identifying data for tenants should be anonymized
     */
    anonymized?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PublicAccess>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersService.GetRentalContractListPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('anonymized', params.anonymized, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PublicAccess>;
      })
    );
  }

  /**
   * Returns a file location where a rental contract list in Excel format can be downloaded.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRentalContractList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRentalContractList(params: {

    /**
     * The customer id
     */
    id: string;

    /**
     * Indicates wether person identifying data for tenants should be anonymized
     */
    anonymized?: boolean;
  },
  context?: HttpContext

): Observable<PublicAccess> {

    return this.getRentalContractList$Response(params,context).pipe(
      map((r: StrictHttpResponse<PublicAccess>) => r.body as PublicAccess)
    );
  }

  /**
   * Path part for operation getCustomerNotes
   */
  static readonly GetCustomerNotesPath = '/api/v1/Customers/{id}/Notes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerNotes$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerNotes$Plain$Response(params: {
    id: string;
    count?: number;
    page?: number;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Notes>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersService.GetCustomerNotesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('count', params.count, {});
      rb.query('page', params.page, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Notes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerNotes$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerNotes$Plain(params: {
    id: string;
    count?: number;
    page?: number;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Notes> {

    return this.getCustomerNotes$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Notes>) => r.body as Notes)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerNotes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerNotes$Response(params: {
    id: string;
    count?: number;
    page?: number;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Notes>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersService.GetCustomerNotesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('count', params.count, {});
      rb.query('page', params.page, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Notes>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerNotes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerNotes(params: {
    id: string;
    count?: number;
    page?: number;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Notes> {

    return this.getCustomerNotes$Response(params,context).pipe(
      map((r: StrictHttpResponse<Notes>) => r.body as Notes)
    );
  }

  /**
   * Path part for operation addCustomerNote
   */
  static readonly AddCustomerNotePath = '/api/v1/Customers/{id}/Notes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addCustomerNote$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  addCustomerNote$Plain$Response(params: {
    id: string;
    body?: {
'note'?: string;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Note>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersService.AddCustomerNotePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Note>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addCustomerNote$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  addCustomerNote$Plain(params: {
    id: string;
    body?: {
'note'?: string;
}
  },
  context?: HttpContext

): Observable<Note> {

    return this.addCustomerNote$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Note>) => r.body as Note)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addCustomerNote()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  addCustomerNote$Response(params: {
    id: string;
    body?: {
'note'?: string;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Note>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersService.AddCustomerNotePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Note>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addCustomerNote$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  addCustomerNote(params: {
    id: string;
    body?: {
'note'?: string;
}
  },
  context?: HttpContext

): Observable<Note> {

    return this.addCustomerNote$Response(params,context).pipe(
      map((r: StrictHttpResponse<Note>) => r.body as Note)
    );
  }

  /**
   * Path part for operation getCustomerNote
   */
  static readonly GetCustomerNotePath = '/api/v1/Customers/{id}/Notes/{noteId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerNote$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerNote$Plain$Response(params: {
    id: string;
    noteId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Note>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersService.GetCustomerNotePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('noteId', params.noteId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Note>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerNote$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerNote$Plain(params: {
    id: string;
    noteId: string;
  },
  context?: HttpContext

): Observable<Note> {

    return this.getCustomerNote$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Note>) => r.body as Note)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerNote()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerNote$Response(params: {
    id: string;
    noteId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Note>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersService.GetCustomerNotePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('noteId', params.noteId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Note>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerNote$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerNote(params: {
    id: string;
    noteId: string;
  },
  context?: HttpContext

): Observable<Note> {

    return this.getCustomerNote$Response(params,context).pipe(
      map((r: StrictHttpResponse<Note>) => r.body as Note)
    );
  }

  /**
   * Path part for operation deleteCustomerNote
   */
  static readonly DeleteCustomerNotePath = '/api/v1/Customers/{id}/Notes/{noteId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCustomerNote$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCustomerNote$Plain$Response(params: {
    id: string;
    noteId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Note>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersService.DeleteCustomerNotePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('noteId', params.noteId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Note>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteCustomerNote$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCustomerNote$Plain(params: {
    id: string;
    noteId: string;
  },
  context?: HttpContext

): Observable<Note> {

    return this.deleteCustomerNote$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Note>) => r.body as Note)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCustomerNote()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCustomerNote$Response(params: {
    id: string;
    noteId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Note>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersService.DeleteCustomerNotePath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('noteId', params.noteId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Note>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteCustomerNote$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCustomerNote(params: {
    id: string;
    noteId: string;
  },
  context?: HttpContext

): Observable<Note> {

    return this.deleteCustomerNote$Response(params,context).pipe(
      map((r: StrictHttpResponse<Note>) => r.body as Note)
    );
  }

  /**
   * Path part for operation getCustomerUnregisteredBuildings
   */
  static readonly GetCustomerUnregisteredBuildingsPath = '/api/v1/Customers/{id}/UnregisteredBuildings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerUnregisteredBuildings$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerUnregisteredBuildings$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Building>>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersService.GetCustomerUnregisteredBuildingsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Building>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerUnregisteredBuildings$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerUnregisteredBuildings$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<Building>> {

    return this.getCustomerUnregisteredBuildings$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Building>>) => r.body as Array<Building>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerUnregisteredBuildings()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerUnregisteredBuildings$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Building>>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersService.GetCustomerUnregisteredBuildingsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Building>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerUnregisteredBuildings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerUnregisteredBuildings(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<Building>> {

    return this.getCustomerUnregisteredBuildings$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Building>>) => r.body as Array<Building>)
    );
  }

  /**
   * Path part for operation getCustomerValuations
   */
  static readonly GetCustomerValuationsPath = '/api/v1/Customers/{id}/Valuations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerValuations$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerValuations$Plain$Response(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ValuationRecord>>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersService.GetCustomerValuationsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ValuationRecord>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerValuations$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerValuations$Plain(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Array<ValuationRecord>> {

    return this.getCustomerValuations$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ValuationRecord>>) => r.body as Array<ValuationRecord>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCustomerValuations()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerValuations$Response(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ValuationRecord>>> {

    const rb = new RequestBuilder(this.rootUrl, CustomersService.GetCustomerValuationsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ValuationRecord>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCustomerValuations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCustomerValuations(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Array<ValuationRecord>> {

    return this.getCustomerValuations$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ValuationRecord>>) => r.body as Array<ValuationRecord>)
    );
  }

}
