import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { I18nService } from '@app/core/i18n.service';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { Angulartics2GoogleAnalytics } from 'angulartics2';
import { merge } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { StingrayAuthenticationService } from './core/authentication/stingray-authentication.service';
import { ToasterComponent } from './core/toaster/toaster.component';
import { Toast, ToasterService, ToastType } from './core/toaster/toaster.service';
import { VersionCheckService } from './core/version-check.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'unite-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  toasts: Toast[] = [];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private translateService: TranslateService,
    private i18nService: I18nService,
    private toasterService: ToasterService,
    private matSnackBar: MatSnackBar,
    private stingrayAuthenticationService: StingrayAuthenticationService,
    private versionCheckService: VersionCheckService,
    private angulartics2GoogleAnalytics: Angulartics2GoogleAnalytics) {

    if (environment.versionCheck) {
      this.versionCheckService.initVersionCheck('/assets/version.json');
    }


    if (environment.production === true) {
      this.angulartics2GoogleAnalytics.startTracking();
    }
  }

  ngOnInit(): void {

    // Check authentication
    this.stingrayAuthenticationService.localAuthSetup();

    // Setup translations
    this.i18nService.init(environment.defaultLanguage, environment.supportedLanguages);

    const onNavigationEnd = this.router.events.pipe(filter(event => event instanceof NavigationEnd));

    // Change page title on navigation or language change, based on route data
    merge(this.translateService.onLangChange, onNavigationEnd)
      .pipe(
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data),
        untilDestroyed(this)
      )
      .subscribe(event => {
        const title = event['title'];
        if (title) {
          this.titleService.setTitle(this.translateService.instant(title));
        }
      });

    // Toasts
    this.toasterService.getToast().subscribe((toast: Toast) => {
      if (!toast) { // Clear all toasts when an empty toast is received
        this.toasts = [];
        return;
      }

      this.toasts.push(toast);

      if (toast.type === ToastType.success) {
        this.matSnackBar.openFromComponent(ToasterComponent, { data: toast, duration: 4000, panelClass: toast.type });
      } else {
        this.matSnackBar.openFromComponent(ToasterComponent, { data: toast, duration: 5000, panelClass: toast.type });
      }
    });
  }

  ngOnDestroy(): void {
    this.i18nService.destroy();
  }
}
