import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'unite-customer-settings',
  templateUrl: './customer-settings.component.html',
  styleUrls: ['./customer-settings.component.scss']
})
export class CustomerSettingsComponent implements OnInit {

  @Input() isCreating = false;
  @Input() userControl: FormControl;
  @Input() existingControl: FormControl;
  @Input() businessunitControl: FormControl;

  constructor() { }

  ngOnInit() {
  }

}
