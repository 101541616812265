<div class="search-total">
  <div class="search-bar-input-container">
    <input fxFlex="100%" 
      #searchBox id="stingray-landing-searchbar" 
      #searchBarInput 
      type="text" 
      class="search-bar-input" 
      [placeholder]="placeholder" 
      [matAutocomplete]="auto" 
      (keyup)="searchTerm.next($event.target.value)" 
      (click)="inputClick();" 
      autocomplete="off">

    <label *ngIf="!loading" for="stingray-landing-searchbar" class="search-icon">
      <mat-icon>search</mat-icon>
    </label>

    <mat-spinner *ngIf="loading" class="search-bar-loader" diameter="24"></mat-spinner>
  </div>

  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="optionSelected($event)" class="search-autocomplete">

    <ng-container *ngIf="isProfessional">
      <div fxLayoutAlign="center center" class="m2" >
        <mat-chip-list multiple="true" >
          <mat-chip (click)="searchOptions.customers = !searchOptions.customers; research()" [selected]="searchOptions.customers" translate>Customers</mat-chip>
          <mat-chip (click)="searchOptions.buildings = !searchOptions.buildings; research()" [selected]="searchOptions.buildings" translate>Buildings</mat-chip>
          <mat-chip (click)="searchOptions.applications = !searchOptions.applications; research()" [selected]="searchOptions.applications" translate>Applications</mat-chip>
          <mat-chip (click)="searchOptions.financings = !searchOptions.financings; research()" [selected]="searchOptions.financings" translate>Financings</mat-chip>
          <mat-chip (click)="searchOptions.mortgages = !searchOptions.mortgages; research()" [selected]="searchOptions.mortgages" translate>Ongoing mortgages</mat-chip>
          <mat-chip (click)="searchOptions.documents = !searchOptions.documents; research()" [selected]="searchOptions.documents" translate>Documents</mat-chip>
          <mat-chip (click)="searchOptions.notes = !searchOptions.notes; research()" [selected]="searchOptions.notes" translate>Notes</mat-chip>
        </mat-chip-list>
      </div>

      <mat-divider></mat-divider>

      <!-- Hidden element -->
      <mat-option *ngIf="!searched" style="display: none;">
      </mat-option>
    </ng-container>


    <mat-option *ngIf="searched && hasNoResults()">
      <div fxLayout="row" fxLayoutAlign="start center">
        <span class="main bold black" translate>Nothing found</span>
      </div>
    </mat-option>

    <ng-container *ngFor="let record of searchResults?.records">

      <!-- Customers -->
    <mat-option *ngIf="record.type === 'Customer'" [routerLink]="['/customers/'+record.customerId+'/detail']" (click)="optionClick(record)" [value]="record.description">
      <div fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="row" fxLayoutAlign="start center" class="main bold black">
          <!-- Add the 'collaboration' class to the span element to indicate that it is a collab -->
          <span *ngIf="record.subtype == 'Collaboration'" class="bg-gray p1 mr2 collaboration">
            <mat-icon>people_outline</mat-icon>
          </span>
          <span *ngIf="record.subtype == 'Person'" class="bg-gray p1 mr2">
            <mat-icon>person_outline</mat-icon>
          </span>
          <span *ngIf="record.subtype == 'Company'" class="bg-gray p1 mr2">
            <mat-icon>business_center</mat-icon>
          </span>
          <span >{{record.description}}</span>
        </div>
        <!-- <div fxLayout="row" fxLayoutAlign="start center" >
          <a [routerLink]="['/customers/'+customer.id+'/buildings']" fxLayout="row" fxLayoutAlign="start center" class="sub">
            <mat-icon class="icon-small">business</mat-icon>
            <span class="accent mat-body-2">{{customer.buildings}} <span translate>buildings</span></span>
          </a>
          <a [routerLink]="['/customers/'+customer.id+'/mortgages']" fxLayout="row" fxLayoutAlign="start center" class="sub">
            <mat-icon class="icon-small">folder_open</mat-icon>
            <span class="accent mat-body-2">{{customer.mortgages}} <span translate>mortgages</span></span>
          </a>

        </div> -->
      </div>
    </mat-option>

    <!-- Buildings -->
    <mat-option *ngIf="record.type === 'Building'" [routerLink]="['/customers/'+record.customerId+'/buildings/'+record.id+'/detail']"  (click)="optionClick(record)"  [value]="record.description">
      <div fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="row" fxLayoutAlign="start center"
          class="main bold black">
          <span class="bg-gray p1 mr2">
            <mat-icon>business</mat-icon>
          </span>
          {{record.description}}
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" >
          <!-- <span fxLayout="row" fxLayoutAlign="start center" class="sub">
            <mat-icon class="icon-small">aspect_ratio</mat-icon>
            <span class="mat-body-2">{{building.surface}} m<sup>2</sup></span>
          </span> -->
          <a [routerLink]="['/customers/'+record.customerId+'/detail']" fxLayout="row" fxLayoutAlign="start center" class="sub">
            <mat-icon class="icon-small">person_outline</mat-icon>
            <span class="accent mat-body-2 "><span>{{record.customerName}}</span></span>
          </a>
        </div>
      </div>
    </mat-option>

    <!-- Mortgages -->
    <mat-option *ngIf="record.type === 'Mortgage'"  [routerLink]="['/customers/'+record.customerId+'/mortgages/'+record.id+'/detail']"  (click)="optionClick(record)"  [value]="record.description">
      <div fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="row" fxLayoutAlign="start center"
          class="main bold black">
          <span class="bg-gray p1 mr2">
            <mat-icon>folder_open</mat-icon>
          </span>
      {{record.description }}
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" >
          <!-- <span fxLayout="row" fxLayoutAlign="start center" class="sub">
            <mat-icon class="icon-small">account_balance</mat-icon>
            <span class="mat-body-2">{{mortgage.loan | currency:'EUR':'symbol':'1.0-0'}}</span>
          </span> -->
          <a [routerLink]="['/customers/'+record.customerId+'/detail']" fxLayout="row" fxLayoutAlign="start center" class="sub">
            <mat-icon class="icon-small">person_outline</mat-icon>
            <span class="accent mat-body-2 "><span>{{record.customerName}}</span></span>
          </a>
        </div>
      </div>
    </mat-option>

    <!-- Applications -->
    <mat-option *ngIf="record.type === 'Application'" [routerLink]="['/customers/'+record.customerId+'/applications/'+record.id+'/detail']"  (click)="optionClick(record)"  [value]="record.description">
      <div fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="row" fxLayoutAlign="start center"
          class="main bold black">
          <span class="bg-gray p1 mr2">
            <mat-icon>folder_open</mat-icon>
          </span>
          {{ record.description}}
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" >
          <!-- <span fxLayout="row" fxLayoutAlign="start center" class="sub">
            <mat-icon class="icon-small">account_balance</mat-icon>
            <span class="mat-body-2">{{application.loan | currency:'EUR':'symbol':'1.0-0'}}</span>
          </span> -->
          <a *ngIf="isProfessional" [routerLink]="['/customers/'+record.customerId+'/detail']" fxLayout="row" fxLayoutAlign="start center" class="sub">
            <mat-icon class="icon-small">person_outline</mat-icon>
            <span class="accent mat-body-2 "><span>{{record.customerName}}</span></span>
          </a>
          <span *ngIf="!isProfessional" fxLayout="row" fxLayoutAlign="start center" class="sub">
            <mat-icon class="icon-small">person_outline</mat-icon>
            <span class="accent mat-body-2 "><span>{{record.customerName}}</span></span>
          </span>
        </div>
      </div>
    </mat-option>
 
    <!-- Financings -->
    <mat-option *ngIf="record.type === 'Financing'"  [routerLink]="['/applications/start?financingId=' + record.Id ]"  (click)="optionClick(record)"  [value]="record.description">
      <div fxLayout="row" fxLayoutAlign="start center">
        <div fxLayout="row" fxLayoutAlign="start center"
          class="main bold black">
          <span class="bg-gray p1 mr2">
            <mat-icon>euro_symbol</mat-icon>
          </span>
          {{ record.description }}
        </div>
        <a *ngIf="isProfessional" [routerLink]="['/customers/'+record.customerId+'/detail']" fxLayout="row" fxLayoutAlign="start center" class="sub">
          <mat-icon class="icon-small">person_outline</mat-icon>
          <span class="accent mat-body-2 "><span>{{record.customerName}}</span></span>
        </a>
        <!-- <span *ngIf="!isProfessional && financing.customerType !== 'Prospect'" fxLayout="row" fxLayoutAlign="start center" class="sub">
          <mat-icon class="icon-small">person_outline</mat-icon>
          <span class="accent mat-body-2 "><span>{{financing.customerName}}</span></span>
        </span> -->
      </div>
    </mat-option>

  </ng-container>

  <!-- Notes -->
  <mat-option *ngFor="let note of searchResults?.notes" [routerLink]="['/customers/'+note.customerId+'/notes/' + note.noteId]">
      <div fxLayout="row" fxLayoutAlign="start center" class="doc">
        <span class="bg-gray p1 mr2">
          <mat-icon>mode_edit</mat-icon>
        </span>
        <div class="main">
          <div *ngIf="hasHighlightMatchOnField(note.highlights, 'content')" class="sub">
            <span [innerHTML]="getHighlightMatchOnField(note.highlights,'content')"></span>
          </div>
        </div>
      </div>
    </mat-option>

    <!-- Documents -->
    <mat-option *ngFor="let document of searchResults?.documents" (click)="downloadDocumentFromResult(document)" [value]="document.originalFileName">
      <div fxLayout="row" fxLayoutAlign="start center" class="doc">
        <span class="bg-gray p1 mr2">
          <mat-icon>insert_drive_file</mat-icon>
        </span>
        <div class="main">
          <div *ngIf="hasHighlightMatchOnField(document.highlights, 'originalFileName')" [innerHTML]="getHighlightMatchOnField(document.highlights,'originalFileName')"
            class="bold black">
          </div>
          <div *ngIf="!hasHighlightMatchOnField(document.highlights, 'originalFileName')" class="bold black">
            {{document.originalFileName}}
          </div>
          <div *ngIf="hasHighlightMatchOnField(document.highlights, 'content')" class="sub">
            <span [innerHTML]="getHighlightMatchOnField(document.highlights,'content')"></span>
          </div>
        </div>
      </div>
    </mat-option>
  </mat-autocomplete>
</div>