import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { registerLocaleData } from '@angular/common';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import localeEN from '@angular/common/locales/en';
import localeNl from '@angular/common/locales/nl';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';
import { CoreModule } from '@app/core/core.module';
import { LoginModule } from '@app/login/login.module';
import { MaterialModule } from '@app/material.module';
import { SharedModule } from '@app/shared/shared.module';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Angulartics2Module } from 'angulartics2';
import { QuillModule, QuillConfig } from 'ngx-quill';
import { ShellModule } from './shell/shell.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { environment } from '@env/environment';

// Import the library
import { StarRatingModule } from 'angular-star-rating';

registerLocaleData(localeNl, 'nl-NL');
registerLocaleData(localeEN, 'en-US');

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    CoreModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    TranslateModule.forRoot(),
    BrowserAnimationsModule,
    MaterialModule,
    Angulartics2Module.forRoot({
      pageTracking: {
        clearIds: true,
        clearHash: true,
      }
    }),
    SharedModule,
    ShellModule.forRoot(),
    QuillModule.forRoot(<QuillConfig>{
      format: 'html',
      placeholder: null,
      modules: {
        toolbar: [
          ['bold', 'italic'],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ]
      },
      theme: 'snow'
    }),
    LoginModule,
    AppRoutingModule,
    InfiniteScrollModule,

    // Use forRoot to import the StarRatingModule
    //StarRatingModule
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useFactory: (translate: TranslateService): string => translate.currentLang !== undefined ? translate.currentLang : environment.defaultLanguage,
      deps: [TranslateService]
    },
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
