import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { CustomersService } from '@app/core/stingray-api/services';
import { Customer, Customers } from '@app/core/stingray-api/models';

@Injectable()
export class CustomerAutoCompleteService {

  constructor(
    private customersService: CustomersService) { }

  getCustomers(reload: boolean = false, name: string = null): Observable<Customer[]> {
    const cache = sessionStorage.getItem('customerAutoComplete');
    if (reload === false && cache) {
      return of(JSON.parse(cache));
    } else {
      return this.customersService.getCustomers({
        types: ['Person', 'Company', 'Collaboration'],
        name: name,
        count: 20
      }).pipe(
        map((customers: Customers) => {
          sessionStorage.setItem('customerAutoComplete', JSON.stringify(customers.items));
          return customers.items;
        }));
    }
  }

}
