/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { TenantCompany } from '../models/tenant-company';
import { TenantPerson } from '../models/tenant-person';

@Injectable({
  providedIn: 'root',
})
export class TenantsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getTenantCompaniesByRentalContractId
   */
  static readonly GetTenantCompaniesByRentalContractIdPath = '/api/v1/Tenants/Companies';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTenantCompaniesByRentalContractId$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTenantCompaniesByRentalContractId$Plain$Response(params?: {
    rentalContractId?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TenantCompany>>> {

    const rb = new RequestBuilder(this.rootUrl, TenantsService.GetTenantCompaniesByRentalContractIdPath, 'get');
    if (params) {
      rb.query('rentalContractId', params.rentalContractId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TenantCompany>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTenantCompaniesByRentalContractId$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTenantCompaniesByRentalContractId$Plain(params?: {
    rentalContractId?: string;
  },
  context?: HttpContext

): Observable<Array<TenantCompany>> {

    return this.getTenantCompaniesByRentalContractId$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TenantCompany>>) => r.body as Array<TenantCompany>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTenantCompaniesByRentalContractId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTenantCompaniesByRentalContractId$Response(params?: {
    rentalContractId?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TenantCompany>>> {

    const rb = new RequestBuilder(this.rootUrl, TenantsService.GetTenantCompaniesByRentalContractIdPath, 'get');
    if (params) {
      rb.query('rentalContractId', params.rentalContractId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TenantCompany>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTenantCompaniesByRentalContractId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTenantCompaniesByRentalContractId(params?: {
    rentalContractId?: string;
  },
  context?: HttpContext

): Observable<Array<TenantCompany>> {

    return this.getTenantCompaniesByRentalContractId$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TenantCompany>>) => r.body as Array<TenantCompany>)
    );
  }

  /**
   * Path part for operation addTenantCompanyToRentalContract
   */
  static readonly AddTenantCompanyToRentalContractPath = '/api/v1/Tenants/Companies';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addTenantCompanyToRentalContract$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addTenantCompanyToRentalContract$Plain$Response(params?: {
    rentalContractId?: string;
    body?: TenantCompany
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TenantCompany>> {

    const rb = new RequestBuilder(this.rootUrl, TenantsService.AddTenantCompanyToRentalContractPath, 'post');
    if (params) {
      rb.query('rentalContractId', params.rentalContractId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TenantCompany>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addTenantCompanyToRentalContract$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addTenantCompanyToRentalContract$Plain(params?: {
    rentalContractId?: string;
    body?: TenantCompany
  },
  context?: HttpContext

): Observable<TenantCompany> {

    return this.addTenantCompanyToRentalContract$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<TenantCompany>) => r.body as TenantCompany)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addTenantCompanyToRentalContract()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addTenantCompanyToRentalContract$Response(params?: {
    rentalContractId?: string;
    body?: TenantCompany
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TenantCompany>> {

    const rb = new RequestBuilder(this.rootUrl, TenantsService.AddTenantCompanyToRentalContractPath, 'post');
    if (params) {
      rb.query('rentalContractId', params.rentalContractId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TenantCompany>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addTenantCompanyToRentalContract$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addTenantCompanyToRentalContract(params?: {
    rentalContractId?: string;
    body?: TenantCompany
  },
  context?: HttpContext

): Observable<TenantCompany> {

    return this.addTenantCompanyToRentalContract$Response(params,context).pipe(
      map((r: StrictHttpResponse<TenantCompany>) => r.body as TenantCompany)
    );
  }

  /**
   * Path part for operation getTenantCompany
   */
  static readonly GetTenantCompanyPath = '/api/v1/Tenants/Companies/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTenantCompany$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTenantCompany$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TenantCompany>> {

    const rb = new RequestBuilder(this.rootUrl, TenantsService.GetTenantCompanyPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TenantCompany>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTenantCompany$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTenantCompany$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<TenantCompany> {

    return this.getTenantCompany$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<TenantCompany>) => r.body as TenantCompany)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTenantCompany()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTenantCompany$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TenantCompany>> {

    const rb = new RequestBuilder(this.rootUrl, TenantsService.GetTenantCompanyPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TenantCompany>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTenantCompany$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTenantCompany(params: {
    id: string;
  },
  context?: HttpContext

): Observable<TenantCompany> {

    return this.getTenantCompany$Response(params,context).pipe(
      map((r: StrictHttpResponse<TenantCompany>) => r.body as TenantCompany)
    );
  }

  /**
   * Path part for operation updateTenantCompany
   */
  static readonly UpdateTenantCompanyPath = '/api/v1/Tenants/Companies/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTenantCompany$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateTenantCompany$Plain$Response(params: {
    id: string;
    body?: TenantCompany
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TenantCompany>> {

    const rb = new RequestBuilder(this.rootUrl, TenantsService.UpdateTenantCompanyPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TenantCompany>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateTenantCompany$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateTenantCompany$Plain(params: {
    id: string;
    body?: TenantCompany
  },
  context?: HttpContext

): Observable<TenantCompany> {

    return this.updateTenantCompany$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<TenantCompany>) => r.body as TenantCompany)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTenantCompany()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateTenantCompany$Response(params: {
    id: string;
    body?: TenantCompany
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TenantCompany>> {

    const rb = new RequestBuilder(this.rootUrl, TenantsService.UpdateTenantCompanyPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TenantCompany>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateTenantCompany$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateTenantCompany(params: {
    id: string;
    body?: TenantCompany
  },
  context?: HttpContext

): Observable<TenantCompany> {

    return this.updateTenantCompany$Response(params,context).pipe(
      map((r: StrictHttpResponse<TenantCompany>) => r.body as TenantCompany)
    );
  }

  /**
   * Path part for operation deleteTenantCompany
   */
  static readonly DeleteTenantCompanyPath = '/api/v1/Tenants/Companies/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteTenantCompany()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTenantCompany$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, TenantsService.DeleteTenantCompanyPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteTenantCompany$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTenantCompany(params: {
    id: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.deleteTenantCompany$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getTenantPersonsByRentalContractId
   */
  static readonly GetTenantPersonsByRentalContractIdPath = '/api/v1/Tenants/Persons';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTenantPersonsByRentalContractId$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTenantPersonsByRentalContractId$Plain$Response(params?: {
    rentalContractId?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TenantPerson>>> {

    const rb = new RequestBuilder(this.rootUrl, TenantsService.GetTenantPersonsByRentalContractIdPath, 'get');
    if (params) {
      rb.query('rentalContractId', params.rentalContractId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TenantPerson>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTenantPersonsByRentalContractId$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTenantPersonsByRentalContractId$Plain(params?: {
    rentalContractId?: string;
  },
  context?: HttpContext

): Observable<Array<TenantPerson>> {

    return this.getTenantPersonsByRentalContractId$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TenantPerson>>) => r.body as Array<TenantPerson>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTenantPersonsByRentalContractId()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTenantPersonsByRentalContractId$Response(params?: {
    rentalContractId?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<TenantPerson>>> {

    const rb = new RequestBuilder(this.rootUrl, TenantsService.GetTenantPersonsByRentalContractIdPath, 'get');
    if (params) {
      rb.query('rentalContractId', params.rentalContractId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<TenantPerson>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTenantPersonsByRentalContractId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTenantPersonsByRentalContractId(params?: {
    rentalContractId?: string;
  },
  context?: HttpContext

): Observable<Array<TenantPerson>> {

    return this.getTenantPersonsByRentalContractId$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<TenantPerson>>) => r.body as Array<TenantPerson>)
    );
  }

  /**
   * Path part for operation addTenantPersonToRentalContract
   */
  static readonly AddTenantPersonToRentalContractPath = '/api/v1/Tenants/Persons';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addTenantPersonToRentalContract$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addTenantPersonToRentalContract$Plain$Response(params?: {
    rentalContractId?: string;
    body?: TenantPerson
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TenantPerson>> {

    const rb = new RequestBuilder(this.rootUrl, TenantsService.AddTenantPersonToRentalContractPath, 'post');
    if (params) {
      rb.query('rentalContractId', params.rentalContractId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TenantPerson>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addTenantPersonToRentalContract$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addTenantPersonToRentalContract$Plain(params?: {
    rentalContractId?: string;
    body?: TenantPerson
  },
  context?: HttpContext

): Observable<TenantPerson> {

    return this.addTenantPersonToRentalContract$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<TenantPerson>) => r.body as TenantPerson)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addTenantPersonToRentalContract()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addTenantPersonToRentalContract$Response(params?: {
    rentalContractId?: string;
    body?: TenantPerson
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TenantPerson>> {

    const rb = new RequestBuilder(this.rootUrl, TenantsService.AddTenantPersonToRentalContractPath, 'post');
    if (params) {
      rb.query('rentalContractId', params.rentalContractId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TenantPerson>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addTenantPersonToRentalContract$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addTenantPersonToRentalContract(params?: {
    rentalContractId?: string;
    body?: TenantPerson
  },
  context?: HttpContext

): Observable<TenantPerson> {

    return this.addTenantPersonToRentalContract$Response(params,context).pipe(
      map((r: StrictHttpResponse<TenantPerson>) => r.body as TenantPerson)
    );
  }

  /**
   * Path part for operation getTenantPerson
   */
  static readonly GetTenantPersonPath = '/api/v1/Tenants/Persons/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTenantPerson$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTenantPerson$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TenantPerson>> {

    const rb = new RequestBuilder(this.rootUrl, TenantsService.GetTenantPersonPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TenantPerson>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTenantPerson$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTenantPerson$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<TenantPerson> {

    return this.getTenantPerson$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<TenantPerson>) => r.body as TenantPerson)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTenantPerson()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTenantPerson$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TenantPerson>> {

    const rb = new RequestBuilder(this.rootUrl, TenantsService.GetTenantPersonPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TenantPerson>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTenantPerson$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTenantPerson(params: {
    id: string;
  },
  context?: HttpContext

): Observable<TenantPerson> {

    return this.getTenantPerson$Response(params,context).pipe(
      map((r: StrictHttpResponse<TenantPerson>) => r.body as TenantPerson)
    );
  }

  /**
   * Path part for operation updateTenantPerson
   */
  static readonly UpdateTenantPersonPath = '/api/v1/Tenants/Persons/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTenantPerson$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateTenantPerson$Plain$Response(params: {
    id: string;
    body?: TenantPerson
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TenantPerson>> {

    const rb = new RequestBuilder(this.rootUrl, TenantsService.UpdateTenantPersonPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TenantPerson>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateTenantPerson$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateTenantPerson$Plain(params: {
    id: string;
    body?: TenantPerson
  },
  context?: HttpContext

): Observable<TenantPerson> {

    return this.updateTenantPerson$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<TenantPerson>) => r.body as TenantPerson)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTenantPerson()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateTenantPerson$Response(params: {
    id: string;
    body?: TenantPerson
  },
  context?: HttpContext

): Observable<StrictHttpResponse<TenantPerson>> {

    const rb = new RequestBuilder(this.rootUrl, TenantsService.UpdateTenantPersonPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<TenantPerson>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateTenantPerson$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateTenantPerson(params: {
    id: string;
    body?: TenantPerson
  },
  context?: HttpContext

): Observable<TenantPerson> {

    return this.updateTenantPerson$Response(params,context).pipe(
      map((r: StrictHttpResponse<TenantPerson>) => r.body as TenantPerson)
    );
  }

  /**
   * Path part for operation deleteTenantPerson
   */
  static readonly DeleteTenantPersonPath = '/api/v1/Tenants/Persons/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteTenantPerson()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTenantPerson$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, TenantsService.DeleteTenantPersonPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteTenantPerson$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTenantPerson(params: {
    id: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.deleteTenantPerson$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
