<form [formGroup]="companyGroup" fxLayout="column" class="pb2">

  <h3 class="mat-title mt2" fxLayout="row" fxLayoutAlign="start center">
    <mat-icon class="icon-small mr1">list_alt</mat-icon>
    <span translate>Company information</span>
  </h3>

  <unite-company-autocomplete [control]="companyGroup.get('name')" (companySelected)="companyProfileSelected($event)"></unite-company-autocomplete>

  <mat-form-field appearance="outline" class="small-field">
    <mat-label translate>Chamber of commerce number</mat-label>
    <input matInput type="text" formControlName="chamberOfCommerceNumber" minLength="8" maxLength="8" required autocomplete="off">
  </mat-form-field>

  <unite-legalform-input [legalFormControl]="companyGroup.get('legalForm')" fxLayout="column"></unite-legalform-input>

  <mat-form-field appearance="outline" class="small-field">
    <mat-label translate>Statutory name</mat-label>
    <input  matInput type="text" formControlName="statutoryName" minlength="1"
      autocomplete="off">
  </mat-form-field>

  <mat-form-field appearance="outline" class="small-field">
    <mat-label translate>Statutory location</mat-label>
    <input matInput type="text" formControlName="statutoryLocation" minlength="1" autocomplete="off"
      autocomplete="off">
  </mat-form-field>

  <mat-form-field appearance="outline" class="small-field">
    <mat-label translate>Founding date</mat-label>
    <input matInput [matDatepicker]="picker" formControlName="foundingDate" autocomplete="off">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>

  <unite-address-input-v2 class="mt2" formGroupName="address" fxLayout="column">
    <h3 class="mat-title mt2" fxLayout="row" fxLayoutAlign="start center">
      <mat-icon class="icon-small mr1">place</mat-icon>
      <span translate>Address</span>
    </h3>
  </unite-address-input-v2>

  <h3 class="mat-title mt2" fxLayout="row" fxLayoutAlign="start center">
    <mat-icon class="icon-small mr1">phone_iphone</mat-icon>
    <span translate>Contact information</span>
  </h3>
    
  <unite-contact-autocomplete [label]="'Contact person' | translate" [control]="companyGroup.get('contact')"></unite-contact-autocomplete>

  <div fxLayout="row" fxFlex="100" fxLayoutGap="3%">
    <mat-form-field fxFlex="50" appearance="outline">
      <mat-label translate>Telephone</mat-label>
      <input matInput type="text" formControlName="phone" autocomplete="off" pattern="((\+\d{8,14})|([0]\d{9}))">
    </mat-form-field>

    <mat-form-field fxFlex="50" appearance="outline">
      <mat-label translate>Mobile</mat-label>
      <input matInput type="text" formControlName="mobile" autocomplete="off" pattern="((\+\d{8,14})|([0]\d{9}))">
    </mat-form-field>
  </div>

  <mat-form-field appearance="outline">
    <mat-label translate>Email</mat-label>
    <input matInput email type="email" formControlName="email" autocomplete="off">
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label translate>IBAN</mat-label>
    <input matInput type="text" formControlName="iban" autocomplete="off">
  </mat-form-field>
  

  <unite-customer-settings 
    [userControl]="companyGroup.get('adviser')" 
    [existingControl]="companyGroup.get('existing')"
    [businessunitControl]="companyGroup.get('businessunit')"
    [isCreating]="true"></unite-customer-settings>

  <h3 class="mat-title mt2" fxLayout="row" fxLayoutAlign="start center">
    <mat-icon class="icon-small mr1">add_circle_outline</mat-icon>
    <span translate>Add company shareholders</span>
  </h3>

  <mat-form-field appearance="outline">
    <mat-icon matPrefix class="mr1">search</mat-icon>
    <mat-label translate>Search for a person or company</mat-label>
    <input matInput type="text" [formControl]="searchShareholderControl" [matAutocomplete]="auto" autocomplete="off">
    <mat-spinner matSuffix *ngIf="searchShareholderControlIsLoading" diameter="24" class="mr1"></mat-spinner>
  </mat-form-field>

  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displaySearchValue" (optionSelected)="addShareholder($event)"
    class="small-autocomplete">
    <ng-container *ngIf="!searchShareholderControlIsLoading">
      <mat-option *ngIf="searchShareholderControl.value && filteredShareholders && filteredShareholders.length == 0">
        <span class="main bold black" translate>Nothing found</span>
      </mat-option>
      <mat-option *ngFor="let filteredShareholder of filteredShareholders" [value]="filteredShareholder"
        fxLayout="row wrap" fxLayoutAlign="space-between center">
        <mat-icon *ngIf="filteredShareholder.type === 'Company'" class="icon-small">business_center</mat-icon>
        <mat-icon *ngIf="filteredShareholder.type === 'Person'" class="icon-small">person_outline</mat-icon>
        <span class="main bold black ">{{ filteredShareholder.name }}</span>
        <span class="sub ml2 " *ngIf="filteredShareholder.address">{{filteredShareholder.address.addressLine1}}, {{filteredShareholder.address.city}}</span>
        <span class="sub ml2 " *ngIf="!filteredShareholder.address" translate>Unknown</span>
      </mat-option>
    </ng-container>
  </mat-autocomplete>

  <mat-list class="boxed-list">
    <ng-container *ngFor="let shareholder of shareholders">
      <mat-list-item>
        <mat-icon *ngIf="shareholder.customerType === 'Company'" mat-list-icon>business_center</mat-icon>
        <mat-icon *ngIf="shareholder.customerType === 'Person'" mat-list-icon>person_outline</mat-icon>
        <h4 mat-line class="mat-title">
          {{shareholder.name}}
        </h4>
        <p mat-line fxLayout="row" fxLayoutAlign="start center">
          <span class="gray mr1" translate>Share</span>
          <strong class="mr1">{{shareholder.share}}%</strong>
          <button mat-icon-button (click)="changeShare(shareholder, shareholder.share - 1)">
            <mat-icon>expand_more</mat-icon>
          </button>
          <button mat-icon-button (click)="changeShare(shareholder, shareholder.share + 1)">
            <mat-icon>expand_less</mat-icon>
          </button>
        </p>
        <button mat-icon-button color="warn" (click)="removeShareholder(shareholder)">
          <mat-icon>clear</mat-icon>
        </button>
      </mat-list-item>
    </ng-container>
  </mat-list>


  <div fxLayout="row" fxLayoutAlign="end center" class="mt3">
    <unite-loading-button [text]="'Add company' | translate" [loading]="loadingCreate" icon="done" (buttonClick)="create()">
    </unite-loading-button>
  </div>
</form>