import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable, EMPTY, throwError } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import { StingrayAuthenticationService } from '../authentication/stingray-authentication.service';

@Injectable()
export class StingrayTokenInterceptor implements HttpInterceptor {

  constructor(private stingrayAuthenticationService: StingrayAuthenticationService) {

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes(environment.stingrayApiEndpoint) === false) {
      return next.handle(request);
    }

    return this.stingrayAuthenticationService.getTokenSilently$().pipe(
      mergeMap(token => {
        const tokenReq = request.clone({
          setHeaders: { Authorization: `Bearer ${token}` }
        });
        return next.handle(tokenReq);
      }),
      catchError(err => throwError(err))
    );
  }
}
