import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable()
export class ShellLoaderService {

  private requestLoader = new Subject<boolean>();
  private contentLoader = new Subject<boolean>();

  constructor() {

  }

  getRequestLoader(): Observable<boolean> {
    return this.requestLoader.asObservable();
  }

  setRequestLoader(loading: boolean) {
    this.requestLoader.next(loading);
  }

  getCustomerContentLoader(): Observable<boolean> {
    return this.contentLoader.asObservable();
  }

  setCustomerContentLoader(loading: boolean) {
    this.contentLoader.next(loading);
  }
}
