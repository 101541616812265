import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { NotariesService } from '@app/core/stingray-api/services';
import { Notary } from '@app/core/stingray-api/models';


@Injectable()
export class NotaryAutoCompleteService {

  constructor(private notariesService: NotariesService) { }

  getNotaries(reload: boolean = false): Observable<Notary[]> {
    //const cache = sessionStorage.getItem('valuatorAutoComplete');
    //if (reload === false && cache) {
    //  return of(JSON.parse(cache));
    //} else {
    return this.notariesService.getNotaries().pipe(map((notaries: Notary[]) => {
      sessionStorage.setItem('notaryAutoComplete', JSON.stringify(notaries));
      return notaries;
    }));
    //}
  }

}
