import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '@app/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgxFileDropModule } from 'ngx-file-drop';
import { AddressInputV2Component } from './address-input-v2/address-input-v2.component';
import { CountryAutocompleteComponent } from './country-autocomplete/country-autocomplete.component';
import { CountryAutoCompleteService } from './country-autocomplete/country-autocomplete.service';
import { CreateCollaborationComponent } from './create-customer/create-collaboration/create-collaboration.component';
import { CreateCompanyComponent } from './create-customer/create-company/create-company.component';
import { CreateDialogComponent } from './create-customer/create-dialog/create-dialog.component';
import { CreatePersonComponent } from './create-customer/create-person/create-person.component';
import { CustomerSettingsComponent } from './customer-settings/customer-settings.component';
import { DeleteDialogComponent } from './delete-dialog/delete-dialog.component';
import { FileApplicationDialogComponent } from './files/file-application-dialog/file-application-dialog.component';
import { FileDialogComponent } from './files/file-dialog/file-dialog.component';
import { FileObjectTabComponent } from './files/file-object-tab/file-object-tab.component';
import { FileRowComponent } from './files/file-row/file-row.component';
import { FileUploadComponent } from './files/file-upload/file-upload.component';
import { FilesTabComponent } from './files/files-tab/files-tab.component';
import { FilesService } from './files/files.service';
import { BooleanFieldComponent } from './forms/boolean-field/boolean-field.component';
import { BuildingPurposeFieldComponent } from './forms/building-purpose-field/building-purpose-field.component';
import { BuildingStatusFieldComponent } from './forms/building-status-field/building-status-field.component';
import { BuildingTypeFieldComponent } from './forms/building-type-field/building-type-field.component';
import { EnergyLabelFieldComponent } from './forms/energy-label-field/energy-label-field.component';
import { InputFieldComponent } from './forms/input-field/input-field.component';
import { IdentityDocumentInputComponent } from './identity-document-input/identity-document-input.component';
import { InfoDialogComponent } from './info-dialog/info-dialog.component';
import { LegalFormInputComponent } from './legalform-input/legalform-input.component';
import { ListItemInputComponent } from './list/list-item-input/list-item-input.component';
import { ListItemReadComponent } from './list/list-item-read/list-item-read.component';
import { ListItemSelectComponent } from './list/list-item-select/list-item-select.component';
import { LoadingButtonComponent } from './loading-button/loading-button.component';
import { MaritalStatusInputComponent } from './maritalstatus-input/maritalstatus-input.component';
import { NationalityAutocompleteComponent } from './nationality-autocomplete/nationality-autocomplete.component';
import { NationalityAutoCompleteService } from './nationality-autocomplete/nationality-autocomplete.service';
//import { SearchBoxComponent } from './search-box/search-box.component';
import { UserAutocompleteComponent } from './user-autocomplete/user-autocomplete.component';
import { UserAutoCompleteService } from './user-autocomplete/user-autocomplete.service';
import { ValuatorAutocompleteComponent } from './valuator-autocomplete/valuator-autocomplete.component';
import { LenderAutocompleteComponent } from './lender-autocomplete/lender-autocomplete.component';
import { RateAutocompleteComponent } from './rate-autocomplete/rate-autocomplete.component';
import { LenderAutoCompleteService } from './lender-autocomplete/lender-autocomplete.service';
import { RateAutoCompleteService } from './rate-autocomplete/rate-autocomplete.service';
import { ValuatorAutoCompleteService } from './valuator-autocomplete/valuator-autocomplete.service';
import { digitsOnlyDirective } from './directives/digits-only.directive';
import { CustomerAutoCompleteService } from './customer-autocomplete/customer-autocomplete.service';
import { CustomerAutocompleteComponent } from './customer-autocomplete/customer-autocomplete.component';
import { AuditListItemComponent } from './audit-list-item/audit-list-item.component';
import { AuditLogDetailComponent } from './audit-log-detail/audit-log-detail.component';
import { MortgageProgressComponent } from './mortgage-progress/mortgage-progress.component';
import { CloseDialogComponent } from './close-dialog/close-dialog.component';
import { CompleteDialogComponent } from './complete-dialog/complete-dialog.component';
import { AccountsShowComponent } from './accounts-show/accounts-show.component';
import { TaxPartnerAutocompleteComponent } from './tax-partner-autocomplete/tax-partner-autocomplete.component';
import { ContactAutocompleteComponent } from './contact-autocomplete/contact-autocomplete.component';
import { BuildingExpectedRentIncomePopupComponent } from './building-expectedrentincome-popup/building-expectedrentincome-popup.component';
import { LivingSituationFieldComponent } from './forms/living-situation-field/living-situation-field.component';
import { CreateByVirComponent } from './create-customer/create-by-vir/create-by-vir.component';
import { IncomeSourceFieldComponent } from './forms/income-source-field/income-source-field.component';
import { FindCadastralIdComponent } from './find-cadastralid/find-cadastralid.component';
import { NotaryAutocompleteComponent } from './notary-autocomplete/notary-autocomplete.component';
import { NotaryAutoCompleteService } from './notary-autocomplete/notary-autocomplete.service';
import { BusinessUnitInputComponent } from './businessunit-input/businessunit-input.component';
import { OriginOwnAssetsInputComponent } from './origin-own-assets-input/origin-own-assets-input.component';
import { IncomeSourceInputComponent } from './income-source-input/income-source-input.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { EditBuildingPanelComponent } from './edit-building-panel/edit-building-panel.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { SearchBoxV2Component } from './search-box-v2/search-box-v2.component';
import { CompanyAutocompleteComponent } from './company-autocomplete/company-autocomplete.component';
import { StarRatingModule } from 'angular-star-rating';
import { SearchCreateApplicantsComponent } from './search-create-applicants/search-create-applicants.component';
import { CalculateFinancingProposalsComponent } from './calculate-financing-proposals/calculate-financing-proposals.component';
import { SelectedProposalCardComponent } from './selected-proposal-card/selected-proposal-card.component';
import { MatDialogModule } from '@angular/material/dialog';
import { AddressSelectionListComponent } from './address-selection-list/address-selection-list.component';
import { CostsHitReachedComponent } from './costs-hit-reached/costs-hit-reached.component';
import { AddressRowFactory } from './address-selection-list/address-row-factory';
import { RentalTypeFieldComponent } from './forms/rental-type-field/rental-type-field.component';


@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    MatDialogModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    TranslateModule,
    FormsModule,
    RouterModule,
    NgxFileDropModule,
    NgxSliderModule,
    StarRatingModule.forRoot()
  ],
  declarations: [
    //SearchBoxComponent,
    SearchBoxV2Component,
    AddressInputV2Component,
    MaritalStatusInputComponent,
    IdentityDocumentInputComponent,
    CloseDialogComponent,
    CompleteDialogComponent,
    DeleteDialogComponent,
    ConfirmDialogComponent,
    BuildingExpectedRentIncomePopupComponent,
    LoadingButtonComponent,
    CreateDialogComponent,
    CreatePersonComponent,
    CreateCompanyComponent,
    CreateCollaborationComponent,
    ListItemInputComponent,
    ListItemReadComponent,
    FileRowComponent,
    FileDialogComponent,
    LegalFormInputComponent,
    FileUploadComponent,
    FileObjectTabComponent,
    FilesTabComponent,
    InputFieldComponent,
    EnergyLabelFieldComponent,
    BooleanFieldComponent,
    BuildingTypeFieldComponent,
    BuildingPurposeFieldComponent,
    ListItemSelectComponent,
    CustomerSettingsComponent,
    BuildingStatusFieldComponent,
    InfoDialogComponent,
    FileApplicationDialogComponent,
    ValuatorAutocompleteComponent,
    CountryAutocompleteComponent,
    UserAutocompleteComponent,
    NationalityAutocompleteComponent,
    LenderAutocompleteComponent,
    RateAutocompleteComponent,
    digitsOnlyDirective,
    CustomerAutocompleteComponent,
    AuditListItemComponent,
    AuditLogDetailComponent,
    MortgageProgressComponent,
    CloseDialogComponent,
    CompleteDialogComponent,
    AccountsShowComponent,
    TaxPartnerAutocompleteComponent,
    ContactAutocompleteComponent,
    LivingSituationFieldComponent,
    CreateByVirComponent,
    IncomeSourceFieldComponent,
    FindCadastralIdComponent,
    NotaryAutocompleteComponent,
    BusinessUnitInputComponent,
    OriginOwnAssetsInputComponent,
    IncomeSourceInputComponent,
    EditBuildingPanelComponent,
    CompanyAutocompleteComponent,
    SearchCreateApplicantsComponent,
    CalculateFinancingProposalsComponent,
    SelectedProposalCardComponent,
    AddressSelectionListComponent,
    CostsHitReachedComponent,
    RentalTypeFieldComponent,
    RentalTypeFieldComponent
  ],
  entryComponents: [
    CreateDialogComponent,
    CloseDialogComponent,
    CompleteDialogComponent,
    DeleteDialogComponent,
    ConfirmDialogComponent,
    BuildingExpectedRentIncomePopupComponent,
    FileDialogComponent,
    InfoDialogComponent,
    FileApplicationDialogComponent,
    FindCadastralIdComponent
  ],
  exports: [
    //SearchBoxComponent,
    SearchBoxV2Component,
    AddressInputV2Component,
    MaritalStatusInputComponent,
    IdentityDocumentInputComponent,
    CloseDialogComponent,
    CompleteDialogComponent,
    DeleteDialogComponent,
    ConfirmDialogComponent,
    BuildingExpectedRentIncomePopupComponent,
    LoadingButtonComponent,
    ListItemInputComponent,
    ListItemReadComponent,
    FileRowComponent,
    LegalFormInputComponent,
    FileUploadComponent,
    FileObjectTabComponent,
    FilesTabComponent,
    InputFieldComponent,
    EnergyLabelFieldComponent,
    BooleanFieldComponent,
    BuildingTypeFieldComponent,
    BuildingPurposeFieldComponent,
    ListItemSelectComponent,
    CustomerSettingsComponent,
    InfoDialogComponent,
    FileApplicationDialogComponent,
    CountryAutocompleteComponent,
    UserAutocompleteComponent,
    NationalityAutocompleteComponent,
    LenderAutocompleteComponent,
    RateAutocompleteComponent,
    ValuatorAutocompleteComponent,
    digitsOnlyDirective,
    CustomerAutocompleteComponent,
    AuditLogDetailComponent,
    MortgageProgressComponent,
    AccountsShowComponent,
    TaxPartnerAutocompleteComponent,
    ContactAutocompleteComponent,
    LivingSituationFieldComponent,
    IncomeSourceFieldComponent,
    BuildingStatusFieldComponent,
    NotaryAutocompleteComponent,
    BusinessUnitInputComponent,
    OriginOwnAssetsInputComponent,
    IncomeSourceInputComponent,
    CreatePersonComponent,
    CreateCompanyComponent,
    CreateCollaborationComponent,
    EditBuildingPanelComponent,
    CompanyAutocompleteComponent,
    SelectedProposalCardComponent,
    CalculateFinancingProposalsComponent,
    AddressSelectionListComponent,
    SearchCreateApplicantsComponent,
    CostsHitReachedComponent
  ],
  providers: [
    FilesService,
    CountryAutoCompleteService,
    UserAutoCompleteService,
    NationalityAutoCompleteService,
    LenderAutoCompleteService,
    RateAutoCompleteService,
    ValuatorAutoCompleteService,
    CustomerAutoCompleteService,
    NotaryAutoCompleteService,
    AddressRowFactory
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return <ModuleWithProviders<SharedModule>>{
      ngModule: SharedModule,
      providers: [
        FilesService,
        CountryAutoCompleteService,
        UserAutoCompleteService,
        NationalityAutoCompleteService,
        LenderAutoCompleteService,
        RateAutoCompleteService,
        ValuatorAutoCompleteService,
        CustomerAutoCompleteService,
        NotaryAutoCompleteService
      ]
    };
  }
}
