<div fxFlex fxLayout="row" fxLayoutAlign="start stretch">
  <div fxFlex="25%" fxFlex.lt-md="100%" fxLayout="column" fxLayoutAlign="center center" class="left-banner">
    <img src="/assets/unite-logo.svg" alt="Unite" class="logo" />
    <button mat-raised-button color="primary" (click)="login()" [disabled]="isLoading" class="login-button">
      <span translate>Login as advisor</span>
    </button>
    <div class="float-button">
      <button mat-button [matMenuTriggerFor]="languageMenu">
        <span *ngIf="currentLanguage == 'en-US'" translate>English</span>
        <span *ngIf="currentLanguage == 'nl-NL'" translate>Dutch</span>
      </button>
      <mat-menu #languageMenu="matMenu">
        <button [disabled]="currentLanguage == 'nl-NL'" mat-menu-item (click)="setLanguage('nl-NL')">
          <mat-icon>language</mat-icon>
          <span translate>Set to Dutch</span>
        </button>
        <button [disabled]="currentLanguage == 'en-US'" mat-menu-item (click)="setLanguage('en-US')">
          <mat-icon>language</mat-icon>
          <span translate>Set to English</span>
        </button>
      </mat-menu>
    </div>
  </div>
  <div fxFlex="75%" fxHide.lt-md class="right-banner">

  </div>
</div>