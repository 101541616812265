import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'unite-legalform-input',
  templateUrl: './legalform-input.component.html',
  styleUrls: ['./legalform-input.component.scss']
})
export class LegalFormInputComponent implements OnInit {

  @Input() legalFormControl: FormControl;

  constructor() {

  }

  ngOnInit() {

  }

}


