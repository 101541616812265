/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Arrangement } from '../models/arrangement';
import { CombinedProposals } from '../models/combined-proposals';
import { CombinedProposalsStatus } from '../models/combined-proposals-status';
import { ComposeProposalsResult } from '../models/compose-proposals-result';
import { Customer } from '../models/customer';
import { Financing } from '../models/financing';
import { FinancingBuilding } from '../models/financing-building';
import { FinancingLender } from '../models/financing-lender';
import { FinancingMortgage } from '../models/financing-mortgage';
import { Financings } from '../models/financings';
import { JsonPatchDocument } from '../models/json-patch-document';
import { NewFinancing } from '../models/new-financing';
import { Offer } from '../models/offer';
import { Proposals } from '../models/proposals';

@Injectable({
  providedIn: 'root',
})
export class FinancingsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getFinancings
   */
  static readonly GetFinancingsPath = '/api/v1/Financings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFinancings$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFinancings$Plain$Response(params?: {
    count?: number;
    page?: number;
    statuses?: Array<'New' | 'Requested' | 'Calculated' | 'Completed'>;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Financings>> {

    const rb = new RequestBuilder(this.rootUrl, FinancingsService.GetFinancingsPath, 'get');
    if (params) {
      rb.query('count', params.count, {});
      rb.query('page', params.page, {});
      rb.query('statuses', params.statuses, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Financings>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFinancings$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFinancings$Plain(params?: {
    count?: number;
    page?: number;
    statuses?: Array<'New' | 'Requested' | 'Calculated' | 'Completed'>;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Financings> {

    return this.getFinancings$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Financings>) => r.body as Financings)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFinancings()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFinancings$Response(params?: {
    count?: number;
    page?: number;
    statuses?: Array<'New' | 'Requested' | 'Calculated' | 'Completed'>;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Financings>> {

    const rb = new RequestBuilder(this.rootUrl, FinancingsService.GetFinancingsPath, 'get');
    if (params) {
      rb.query('count', params.count, {});
      rb.query('page', params.page, {});
      rb.query('statuses', params.statuses, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Financings>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFinancings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFinancings(params?: {
    count?: number;
    page?: number;
    statuses?: Array<'New' | 'Requested' | 'Calculated' | 'Completed'>;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Financings> {

    return this.getFinancings$Response(params,context).pipe(
      map((r: StrictHttpResponse<Financings>) => r.body as Financings)
    );
  }

  /**
   * Path part for operation addFinancing
   */
  static readonly AddFinancingPath = '/api/v1/Financings';

  /**
   * Adds financing based on buildings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addFinancing$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addFinancing$Plain$Response(params?: {
    body?: NewFinancing
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Financing>>> {

    const rb = new RequestBuilder(this.rootUrl, FinancingsService.AddFinancingPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Financing>>;
      })
    );
  }

  /**
   * Adds financing based on buildings.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addFinancing$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addFinancing$Plain(params?: {
    body?: NewFinancing
  },
  context?: HttpContext

): Observable<Array<Financing>> {

    return this.addFinancing$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Financing>>) => r.body as Array<Financing>)
    );
  }

  /**
   * Adds financing based on buildings.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addFinancing()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addFinancing$Response(params?: {
    body?: NewFinancing
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Financing>>> {

    const rb = new RequestBuilder(this.rootUrl, FinancingsService.AddFinancingPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Financing>>;
      })
    );
  }

  /**
   * Adds financing based on buildings.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addFinancing$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addFinancing(params?: {
    body?: NewFinancing
  },
  context?: HttpContext

): Observable<Array<Financing>> {

    return this.addFinancing$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Financing>>) => r.body as Array<Financing>)
    );
  }

  /**
   * Path part for operation getFinancingById
   */
  static readonly GetFinancingByIdPath = '/api/v1/Financings/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFinancingById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFinancingById$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Financing>> {

    const rb = new RequestBuilder(this.rootUrl, FinancingsService.GetFinancingByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Financing>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFinancingById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFinancingById$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Financing> {

    return this.getFinancingById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Financing>) => r.body as Financing)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFinancingById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFinancingById$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Financing>> {

    const rb = new RequestBuilder(this.rootUrl, FinancingsService.GetFinancingByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Financing>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFinancingById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFinancingById(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Financing> {

    return this.getFinancingById$Response(params,context).pipe(
      map((r: StrictHttpResponse<Financing>) => r.body as Financing)
    );
  }

  /**
   * Path part for operation updateFinancing
   */
  static readonly UpdateFinancingPath = '/api/v1/Financings/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateFinancing$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateFinancing$Plain$Response(params: {
    id: string;
    body?: Financing
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Financing>> {

    const rb = new RequestBuilder(this.rootUrl, FinancingsService.UpdateFinancingPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Financing>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateFinancing$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateFinancing$Plain(params: {
    id: string;
    body?: Financing
  },
  context?: HttpContext

): Observable<Financing> {

    return this.updateFinancing$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Financing>) => r.body as Financing)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateFinancing()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateFinancing$Response(params: {
    id: string;
    body?: Financing
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Financing>> {

    const rb = new RequestBuilder(this.rootUrl, FinancingsService.UpdateFinancingPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Financing>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateFinancing$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateFinancing(params: {
    id: string;
    body?: Financing
  },
  context?: HttpContext

): Observable<Financing> {

    return this.updateFinancing$Response(params,context).pipe(
      map((r: StrictHttpResponse<Financing>) => r.body as Financing)
    );
  }

  /**
   * Path part for operation deleteFinancingById
   */
  static readonly DeleteFinancingByIdPath = '/api/v1/Financings/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteFinancingById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFinancingById$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, FinancingsService.DeleteFinancingByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteFinancingById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFinancingById(params: {
    id: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.deleteFinancingById$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation patchFinancing
   */
  static readonly PatchFinancingPath = '/api/v1/Financings/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchFinancing$Plain()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchFinancing$Plain$Response(params: {
    id: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Financing>> {

    const rb = new RequestBuilder(this.rootUrl, FinancingsService.PatchFinancingPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json-patch+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Financing>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchFinancing$Plain$Response()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchFinancing$Plain(params: {
    id: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<Financing> {

    return this.patchFinancing$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Financing>) => r.body as Financing)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchFinancing()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchFinancing$Response(params: {
    id: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Financing>> {

    const rb = new RequestBuilder(this.rootUrl, FinancingsService.PatchFinancingPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json-patch+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Financing>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchFinancing$Response()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchFinancing(params: {
    id: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<Financing> {

    return this.patchFinancing$Response(params,context).pipe(
      map((r: StrictHttpResponse<Financing>) => r.body as Financing)
    );
  }

  /**
   * Path part for operation completeFinancing
   */
  static readonly CompleteFinancingPath = '/api/v1/Financings/{id}/Complete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `completeFinancing$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  completeFinancing$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Financing>> {

    const rb = new RequestBuilder(this.rootUrl, FinancingsService.CompleteFinancingPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Financing>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `completeFinancing$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  completeFinancing$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Financing> {

    return this.completeFinancing$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Financing>) => r.body as Financing)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `completeFinancing()` instead.
   *
   * This method doesn't expect any request body.
   */
  completeFinancing$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Financing>> {

    const rb = new RequestBuilder(this.rootUrl, FinancingsService.CompleteFinancingPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Financing>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `completeFinancing$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  completeFinancing(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Financing> {

    return this.completeFinancing$Response(params,context).pipe(
      map((r: StrictHttpResponse<Financing>) => r.body as Financing)
    );
  }

  /**
   * Path part for operation calculateProposals
   */
  static readonly CalculateProposalsPath = '/api/v1/Financings/{id}/Calculate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `calculateProposals$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  calculateProposals$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Proposals>> {

    const rb = new RequestBuilder(this.rootUrl, FinancingsService.CalculateProposalsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Proposals>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `calculateProposals$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  calculateProposals$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Proposals> {

    return this.calculateProposals$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Proposals>) => r.body as Proposals)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `calculateProposals()` instead.
   *
   * This method doesn't expect any request body.
   */
  calculateProposals$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Proposals>> {

    const rb = new RequestBuilder(this.rootUrl, FinancingsService.CalculateProposalsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Proposals>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `calculateProposals$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  calculateProposals(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Proposals> {

    return this.calculateProposals$Response(params,context).pipe(
      map((r: StrictHttpResponse<Proposals>) => r.body as Proposals)
    );
  }

  /**
   * Path part for operation compose
   */
  static readonly ComposePath = '/api/v1/Financings/{id}/Compose';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `compose$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  compose$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ComposeProposalsResult>> {

    const rb = new RequestBuilder(this.rootUrl, FinancingsService.ComposePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ComposeProposalsResult>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `compose$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  compose$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<ComposeProposalsResult> {

    return this.compose$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<ComposeProposalsResult>) => r.body as ComposeProposalsResult)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `compose()` instead.
   *
   * This method doesn't expect any request body.
   */
  compose$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ComposeProposalsResult>> {

    const rb = new RequestBuilder(this.rootUrl, FinancingsService.ComposePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ComposeProposalsResult>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `compose$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  compose(params: {
    id: string;
  },
  context?: HttpContext

): Observable<ComposeProposalsResult> {

    return this.compose$Response(params,context).pipe(
      map((r: StrictHttpResponse<ComposeProposalsResult>) => r.body as ComposeProposalsResult)
    );
  }

  /**
   * Path part for operation getCombinedProposalsStatus
   */
  static readonly GetCombinedProposalsStatusPath = '/api/v1/Financings/{id}/Calculation/CombinedProposals/Status';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCombinedProposalsStatus$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCombinedProposalsStatus$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CombinedProposalsStatus>> {

    const rb = new RequestBuilder(this.rootUrl, FinancingsService.GetCombinedProposalsStatusPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CombinedProposalsStatus>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCombinedProposalsStatus$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCombinedProposalsStatus$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<CombinedProposalsStatus> {

    return this.getCombinedProposalsStatus$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<CombinedProposalsStatus>) => r.body as CombinedProposalsStatus)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCombinedProposalsStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCombinedProposalsStatus$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CombinedProposalsStatus>> {

    const rb = new RequestBuilder(this.rootUrl, FinancingsService.GetCombinedProposalsStatusPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CombinedProposalsStatus>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCombinedProposalsStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCombinedProposalsStatus(params: {
    id: string;
  },
  context?: HttpContext

): Observable<CombinedProposalsStatus> {

    return this.getCombinedProposalsStatus$Response(params,context).pipe(
      map((r: StrictHttpResponse<CombinedProposalsStatus>) => r.body as CombinedProposalsStatus)
    );
  }

  /**
   * Path part for operation getCombinedProposalsResult
   */
  static readonly GetCombinedProposalsResultPath = '/api/v1/Financings/{id}/Calculation/CombinedProposals/Result';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCombinedProposalsResult$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCombinedProposalsResult$Plain$Response(params: {
    id: string;
    resultId?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CombinedProposals>> {

    const rb = new RequestBuilder(this.rootUrl, FinancingsService.GetCombinedProposalsResultPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('resultId', params.resultId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CombinedProposals>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCombinedProposalsResult$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCombinedProposalsResult$Plain(params: {
    id: string;
    resultId?: string;
  },
  context?: HttpContext

): Observable<CombinedProposals> {

    return this.getCombinedProposalsResult$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<CombinedProposals>) => r.body as CombinedProposals)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCombinedProposalsResult()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCombinedProposalsResult$Response(params: {
    id: string;
    resultId?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CombinedProposals>> {

    const rb = new RequestBuilder(this.rootUrl, FinancingsService.GetCombinedProposalsResultPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('resultId', params.resultId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CombinedProposals>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCombinedProposalsResult$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCombinedProposalsResult(params: {
    id: string;
    resultId?: string;
  },
  context?: HttpContext

): Observable<CombinedProposals> {

    return this.getCombinedProposalsResult$Response(params,context).pipe(
      map((r: StrictHttpResponse<CombinedProposals>) => r.body as CombinedProposals)
    );
  }

  /**
   * Path part for operation getAllCombinedProposalsStatus
   */
  static readonly GetAllCombinedProposalsStatusPath = '/api/v1/Financings/CombinedProposals/Status';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllCombinedProposalsStatus$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllCombinedProposalsStatus$Plain$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<CombinedProposalsStatus>>> {

    const rb = new RequestBuilder(this.rootUrl, FinancingsService.GetAllCombinedProposalsStatusPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CombinedProposalsStatus>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllCombinedProposalsStatus$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllCombinedProposalsStatus$Plain(params?: {
  },
  context?: HttpContext

): Observable<Array<CombinedProposalsStatus>> {

    return this.getAllCombinedProposalsStatus$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<CombinedProposalsStatus>>) => r.body as Array<CombinedProposalsStatus>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllCombinedProposalsStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllCombinedProposalsStatus$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<CombinedProposalsStatus>>> {

    const rb = new RequestBuilder(this.rootUrl, FinancingsService.GetAllCombinedProposalsStatusPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CombinedProposalsStatus>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllCombinedProposalsStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllCombinedProposalsStatus(params?: {
  },
  context?: HttpContext

): Observable<Array<CombinedProposalsStatus>> {

    return this.getAllCombinedProposalsStatus$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<CombinedProposalsStatus>>) => r.body as Array<CombinedProposalsStatus>)
    );
  }

  /**
   * Path part for operation getFinancingBorrowersById
   */
  static readonly GetFinancingBorrowersByIdPath = '/api/v1/Financings/{id}/Borrowers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFinancingBorrowersById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFinancingBorrowersById$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Customer>>> {

    const rb = new RequestBuilder(this.rootUrl, FinancingsService.GetFinancingBorrowersByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Customer>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFinancingBorrowersById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFinancingBorrowersById$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<Customer>> {

    return this.getFinancingBorrowersById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Customer>>) => r.body as Array<Customer>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFinancingBorrowersById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFinancingBorrowersById$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Customer>>> {

    const rb = new RequestBuilder(this.rootUrl, FinancingsService.GetFinancingBorrowersByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Customer>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFinancingBorrowersById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFinancingBorrowersById(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<Customer>> {

    return this.getFinancingBorrowersById$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Customer>>) => r.body as Array<Customer>)
    );
  }

  /**
   * Path part for operation getFinancingBuildings
   */
  static readonly GetFinancingBuildingsPath = '/api/v1/Financings/{id}/Buildings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFinancingBuildings$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFinancingBuildings$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<FinancingBuilding>>> {

    const rb = new RequestBuilder(this.rootUrl, FinancingsService.GetFinancingBuildingsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FinancingBuilding>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFinancingBuildings$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFinancingBuildings$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<FinancingBuilding>> {

    return this.getFinancingBuildings$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<FinancingBuilding>>) => r.body as Array<FinancingBuilding>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFinancingBuildings()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFinancingBuildings$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<FinancingBuilding>>> {

    const rb = new RequestBuilder(this.rootUrl, FinancingsService.GetFinancingBuildingsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FinancingBuilding>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFinancingBuildings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFinancingBuildings(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<FinancingBuilding>> {

    return this.getFinancingBuildings$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<FinancingBuilding>>) => r.body as Array<FinancingBuilding>)
    );
  }

  /**
   * Path part for operation addFinancingBuilding
   */
  static readonly AddFinancingBuildingPath = '/api/v1/Financings/{id}/Buildings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addFinancingBuilding$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addFinancingBuilding$Plain$Response(params: {
    id: string;
    body?: FinancingBuilding
  },
  context?: HttpContext

): Observable<StrictHttpResponse<FinancingBuilding>> {

    const rb = new RequestBuilder(this.rootUrl, FinancingsService.AddFinancingBuildingPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FinancingBuilding>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addFinancingBuilding$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addFinancingBuilding$Plain(params: {
    id: string;
    body?: FinancingBuilding
  },
  context?: HttpContext

): Observable<FinancingBuilding> {

    return this.addFinancingBuilding$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<FinancingBuilding>) => r.body as FinancingBuilding)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addFinancingBuilding()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addFinancingBuilding$Response(params: {
    id: string;
    body?: FinancingBuilding
  },
  context?: HttpContext

): Observable<StrictHttpResponse<FinancingBuilding>> {

    const rb = new RequestBuilder(this.rootUrl, FinancingsService.AddFinancingBuildingPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FinancingBuilding>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addFinancingBuilding$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addFinancingBuilding(params: {
    id: string;
    body?: FinancingBuilding
  },
  context?: HttpContext

): Observable<FinancingBuilding> {

    return this.addFinancingBuilding$Response(params,context).pipe(
      map((r: StrictHttpResponse<FinancingBuilding>) => r.body as FinancingBuilding)
    );
  }

  /**
   * Path part for operation updateFinancingBuilding
   */
  static readonly UpdateFinancingBuildingPath = '/api/v1/Financings/{id}/Buildings/{buildingId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateFinancingBuilding$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateFinancingBuilding$Plain$Response(params: {
    id: string;
    buildingId: string;
    body?: FinancingBuilding
  },
  context?: HttpContext

): Observable<StrictHttpResponse<FinancingBuilding>> {

    const rb = new RequestBuilder(this.rootUrl, FinancingsService.UpdateFinancingBuildingPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('buildingId', params.buildingId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FinancingBuilding>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateFinancingBuilding$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateFinancingBuilding$Plain(params: {
    id: string;
    buildingId: string;
    body?: FinancingBuilding
  },
  context?: HttpContext

): Observable<FinancingBuilding> {

    return this.updateFinancingBuilding$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<FinancingBuilding>) => r.body as FinancingBuilding)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateFinancingBuilding()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateFinancingBuilding$Response(params: {
    id: string;
    buildingId: string;
    body?: FinancingBuilding
  },
  context?: HttpContext

): Observable<StrictHttpResponse<FinancingBuilding>> {

    const rb = new RequestBuilder(this.rootUrl, FinancingsService.UpdateFinancingBuildingPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('buildingId', params.buildingId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FinancingBuilding>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateFinancingBuilding$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateFinancingBuilding(params: {
    id: string;
    buildingId: string;
    body?: FinancingBuilding
  },
  context?: HttpContext

): Observable<FinancingBuilding> {

    return this.updateFinancingBuilding$Response(params,context).pipe(
      map((r: StrictHttpResponse<FinancingBuilding>) => r.body as FinancingBuilding)
    );
  }

  /**
   * Path part for operation deleteFinancingBuilding
   */
  static readonly DeleteFinancingBuildingPath = '/api/v1/Financings/{id}/Buildings/{buildingId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteFinancingBuilding()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFinancingBuilding$Response(params: {
    id: string;
    buildingId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, FinancingsService.DeleteFinancingBuildingPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('buildingId', params.buildingId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteFinancingBuilding$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFinancingBuilding(params: {
    id: string;
    buildingId: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.deleteFinancingBuilding$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getFinancingMortgagesById
   */
  static readonly GetFinancingMortgagesByIdPath = '/api/v1/Financings/{id}/Mortgages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFinancingMortgagesById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFinancingMortgagesById$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<FinancingMortgage>>> {

    const rb = new RequestBuilder(this.rootUrl, FinancingsService.GetFinancingMortgagesByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FinancingMortgage>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFinancingMortgagesById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFinancingMortgagesById$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<FinancingMortgage>> {

    return this.getFinancingMortgagesById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<FinancingMortgage>>) => r.body as Array<FinancingMortgage>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFinancingMortgagesById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFinancingMortgagesById$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<FinancingMortgage>>> {

    const rb = new RequestBuilder(this.rootUrl, FinancingsService.GetFinancingMortgagesByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FinancingMortgage>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFinancingMortgagesById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFinancingMortgagesById(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<FinancingMortgage>> {

    return this.getFinancingMortgagesById$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<FinancingMortgage>>) => r.body as Array<FinancingMortgage>)
    );
  }

  /**
   * Path part for operation addFinancingMortgageById
   */
  static readonly AddFinancingMortgageByIdPath = '/api/v1/Financings/{id}/Mortgages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addFinancingMortgageById$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addFinancingMortgageById$Plain$Response(params: {
    id: string;
    body?: FinancingMortgage
  },
  context?: HttpContext

): Observable<StrictHttpResponse<FinancingMortgage>> {

    const rb = new RequestBuilder(this.rootUrl, FinancingsService.AddFinancingMortgageByIdPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FinancingMortgage>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addFinancingMortgageById$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addFinancingMortgageById$Plain(params: {
    id: string;
    body?: FinancingMortgage
  },
  context?: HttpContext

): Observable<FinancingMortgage> {

    return this.addFinancingMortgageById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<FinancingMortgage>) => r.body as FinancingMortgage)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addFinancingMortgageById()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addFinancingMortgageById$Response(params: {
    id: string;
    body?: FinancingMortgage
  },
  context?: HttpContext

): Observable<StrictHttpResponse<FinancingMortgage>> {

    const rb = new RequestBuilder(this.rootUrl, FinancingsService.AddFinancingMortgageByIdPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FinancingMortgage>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addFinancingMortgageById$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addFinancingMortgageById(params: {
    id: string;
    body?: FinancingMortgage
  },
  context?: HttpContext

): Observable<FinancingMortgage> {

    return this.addFinancingMortgageById$Response(params,context).pipe(
      map((r: StrictHttpResponse<FinancingMortgage>) => r.body as FinancingMortgage)
    );
  }

  /**
   * Path part for operation updateFinancingMortgageById
   */
  static readonly UpdateFinancingMortgageByIdPath = '/api/v1/Financings/{id}/Mortgages/{mortgageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateFinancingMortgageById$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateFinancingMortgageById$Plain$Response(params: {
    id: string;
    mortgageId: string;
    body?: FinancingMortgage
  },
  context?: HttpContext

): Observable<StrictHttpResponse<FinancingMortgage>> {

    const rb = new RequestBuilder(this.rootUrl, FinancingsService.UpdateFinancingMortgageByIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('mortgageId', params.mortgageId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FinancingMortgage>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateFinancingMortgageById$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateFinancingMortgageById$Plain(params: {
    id: string;
    mortgageId: string;
    body?: FinancingMortgage
  },
  context?: HttpContext

): Observable<FinancingMortgage> {

    return this.updateFinancingMortgageById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<FinancingMortgage>) => r.body as FinancingMortgage)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateFinancingMortgageById()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateFinancingMortgageById$Response(params: {
    id: string;
    mortgageId: string;
    body?: FinancingMortgage
  },
  context?: HttpContext

): Observable<StrictHttpResponse<FinancingMortgage>> {

    const rb = new RequestBuilder(this.rootUrl, FinancingsService.UpdateFinancingMortgageByIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('mortgageId', params.mortgageId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FinancingMortgage>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateFinancingMortgageById$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateFinancingMortgageById(params: {
    id: string;
    mortgageId: string;
    body?: FinancingMortgage
  },
  context?: HttpContext

): Observable<FinancingMortgage> {

    return this.updateFinancingMortgageById$Response(params,context).pipe(
      map((r: StrictHttpResponse<FinancingMortgage>) => r.body as FinancingMortgage)
    );
  }

  /**
   * Path part for operation deleteFinancingMortgageById
   */
  static readonly DeleteFinancingMortgageByIdPath = '/api/v1/Financings/{id}/Mortgages/{mortgageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteFinancingMortgageById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFinancingMortgageById$Response(params: {
    id: string;
    mortgageId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, FinancingsService.DeleteFinancingMortgageByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('mortgageId', params.mortgageId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteFinancingMortgageById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteFinancingMortgageById(params: {
    id: string;
    mortgageId: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.deleteFinancingMortgageById$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getFinancingProposals
   */
  static readonly GetFinancingProposalsPath = '/api/v1/Financings/{id}/Proposals';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFinancingProposals$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFinancingProposals$Plain$Response(params: {
    id: string;
    count?: number;
    page?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Proposals>> {

    const rb = new RequestBuilder(this.rootUrl, FinancingsService.GetFinancingProposalsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('count', params.count, {});
      rb.query('page', params.page, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Proposals>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFinancingProposals$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFinancingProposals$Plain(params: {
    id: string;
    count?: number;
    page?: number;
  },
  context?: HttpContext

): Observable<Proposals> {

    return this.getFinancingProposals$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Proposals>) => r.body as Proposals)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFinancingProposals()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFinancingProposals$Response(params: {
    id: string;
    count?: number;
    page?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Proposals>> {

    const rb = new RequestBuilder(this.rootUrl, FinancingsService.GetFinancingProposalsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('count', params.count, {});
      rb.query('page', params.page, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Proposals>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFinancingProposals$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFinancingProposals(params: {
    id: string;
    count?: number;
    page?: number;
  },
  context?: HttpContext

): Observable<Proposals> {

    return this.getFinancingProposals$Response(params,context).pipe(
      map((r: StrictHttpResponse<Proposals>) => r.body as Proposals)
    );
  }

  /**
   * Path part for operation getArrangements
   */
  static readonly GetArrangementsPath = '/api/v1/Financings/{id}/Arrangements';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getArrangements$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArrangements$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Arrangement>>> {

    const rb = new RequestBuilder(this.rootUrl, FinancingsService.GetArrangementsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Arrangement>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getArrangements$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArrangements$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<Arrangement>> {

    return this.getArrangements$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Arrangement>>) => r.body as Array<Arrangement>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getArrangements()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArrangements$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Arrangement>>> {

    const rb = new RequestBuilder(this.rootUrl, FinancingsService.GetArrangementsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Arrangement>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getArrangements$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArrangements(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<Arrangement>> {

    return this.getArrangements$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Arrangement>>) => r.body as Array<Arrangement>)
    );
  }

  /**
   * Path part for operation addArrangement
   */
  static readonly AddArrangementPath = '/api/v1/Financings/{id}/Arrangements';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addArrangement$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addArrangement$Plain$Response(params: {
    id: string;
    body?: Arrangement
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Arrangement>> {

    const rb = new RequestBuilder(this.rootUrl, FinancingsService.AddArrangementPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Arrangement>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addArrangement$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addArrangement$Plain(params: {
    id: string;
    body?: Arrangement
  },
  context?: HttpContext

): Observable<Arrangement> {

    return this.addArrangement$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Arrangement>) => r.body as Arrangement)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addArrangement()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addArrangement$Response(params: {
    id: string;
    body?: Arrangement
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Arrangement>> {

    const rb = new RequestBuilder(this.rootUrl, FinancingsService.AddArrangementPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Arrangement>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addArrangement$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addArrangement(params: {
    id: string;
    body?: Arrangement
  },
  context?: HttpContext

): Observable<Arrangement> {

    return this.addArrangement$Response(params,context).pipe(
      map((r: StrictHttpResponse<Arrangement>) => r.body as Arrangement)
    );
  }

  /**
   * Path part for operation getArrangementById
   */
  static readonly GetArrangementByIdPath = '/api/v1/Financings/{id}/Arrangements/{arrangementId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getArrangementById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArrangementById$Plain$Response(params: {
    id: string;
    arrangementId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Arrangement>> {

    const rb = new RequestBuilder(this.rootUrl, FinancingsService.GetArrangementByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('arrangementId', params.arrangementId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Arrangement>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getArrangementById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArrangementById$Plain(params: {
    id: string;
    arrangementId: string;
  },
  context?: HttpContext

): Observable<Arrangement> {

    return this.getArrangementById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Arrangement>) => r.body as Arrangement)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getArrangementById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArrangementById$Response(params: {
    id: string;
    arrangementId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Arrangement>> {

    const rb = new RequestBuilder(this.rootUrl, FinancingsService.GetArrangementByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('arrangementId', params.arrangementId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Arrangement>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getArrangementById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getArrangementById(params: {
    id: string;
    arrangementId: string;
  },
  context?: HttpContext

): Observable<Arrangement> {

    return this.getArrangementById$Response(params,context).pipe(
      map((r: StrictHttpResponse<Arrangement>) => r.body as Arrangement)
    );
  }

  /**
   * Path part for operation updateArrangement
   */
  static readonly UpdateArrangementPath = '/api/v1/Financings/{id}/Arrangements/{arrangementId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateArrangement$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateArrangement$Plain$Response(params: {
    id: string;
    arrangementId: string;
    body?: Arrangement
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Arrangement>> {

    const rb = new RequestBuilder(this.rootUrl, FinancingsService.UpdateArrangementPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('arrangementId', params.arrangementId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Arrangement>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateArrangement$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateArrangement$Plain(params: {
    id: string;
    arrangementId: string;
    body?: Arrangement
  },
  context?: HttpContext

): Observable<Arrangement> {

    return this.updateArrangement$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Arrangement>) => r.body as Arrangement)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateArrangement()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateArrangement$Response(params: {
    id: string;
    arrangementId: string;
    body?: Arrangement
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Arrangement>> {

    const rb = new RequestBuilder(this.rootUrl, FinancingsService.UpdateArrangementPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('arrangementId', params.arrangementId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Arrangement>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateArrangement$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateArrangement(params: {
    id: string;
    arrangementId: string;
    body?: Arrangement
  },
  context?: HttpContext

): Observable<Arrangement> {

    return this.updateArrangement$Response(params,context).pipe(
      map((r: StrictHttpResponse<Arrangement>) => r.body as Arrangement)
    );
  }

  /**
   * Path part for operation deleteArrangementById
   */
  static readonly DeleteArrangementByIdPath = '/api/v1/Financings/{id}/Arrangements/{arrangementId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteArrangementById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteArrangementById$Response(params: {
    id: string;
    arrangementId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, FinancingsService.DeleteArrangementByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('arrangementId', params.arrangementId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteArrangementById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteArrangementById(params: {
    id: string;
    arrangementId: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.deleteArrangementById$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getFinancingLenders
   */
  static readonly GetFinancingLendersPath = '/api/v1/Financings/{id}/Lenders';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFinancingLenders$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFinancingLenders$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<FinancingLender>>> {

    const rb = new RequestBuilder(this.rootUrl, FinancingsService.GetFinancingLendersPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FinancingLender>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFinancingLenders$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFinancingLenders$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<FinancingLender>> {

    return this.getFinancingLenders$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<FinancingLender>>) => r.body as Array<FinancingLender>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getFinancingLenders()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFinancingLenders$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<FinancingLender>>> {

    const rb = new RequestBuilder(this.rootUrl, FinancingsService.GetFinancingLendersPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<FinancingLender>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getFinancingLenders$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getFinancingLenders(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<FinancingLender>> {

    return this.getFinancingLenders$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<FinancingLender>>) => r.body as Array<FinancingLender>)
    );
  }

  /**
   * Path part for operation updateFinancingLender
   */
  static readonly UpdateFinancingLenderPath = '/api/v1/Financings/{id}/Lenders/{lenderId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateFinancingLender$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateFinancingLender$Plain$Response(params: {
    id: string;
    lenderId: string;
    Id?: string;
    Name?: string;
    Number?: number;
    Visible?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<FinancingLender>> {

    const rb = new RequestBuilder(this.rootUrl, FinancingsService.UpdateFinancingLenderPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('lenderId', params.lenderId, {});
      rb.query('Id', params.Id, {});
      rb.query('Name', params.Name, {});
      rb.query('Number', params.Number, {});
      rb.query('Visible', params.Visible, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FinancingLender>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateFinancingLender$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateFinancingLender$Plain(params: {
    id: string;
    lenderId: string;
    Id?: string;
    Name?: string;
    Number?: number;
    Visible?: boolean;
  },
  context?: HttpContext

): Observable<FinancingLender> {

    return this.updateFinancingLender$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<FinancingLender>) => r.body as FinancingLender)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateFinancingLender()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateFinancingLender$Response(params: {
    id: string;
    lenderId: string;
    Id?: string;
    Name?: string;
    Number?: number;
    Visible?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<FinancingLender>> {

    const rb = new RequestBuilder(this.rootUrl, FinancingsService.UpdateFinancingLenderPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('lenderId', params.lenderId, {});
      rb.query('Id', params.Id, {});
      rb.query('Name', params.Name, {});
      rb.query('Number', params.Number, {});
      rb.query('Visible', params.Visible, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FinancingLender>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateFinancingLender$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateFinancingLender(params: {
    id: string;
    lenderId: string;
    Id?: string;
    Name?: string;
    Number?: number;
    Visible?: boolean;
  },
  context?: HttpContext

): Observable<FinancingLender> {

    return this.updateFinancingLender$Response(params,context).pipe(
      map((r: StrictHttpResponse<FinancingLender>) => r.body as FinancingLender)
    );
  }

  /**
   * Path part for operation patchFinancingLender
   */
  static readonly PatchFinancingLenderPath = '/api/v1/Financings/{id}/Lenders/{lenderId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchFinancingLender$Plain()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchFinancingLender$Plain$Response(params: {
    id: string;
    lenderId: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<FinancingLender>> {

    const rb = new RequestBuilder(this.rootUrl, FinancingsService.PatchFinancingLenderPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('lenderId', params.lenderId, {});
      rb.body(params.body, 'application/json-patch+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FinancingLender>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchFinancingLender$Plain$Response()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchFinancingLender$Plain(params: {
    id: string;
    lenderId: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<FinancingLender> {

    return this.patchFinancingLender$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<FinancingLender>) => r.body as FinancingLender)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchFinancingLender()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchFinancingLender$Response(params: {
    id: string;
    lenderId: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<FinancingLender>> {

    const rb = new RequestBuilder(this.rootUrl, FinancingsService.PatchFinancingLenderPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('lenderId', params.lenderId, {});
      rb.body(params.body, 'application/json-patch+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FinancingLender>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchFinancingLender$Response()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchFinancingLender(params: {
    id: string;
    lenderId: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<FinancingLender> {

    return this.patchFinancingLender$Response(params,context).pipe(
      map((r: StrictHttpResponse<FinancingLender>) => r.body as FinancingLender)
    );
  }

  /**
   * Path part for operation getOffers
   */
  static readonly GetOffersPath = '/api/v1/Financings/{id}/Offers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOffers$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOffers$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Offer>>> {

    const rb = new RequestBuilder(this.rootUrl, FinancingsService.GetOffersPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Offer>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOffers$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOffers$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<Offer>> {

    return this.getOffers$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Offer>>) => r.body as Array<Offer>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOffers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOffers$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Offer>>> {

    const rb = new RequestBuilder(this.rootUrl, FinancingsService.GetOffersPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Offer>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOffers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOffers(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<Offer>> {

    return this.getOffers$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Offer>>) => r.body as Array<Offer>)
    );
  }

  /**
   * Path part for operation addOffer
   */
  static readonly AddOfferPath = '/api/v1/Financings/{id}/Offers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addOffer$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addOffer$Plain$Response(params: {
    id: string;
    body?: Offer
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Offer>> {

    const rb = new RequestBuilder(this.rootUrl, FinancingsService.AddOfferPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Offer>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addOffer$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addOffer$Plain(params: {
    id: string;
    body?: Offer
  },
  context?: HttpContext

): Observable<Offer> {

    return this.addOffer$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Offer>) => r.body as Offer)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addOffer()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addOffer$Response(params: {
    id: string;
    body?: Offer
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Offer>> {

    const rb = new RequestBuilder(this.rootUrl, FinancingsService.AddOfferPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Offer>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addOffer$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addOffer(params: {
    id: string;
    body?: Offer
  },
  context?: HttpContext

): Observable<Offer> {

    return this.addOffer$Response(params,context).pipe(
      map((r: StrictHttpResponse<Offer>) => r.body as Offer)
    );
  }

  /**
   * Path part for operation getOfferById
   */
  static readonly GetOfferByIdPath = '/api/v1/Financings/{id}/Offers/{offerId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOfferById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOfferById$Plain$Response(params: {
    id: string;
    offerId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Offer>> {

    const rb = new RequestBuilder(this.rootUrl, FinancingsService.GetOfferByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('offerId', params.offerId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Offer>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOfferById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOfferById$Plain(params: {
    id: string;
    offerId: string;
  },
  context?: HttpContext

): Observable<Offer> {

    return this.getOfferById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Offer>) => r.body as Offer)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getOfferById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOfferById$Response(params: {
    id: string;
    offerId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Offer>> {

    const rb = new RequestBuilder(this.rootUrl, FinancingsService.GetOfferByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('offerId', params.offerId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Offer>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getOfferById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getOfferById(params: {
    id: string;
    offerId: string;
  },
  context?: HttpContext

): Observable<Offer> {

    return this.getOfferById$Response(params,context).pipe(
      map((r: StrictHttpResponse<Offer>) => r.body as Offer)
    );
  }

  /**
   * Path part for operation updateOffer
   */
  static readonly UpdateOfferPath = '/api/v1/Financings/{id}/Offers/{offerId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateOffer$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateOffer$Plain$Response(params: {
    id: string;
    offerId: string;
    body?: Offer
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Offer>> {

    const rb = new RequestBuilder(this.rootUrl, FinancingsService.UpdateOfferPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('offerId', params.offerId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Offer>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateOffer$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateOffer$Plain(params: {
    id: string;
    offerId: string;
    body?: Offer
  },
  context?: HttpContext

): Observable<Offer> {

    return this.updateOffer$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Offer>) => r.body as Offer)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateOffer()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateOffer$Response(params: {
    id: string;
    offerId: string;
    body?: Offer
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Offer>> {

    const rb = new RequestBuilder(this.rootUrl, FinancingsService.UpdateOfferPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('offerId', params.offerId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Offer>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateOffer$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateOffer(params: {
    id: string;
    offerId: string;
    body?: Offer
  },
  context?: HttpContext

): Observable<Offer> {

    return this.updateOffer$Response(params,context).pipe(
      map((r: StrictHttpResponse<Offer>) => r.body as Offer)
    );
  }

  /**
   * Path part for operation deleteOfferById
   */
  static readonly DeleteOfferByIdPath = '/api/v1/Financings/{id}/Offers/{offerId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteOfferById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteOfferById$Response(params: {
    id: string;
    offerId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, FinancingsService.DeleteOfferByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('offerId', params.offerId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteOfferById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteOfferById(params: {
    id: string;
    offerId: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.deleteOfferById$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
