import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { StingrayAuthenticationService } from '@app/core/authentication/stingray-authentication.service';
import { Customer } from '@app/core/stingray-api/models';
import { environment } from '@env/environment';
import { CreateDialogCofiguration } from './create-dialog-cofiguration';

@Component({
  selector: 'unite-create-dialog',
  templateUrl: './create-dialog.component.html',
  styleUrls: ['./create-dialog.component.scss']
})
export class CreateDialogComponent implements OnInit {

  collaborationMemberId: string;
  isProductionEnvironment = environment.production;
  isVirEnabledAccount = false;
  type = 'Person';

  constructor(
    private dialogRef: MatDialogRef<CreateDialogComponent>,
    private stingrayAuthenticationService: StingrayAuthenticationService,
    @Inject(MAT_DIALOG_DATA) public config: CreateDialogCofiguration
  ) {
    if (config) {
      this.type = config.type;
      this.collaborationMemberId = config.collaborationMemberId;
      console.log('collaboration member id', config.collaborationMemberId);
    }
  }

  ngOnInit(): void {
    this.isVirEnabledAccount = this.stingrayAuthenticationService.isVirEnabledAccount();
  }

  newCustomer(customer: Customer): void {
    this.dialogRef.close(customer);
  }

  close(): void {
    this.dialogRef.close();
  }

  setType(type: string): void {
    this.type = type;
  }
}
