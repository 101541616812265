<div fxFlex>
  <unite-header></unite-header>
  <mat-sidenav-container class="main-sidenav-container" fxFill>
    <mat-sidenav class="main-sidenav" #sidenav mode="over" ngClass.gt-sm="has-border">
      <nav>
        <mat-list>
          <mat-divider></mat-divider>
          <a mat-list-item routerLink="/home" routerLinkActive="active" (click)="sidenav.close()">
            <span translate>Home</span>
          </a>
          <ng-container *ngIf="!isProfessional">
            <mat-divider></mat-divider>
            <a mat-list-item routerLink="/applications/start" routerLinkActive="active" (click)="sidenav.close()">
              <span translate>Apply for financing</span>
            </a>
          </ng-container>
          <ng-container *ngIf="isProfessional">
            <mat-divider></mat-divider>
            <a mat-list-item routerLink="/customers" routerLinkActive="active" (click)="sidenav.close()">
              <span translate>Clients</span>
            </a>
            <mat-divider></mat-divider>
            <a mat-list-item routerLink="/buildings" routerLinkActive="active" (click)="sidenav.close()">
              <span translate>Buildings</span>
            </a>
          </ng-container>
          <mat-divider></mat-divider>
          <a mat-list-item routerLink="/financings" routerLinkActive="active" (click)="sidenav.close()">
            <span translate>Financings</span>
          </a>
          <mat-divider></mat-divider>
          <a mat-list-item routerLink="/applications" routerLinkActive="active" (click)="sidenav.close()">
            <span translate>Applications</span>
          </a>
          <ng-container *ngIf="isProfessional">
            <mat-divider></mat-divider>
            <a mat-list-item routerLink="/mortgages" routerLinkActive="active" (click)="sidenav.close()">
              <span translate>Ongoing mortgages</span>
            </a>
            <mat-divider></mat-divider>
            <a mat-list-item routerLink="/tasks" routerLinkActive="active" (click)="sidenav.close()">
              <span translate>Tasks</span>
            </a>
          </ng-container>
          <mat-divider></mat-divider>
          <a mat-list-item routerLink="/reports" routerLinkActive="active" (click)="sidenav.close()">
            <span translate>Reports</span>
          </a>
          <mat-divider></mat-divider>
        </mat-list>
      </nav>
    </mat-sidenav>
    <mat-sidenav-content class="main-sidenav-content">
      <div class="content">
        <div *ngIf="loading === true" class="loader loader-page">
          <mat-spinner diameter="32"></mat-spinner>
        </div>
        <router-outlet *ngIf="loading === false"></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

<div *ngIf="requestLoader === true" class="request-loader">
  <mat-spinner diameter="32"></mat-spinner>
</div>