import { Injectable } from "@angular/core";
import { Address, Building, BuildingInfo, Customer } from "@app/core/stingray-api/models";
import { BuildingInfoService } from "@app/core/stingray-api/services";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import _ from "lodash";
import { Observable } from "rxjs";
import { EditBuildingPanelModel } from "../edit-building-panel/edit-building-panel-model";
import { EditBuildingPanelOptions } from "../edit-building-panel/edit-building-panel-options";
import { AddressRow } from "./address-row";
import { TranslateService } from "@ngx-translate/core";


@UntilDestroy()
@Injectable()
export class AddressRowFactory {

  constructor(
    private buildingInfoService: BuildingInfoService,
    private translateService: TranslateService) { }

    public createAddressRow(customerId: string, address: Address, owned: boolean, selected: boolean, isUnlinkedCombination: boolean, existingBuilding: Building, mortgageIds: string[], options: Partial<EditBuildingPanelOptions>, includeValue: boolean, includeEnergy: boolean): Observable<AddressRow> {
        return new Observable(obs => {
    
          this.buildingInfoService.getBuildingInfo(
            {
              postalCode: address.postalCode,
              houseNumber: parseInt(address.houseNumber, 10),
              houseNumberAddition: address.houseNumberAddition,
              includeRegistration: true,
              includeValue: includeValue,
              includeEnergy: includeEnergy
            })
            .pipe(untilDestroyed(this))
            .subscribe((info: BuildingInfo) => {
    
              let name = `${address.addressLine1}, ${address.city}`;
              let additionalAddresses: Address[] = [];
    
              /* Check if it's a multi-tenant building */
              if (info && info.registration && info.registration.addresses.length > 1 && isUnlinkedCombination === false) {
                const streets = _.groupBy(info.registration.addresses, x => x.street);
                name = `${Object.keys(streets)[0]} ${this.translateService.instant('multi-tenant building')}`;
                additionalAddresses = info.registration.addresses;
              }
    
              const modelBuilding = existingBuilding ?? {
                address: address,
                constructionYear: info?.registration?.constructionYear,
                energyIndex: info?.energy?.energyIndex,
                energyPerformanceRegistrationDate: info?.energy?.energyPerformanceRegistrationDate,
                estimatedValueByMunicipality: info?.value?.value,
                estimatedValueByMunicipalityDate: info?.value?.startDate,
                finalEnergyLabel: info?.energy?.finalEnergyLabel,
                status: owned ? 'Owned' : 'Purchase',
                surface: info?.registration?.surface,
                type: info?.registration?.type
              } as Building;
    
              const editBuildingModel = { 
                customerId: customerId, 
                name, 
                address, 
                additionalAddresses, 
                building: modelBuilding,
                mortgageIds 
              } as EditBuildingPanelModel;
              
              const addressRow = { 
                name, 
                editBuildingModel, 
                selected, 
                options,
                address, 
                additionalAddresses, 
                owned,
                unlinked: isUnlinkedCombination,
              } as AddressRow;  
              
              obs.next(addressRow);
              obs.complete();
            });    
        })
      }
}
