<div mat-dialog-title>
  <div fxLayout="row wrap" fxLayoutAlign="space-between start" fxLayoutGap="1rem">
    <h1 class="mat-h2" translate>Add new client</h1>
    <button mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <nav mat-tab-nav-bar>
    <a mat-tab-link (click)="setType('Person')" [active]="type === 'Person'">
      <mat-icon class="icon-small mr1">person_outline</mat-icon>
      <span translate>Person</span>
    </a>
    <a mat-tab-link (click)="setType('Company')" [active]="type === 'Company'">
      <mat-icon class="icon-small mr1">business_center</mat-icon>
      <span translate>Company</span>
    </a>
    <a mat-tab-link (click)="setType('Collaboration')" [active]="type === 'Collaboration'">
      <mat-icon class="icon-small mr1">people_outline</mat-icon>
      <span translate>Collaboration</span>
    </a>
    <a mat-tab-link (click)="setType('Address')" [active]="type === 'Address'" *ngIf="isVirEnabledAccount">
      <mat-icon class="icon-small mr1">home_work</mat-icon>
      <span translate>VIR</span>
    </a>
  </nav>
</div>

<mat-dialog-content>
  <unite-create-person *ngIf="type === 'Person'" (person)="newCustomer($event)"></unite-create-person>
  <unite-create-company *ngIf="type === 'Company'" (company)="newCustomer($event)"></unite-create-company>
  <unite-create-collaboration *ngIf="type === 'Collaboration'" [memberId]="collaborationMemberId" (collaboration)="newCustomer($event)"></unite-create-collaboration>
  <unite-create-by-vir *ngIf="type === 'Address'" (customer)="newCustomer($event)"></unite-create-by-vir>
</mat-dialog-content>