import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, Input, ViewEncapsulation } from '@angular/core';
import { Address } from '@app/core/stingray-api/models/address';
import { UntypedFormGroup, ControlContainer, Validators } from '@angular/forms';
import { AddressesService } from '@app/core/stingray-api/services';
import { distinctUntilChanged, debounceTime, switchMap, catchError, filter, finalize } from 'rxjs/operators';
import { of, Subject } from 'rxjs';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { postalcodeValidator } from '@app/validators/postalcode-validator';
import { housenumberValidator } from '@app/validators/housenumber-validator';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';

@UntilDestroy()
@Component({
  selector: 'unite-address-input-v2',
  templateUrl: './address-input-v2.component.html',
  styleUrls: ['./address-input-v2.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('fadeAnimation', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [
        style({ opacity: 0 }),
        animate('100ms 400ms')
      ]),
      transition(':leave',
        animate(0, style({ opacity: 0 })))
    ])
  ]
})
export class AddressInputV2Component implements OnInit {

  @Output() addressSelected = new EventEmitter<boolean>();
  @Input() disableManualEnter = false;
  @Input() clearSelected = false;
  @Input() focusSelected = false;
  @Input() appearance = 'outline';
  @Input() class: string = null;

  @ViewChild('searchBox') searchBox: ElementRef<HTMLInputElement>;

  loading = false;
  manualInput = false;
  addressGroup: UntypedFormGroup;
  possibleAddresses: Address[] = [];
  selectedAddress: Address = null;

  searched = false;
  searchTerm = new Subject<string>();

  constructor(
    private addressService: AddressesService,
    private controlContainer: ControlContainer,
    private elementRef: ElementRef
  ) { }

  ngOnInit(): void {
    this.addressGroup = this.controlContainer.control as UntypedFormGroup;


    this.addressGroup.valueChanges.pipe(
      debounceTime(500),
      filter(() => this.manualInput === true),
      distinctUntilChanged(),
      untilDestroyed(this)
    ).subscribe(() => {
      this.notifyAddressSelected();
    });

    // Check whether the combination of the postal code and the house number is a valid BAG-address
    if (this.addressGroup.get('postalCode').value !== '' && 
      this.addressGroup.get('country').value && 
      this.addressGroup.get('country').value.name !== 'Nederland') {
      this.changeManualInput(true);
    } else if (this.addressGroup.get('postalCode').value !== '' &&
      this.addressGroup.get('houseNumber').value !== '' && !isNaN(this.addressGroup.get('houseNumber').value)) {
      this.addressService.getAddresses({ postalCode: this.postalCode, houseNumber: +this.houseNumber }).pipe(
        catchError(() => of([]))).subscribe((possibleAddresses: Address[]) => {
          this.possibleAddresses = possibleAddresses;
          if (this.possibleAddresses.length === 0) {
            this.changeManualInput(true);
          } else {
            this.addressGroup.get('postalCode').setValue(this.postalCode, { emitEvent: false });
          }
        });
    } else if (this.addressGroup.get('postalCode').value === '' && this.addressGroup.get('postalCode').value === '') {
      this.changeManualInput(false);
    } else {
      this.changeManualInput(true);
    }


    this.searchTerm.pipe(

      // ignore new term if same as previous term
      distinctUntilChanged(),

      // wait 300ms after each keystroke before considering the term
      debounceTime(300),

      // switch to new search observable each time the term changes
      switchMap((term: string) => {
        this.addressGroup.markAllAsTouched();
        this.searched = true;
        this.loading = true;

        if (term && term.trim() !== '') {
          this.addressGroup.reset();
          return this.addressService.queryAddresses({ query: term });
        } else {
          this.loading = false;
          return of([]);
        }
      }),

      finalize(() => {
        this.loading = false;
      })
    ).subscribe((possibleAddresses: Address[]) => {
      this.loading = false;
      this.possibleAddresses = possibleAddresses;
    });
  }

  getInputElement(): HTMLElement {
    return this.elementRef.nativeElement.querySelector('input');
  }

  optionSelected(event: MatAutocompleteSelectedEvent): void {
    this.selectedAddressFromList(event.option.value);
  }


  renderPossibleAddress(address: Address): string {
    if (!address) {
      return null;
    }

    return address.addressLine1 + ', ' + (address.postalCode ?? '') + ' ' + address.city;
  }

  changeManualInput(manual?: boolean): void {


    if (manual != null) {
      this.manualInput = manual;
    } else {
      this.manualInput = !this.manualInput;
    }
    if (this.manualInput) {
      this.addressGroup.get('houseNumber').setValidators([Validators.required]);
      this.addressGroup.get('houseNumber').updateValueAndValidity();
      this.addressGroup.get('postalCode').setValidators([Validators.required]);
      this.addressGroup.get('postalCode').updateValueAndValidity();
    } else {
      this.addressGroup.get('houseNumber').setValidators([Validators.required, housenumberValidator]);
      this.addressGroup.get('houseNumber').updateValueAndValidity();

      if (this.addressGroup.get('postalCode').value) {
        this.addressGroup.get('postalCode').setValue(this.postalCode);
      }
      this.addressGroup.get('postalCode').setValidators([Validators.required, postalcodeValidator]);
      this.addressGroup.get('postalCode').updateValueAndValidity();
    }
  }

  get postalCode(): string {
    let postcode: string = this.addressGroup.get('postalCode').value;
    if (postcode) {
      postcode = postcode.replace(/\s/g, '');
      postcode = postcode.slice(0, 6);
      postcode = postcode.toLocaleUpperCase();
      postcode = postcode.replace(/[^A-Z0-9]/g, '');
    }
    return postcode;
  }

  get houseNumber(): string {
    return this.addressGroup.get('houseNumber').value;
  }

  isAddressSelected(): boolean {
    return this.isValidAddress();
  }

  isValidAddress(): boolean {
    return this.addressGroup.valid &&
      this.loading === false;
  }

  showValidationError(): boolean {
    return !this.isValidAddress() && this.addressGroup.touched;
  }

  clearAddress(): void {
    this.addressGroup.reset();
  }

  notifyAddressSelected(): void {
    if (this.loading === false && this.isValidAddress()) {

      if (this.manualInput === true) {
        if (this.addressGroup.get('postalCode').valid &&
          this.addressGroup.get('houseNumber').valid &&
          this.addressGroup.get('street').valid &&
          this.addressGroup.get('city').valid) {

          const addressLine1 = `${this.addressGroup.get('street').value} ${this.addressGroup.get('houseNumber').value}${this.addressGroup.get('houseNumberAddition').value ?? ''}`;
          this.addressGroup.get('addressLine1').setValue(addressLine1, { emitEvent: false });
          this.addressSelected.emit(true);
          this.clearSearchInput();

          this.manualInput = false; // reset back to original
        }
      } else {
        if (this.addressGroup.get('postalCode').valid && this.addressGroup.get('houseNumber').valid) {
          this.addressSelected.emit(true);
          this.clearSearchInput();
        }
      }
    }
  }

  getAddressLabel(): string {
    if (!this.isValidAddress()) {
      return '';
    }

    const street = this.addressGroup.get('street').value;
    const housenumber = this.addressGroup.get('houseNumber').value;
    const housenumberAddition = this.addressGroup.get('houseNumberAddition').value;
    const city = this.addressGroup.get('city').value;
    const postalCode = this.addressGroup.get('postalCode').value;
    const country = this.addressGroup.get('country').value;

    if (street.length > 0 && city.length > 0) {
      const countryName = country ? country.name : '';
      return street + ' ' + housenumber + (housenumberAddition ?? '') + ', ' + postalCode + ' ' + city + ', ' + countryName;
    } else {
      return '';
    }
  }

  private clearSearchInput(): void {
    if (this.clearSelected && this.searchBox && this.searchBox.nativeElement) {
      this.searchBox.nativeElement.value = null;
    }
  }

  private selectedAddressFromList(selectedAddress: Address) {

    this.addressGroup.markAllAsTouched();
    if (selectedAddress && this.getAddressLabel() !== this.renderPossibleAddress(selectedAddress)) {
      this.addressGroup.get('postalCode').setValue(selectedAddress.postalCode, { emitEvent: false });
      this.addressGroup.get('houseNumber').setValue(selectedAddress.houseNumber, { emitEvent: false });
      this.addressGroup.get('houseNumberAddition').setValue(selectedAddress.houseNumberAddition, { emitEvent: false });
      this.addressGroup.get('district').setValue(selectedAddress.district, { emitEvent: false });
      this.addressGroup.get('city').setValue(selectedAddress.city, { emitEvent: false });
      this.addressGroup.get('street').setValue(selectedAddress.street, { emitEvent: false });
      this.addressGroup.get('addressLine1').setValue(selectedAddress.addressLine1, { emitEvent: false });
      this.addressGroup.get('waterAuthority').setValue(selectedAddress.waterAuthority, { emitEvent: false });
      this.addressGroup.get('latitude').setValue(selectedAddress.latitude, { emitEvent: false });
      this.addressGroup.get('longitude').setValue(selectedAddress.longitude, { emitEvent: false });
      this.addressGroup.get('province').setValue(selectedAddress.province, { emitEvent: false });
      this.addressGroup.get('country').setValue(selectedAddress.country, { emitEvent: false });
      this.addressGroup.get('municipality').setValue(selectedAddress.municipality, { emitEvent: false });
      this.addressGroup.get('cadastralDesignations').setValue(selectedAddress.cadastralDesignations, { emitEvent: false });
      this.addressGroup.get('municipalityRegisterResidenceId').setValue(selectedAddress.municipalityRegisterResidenceId, { emitEvent: false });
      this.addressGroup.get('municipalityRegisterPublicSpaceId').setValue(selectedAddress.municipalityRegisterPublicSpaceId, { emitEvent: false });
      this.addressGroup.get('municipalityRegisterAddressId').setValue(selectedAddress.municipalityRegisterAddressId, { emitEvent: false });

      this.addressGroup.updateValueAndValidity();

      if (selectedAddress.postalCode) {
        this.selectedAddress = selectedAddress;
        this.notifyAddressSelected();
      } else {
        this.changeManualInput(true);
      }
    }
  }

}
