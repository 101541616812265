<mat-form-field fxFlex appearance="outline">
    <mat-label>{{label}}</mat-label>
    <mat-spinner *ngIf="isLoadingTaxPartners === true" matSuffix diameter="14" class="mr1"></mat-spinner>
    <input [formControl]="control" type="text" matInput [matAutocomplete]="taxPartnerAutoComplete"
      [required]="required !== false" [attr.cdkFocusInitial]="focus !== false ? 'true' : null" autocomplete="off">
    <mat-autocomplete autoActiveFirstOption #taxPartnerAutoComplete="matAutocomplete" [displayWith]="displayWithCustomer">
      <mat-option *ngIf="required === false" [value]="null">
        <span translate>None</span>
      </mat-option>
      <mat-option *ngFor="let taxPartner of taxPartnersFiltered" [value]="taxPartner">
        <mat-icon class="icon-small mr1" *ngIf="taxPartner.type == 'Person'">person_outline</mat-icon>
        {{taxPartner.name}} <span *ngIf="taxPartner.email" class="gray">({{taxPartner.email}})</span>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>