<form [formGroup]="collaborationGroup" fxLayout="column">

  <h3 class="mat-title mt2" fxLayout="row" fxLayoutAlign="start center">
    <mat-icon class="icon-small mr1">add_circle_outline</mat-icon>
    <span translate>Add collaboration members</span>
  </h3>

  <mat-form-field appearance="outline">
    <mat-icon matPrefix class="mr1">search</mat-icon>
    <mat-label translate>Search for a person or company</mat-label>
    <input matInput type="text" [formControl]="searchMemberControl" [matAutocomplete]="auto" autocomplete="off">
    <mat-spinner matSuffix *ngIf="searchMemberControlIsLoading" diameter="24" class="mr1"></mat-spinner>
  </mat-form-field>

  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displaySearchValue" (optionSelected)="addMember($event)"
    class="small-autocomplete">
    <ng-container *ngIf="!searchMemberControlIsLoading">
      <mat-option *ngIf="searchMemberControl.value && filteredMembers && filteredMembers.length == 0">
        <span class="main bold black" translate>Nothing found</span>
      </mat-option>
      <mat-option *ngFor="let filteredMember of filteredMembers" [value]="filteredMember" fxLayout="row wrap"
        fxLayoutAlign="space-between center">
        <mat-icon *ngIf="filteredMember.type == 'Company'" class="icon-small">business_center</mat-icon>
        <mat-icon *ngIf="filteredMember.type == 'Person'" class="icon-small">person_outline</mat-icon>

        <span class="main bold black ">{{ filteredMember.name }}</span>
        <span class="sub ml2" *ngIf="filteredMember.address">{{filteredMember.address.addressLine1}}, {{filteredMember.address.city}}</span>
        <span class="sub ml2" *ngIf="!filteredMember.address" translate>Unknown</span>
      </mat-option>
    </ng-container>
  </mat-autocomplete>

  <mat-list class="boxed-list mb1">
    <ng-container *ngFor="let member of members">
      <mat-list-item>
        <mat-icon *ngIf="member.type == 'Company'" mat-list-icon>business_center</mat-icon>
        <mat-icon *ngIf="member.type == 'Person'" mat-list-icon>person_outline</mat-icon>
        <h4 mat-line class="mat-title">
          {{member.name}}
        </h4>
        <p mat-line fxLayout="row" fxLayoutAlign="start center">
          <span class="gray mr1" translate>Share</span>
          <strong class="mr1">{{member.share}}%</strong>
          <button mat-icon-button (click)="changeShare(member, member.share -.5)">
            <mat-icon>expand_more</mat-icon>
          </button>
          <button mat-icon-button (click)="changeShare(member, member.share + .5)">
            <mat-icon>expand_less</mat-icon>
          </button>
        </p>

        <button mat-icon-button color="warn" (click)="removeMember(member)">
          <mat-icon>clear</mat-icon>
        </button>
      </mat-list-item>
    </ng-container>
  </mat-list>

  <h3 class="mat-title mt3" fxLayout="row" fxLayoutAlign="start center">
    <mat-icon class="icon-small mr1">list_alt</mat-icon>
    <span translate>Collaboration information</span>
  </h3>

  <mat-form-field appearance="outline" class="small-field">
    <mat-label translate>Name</mat-label>
    <input matInput type="text" formControlName="name" minlength="1" required autocomplete="off">
  </mat-form-field>

  <unite-address-input-v2 class="mt2" formGroupName="address" fxLayout="column">
    <h3 class="mat-title mt2" fxLayout="row" fxLayoutAlign="start center">
      <mat-icon class="icon-small mr1">place</mat-icon>
      <span translate>Address</span>
    </h3>
  </unite-address-input-v2>

  <h3 class="mat-title mt2" fxLayout="row" fxLayoutAlign="start center">
    <mat-icon class="icon-small mr1">phone_iphone</mat-icon>
    <span translate>Contact information</span>
  </h3>
  <div fxLayout="row" fxFlex="100" fxLayoutGap="3%">
    <mat-form-field fxFlex="50" appearance="outline">
      <mat-label translate>Telephone</mat-label>
      <input matInput type="text" formControlName="phone" autocomplete="off" pattern="((\+\d{8,14})|([0]\d{9}))">
    </mat-form-field>

    <mat-form-field fxFlex="50" appearance="outline">
      <mat-label translate>Mobile</mat-label>
      <input matInput type="text" formControlName="mobile" autocomplete="off" pattern="((\+\d{8,14})|([0]\d{9}))">
    </mat-form-field>
  </div>

  <mat-form-field appearance="outline">
    <mat-label translate>Email</mat-label>
    <input matInput email type="email" formControlName="email" autocomplete="off">
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label translate>IBAN</mat-label>
    <input matInput type="text" formControlName="iban" autocomplete="off">
  </mat-form-field>

  <unite-customer-settings 
  [userControl]="collaborationGroup.get('adviser')"
  [existingControl]="collaborationGroup.get('existing')" 
  [isCreating]="true"
  [businessunitControl]="collaborationGroup.get('businessunit')"
  >
  </unite-customer-settings>

  <div fxLayout="row" fxLayoutAlign="end center" class="mt3">
    <unite-loading-button [text]="'Add collaboration' | translate" [loading]="loadingCreate" icon="done"
      (buttonClick)="create()"></unite-loading-button>
  </div>
</form>