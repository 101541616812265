/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Customer } from '../models/customer';
import { FinancialPosition } from '../models/financial-position';
import { GuidId } from '../models/guid-id';
import { IncomeTest } from '../models/income-test';
import { InvestmentProfile } from '../models/investment-profile';
import { JsonPatchDocument } from '../models/json-patch-document';
import { Person } from '../models/person';
import { Persons } from '../models/persons';

@Injectable({
  providedIn: 'root',
})
export class PersonsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getPersonById
   */
  static readonly GetPersonByIdPath = '/api/v1/Persons/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPersonById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPersonById$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Person>> {

    const rb = new RequestBuilder(this.rootUrl, PersonsService.GetPersonByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Person>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPersonById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPersonById$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Person> {

    return this.getPersonById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Person>) => r.body as Person)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPersonById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPersonById$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Person>> {

    const rb = new RequestBuilder(this.rootUrl, PersonsService.GetPersonByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Person>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPersonById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPersonById(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Person> {

    return this.getPersonById$Response(params,context).pipe(
      map((r: StrictHttpResponse<Person>) => r.body as Person)
    );
  }

  /**
   * Path part for operation updatePerson
   */
  static readonly UpdatePersonPath = '/api/v1/Persons/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePerson$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updatePerson$Plain$Response(params: {
    id: string;
    body?: Person
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Person>> {

    const rb = new RequestBuilder(this.rootUrl, PersonsService.UpdatePersonPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Person>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updatePerson$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updatePerson$Plain(params: {
    id: string;
    body?: Person
  },
  context?: HttpContext

): Observable<Person> {

    return this.updatePerson$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Person>) => r.body as Person)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updatePerson()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updatePerson$Response(params: {
    id: string;
    body?: Person
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Person>> {

    const rb = new RequestBuilder(this.rootUrl, PersonsService.UpdatePersonPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Person>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updatePerson$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updatePerson(params: {
    id: string;
    body?: Person
  },
  context?: HttpContext

): Observable<Person> {

    return this.updatePerson$Response(params,context).pipe(
      map((r: StrictHttpResponse<Person>) => r.body as Person)
    );
  }

  /**
   * Path part for operation deletePersonById
   */
  static readonly DeletePersonByIdPath = '/api/v1/Persons/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deletePersonById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePersonById$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, PersonsService.DeletePersonByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deletePersonById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deletePersonById(params: {
    id: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.deletePersonById$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation patchPerson
   */
  static readonly PatchPersonPath = '/api/v1/Persons/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchPerson$Plain()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchPerson$Plain$Response(params: {
    id: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Person>> {

    const rb = new RequestBuilder(this.rootUrl, PersonsService.PatchPersonPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json-patch+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Person>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchPerson$Plain$Response()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchPerson$Plain(params: {
    id: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<Person> {

    return this.patchPerson$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Person>) => r.body as Person)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchPerson()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchPerson$Response(params: {
    id: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Person>> {

    const rb = new RequestBuilder(this.rootUrl, PersonsService.PatchPersonPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json-patch+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Person>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchPerson$Response()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchPerson(params: {
    id: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<Person> {

    return this.patchPerson$Response(params,context).pipe(
      map((r: StrictHttpResponse<Person>) => r.body as Person)
    );
  }

  /**
   * Path part for operation getPersonGeneratedInvestmentProfile
   */
  static readonly GetPersonGeneratedInvestmentProfilePath = '/api/v1/Persons/{id}/GeneratedInvestmentProfile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPersonGeneratedInvestmentProfile$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPersonGeneratedInvestmentProfile$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<InvestmentProfile>> {

    const rb = new RequestBuilder(this.rootUrl, PersonsService.GetPersonGeneratedInvestmentProfilePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvestmentProfile>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPersonGeneratedInvestmentProfile$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPersonGeneratedInvestmentProfile$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<InvestmentProfile> {

    return this.getPersonGeneratedInvestmentProfile$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<InvestmentProfile>) => r.body as InvestmentProfile)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPersonGeneratedInvestmentProfile()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPersonGeneratedInvestmentProfile$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<InvestmentProfile>> {

    const rb = new RequestBuilder(this.rootUrl, PersonsService.GetPersonGeneratedInvestmentProfilePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvestmentProfile>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPersonGeneratedInvestmentProfile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPersonGeneratedInvestmentProfile(params: {
    id: string;
  },
  context?: HttpContext

): Observable<InvestmentProfile> {

    return this.getPersonGeneratedInvestmentProfile$Response(params,context).pipe(
      map((r: StrictHttpResponse<InvestmentProfile>) => r.body as InvestmentProfile)
    );
  }

  /**
   * Path part for operation getPersonRelatedCustomers
   */
  static readonly GetPersonRelatedCustomersPath = '/api/v1/Persons/{id}/RelatedCustomers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPersonRelatedCustomers$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPersonRelatedCustomers$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Customer>>> {

    const rb = new RequestBuilder(this.rootUrl, PersonsService.GetPersonRelatedCustomersPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Customer>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPersonRelatedCustomers$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPersonRelatedCustomers$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<Customer>> {

    return this.getPersonRelatedCustomers$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Customer>>) => r.body as Array<Customer>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPersonRelatedCustomers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPersonRelatedCustomers$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Customer>>> {

    const rb = new RequestBuilder(this.rootUrl, PersonsService.GetPersonRelatedCustomersPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Customer>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPersonRelatedCustomers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPersonRelatedCustomers(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<Customer>> {

    return this.getPersonRelatedCustomers$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Customer>>) => r.body as Array<Customer>)
    );
  }

  /**
   * Path part for operation getPersons
   */
  static readonly GetPersonsPath = '/api/v1/Persons';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPersons$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPersons$Plain$Response(params?: {
    name?: string;
    count?: number;
    page?: number;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Persons>> {

    const rb = new RequestBuilder(this.rootUrl, PersonsService.GetPersonsPath, 'get');
    if (params) {
      rb.query('name', params.name, {});
      rb.query('count', params.count, {});
      rb.query('page', params.page, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Persons>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPersons$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPersons$Plain(params?: {
    name?: string;
    count?: number;
    page?: number;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Persons> {

    return this.getPersons$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Persons>) => r.body as Persons)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPersons()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPersons$Response(params?: {
    name?: string;
    count?: number;
    page?: number;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Persons>> {

    const rb = new RequestBuilder(this.rootUrl, PersonsService.GetPersonsPath, 'get');
    if (params) {
      rb.query('name', params.name, {});
      rb.query('count', params.count, {});
      rb.query('page', params.page, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Persons>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPersons$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPersons(params?: {
    name?: string;
    count?: number;
    page?: number;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Persons> {

    return this.getPersons$Response(params,context).pipe(
      map((r: StrictHttpResponse<Persons>) => r.body as Persons)
    );
  }

  /**
   * Path part for operation addPerson
   */
  static readonly AddPersonPath = '/api/v1/Persons';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addPerson$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addPerson$Plain$Response(params?: {
    body?: Person
  },
  context?: HttpContext

): Observable<StrictHttpResponse<GuidId>> {

    const rb = new RequestBuilder(this.rootUrl, PersonsService.AddPersonPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GuidId>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addPerson$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addPerson$Plain(params?: {
    body?: Person
  },
  context?: HttpContext

): Observable<GuidId> {

    return this.addPerson$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<GuidId>) => r.body as GuidId)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addPerson()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addPerson$Response(params?: {
    body?: Person
  },
  context?: HttpContext

): Observable<StrictHttpResponse<GuidId>> {

    const rb = new RequestBuilder(this.rootUrl, PersonsService.AddPersonPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GuidId>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addPerson$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addPerson(params?: {
    body?: Person
  },
  context?: HttpContext

): Observable<GuidId> {

    return this.addPerson$Response(params,context).pipe(
      map((r: StrictHttpResponse<GuidId>) => r.body as GuidId)
    );
  }

  /**
   * Path part for operation updateFinancialPosition
   */
  static readonly UpdateFinancialPositionPath = '/api/v1/Persons/{id}/FinancialPosition';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateFinancialPosition$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateFinancialPosition$Plain$Response(params: {
    id: string;
    body?: FinancialPosition
  },
  context?: HttpContext

): Observable<StrictHttpResponse<FinancialPosition>> {

    const rb = new RequestBuilder(this.rootUrl, PersonsService.UpdateFinancialPositionPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FinancialPosition>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateFinancialPosition$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateFinancialPosition$Plain(params: {
    id: string;
    body?: FinancialPosition
  },
  context?: HttpContext

): Observable<FinancialPosition> {

    return this.updateFinancialPosition$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<FinancialPosition>) => r.body as FinancialPosition)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateFinancialPosition()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateFinancialPosition$Response(params: {
    id: string;
    body?: FinancialPosition
  },
  context?: HttpContext

): Observable<StrictHttpResponse<FinancialPosition>> {

    const rb = new RequestBuilder(this.rootUrl, PersonsService.UpdateFinancialPositionPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<FinancialPosition>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateFinancialPosition$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateFinancialPosition(params: {
    id: string;
    body?: FinancialPosition
  },
  context?: HttpContext

): Observable<FinancialPosition> {

    return this.updateFinancialPosition$Response(params,context).pipe(
      map((r: StrictHttpResponse<FinancialPosition>) => r.body as FinancialPosition)
    );
  }

  /**
   * Path part for operation updateIncomeTest
   */
  static readonly UpdateIncomeTestPath = '/api/v1/Persons/{id}/IncomeTest';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateIncomeTest$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateIncomeTest$Plain$Response(params: {
    id: string;
    body?: IncomeTest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<IncomeTest>> {

    const rb = new RequestBuilder(this.rootUrl, PersonsService.UpdateIncomeTestPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IncomeTest>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateIncomeTest$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateIncomeTest$Plain(params: {
    id: string;
    body?: IncomeTest
  },
  context?: HttpContext

): Observable<IncomeTest> {

    return this.updateIncomeTest$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<IncomeTest>) => r.body as IncomeTest)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateIncomeTest()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateIncomeTest$Response(params: {
    id: string;
    body?: IncomeTest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<IncomeTest>> {

    const rb = new RequestBuilder(this.rootUrl, PersonsService.UpdateIncomeTestPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<IncomeTest>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateIncomeTest$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateIncomeTest(params: {
    id: string;
    body?: IncomeTest
  },
  context?: HttpContext

): Observable<IncomeTest> {

    return this.updateIncomeTest$Response(params,context).pipe(
      map((r: StrictHttpResponse<IncomeTest>) => r.body as IncomeTest)
    );
  }

  /**
   * Path part for operation updateInvestmentProfile
   */
  static readonly UpdateInvestmentProfilePath = '/api/v1/Persons/{id}/InvestmentProfile';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateInvestmentProfile$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateInvestmentProfile$Plain$Response(params: {
    id: string;
    body?: InvestmentProfile
  },
  context?: HttpContext

): Observable<StrictHttpResponse<InvestmentProfile>> {

    const rb = new RequestBuilder(this.rootUrl, PersonsService.UpdateInvestmentProfilePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvestmentProfile>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateInvestmentProfile$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateInvestmentProfile$Plain(params: {
    id: string;
    body?: InvestmentProfile
  },
  context?: HttpContext

): Observable<InvestmentProfile> {

    return this.updateInvestmentProfile$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<InvestmentProfile>) => r.body as InvestmentProfile)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateInvestmentProfile()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateInvestmentProfile$Response(params: {
    id: string;
    body?: InvestmentProfile
  },
  context?: HttpContext

): Observable<StrictHttpResponse<InvestmentProfile>> {

    const rb = new RequestBuilder(this.rootUrl, PersonsService.UpdateInvestmentProfilePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<InvestmentProfile>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateInvestmentProfile$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateInvestmentProfile(params: {
    id: string;
    body?: InvestmentProfile
  },
  context?: HttpContext

): Observable<InvestmentProfile> {

    return this.updateInvestmentProfile$Response(params,context).pipe(
      map((r: StrictHttpResponse<InvestmentProfile>) => r.body as InvestmentProfile)
    );
  }

}
