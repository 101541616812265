import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'unite-maritalstatus-input',
  templateUrl: './maritalstatus-input.component.html',
  styleUrls: ['./maritalstatus-input.component.scss'],
  encapsulation : ViewEncapsulation.None
})
export class MaritalStatusInputComponent implements OnInit {

  @Input() control: FormControl;
  @Input() appearance: string = 'outline'
  @Input() class: string = '';
  @Input() label: string = null;
  @Input() hideRequiredMarker: boolean = false;
  @Input() showPlaceholder = false;

  constructor() { }

  ngOnInit() { }

  showValidationError(): boolean {
    return this.control.touched && this.control.valid === false;
  }

}


