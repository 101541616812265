import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';

// Versioning
import { VersionCheckService } from './version-check.service';

// HTTP and interceptors
import { HttpClientJsonpModule, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoaderInterceptor } from './interceptors/loader.interceptor';
import { StingrayTokenInterceptor } from './interceptors/stingray-api-token.interceptor';
import { OctopusApiTokenInterceptor } from './interceptors/octopus-api-token.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';

// Routing
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { RouteReusableStrategy } from './route-reusable-strategy';

// Translations
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { I18nService } from './i18n.service';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { PaginatorI18n } from './translation-providers/paginator-i18n';

// Authentication and subscriptions
import { StingrayAuthenticationService } from './authentication/stingray-authentication.service';
import { AuthenticationGuard } from './authentication/guards/authentication.guard';
import { AdminGuard } from './authentication/guards/admin.guard';

// Api modules
import { ApiModule as OctopusApiModule } from '@app/core/octopus-api/api.module';
import { ApiModule as StingrayApiModule } from '@app/core/stingray-api/api.module';

// Layout
import { MaterialModule } from '@app/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';

// Shared
import { SharedModule } from '@app/shared/shared.module';

// Toaster
import { ToasterComponent } from './toaster/toaster.component';
import { ToasterService } from './toaster/toaster.service';
import { GoogleAnalyticsService } from './analytics/google-analytics.service';
import { Angulartics2Module } from 'angulartics2';
import { OctopusApiAuthenticationService } from './authentication/octopus-api-authentication.service';
import { PortalService } from './portal/portal.service';
import { PortalGuard } from './portal/portal.guard';
import { environment } from '@env/environment';
import { ProfessionalSubscriptionGuard } from './authentication/guards/professional-subscription.guard';


@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    HttpClientJsonpModule,
    TranslateModule,
    RouterModule,
    MaterialModule,
    FlexLayoutModule,
    SharedModule,
    OctopusApiModule.forRoot({ rootUrl: environment.octopusApiEndpoint }),
    StingrayApiModule.forRoot({ rootUrl: environment.stingrayApiEndpoint }),
    Angulartics2Module
  ],
  declarations: [
    ToasterComponent,
  ],
  entryComponents: [
    ToasterComponent
  ],
  providers: [
    VersionCheckService,
    StingrayAuthenticationService,
    OctopusApiAuthenticationService,
    AuthenticationGuard,
    ProfessionalSubscriptionGuard,
    AdminGuard,
    PortalService,
    PortalGuard,
    I18nService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: StingrayTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: OctopusApiTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: RouteReuseStrategy, useClass: RouteReusableStrategy
    },
    ToasterService,
    {
      provide: MatPaginatorIntl, deps: [TranslateService],
      useFactory: (translateService: TranslateService) => new PaginatorI18n(translateService).getPaginatorIntl()
    },
    GoogleAnalyticsService
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    // Import guard
    if (parentModule) {
      throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
    }
  }
}
