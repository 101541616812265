<mat-form-field appearance="outline">
  <mat-label translate>Income sources</mat-label>
    <mat-select multiple placeholder="..." [formControl]="control">
      <mat-option [value]="'Employment'"><span translate>Employment</span></mat-option>
      <mat-option [value]="'CompanyIncomeTax'"><span translate>Company income tax</span></mat-option>
      <mat-option [value]="'CompanyCorporationTax'"><span translate>Company corporation tax</span></mat-option>
      <mat-option [value]="'Pension'"><span translate>Pension</span></mat-option>
      <mat-option [value]="'Other'"><span translate>Other</span></mat-option>
      <mat-option [value]="'RealEstate'"><span translate>Real estate</span></mat-option>
      <mat-option [value]="'No'"><span translate>No income</span></mat-option>
    </mat-select>
  </mat-form-field>