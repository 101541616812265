import { Injectable } from '@angular/core';
import { Organization } from '../stingray-api/models';

@Injectable()
export class PortalService {

  private _hasPortal: boolean;
  get hasPortal() {
    return this._hasPortal;
  }

  constructor() {
  }

  setPortal(organization: Organization) {
    if (organization.auth0Domain !== null && organization.auth0Domain !== '') {
      this._hasPortal = true;
    } else {
      this._hasPortal = false;
    }
  }
}
