/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Application } from '../models/application';
import { ApplicationStatusHistory } from '../models/application-status-history';
import { ApplicationType } from '../models/application-type';
import { CalculationOptions } from '../models/calculation-options';
import { Comment } from '../models/comment';
import { Contact } from '../models/contact';
import { CostsBuyer } from '../models/costs-buyer';
import { GuidId } from '../models/guid-id';
import { NewApplication } from '../models/new-application';
import { RequiredDocument } from '../models/required-document';
import { RequiredDocuments } from '../models/required-documents';

@Injectable({
  providedIn: 'root',
})
export class ApplicationsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getApplicationById
   */
  static readonly GetApplicationByIdPath = '/api/v1/Applications/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApplicationById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationById$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Application>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.GetApplicationByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Application>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getApplicationById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationById(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Application> {

    return this.getApplicationById$Response(params,context).pipe(
      map((r: StrictHttpResponse<Application>) => r.body as Application)
    );
  }

  /**
   * Path part for operation updateAdviser
   */
  static readonly UpdateAdviserPath = '/api/v1/Applications/{id}/Adviser';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAdviser()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateAdviser$Response(params: {
    id: string;
    body?: Contact
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Application>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.UpdateAdviserPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Application>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateAdviser$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateAdviser(params: {
    id: string;
    body?: Contact
  },
  context?: HttpContext

): Observable<Application> {

    return this.updateAdviser$Response(params,context).pipe(
      map((r: StrictHttpResponse<Application>) => r.body as Application)
    );
  }

  /**
   * Path part for operation getCalculationOptions
   */
  static readonly GetCalculationOptionsPath = '/api/v1/Applications/{id}/CalculationOptions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCalculationOptions()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCalculationOptions$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<CalculationOptions>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.GetCalculationOptionsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CalculationOptions>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCalculationOptions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCalculationOptions(params: {
    id: string;
  },
  context?: HttpContext

): Observable<CalculationOptions> {

    return this.getCalculationOptions$Response(params,context).pipe(
      map((r: StrictHttpResponse<CalculationOptions>) => r.body as CalculationOptions)
    );
  }

  /**
   * Path part for operation cancelApplication
   */
  static readonly CancelApplicationPath = '/api/v1/Applications/{id}/Cancel';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cancelApplication()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  cancelApplication$Response(params: {
    id: string;
    body?: Comment
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Application>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.CancelApplicationPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Application>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `cancelApplication$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  cancelApplication(params: {
    id: string;
    body?: Comment
  },
  context?: HttpContext

): Observable<Application> {

    return this.cancelApplication$Response(params,context).pipe(
      map((r: StrictHttpResponse<Application>) => r.body as Application)
    );
  }

  /**
   * Path part for operation updateContact
   */
  static readonly UpdateContactPath = '/api/v1/Applications/{id}/Contact';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateContact()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateContact$Response(params: {
    id: string;
    body?: Contact
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Application>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.UpdateContactPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Application>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateContact$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateContact(params: {
    id: string;
    body?: Contact
  },
  context?: HttpContext

): Observable<Application> {

    return this.updateContact$Response(params,context).pipe(
      map((r: StrictHttpResponse<Application>) => r.body as Application)
    );
  }

  /**
   * Path part for operation updateCostsBuyer
   */
  static readonly UpdateCostsBuyerPath = '/api/v1/Applications/{id}/CostsBuyer';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCostsBuyer()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCostsBuyer$Response(params: {
    id: string;
    body?: CostsBuyer
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Application>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.UpdateCostsBuyerPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Application>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCostsBuyer$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCostsBuyer(params: {
    id: string;
    body?: CostsBuyer
  },
  context?: HttpContext

): Observable<Application> {

    return this.updateCostsBuyer$Response(params,context).pipe(
      map((r: StrictHttpResponse<Application>) => r.body as Application)
    );
  }

  /**
   * Path part for operation updateExecutionDate
   */
  static readonly UpdateExecutionDatePath = '/api/v1/Applications/{id}/ExecutionDate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateExecutionDate()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateExecutionDate$Response(params: {
    id: string;
    value?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Application>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.UpdateExecutionDatePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('value', params.value, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Application>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateExecutionDate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateExecutionDate(params: {
    id: string;
    value?: string;
  },
  context?: HttpContext

): Observable<Application> {

    return this.updateExecutionDate$Response(params,context).pipe(
      map((r: StrictHttpResponse<Application>) => r.body as Application)
    );
  }

  /**
   * Path part for operation updateMortgage
   */
  static readonly UpdateMortgagePath = '/api/v1/Applications/{id}/Mortgage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateMortgage()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateMortgage$Response(params: {
    id: string;
    calculationId?: string;
    proposalId?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Application>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.UpdateMortgagePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('calculationId', params.calculationId, {});
      rb.query('proposalId', params.proposalId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Application>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateMortgage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateMortgage(params: {
    id: string;
    calculationId?: string;
    proposalId?: string;
  },
  context?: HttpContext

): Observable<Application> {

    return this.updateMortgage$Response(params,context).pipe(
      map((r: StrictHttpResponse<Application>) => r.body as Application)
    );
  }

  /**
   * Path part for operation sendApplicationRequest
   */
  static readonly SendApplicationRequestPath = '/api/v1/Applications/{id}/Request';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendApplicationRequest()` instead.
   *
   * This method doesn't expect any request body.
   */
  sendApplicationRequest$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Application>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.SendApplicationRequestPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Application>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendApplicationRequest$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sendApplicationRequest(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Application> {

    return this.sendApplicationRequest$Response(params,context).pipe(
      map((r: StrictHttpResponse<Application>) => r.body as Application)
    );
  }

  /**
   * Path part for operation addApplication
   */
  static readonly AddApplicationPath = '/api/v1/Applications';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addApplication()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addApplication$Response(params?: {
    body?: NewApplication
  },
  context?: HttpContext

): Observable<StrictHttpResponse<GuidId>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.AddApplicationPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GuidId>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addApplication$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addApplication(params?: {
    body?: NewApplication
  },
  context?: HttpContext

): Observable<GuidId> {

    return this.addApplication$Response(params,context).pipe(
      map((r: StrictHttpResponse<GuidId>) => r.body as GuidId)
    );
  }

  /**
   * Path part for operation uploadDocumentFile
   */
  static readonly UploadDocumentFilePath = '/api/v1/Applications/{id}/Documents/{documentId}/File';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadDocumentFile()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadDocumentFile$Response(params: {
    id: string;
    documentId: string;
    body?: {
'file'?: File;
}
  },
  context?: HttpContext

): Observable<StrictHttpResponse<RequiredDocument>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.UploadDocumentFilePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('documentId', params.documentId, {});
      rb.body(params.body, 'multipart/form-data');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RequiredDocument>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadDocumentFile$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  uploadDocumentFile(params: {
    id: string;
    documentId: string;
    body?: {
'file'?: File;
}
  },
  context?: HttpContext

): Observable<RequiredDocument> {

    return this.uploadDocumentFile$Response(params,context).pipe(
      map((r: StrictHttpResponse<RequiredDocument>) => r.body as RequiredDocument)
    );
  }

  /**
   * Path part for operation uploadDocumentFromUrl
   */
  static readonly UploadDocumentFromUrlPath = '/api/v1/Applications/{id}/Documents/{documentId}/Url';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `uploadDocumentFromUrl()` instead.
   *
   * This method doesn't expect any request body.
   */
  uploadDocumentFromUrl$Response(params: {
    id: string;
    documentId: string;
    filename?: string;
    url?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<RequiredDocument>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.UploadDocumentFromUrlPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('documentId', params.documentId, {});
      rb.query('filename', params.filename, {});
      rb.query('url', params.url, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RequiredDocument>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `uploadDocumentFromUrl$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  uploadDocumentFromUrl(params: {
    id: string;
    documentId: string;
    filename?: string;
    url?: string;
  },
  context?: HttpContext

): Observable<RequiredDocument> {

    return this.uploadDocumentFromUrl$Response(params,context).pipe(
      map((r: StrictHttpResponse<RequiredDocument>) => r.body as RequiredDocument)
    );
  }

  /**
   * Path part for operation deleteDocument
   */
  static readonly DeleteDocumentPath = '/api/v1/Applications/{id}/Documents/{documentId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDocument$Response(params: {
    id: string;
    documentId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<RequiredDocument>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.DeleteDocumentPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('documentId', params.documentId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RequiredDocument>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDocument(params: {
    id: string;
    documentId: string;
  },
  context?: HttpContext

): Observable<RequiredDocument> {

    return this.deleteDocument$Response(params,context).pipe(
      map((r: StrictHttpResponse<RequiredDocument>) => r.body as RequiredDocument)
    );
  }

  /**
   * Path part for operation getDocuments
   */
  static readonly GetDocumentsPath = '/api/v1/Applications/{id}/Documents';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocuments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocuments$Response(params: {
    id: string;
    count?: number;
    page?: number;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<RequiredDocuments>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.GetDocumentsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('count', params.count, {});
      rb.query('page', params.page, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<RequiredDocuments>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDocuments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocuments(params: {
    id: string;
    count?: number;
    page?: number;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<RequiredDocuments> {

    return this.getDocuments$Response(params,context).pipe(
      map((r: StrictHttpResponse<RequiredDocuments>) => r.body as RequiredDocuments)
    );
  }

  /**
   * Path part for operation getApplicationStatusHistory
   */
  static readonly GetApplicationStatusHistoryPath = '/api/v1/Applications/{id}/StatusHistory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApplicationStatusHistory()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationStatusHistory$Response(params: {
    id: string;
    count?: number;
    page?: number;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ApplicationStatusHistory>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.GetApplicationStatusHistoryPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('count', params.count, {});
      rb.query('page', params.page, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationStatusHistory>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getApplicationStatusHistory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationStatusHistory(params: {
    id: string;
    count?: number;
    page?: number;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<ApplicationStatusHistory> {

    return this.getApplicationStatusHistory$Response(params,context).pipe(
      map((r: StrictHttpResponse<ApplicationStatusHistory>) => r.body as ApplicationStatusHistory)
    );
  }

  /**
   * Path part for operation getApplicationTypes
   */
  static readonly GetApplicationTypesPath = '/api/v1/ApplicationTypes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApplicationTypes$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationTypes$Plain$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ApplicationType>>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.GetApplicationTypesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ApplicationType>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getApplicationTypes$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationTypes$Plain(params?: {
  },
  context?: HttpContext

): Observable<Array<ApplicationType>> {

    return this.getApplicationTypes$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ApplicationType>>) => r.body as Array<ApplicationType>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getApplicationTypes$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationTypes$Json$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ApplicationType>>> {

    const rb = new RequestBuilder(this.rootUrl, ApplicationsService.GetApplicationTypesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ApplicationType>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getApplicationTypes$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getApplicationTypes$Json(params?: {
  },
  context?: HttpContext

): Observable<Array<ApplicationType>> {

    return this.getApplicationTypes$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ApplicationType>>) => r.body as Array<ApplicationType>)
    );
  }

}
