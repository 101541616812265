import { Injectable } from '@angular/core';
import { Angulartics2 } from 'angulartics2';
import { environment } from '@env/environment';


@Injectable()
export class GoogleAnalyticsService {

  constructor(private angulartics2: Angulartics2) { }

  // Create our event emitter to send our data to Google Analytics
  public trackEvent(eventCategory: string, eventAction: string, eventLabel: string = null, eventValue: number = null): void {
    if (environment.production) {
      this.angulartics2.eventTrack.next({
        action: eventAction,
        properties: {
          category: eventCategory,
          label: eventLabel,
          value: eventValue
        },
      });
    } else {
      console.log('Track event [' + eventCategory + ',' + eventAction + ',' + eventLabel + ', ', +eventValue + ']');
    }
  }

  public trackException(exceptionDescription: string): void {
    this.angulartics2.exceptionTrack.next({
      description: exceptionDescription
    });
  }
}
