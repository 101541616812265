<div fxLayout="row" fxLayoutAlign="stretch" class="header">
  <a class="logo" [routerLink]="['/home']">
    <img [src]="'/assets/unite-logo-' + subscription.toLowerCase() + '.svg'" alt="Unite" />
  </a>

  <mat-toolbar fxLayout="row" fxLayoutAlign="start center" fxFlex fxLayoutGap="0.25rem">
    <button fxHide.gt-sm mat-icon-button (click)="toggleGeneralSideNav()" class="header-icon-button">
      <mat-icon>menu</mat-icon>
    </button>

    <button *ngIf="hasLeftSidenav === true" fxHide.gt-sm mat-icon-button (click)="toggleLeftSideNav()" class="header-icon-button">
      <mat-icon>person_outline</mat-icon>
    </button>

    <button fxHide.lt-md mat-icon-button class="header-icon-button" [matMenuTriggerFor]="listsMenu" *ngIf="isProfessional">
      <mat-icon>list</mat-icon>
    </button>
    
    <mat-menu overlapTrigger="false" #listsMenu="matMenu">
      <a mat-menu-item [routerLink]="['/customers']" [routerLinkActive]="['active']">
        <mat-icon>person_outline</mat-icon>
        <span translate>Clients</span>
      </a>
      <a mat-menu-item [routerLink]="['/buildings']" [routerLinkActive]="['active']">
        <mat-icon>business</mat-icon>
        <span translate>Buildings</span>
      </a>
      <a mat-menu-item [routerLink]="['/financings']" [routerLinkActive]="['active']">
        <mat-icon>euro_symbol</mat-icon>
        <span translate>Financings</span>
      </a>
      <a mat-menu-item [routerLink]="['/applications']" [routerLinkActive]="['active']">
        <mat-icon>access_time</mat-icon>
        <span translate>Applications</span>
      </a>
      <a mat-menu-item [routerLink]="['/mortgages']" [routerLinkActive]="['active']">
        <mat-icon>folder_open</mat-icon>
        <span translate>Ongoing mortgages</span>
      </a>
      <a mat-menu-item [routerLink]="['/tasks']" [routerLinkActive]="['active']">
        <mat-icon>assignment</mat-icon>
        <span translate>Tasks</span>
      </a>
      <a mat-menu-item [routerLink]="['/reports']" [routerLinkActive]="['active']">
        <mat-icon>real_estate_agent</mat-icon>
        <span translate>Reports</span>
      </a>
    </mat-menu>

    <button fxHide.lt-md mat-icon-button class="header-icon-button" [matMenuTriggerFor]="addMenu" *ngIf="isProfessional">
      <mat-icon>add</mat-icon>
    </button>
    <mat-menu overlapTrigger="false" #addMenu="matMenu">
      <div translate class="mat-body-2 gray pt1 px2">Add new</div>
      <button mat-menu-item (click)="openCreateCustomerDialog()">
        <mat-icon>person_outline</mat-icon>
        <span translate>Client</span>
      </button>
    </mat-menu>

    <unite-search-box-v2 fxHide.lt-sm fxFlex="100" [placeholder]=" ( isProfessional ? 'Search for client, property, mortgage' : 'Search for application' )  | translate"> 
    </unite-search-box-v2>

    <a href="https://unite.nl/faq/" target="_blank" mat-icon-button class="header-icon-button" *ngIf="isProfessional">
      <mat-icon>help_outline</mat-icon>
    </a>

    <div fxFlex fxHide.gt-xs></div>

    <button mat-icon-button [matMenuTriggerFor]="settingsMenu" class="header-icon-button">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu overlapTrigger="false" #settingsMenu="matMenu">
      <button [matTooltip]="'The browser will reload' | translate" matTooltipPosition="left" mat-menu-item
        (click)="setLanguage('en-US')" *ngIf="currentLanguage != 'en-US'">
        <mat-icon>language</mat-icon>
        <span translate>Set to English</span>
      </button>
      <button [matTooltip]="'The browser will reload' | translate" matTooltipPosition="left" mat-menu-item
        (click)="setLanguage('nl-NL')" *ngIf="currentLanguage != 'nl-NL'">
        <mat-icon>language</mat-icon>
        <span translate>Set to Dutch</span>
      </button>
      <button mat-menu-item>
        <mat-icon>person_outline</mat-icon>
        <span>{{loginName}}</span>
      </button>
      <a mat-menu-item [routerLink]="['/settings']" *ngIf="userIsAdministrator">
        <mat-icon>settings</mat-icon>
        <span translate>Settings</span>
      </a>
      <a *ngIf="hasPortal" mat-menu-item [routerLink]="['/portal']">
        <mat-icon>phonelink</mat-icon>
        <span translate>Portal</span>
      </a>
      <a *ngIf="isCombinedProposalsEnabledAccount" mat-menu-item [routerLink]="['/combined-proposals/overview']">
        <mat-icon><span class="material-icons">
          calculate
          </span></mat-icon>
        <span translate>Combined proposals</span>
      </a>
      <button mat-menu-item (click)="logout()">
        <mat-icon>swap_horiz</mat-icon>
        <span translate>Logout</span>
      </button>
    </mat-menu>
  </mat-toolbar>
</div>