<mat-form-field fxFlex appearance="outline">
    <mat-label>{{label}}</mat-label>
    <mat-spinner *ngIf="isLoadingContacts === true" matSuffix diameter="14" class="mr1"></mat-spinner>
    <input [formControl]="control" type="text" matInput [matAutocomplete]="contactAutoComplete"
      [required]="required !== false" [attr.cdkFocusInitial]="focus !== false ? 'true' : null" autocomplete="off">
    <mat-autocomplete autoActiveFirstOption #contactAutoComplete="matAutocomplete" [displayWith]="displayWithCustomer">
      <mat-option *ngIf="required === false" [value]="null">
        <span translate>None</span>
      </mat-option>
      <mat-option *ngFor="let contact of contactsFiltered" [value]="contact">
        <mat-icon class="icon-small mr1" *ngIf="contact.type == 'Person'">person_outline</mat-icon>
        {{contact.name}} <span *ngIf="contact.email" class="gray">({{contact.email}})</span>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>