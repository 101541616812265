import { Output, Component, OnInit, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BuildingOwnerCompany, BuildingOwnerPerson, Customer, VirEstimatedCosts } from '@app/core/stingray-api/models';
import { BuildingInfoService, VirService, CustomersService } from '@app/core/stingray-api/services';
import { createAddressFormGroup } from '@app/shared/address-input-v2/address-input-v2-tools';
import { housenumberValidator } from '@app/validators/housenumber-validator';
import { postalcodeValidator } from '@app/validators/postalcode-validator';
import { from } from 'rxjs';
import { concatMap, finalize, tap } from 'rxjs/operators';


export class BuildingOwnerRowMatch {
  customerId: string;
  customerName: string;
}

export class BuildingOwnerRow {
  cadastralId: string;
  name: string;
  share: number;
  matches: BuildingOwnerRowMatch[];
  form: UntypedFormGroup;
  company: BuildingOwnerCompany;
  person: BuildingOwnerPerson;
  isEstimatingCosts = false;
  estimatedCosts: VirEstimatedCosts;
}

@Component({
  selector: 'unite-create-by-vir',
  templateUrl: './create-by-vir.component.html',
  styleUrls: ['./create-by-vir.component.scss']
})
export class CreateByVirComponent implements OnInit {

  @Output() customer = new EventEmitter<Customer>();

  addressFormGroup: UntypedFormGroup;
  isRetrievingOwners = false;
  isValidAddress = false;
  isStartingVir = false;
  isCreatingCollaboration = false;
  retrievedOwners = false;
  owners: BuildingOwnerRow[] = [];

  adviserFormControl: FormControl;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private buildingInfoService: BuildingInfoService,
    private virService: VirService,
    private customersService: CustomersService
  ) { }

  ngOnInit() {
    this.adviserFormControl = new FormControl(null, [Validators.required]);
    // this.adviserFormControl.valueChanges.subscribe(x => {
    //   this.owners.forEach(o => {
    //     o.form = this.buildOwnerForm(o.form.get('existingCustomerId').value);
    //   })
    // })


    this.addressFormGroup = this.formBuilder.group({
      address: createAddressFormGroup(this.formBuilder),
    });

    this.addressFormGroup.get('address').valueChanges.subscribe(() => {
      this.isValidAddress = this.addressFormGroup.get('address').valid;
      this.retrievedOwners = false;
      this.isRetrievingOwners = false;
    });
  }

  retrieveOwners() {
    this.isRetrievingOwners = true;
    const postalCode = this.addressFormGroup.get('address').value.postalCode;
    const houseNumber = this.addressFormGroup.get('address').value.houseNumber;
    const houseNumberAddition = this.addressFormGroup.get('address').value.houseNumberAddition;

    this.buildingInfoService
      .getBuildingOwnerInfo({ postalCode, houseNumber, houseNumberAddition })
      .pipe(finalize(() => {
        this.isRetrievingOwners = false;
        this.retrievedOwners = true;
      }))
      .subscribe(t => {
        this.owners = [];
        t.persons.forEach(p => {
          this.owners.push({
            cadastralId: p.cadastralId,
            name: p.fullName,
            share: p.share,
            matches: p.matches.map(t => <BuildingOwnerRowMatch>{ customerId: t.id, customerName: t.name }),
            form: this.buildOwnerForm(p.matches.length === 0 ? null : p.matches[0].id),
            person: p,
            company: null,
            isEstimatingCosts: true,
            estimatedCosts: null
          });
        });
        t.companies.forEach(c => {
          this.owners.push({
            cadastralId: c.cadastralId,
            name: c.name,
            share: c.share,
            matches: c.matches.map(t => <BuildingOwnerRowMatch>{ customerId: t.id, customerName: t.name }),
            form: this.buildOwnerForm(c.matches.length === 0 ? null : c.matches[0].id),
            person: null,
            company: c,
            isEstimatingCosts: true,
            estimatedCosts: null
          });
        });


        const estimateCostsRequests = this.owners.map(b => this.virService.getEstimatedCosts({ id: b.cadastralId }));

        from(estimateCostsRequests).pipe(
          concatMap(estimateCostsRequests => estimateCostsRequests)
        ).pipe(tap(b => {
          const row = this.owners.filter(x => x.cadastralId == b.id)[0];
          row.isEstimatingCosts = false;
          row.estimatedCosts = b;
        })).subscribe((b) => {

        });

      });
  }

  startVir(owner: BuildingOwnerRow) {

    this.adviserFormControl.markAsTouched();
    if (this.adviserFormControl.valid) {
      this.isStartingVir = true;

      this.virService.startSession({
        body: {
          person: owner.person,
          company: owner.company,
          adviserId: this.adviserFormControl.value ? this.adviserFormControl.value.id : null,
          customerId: owner.form.get('isExistingCustomer').value ? owner.form.get('existingCustomerId').value : null,
          options: {
            includeOwnership: !!owner.form.get('includeOwnership').value,
            includeMortgages: !!owner.form.get('includeMortgages').value,
          }
        }
      })
        .pipe(finalize(() => this.isStartingVir = false))
        .subscribe(t => {

          this.customersService
            .getCustomerById({ id: t.options.customerId })
            .subscribe(b => this.customer.emit(b));

        });
    }
  }

  processVirCollaborationByAddress() {
    this.adviserFormControl.markAsTouched();
    if (this.adviserFormControl.valid) {

      this.isCreatingCollaboration = true;

      const postalCode = this.addressFormGroup.get('address').value.postalCode;
      const houseNumber = this.addressFormGroup.get('address').value.houseNumber;
      const houseNumberAddition = this.addressFormGroup.get('address').value.houseNumberAddition;

      this.virService.processCollaborationByAddress({
        id: this.owners[0].cadastralId,
        adviserId: this.adviserFormControl.value ? this.adviserFormControl.value.id : null,
        postalCode: postalCode,
        houseNumber: houseNumber,
        houseNumberAddition: houseNumberAddition
      })
        .pipe(finalize(() => this.isCreatingCollaboration = false))
        .subscribe(collaboration => {
          this.customer.emit(collaboration);
        });
    }
  }

  private buildOwnerForm(firstExistingCustomerId: string): UntypedFormGroup {
    const form = this.formBuilder.group({
      includeMortgages: [false, []],
      includeOwnership: [false, []],
      isExistingCustomer: [!!firstExistingCustomerId, []],
      existingCustomerId: [firstExistingCustomerId, []],
    });

    /* Check the checkbox when a customer id is checked */
    form.get('existingCustomerId').valueChanges.subscribe(existingCustomerId => {
      if (existingCustomerId) {
        form.get('isExistingCustomer').setValue(true);
      }
    });

    return form;
  }

}
