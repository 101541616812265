import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { StingrayAuthenticationService } from '../stingray-authentication.service';

@Injectable()
export class AuthenticationGuard implements CanActivate {

  constructor(private stingrayAuthenticationService: StingrayAuthenticationService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean|UrlTree> | boolean {
    return this.stingrayAuthenticationService.isAuthenticated$.pipe(
      tap(loggedIn => {
        if (!loggedIn) {
          this.stingrayAuthenticationService.login(state.url);
        }
      })
    );
  }
}
