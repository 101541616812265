import { Component, EventEmitter, OnInit, Output, ElementRef } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { Company, CompanyProfile, CompanySearchResult, Customer, GuidId, NewCompany, Shareholder } from '@app/core/stingray-api/models';
import { CompaniesService, CompanyInfoService, CustomersService } from '@app/core/stingray-api/services';
import * as _ from 'lodash';
import * as moment from 'moment';
import { of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, finalize, map, switchMap, tap } from 'rxjs/operators';
import { GoogleAnalyticsService } from '@app/core/analytics/google-analytics.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { createAddressFormGroup } from '@app/shared/address-input-v2/address-input-v2-tools';

@UntilDestroy()
@Component({
  selector: 'unite-create-company',
  templateUrl: './create-company.component.html',
  styleUrls: ['./create-company.component.scss']
})
export class CreateCompanyComponent implements OnInit {

  @Output() company = new EventEmitter<Company>();
  companyGroup: UntypedFormGroup;

  loadingCreate = false;

  isSearchingCompanies = false;
  companySearchResults: CompanySearchResult[] = [];

  // Shareholders
  searchShareholderControl: FormControl;
  searchShareholderControlIsLoading = false;
  filteredShareholders: Customer[];
  shareholders: Shareholder[] = [];

  constructor(
    private el: ElementRef,
    private googleAnalyticsService: GoogleAnalyticsService,
    private formBuilder: UntypedFormBuilder,
    private customersService: CustomersService,
    private companiesService: CompaniesService) {
    this.companyGroup = this.formBuilder.group({
      chamberOfCommerceNumber: [, [Validators.required]],
      statutoryName: [],
      statutoryLocation: [],
      name: [, [Validators.required]],
      email: [],
      mobile: [],
      phone: [],
      iban: [],
      foundingDate: [],
      businessunit: [],
      address: createAddressFormGroup(this.formBuilder),
      legalForm: [],
      adviser: [],
      contact: [],
      existing: [false],
    });



    this.searchShareholderControl = this.formBuilder.control('');
    this.searchShareholderControl.valueChanges.pipe(
      tap(() => this.searchShareholderControlIsLoading = true),
      debounceTime(350),
      distinctUntilChanged(),
      untilDestroyed(this),
      switchMap((value: any) => {
        if (value && typeof value === 'string' && value.trim() !== '') {
          return this.customersService.getCustomers({
            name: value,
            types: ['Person', 'Company']
          }).pipe(map(data => data.items), catchError(() => of(null)));
        } else {
          this.searchShareholderControlIsLoading = false;
          return of(null);
        }
      })).subscribe((filteredShareholders: Customer[]) => {
        // Remove the filtered customers that are already chosen
        this.filteredShareholders = _.differenceWith(filteredShareholders, this.shareholders,
          (filteredShareholder: Customer, shareholder: Shareholder) => {
            return filteredShareholder.id === shareholder.customerId;
          });

        this.searchShareholderControlIsLoading = false;
      });
  }

  ngOnInit() {

  }

  companyProfileSelected(companyProfile: CompanyProfile): void {
    if (companyProfile) {
        this.companyGroup.get('address').patchValue(companyProfile.address);
        this.companyGroup.get('foundingDate').setValue(moment(companyProfile.registrationDate));
        this.companyGroup.get('chamberOfCommerceNumber').setValue(companyProfile.chamberOfCommerceNumber);
        this.companyGroup.get('statutoryName').setValue(companyProfile.statutoryName);
        this.companyGroup.get('statutoryLocation').setValue(companyProfile.statutoryLocation);
        this.companyGroup.get('legalForm').setValue(companyProfile.legalForm);
      
    }
  }


  create() {
    this.companyGroup.markAllAsTouched();

    if (this.companyGroup.valid) {
      this.googleAnalyticsService.trackEvent('customer', 'add', 'company');
      this.loadingCreate = true;
      
      

      const company = <NewCompany>this.companyGroup.value;

      // Normalize autocomplete result back to just a name
      if(this.companyGroup.get('name').value.name) {
        company.name = this.companyGroup.get('name').value.name;
      }

      if (company.foundingDate) {
        company.foundingDate = (<moment.Moment>this.companyGroup.value.foundingDate).locale('en').format('L');
      }
      company.shareholders = this.shareholders;

      this.companiesService.addCompany({ body: company }).pipe(finalize(() => {
        this.loadingCreate = false;

      })).subscribe((response: GuidId) => {
        company.id = response.id;
        this.company.emit(company);
      });
    } else {
      this.scrollToFirstInvalidControl();
    }
  }

  addShareholder(matAutocompleteSelectedEvent: MatAutocompleteSelectedEvent) {
    if (matAutocompleteSelectedEvent.option.value) {
      const customer: Customer = matAutocompleteSelectedEvent.option.value;

      this.shareholders.push({ customerId: customer.id, name: customer.name, share: 0, customerType: customer.type });

      this.shareholders.map((shareholder: Shareholder) => {
        shareholder.share = Math.round(100 / this.shareholders.length);
      });
    }
    this.searchShareholderControl.setValue('', { emitEvent: false });
  }

  changeShare(shareholder: Shareholder, share: number | string) {
    this.shareholders[this.shareholders.indexOf(shareholder)].share = +share;
  }

  removeShareholder(shareholder: Shareholder) {
    this.shareholders.splice(this.shareholders.indexOf(shareholder), 1);
    this.shareholders.map((shareholderUpdate: Shareholder) => {
      shareholderUpdate.share = Math.round(100 / this.shareholders.length);
    });
  }

  displaySearchValue(customer: Customer) {
    if (customer) { return customer.name; }
  }

  private scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
      "form .ng-invalid"
    );

    firstInvalidControl.scrollIntoView();
  }

}
