<form [formGroup]="personGroup" fxLayout="column" class="pb2">

  <h3 class="mat-title mt2" fxLayout="row" fxLayoutAlign="start center">
    <mat-icon class="icon-small mr1">list_alt</mat-icon>
    <span translate>Personal information</span>
  </h3>
  <mat-radio-group formControlName="gender" required class="mb2 py1">
    <mat-radio-button value="Male" color="primary" class="mr2"><span translate>Mr.</span></mat-radio-button>
    <mat-radio-button value="Female" color="primary" class="mr2"><span translate>Mrs.</span></mat-radio-button>
  </mat-radio-group>

  <mat-form-field appearance="outline" class="small-field">
    <mat-label translate>Initials</mat-label>
    <input matInput type="text" formControlName="initials" minLength="1" required autocomplete="off">
  </mat-form-field>

  <div fxLayout="row" fxLayoutGap="3%">
    <mat-form-field fxFlex="40" appearance="outline">
      <mat-label translate>First name</mat-label>
      <input matInput type="text" formControlName="firstName" minlength="1" required autocomplete="off">
    </mat-form-field>

    <mat-form-field fxFlex="20" appearance="outline">
      <mat-label translate>Middle name</mat-label>
      <input matInput type="text" formControlName="middleName" placeholder="" minlength="1" autocomplete="off">
    </mat-form-field>

    <mat-form-field fxFlex="40" appearance="outline">
      <mat-label translate>Surname</mat-label>
      <input matInput type="text" formControlName="surname" placeholder="" minlength="1" required autocomplete="off">
    </mat-form-field>
  </div>

  <mat-form-field appearance="outline">
    <mat-label translate>Given names</mat-label>
    <input matInput type="text" formControlName="givenNames" minlength="1" maxlength="512" autocomplete="off">
  </mat-form-field>

  <mat-form-field appearance="outline" class="small-field">
    <mat-label translate>Birthdate</mat-label>
    <input matInput [matDatepicker]="picker" formControlName="birthdate" autocomplete="off">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
  </mat-form-field>

  <unite-maritalstatus-input [control]="personGroup.get('maritalStatus')"  [label]="'Marital status' | translate" fxLayout="column">
  </unite-maritalstatus-input>
        
  <unite-income-source-field [control]="personGroup.get('incomeSources')">
  </unite-income-source-field>

  <unite-tax-partner-autocomplete [label]="'Tax partner' | translate" [control]="personGroup.get('taxPartner')">
  </unite-tax-partner-autocomplete>

  <unite-nationality-autocomplete [control]="personGroup.get('nationality')" [label]="'Nationality' | translate">
  </unite-nationality-autocomplete>

  <unite-address-input-v2 class="mt2" formGroupName="address" fxLayout="column">
    <h3 class="mat-title m2" fxLayout="row" fxLayoutAlign="start center">
      <mat-icon class="icon-small mr1">place</mat-icon>
      <span translate>Address</span>
    </h3>
  </unite-address-input-v2>

  <h3 class="mat-title mt2" fxLayout="row" fxLayoutAlign="start center">
    <mat-icon class="icon-small mr1">phone_iphone</mat-icon>
    <span translate>Contact information</span>
  </h3>
  <div fxLayout="row" fxFlex="100" fxLayoutGap="3%">
    <mat-form-field fxFlex="50" appearance="outline">
      <mat-label translate>Telephone</mat-label>
      <input matInput type="text" formControlName="phone" autocomplete="off" pattern="((\+\d{8,14})|([0]\d{9}))">
    </mat-form-field>

    <mat-form-field fxFlex="50" appearance="outline">
      <mat-label translate>Mobile</mat-label>
      <input matInput type="text" formControlName="mobile" autocomplete="off" pattern="((\+\d{8,14})|([0]\d{9}))">
    </mat-form-field>
  </div>

  <mat-form-field appearance="outline">
    <mat-label translate>Email</mat-label>
    <input matInput email type="email" formControlName="email" autocomplete="off">
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label translate>IBAN</mat-label>
    <input matInput type="text" formControlName="iban" maxlength="34" autocomplete="off">
  </mat-form-field>

  <unite-customer-settings 
    [userControl]="personGroup.get('adviser')" 
    [existingControl]="personGroup.get('existing')"
    [businessunitControl]="personGroup.get('businessunit')"
    [isCreating]="true"></unite-customer-settings>


    

  <mat-radio-group class="mat-radio-group-mb1" formControlName="isConsumer">
    <mat-radio-button color="primary" class="mr2" [value]="true"><span translate>Consumer</span>
    </mat-radio-button>
    <mat-radio-button color="primary" class="mr2" [value]="false"><span translate>Professional</span>
    </mat-radio-button>
  </mat-radio-group>

  <div class="info-box" translate *ngIf="!this.supportsConsumerRates">Your organization does not support consumers.</div>

  <div fxLayout="row" fxLayoutAlign="end center">
    <unite-loading-button [text]="'Add client' | translate" [loading]="isLoadingCreate" icon="done"
      (buttonClick)="create()">
    </unite-loading-button>
  </div>
</form>