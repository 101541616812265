import { Component, OnInit, EventEmitter, Output, ElementRef, Input } from '@angular/core';
import { Validators, UntypedFormGroup, FormControl, UntypedFormBuilder } from '@angular/forms';
import { CollaborationsService, CustomersService } from '@app/core/stingray-api/services';
import { Collaboration, GuidId, Customer, Member, NewCollaboration } from '@app/core/stingray-api/models';
import { debounceTime, tap, switchMap, finalize, distinctUntilChanged, map, catchError } from 'rxjs/operators';
import * as _ from 'lodash';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { of } from 'rxjs';
import { GoogleAnalyticsService } from '@app/core/analytics/google-analytics.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { createAddressFormGroup } from '@app/shared/address-input-v2/address-input-v2-tools';

@UntilDestroy()
@Component({
  selector: 'unite-create-collaboration',
  templateUrl: './create-collaboration.component.html',
  styleUrls: ['./create-collaboration.component.scss']
})
export class CreateCollaborationComponent implements OnInit {

  @Input() memberId: string;
  @Output() collaboration = new EventEmitter<Collaboration>();

  collaborationGroup: UntypedFormGroup;

  loadingCreate = false;

  // Members
  searchMemberControl: FormControl;
  searchMemberControlIsLoading = false;
  filteredMembers: Customer[];
  members: Member[] = [];

  constructor(
    private el: ElementRef,
    private googleAnalyticsService: GoogleAnalyticsService,
    private formBuilder: UntypedFormBuilder,
    private customersService: CustomersService,
    private collaborationsService: CollaborationsService) {
    this.collaborationGroup = this.formBuilder.group({
      name: [, [Validators.required]],
      email: [],
      mobile: [],
      phone: [],
      iban: [],
      businessunit: [],
      address: createAddressFormGroup(this.formBuilder),
      adviser: [],
      existing: [false],
    });



    this.searchMemberControl = this.formBuilder.control('');
    this.searchMemberControl.valueChanges.pipe(
      tap(() => this.searchMemberControlIsLoading = true),
      debounceTime(350),
      distinctUntilChanged(),
      untilDestroyed(this),
      switchMap((value: any) => {
        if (value && typeof value === 'string' && value.trim() !== '') {
          return this.customersService.getCustomers({
            name: value,
            types: ['Person', 'Company']
          }).pipe(map(data => data.items), catchError(() => of(null)));
        } else {
          this.searchMemberControlIsLoading = false;
          return of(null);
        }
      })).subscribe((filteredMembers: Customer[]) => {
        // Remove the filtered customers that are already chosen
        this.filteredMembers = _.differenceWith(filteredMembers, this.members, (filteredMemer: Customer, member: Member) => {
          return filteredMemer.id === member.customerId;
        });

        this.searchMemberControlIsLoading = false;
      });
  }

  ngOnInit() {
    if (this.memberId) {
      this.customersService.getCustomerById({ id: this.memberId }).subscribe(customer => {
        this.filteredMembers = [customer];
        this.addCustomerAsMember(customer);
      });
    }
  }

  create() {
    this.collaborationGroup.markAllAsTouched();

    if (this.collaborationGroup.valid) {
      this.googleAnalyticsService.trackEvent('customer', 'add', 'collaboration');
      this.loadingCreate = true;
      const collaboration = <NewCollaboration>this.collaborationGroup.value;
      collaboration.members = this.members;
      this.collaborationsService.addCollaboration({ body: collaboration }).pipe(finalize(() => {
        this.loadingCreate = false;
      })).subscribe((response: GuidId) => {
        collaboration.id = response.id;
        this.collaboration.emit(collaboration);
      });
    } else {
      this.scrollToFirstInvalidControl();
    }
  }

  addMember(matAutocompleteSelectedEvent: MatAutocompleteSelectedEvent) {
    if (matAutocompleteSelectedEvent.option.value) {
      const customer: Customer = matAutocompleteSelectedEvent.option.value;
      this.addCustomerAsMember(customer);
    }
    this.searchMemberControl.setValue('', { emitEvent: false });
  }

  private addCustomerAsMember(customer: Customer) {
    this.members.push({ customerId: customer.id, name: customer.name, type: customer.type, share: 0 });

    this.generateKlantnaam();

    this.members.map((member: Member) => {
      member.share = Math.round(100 / this.members.length);
    });

    // Add the contact information of the first member to the collaboration
    if (!this.collaborationGroup.get('email').value) {
      this.collaborationGroup.get('email').setValue(customer.email, { emitValue: false });
    }
    if (!this.collaborationGroup.get('mobile').value) {
      this.collaborationGroup.get('mobile').setValue(customer.mobile, { emitValue: false });
    }
    if (!this.collaborationGroup.get('phone').value) {
      this.collaborationGroup.get('phone').setValue(customer.phone, { emitValue: false });
    }
    if (!this.collaborationGroup.get('address').get('street').value) {
      this.collaborationGroup.get('address').patchValue(customer.address, { emitValue: false });
    }
  }

  changeShare(member: Member, share: number | string) {
    this.members[this.members.indexOf(member)].share = +share;
  }

  generateKlantnaam(): void {
    this.collaborationGroup.get('name').setValue('');
    this.members.forEach((member: Member, index) => {
      if (index !== 0) {
        this.collaborationGroup.get('name').setValue(this.collaborationGroup.value.name + ' - ');
      }
      this.collaborationGroup.get('name').setValue(this.collaborationGroup.value.name + member.name);
    });
  }

  removeMember(member: Member) {
    this.members.splice(this.members.indexOf(member), 1);

    this.members.map((memberUpdate: Member) => {
      memberUpdate.share = Math.round(100 / this.members.length);
    });

    this.generateKlantnaam();
  }

  displaySearchValue(customer: Customer) {
    if (customer) { return customer.name; }
  }

  private scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
      "form .ng-invalid"
    );

    firstInvalidControl.scrollIntoView();
  }

}
