
<div *ngIf="!isStartingVir && !isCreatingCollaboration">
    <h3 class="medium" translate>Create customer & start VIR</h3>
    <p translate>'VIR' is the real estate indexation tool that will index all buildings, mortgages and collaborations of a given person or company</p>
    

    <form [formGroup]="addressFormGroup">
        <unite-address-input-v2 class="mt2" formGroupName="address" fxLayout="column" [disableManualEnter]="true">
        </unite-address-input-v2>
        <div class="mt2" *ngIf="!retrievedOwners">
            <unite-loading-button 
                [disabled]="!isValidAddress"
                [loading]="isRetrievingOwners" 
                [text]="'Retrieve owners' | translate" 
                (click)="retrieveOwners()">
            </unite-loading-button>
            <em translate>Cadastral charges will apply</em>
        </div>
    </form>

    <div *ngIf="retrievedOwners && owners.length > 0">
        <unite-user-autocomplete [label]="'Client adviser' | translate" required [control]="adviserFormControl"></unite-user-autocomplete>   
        <ng-container  *ngIf="owners.length > 1">
            <hr />
            <div fxLayout="start center">
                <div fxFlex="70%">
                    <strong>
                        <span *ngFor="let owner of owners; let i = index">
                            {{ owner.name }}
                            <span *ngIf="i < (owners.length - 1)"> & </span>
                        </span>
                    </strong><br />
                    <mat-checkbox [checked]="true" [disabled]="true" >
                        {{ 'Buildings' | translate }}
                    </mat-checkbox>
                </div>
                <div fxFlex="30%" fxLayout="row" fxLayoutAlign="end center">
                    <button mat-raised-button (click)="processVirCollaborationByAddress()" color="primary">{{ 'Create customer & start VIR' | translate }}</button>
                </div>
            </div>
        </ng-container>
        <hr />
        <div *ngFor="let owner of owners" class="mt1">
            <div fxFlex="70%">
                <div fxLayoutAlign="start center">
                    <strong>{{ owner.name }}</strong> 
                    <span class="ml1" *ngIf="owner.isEstimatingCosts"><mat-spinner diameter="16"></mat-spinner></span>
                    <span class="ml1" *ngIf="!owner.isEstimatingCosts && owner.estimatedCosts"> ({{ owner.estimatedCosts.numberOfRegistrations }} {{ (owner.estimatedCosts.numberOfRegistrations === 1 ? 'cadastral registration' : 'cadastral registrations') | translate }})</span>
                </div>
        
                <mat-checkbox [checked]="true" [disabled]="true" >
                    {{ 'Buildings' | translate }}
                </mat-checkbox> <br />
                <mat-checkbox [formControl]="owner.form.get('includeOwnership')" >
                    {{ 'Collaborations / purchase information / groundlease' | translate }}
                    <span *ngIf="owner.estimatedCosts">({{ owner.estimatedCosts.ownerInformationCosts | currency:'EUR':'symbol':'1.0-0' }})</span>
                </mat-checkbox> <br />
                <mat-checkbox [formControl]="owner.form.get('includeMortgages')" >
                    {{ 'Mortgages / seizures' | translate }}
                    <span *ngIf="owner.estimatedCosts">({{ owner.estimatedCosts.mortgageInformationCosts | currency:'EUR':'symbol':'1.0-0' }})</span>
                </mat-checkbox> <br />
                <div fxLayout="row" fxLayoutAlign="start center"  *ngIf="owner.matches.length > 0"> 
                    <mat-checkbox [formControl]="owner.form.get('isExistingCustomer')" >{{ 'Is existing customer' | translate }}</mat-checkbox> 
                    <mat-select [formControl]="owner.form.get('existingCustomerId')"  class="ml1">
                        <mat-option [value]="match.customerId" *ngFor="let match of owner.matches">{{ match.customerName }}</mat-option>
                    </mat-select>
                </div>
            </div>
            <div fxFlex="30%" fxLayout="row" fxLayoutAlign="end center">
                <button mat-raised-button color="primary" (click)="startVir(owner)">{{ 'Create customer & start VIR' | translate }}</button> 
            </div>
            
        </div>

    </div>

    <div *ngIf="!isRetrievingOwners && retrievedOwners && owners.length === 0" translate>No owners found</div>

</div>

<div *ngIf="isStartingVir || isCreatingCollaboration" class="loader mt1">
    <mat-spinner diameter="32"></mat-spinner>
    <span translate *ngIf="isStartingVir">Starting VIR</span>
    <span translate *ngIf="isCreatingCollaboration">Creating collaboration</span>
</div>
