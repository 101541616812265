<!-- Template input-->
<ng-content></ng-content>

  <!-- State 1: Search address -->
<div fxLayoutAlign="start center" *ngIf="!this.isAddressSelected() || clearSelected" >

      <mat-form-field *ngIf="manualInput === false" 
        [appearance]="appearance" 
        [ngClass]="{ 'invalid': showValidationError() }"
        [class]="class + ' address-material-input-' + appearance" fxFlex="80">
        <mat-label translate *ngIf="appearance !== 'none'">Search for address</mat-label>
        <input matInput [placeholder]="appearance === 'none' ? '...' : ''" #searchBox type="text" value="{{getAddressLabel()}}" [matAutocomplete]="auto" autocomplete="off" (keyup)="$event.key !== 'Enter' ? searchTerm.next($event.target.value) : searchTerm" >
        <mat-spinner matSuffix class="px2" *ngIf="loading" [diameter]="appearance === 'outline' ? 24 : 16"></mat-spinner>
        <button mat-button mat-icon-button matSuffix (click)="changeManualInput()" *ngIf="!loading && !manualInput && !disableManualEnter" >
          <mat-icon class="icon-small" (click)="changeManualInput()">search_off</mat-icon>
        </button>
      </mat-form-field> 

      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="optionSelected($event)" [displayWith]="renderPossibleAddress">
        <mat-option *ngIf="searched && !loading && possibleAddresses.length === 0">
          <div fxLayout="row" fxLayoutAlign="start center" >
            <span class="main black" (click)="changeManualInput(true)">
              <span *ngIf="!disableManualEnter" translate>Nothing found, click to enter details manually</span>
              <span *ngIf="disableManualEnter" translate>Nothing found</span>
            </span>
          </div>
        </mat-option>
        <mat-option *ngFor="let address of possibleAddresses" [value]="address">
          <div>{{ renderPossibleAddress(address) }}</div>
        </mat-option>
      </mat-autocomplete>
  </div>
  
  <!-- State 2: Address selected -->
  <div *ngIf="isAddressSelected() && !clearSelected && appearance !== 'outline'">
    <span class="good" fxLayout="row" fxLayoutAlign="start center" fxFlex="nogrow">
      <!-- <mat-icon class="icon-small mr1">place</mat-icon> -->
      <span>{{getAddressLabel()}}</span>
      <mat-icon class="icon-small" (click)="clearAddress()">clear</mat-icon>
    </span>
  </div>
  
  <div *ngIf="isAddressSelected() && !clearSelected && appearance === 'outline'" fxLayout="row" fxLayoutAlign="start center">
    <mat-form-field [appearance]="appearance" [class]="class" fxFlex="80">
      <mat-label translate>Address</mat-label>
      <input matInput type="text" value="{{getAddressLabel()}}" readonly>
      <button mat-button mat-icon-button matSuffix (click)="clearAddress()">
        <mat-icon class="icon-small" (click)="clearAddress()">clear</mat-icon>
      </button>
    </mat-form-field>    
  </div>


  <!-- State 3: Manual input -->
  <div *ngIf="manualInput === true && !this.isAddressSelected()" [formGroup]="addressGroup">

    <div fxLayoutGap="0.75rem">
      <mat-form-field [appearance]="appearance" [class]="class" fxFlex="60">
        <mat-label translate>Street</mat-label>
        <input matInput type="text" formControlName="street" [required]="manualInput" autocomplete="off">
      </mat-form-field>

      <mat-form-field [appearance]="appearance" [class]="class" fxFlex="20">
        <mat-label translate>House number</mat-label>
        <input matInput type="text" formControlName="houseNumber" min="1" [required]="manualInput" autocomplete="off">
      </mat-form-field>

      <mat-form-field [class]="class" [appearance]="appearance" fxFlex="20">
        <mat-label translate>Addition</mat-label>
        <input matInput type="text" formControlName="houseNumberAddition" autocomplete="off">
      </mat-form-field>
    </div>

    <div fxLayoutGap="0.75rem">
      <mat-form-field [appearance]="appearance" [class]="class" fxFlex="40">
        <mat-label translate>Postalcode</mat-label>
        <input matInput type="text" formControlName="postalCode" [maxlength]="manualInput ? 18 : 6" [required]="manualInput" autocomplete="off">
      </mat-form-field>

      <mat-form-field [appearance]="appearance" [class]="class" fxFlex="40">
        <mat-label translate>City</mat-label>
        <input matInput type="text" formControlName="city" [required]="manualInput" autocomplete="off">
      </mat-form-field>

      <unite-country-autocomplete required [label]="'Country' | translate" [class]="class" [appearance]="appearance" [control]="addressGroup.get('country')" fxFlex="20">
      </unite-country-autocomplete>
    </div>
  </div>

  <div *ngIf="!addressGroup.pristine && !isValidAddress()" translate>
    <span class="pl1 py2 pr2 warning" fxLayout="row" fxLayoutAlign="start center" fxFlex="nogrow">
      <mat-icon class="icon-small mr1">error_outline</mat-icon>
      <span translate>This is not a valid address</span>
    </span>
  </div>
  
  

<!-- </ng-container> -->

<!-- <a class="link mt0" *ngIf="manualInput === false && disableManualEnter == false" (click)="changeManualInput()" translate>Can not find the right the postalcode / house number</a>
<a class="link mt0" *ngIf="manualInput === true" (click)="changeManualInput()" translate>Back to automatically search for addresses</a> -->

