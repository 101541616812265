/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Collaboration } from '../models/collaboration';
import { JsonPatchDocument } from '../models/json-patch-document';
import { Member } from '../models/member';
import { NewCollaboration } from '../models/new-collaboration';

@Injectable({
  providedIn: 'root',
})
export class CollaborationsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getCollaborationById
   */
  static readonly GetCollaborationByIdPath = '/api/v1/Collaborations/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCollaborationById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCollaborationById$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Collaboration>> {

    const rb = new RequestBuilder(this.rootUrl, CollaborationsService.GetCollaborationByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Collaboration>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCollaborationById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCollaborationById$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Collaboration> {

    return this.getCollaborationById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Collaboration>) => r.body as Collaboration)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCollaborationById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCollaborationById$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Collaboration>> {

    const rb = new RequestBuilder(this.rootUrl, CollaborationsService.GetCollaborationByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Collaboration>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCollaborationById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCollaborationById(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Collaboration> {

    return this.getCollaborationById$Response(params,context).pipe(
      map((r: StrictHttpResponse<Collaboration>) => r.body as Collaboration)
    );
  }

  /**
   * Path part for operation updateCollaboration
   */
  static readonly UpdateCollaborationPath = '/api/v1/Collaborations/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCollaboration$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCollaboration$Plain$Response(params: {
    id: string;
    body?: Collaboration
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Collaboration>> {

    const rb = new RequestBuilder(this.rootUrl, CollaborationsService.UpdateCollaborationPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Collaboration>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCollaboration$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCollaboration$Plain(params: {
    id: string;
    body?: Collaboration
  },
  context?: HttpContext

): Observable<Collaboration> {

    return this.updateCollaboration$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Collaboration>) => r.body as Collaboration)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCollaboration()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCollaboration$Response(params: {
    id: string;
    body?: Collaboration
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Collaboration>> {

    const rb = new RequestBuilder(this.rootUrl, CollaborationsService.UpdateCollaborationPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Collaboration>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCollaboration$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCollaboration(params: {
    id: string;
    body?: Collaboration
  },
  context?: HttpContext

): Observable<Collaboration> {

    return this.updateCollaboration$Response(params,context).pipe(
      map((r: StrictHttpResponse<Collaboration>) => r.body as Collaboration)
    );
  }

  /**
   * Path part for operation patchCollaboration
   */
  static readonly PatchCollaborationPath = '/api/v1/Collaborations/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchCollaboration$Plain()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchCollaboration$Plain$Response(params: {
    id: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Collaboration>> {

    const rb = new RequestBuilder(this.rootUrl, CollaborationsService.PatchCollaborationPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json-patch+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Collaboration>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchCollaboration$Plain$Response()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchCollaboration$Plain(params: {
    id: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<Collaboration> {

    return this.patchCollaboration$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Collaboration>) => r.body as Collaboration)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchCollaboration()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchCollaboration$Response(params: {
    id: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Collaboration>> {

    const rb = new RequestBuilder(this.rootUrl, CollaborationsService.PatchCollaborationPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json-patch+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Collaboration>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchCollaboration$Response()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchCollaboration(params: {
    id: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<Collaboration> {

    return this.patchCollaboration$Response(params,context).pipe(
      map((r: StrictHttpResponse<Collaboration>) => r.body as Collaboration)
    );
  }

  /**
   * Path part for operation addCollaboration
   */
  static readonly AddCollaborationPath = '/api/v1/Collaborations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addCollaboration$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addCollaboration$Plain$Response(params?: {
    body?: NewCollaboration
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Collaboration>> {

    const rb = new RequestBuilder(this.rootUrl, CollaborationsService.AddCollaborationPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Collaboration>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addCollaboration$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addCollaboration$Plain(params?: {
    body?: NewCollaboration
  },
  context?: HttpContext

): Observable<Collaboration> {

    return this.addCollaboration$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Collaboration>) => r.body as Collaboration)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addCollaboration()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addCollaboration$Response(params?: {
    body?: NewCollaboration
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Collaboration>> {

    const rb = new RequestBuilder(this.rootUrl, CollaborationsService.AddCollaborationPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Collaboration>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addCollaboration$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addCollaboration(params?: {
    body?: NewCollaboration
  },
  context?: HttpContext

): Observable<Collaboration> {

    return this.addCollaboration$Response(params,context).pipe(
      map((r: StrictHttpResponse<Collaboration>) => r.body as Collaboration)
    );
  }

  /**
   * Path part for operation deleteCollaborationById
   */
  static readonly DeleteCollaborationByIdPath = '/api/v1/Collaborations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCollaborationById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCollaborationById$Response(params?: {
    id?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CollaborationsService.DeleteCollaborationByIdPath, 'delete');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteCollaborationById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCollaborationById(params?: {
    id?: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.deleteCollaborationById$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getCollaborationMembers
   */
  static readonly GetCollaborationMembersPath = '/api/v1/Collaborations/{id}/Members';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCollaborationMembers$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCollaborationMembers$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Member>>> {

    const rb = new RequestBuilder(this.rootUrl, CollaborationsService.GetCollaborationMembersPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Member>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCollaborationMembers$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCollaborationMembers$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<Member>> {

    return this.getCollaborationMembers$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Member>>) => r.body as Array<Member>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCollaborationMembers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCollaborationMembers$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Member>>> {

    const rb = new RequestBuilder(this.rootUrl, CollaborationsService.GetCollaborationMembersPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Member>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCollaborationMembers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCollaborationMembers(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<Member>> {

    return this.getCollaborationMembers$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Member>>) => r.body as Array<Member>)
    );
  }

  /**
   * Path part for operation updateCollaborationMembers
   */
  static readonly UpdateCollaborationMembersPath = '/api/v1/Collaborations/{id}/Members';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCollaborationMembers$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCollaborationMembers$Plain$Response(params: {
    id: string;
    body?: Array<Member>
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Member>>> {

    const rb = new RequestBuilder(this.rootUrl, CollaborationsService.UpdateCollaborationMembersPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Member>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCollaborationMembers$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCollaborationMembers$Plain(params: {
    id: string;
    body?: Array<Member>
  },
  context?: HttpContext

): Observable<Array<Member>> {

    return this.updateCollaborationMembers$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Member>>) => r.body as Array<Member>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCollaborationMembers()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCollaborationMembers$Response(params: {
    id: string;
    body?: Array<Member>
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Member>>> {

    const rb = new RequestBuilder(this.rootUrl, CollaborationsService.UpdateCollaborationMembersPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Member>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCollaborationMembers$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCollaborationMembers(params: {
    id: string;
    body?: Array<Member>
  },
  context?: HttpContext

): Observable<Array<Member>> {

    return this.updateCollaborationMembers$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Member>>) => r.body as Array<Member>)
    );
  }

  /**
   * Path part for operation addCollaborationMembers
   */
  static readonly AddCollaborationMembersPath = '/api/v1/Collaborations/{id}/Members';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addCollaborationMembers$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addCollaborationMembers$Plain$Response(params: {
    id: string;
    body?: Array<Member>
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Member>>> {

    const rb = new RequestBuilder(this.rootUrl, CollaborationsService.AddCollaborationMembersPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Member>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addCollaborationMembers$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addCollaborationMembers$Plain(params: {
    id: string;
    body?: Array<Member>
  },
  context?: HttpContext

): Observable<Array<Member>> {

    return this.addCollaborationMembers$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Member>>) => r.body as Array<Member>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addCollaborationMembers()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addCollaborationMembers$Response(params: {
    id: string;
    body?: Array<Member>
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Member>>> {

    const rb = new RequestBuilder(this.rootUrl, CollaborationsService.AddCollaborationMembersPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Member>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addCollaborationMembers$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addCollaborationMembers(params: {
    id: string;
    body?: Array<Member>
  },
  context?: HttpContext

): Observable<Array<Member>> {

    return this.addCollaborationMembers$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Member>>) => r.body as Array<Member>)
    );
  }

  /**
   * Path part for operation updateCollaborationMember
   */
  static readonly UpdateCollaborationMemberPath = '/api/v1/Collaborations/{id}/Members/{memberId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCollaborationMember()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCollaborationMember$Response(params: {
    id: string;
    memberId: string;
    body?: Member
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CollaborationsService.UpdateCollaborationMemberPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('memberId', params.memberId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCollaborationMember$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateCollaborationMember(params: {
    id: string;
    memberId: string;
    body?: Member
  },
  context?: HttpContext

): Observable<void> {

    return this.updateCollaborationMember$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation deleteCollaborationMemberById
   */
  static readonly DeleteCollaborationMemberByIdPath = '/api/v1/Collaborations/{id}/Members/{memberId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCollaborationMemberById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCollaborationMemberById$Response(params: {
    id: string;
    memberId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, CollaborationsService.DeleteCollaborationMemberByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('memberId', params.memberId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteCollaborationMemberById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCollaborationMemberById(params: {
    id: string;
    memberId: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.deleteCollaborationMemberById$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
