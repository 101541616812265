<mat-form-field fxFlex [appearance]="appearance" [class]="class">
  <mat-label>{{label}}</mat-label>
  <mat-spinner *ngIf="isLoadingCountries === true" matSuffix diameter="14" class="mr1"></mat-spinner>
  <input [formControl]="control" type="text" matInput [matAutocomplete]="countryAutoComplete"
    [required]="required !== false" [attr.cdkFocusInitial]="focus !== false ? 'true' : null" autocomplete="off">
  <mat-autocomplete autoActiveFirstOption #countryAutoComplete="matAutocomplete" [displayWith]="displayWithCountry">
    <mat-option *ngIf="required === false" [value]="null">
      <span translate>None</span>
    </mat-option>
    <mat-option *ngFor="let country of countriesFiltered" [value]="country">
      {{country.name}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>