import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { GoogleAnalyticsService } from '@app/core/analytics/google-analytics.service';
import { DocumentSearchResult, SearchResults, SearchHighlight, SearchRecord } from '@app/core/stingray-api/models';
import { SearchService } from '@app/core/stingray-api/services';
import { PublicAccessService } from '@app/core/stingray-api/services/public-access.service';
import { of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, finalize, switchMap } from 'rxjs/operators';
import { MatLegacyAutocomplete as MatAutocomplete, MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent, MatLegacyAutocompleteTrigger as MatAutocompleteTrigger } from '@angular/material/legacy-autocomplete';
import { ActivatedRoute, Router } from '@angular/router';
import { StingrayAuthenticationService } from '@app/core/authentication/stingray-authentication.service';
import { type } from 'os';
import { tickStep } from 'd3';

const LOCALSTORAGE_SEARCH_SELECTED_KEY = 'stingray.searchbox.selected';
const LOCALSTORAGE_SEARCH_OPTIONS_KEY = 'stingray.searchbox.options';

@Component({
  selector: 'unite-search-box-v2',
  templateUrl: './search-box-v2.component.html',
  styleUrls: ['./search-box-v2.component.scss']
})
export class SearchBoxV2Component implements OnInit {
  @ViewChild('searchBarInput', { static: true }) inputEl: ElementRef;
  @ViewChild(MatAutocompleteTrigger) auto: MatAutocompleteTrigger;

  @Input() placeholder = '';
  @Input() value = '';


  searchResults: SearchResults;
  searchTerm = new Subject<string>();

  searchOptions: { count: number, page: number, terms?: string, customers: boolean, buildings: boolean, applications: boolean,  notes: boolean, documents: boolean, financings: boolean, mortgages: boolean};
  searching: boolean;

  loading = false;
  searched = false;

  isProfessional = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private searchService: SearchService,
    private publicAccessService: PublicAccessService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private stingrayAuthenticationService: StingrayAuthenticationService
    ) {
  }

  ngOnInit(): void {
    this.isProfessional = this.stingrayAuthenticationService.organization.subscription === 'Professional';

    this.initializeSearchOptions();
    this.initializeSearchTermSubject();
    this.initializeSearchResultsFromPreviouslySelected();
  }

  private initializeSearchOptions() {

    this.searchOptions = this.getDefaultSearchOptions();

    let rawPreviousSearchOptions = localStorage.getItem(this.getLocalStorageOptionsKey());

    if(rawPreviousSearchOptions) {
      try {
        this.searchOptions = JSON.parse(rawPreviousSearchOptions);
      } catch (error) {
        this.searchOptions = this.getDefaultSearchOptions();
      }
    }

    this.searchOptions.count = 10;
  }

  /**
   * Access to entities is managed on --> 3 <--- layers
   * 1. This client application, not allowing the user to set searching for some entities to true
   * 2. The API, not allowing true values for inaccesible entitities.
   * 3. The underlying search-index not having the entities indexed for non-professional organizations
   */
  private getDefaultSearchOptions() {
    return {
      count: 10,
      page: 0,
      
      // Default on, only for professional organizations
      customers: this.isProfessional,
      buildings: this.isProfessional,

      // Default on, for non professional organizations (no access to other entities)
      applications: !this.isProfessional,
      financings: !this.isProfessional,

      // Default false
      documents: false,
      notes: false,
      mortgages: false
    };
  }

  private initializeSearchResultsFromPreviouslySelected() {
    this.searchResults = {
      records: this.getPreviouslySelected(),
      notes: [],
      documents: []
    };
  }

  private initializeSearchTermSubject() {
    
    this.loading = false;

    this.searchTerm =  new Subject<string>();
 
    this.searchTerm.pipe(
      // wait 300ms after each keystroke before considering the term
      debounceTime(300),

      // ignore new term if same as previous term
      //distinctUntilChanged(),
      // switch to new search observable each time the term changes
      switchMap((term: string) => {
        this.searchOptions.terms = term;


        

        this.searched = true;
        this.loading = true;

        this.saveSearchOptions();

        if (term && term.trim() !== '') {
          this.googleAnalyticsService.trackEvent('search', 'query');
          return this.searchService.searchByTerms(this.searchOptions);
        } else {
          return of(null);
        }
      }),
      catchError((err) => {
        // Reinitialize upon error, so next searches are processed (if not it would become not responsive)
        this.initializeSearchTermSubject();
        return [];
      }),
      finalize(() => {
        this.loading = false;
      })
    ).subscribe((searchResults: SearchResults) => {
      this.loading = false;

      if(searchResults) {
        this.searchResults = searchResults;  
      } else {
        this.initializeSearchResultsFromPreviouslySelected();
      }
      
      if (this.route.snapshot.queryParams['q']) {
        const query = this.route.snapshot.queryParams['q'];

        // Searching through querystring, navigate to first proposed result
        if (query) {
            let record = this.searchResults.records[0];

            if(record.type === 'Application') {
              this.router.navigateByUrl('/customers/' + record.customerId + '/applications/' + record.id + '/detail');
            }
            else {
              this.router.navigateByUrl('/customers/' + record.customerId + '/detail');
            }

            this.auto.closePanel();
        }
      }
    });
  }



  ngAfterViewInit() {

    /* Trigger deeplink search */
    if (this.route.snapshot.queryParams['q']) {
      const query = this.route.snapshot.queryParams['q'];
      this.inputEl.nativeElement.value = query;
      this.searchResults.records = [];
      this.searchOptions.applications = true;
      this.searchOptions.customers = true;
      this.searchOptions.buildings = false;
      this.searchOptions.documents = false;
      this.searchOptions.notes = false;
      this.searchOptions.financings = false;
      this.searchOptions.mortgages = false;
      this.search(`"${query}"`); // Treat the querystring as an exact search
    }
  }

  public optionSelected(event: MatAutocompleteSelectedEvent) {
    event.option._getHostElement().click();
  }

  public research() {
    this.search(this.searchOptions.terms);
  }

  public hasNoResults(): boolean {
    return this.searchResults != null && 
           this.loading === false &&
           this.searchResults.notes.length === 0 &&
           this.searchResults.documents.length === 0 && 
           this.searchResults.records.length === 0;
  }

  public inputClick() {
    this.inputEl.nativeElement.select();
    this.auto.openPanel();
    
  }


  public hasHighlightMatchOnField(highlights: SearchHighlight[], fieldName: string): boolean {
    return typeof highlights.find(x => x.field === fieldName) !== 'undefined';
  }

  public getHighlightMatchOnField(highlights: SearchHighlight[], fieldName: string): string {
    return highlights.find(x => x.field === fieldName).match;
  }

  /**
   * Instructs the app to download the document
   */
  public downloadDocumentFromResult(result: DocumentSearchResult): void {
    this.googleAnalyticsService.trackEvent('document', 'download', 'search');
    this.publicAccessService.getPublicAccessForDocumentOpen({ storageFileName: result.storageFileName }).subscribe(t => window.open(t.publicUri, '_blank'));
  }

  public search(term: string): void {
    this.searchTerm.next(term);
  }

  private getPreviouslySelected() : SearchRecord[] {
    let rawSelectedSearchRecords = localStorage.getItem(this.getLocalStorageSelectedKey());

    if(!rawSelectedSearchRecords) {
      return [];
    } else {
      try {
          return JSON.parse(rawSelectedSearchRecords) as SearchRecord[];
      } catch (error) {
        return [];
      }
    }
  }
  

  public savePreviouslySelected(record: SearchRecord) {
    let previousSearchRecords = this.getPreviouslySelected();
    let matchingRecord = previousSearchRecords.filter(s => s.id === record.id)[0];

    if(!matchingRecord) {
      previousSearchRecords.unshift(record);
      
    } else {
      let removedMatchedRecord = previousSearchRecords.splice(previousSearchRecords.indexOf(matchingRecord), 1)[0];
      previousSearchRecords.unshift(removedMatchedRecord)
    }

    localStorage.setItem(this.getLocalStorageSelectedKey(), JSON.stringify(previousSearchRecords.slice(0,5)));
  }

  public optionClick(record: SearchRecord) {
    this.savePreviouslySelected(record);
    this.initializeSearchResultsFromPreviouslySelected();    
  }

  private saveSearchOptions() {
    localStorage.setItem(this.getLocalStorageOptionsKey(), JSON.stringify(this.searchOptions));
  }

  private getLocalStorageSelectedKey() {
    return  [LOCALSTORAGE_SEARCH_SELECTED_KEY,  this.stingrayAuthenticationService.organizationId].join('.');
  }

  private getLocalStorageOptionsKey() {
    return  [LOCALSTORAGE_SEARCH_OPTIONS_KEY,  this.stingrayAuthenticationService.organizationId].join('.');
  }
}
