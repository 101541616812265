import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'unite-income-source-field',
  templateUrl: './income-source-field.component.html',
  styleUrls: ['./income-source-field.component.scss']
})
export class IncomeSourceFieldComponent implements OnInit {

  @Input() control: FormControl;
  @Input() classField = '';

  @Input() editIcon = false;
  @Input() checkIcon = true;

  constructor() {
  }

  ngOnInit(): void {
  }

  onEnter(event: KeyboardEvent) {
    (<HTMLInputElement>event.target).blur();
  }
}
