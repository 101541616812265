<ng-container *ngIf="matIconButton === true && matStrokedButton === false">
  <button fxFlex [matTooltip]="tooltip" [ngClass]="{'small-button': small !== false}" mat-icon-button [color]="color"
    [disabled]="loading || disabled" [class.is-loading]="loading" (click)="buttonClick.emit()">
    <mat-icon *ngIf="icon && matIconPosition === 'prefix'" class="not-loading">{{icon}}</mat-icon>
    <span class="loading" *ngIf="loading">
      <mat-spinner diameter="24"></mat-spinner>
    </span>
    <mat-icon *ngIf="icon && matIconPosition === 'suffix'" class="not-loading">{{icon}}</mat-icon>
  </button>
</ng-container>
<ng-container *ngIf="matStrokedButton === true && matIconButton === false">
  <button fxFlex [matTooltip]="tooltip" [ngClass]="{'small-button': small !== false}" mat-stroked-button [color]="color"
    [disabled]="loading || disabled" [class.is-loading]="loading" (click)="buttonClick.emit()">
    <mat-icon *ngIf="icon && matIconPosition === 'prefix'" class="not-loading" [class.mr1]="text && text !== ''">{{icon}}</mat-icon>
    <span *ngIf="text && text !== ''" class="not-loading">{{text}}</span>
    <span class="loading" *ngIf="loading">
      <mat-spinner diameter="24"></mat-spinner>
    </span>
    <mat-icon *ngIf="icon && matIconPosition === 'suffix'" class="not-loading" [class.ml1]="text && text !== ''">{{icon}}</mat-icon>
  </button>
</ng-container>
<ng-container *ngIf="matStrokedButton === false && matIconButton === false">
  <button fxFlex [matTooltip]="tooltip" [ngClass]="{'small-button': small !== false}" mat-flat-button [color]="color"
    [disabled]="loading || disabled" [class.is-loading]="loading" (click)="buttonClick.emit()">
    <mat-icon *ngIf="icon && matIconPosition === 'prefix'" class="not-loading" [class.mr1]="text && text !== ''">{{icon}}</mat-icon>
    <span *ngIf="text && text !== ''" class="not-loading">{{text}}</span>
    <span class="loading" *ngIf="loading">
      <mat-spinner diameter="24"></mat-spinner>
    </span>
    <mat-icon *ngIf="icon && matIconPosition === 'suffix'" class="not-loading" [class.ml1]="text && text !== ''">{{icon}}</mat-icon>
  </button>
</ng-container>