import { Injectable } from '@angular/core';
import { Lender } from '@app/core/stingray-api/models';
import { LendersService } from '@app/core/stingray-api/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class LenderAutoCompleteService {

  constructor(private lendersService: LendersService) { }

  /**
   * Clears the session storage so any subsequent call
   * forces to retrieve the lender list from the backend
   */
  clearCache() {
    sessionStorage.removeItem('lenderAutoComplete');
  }

  getLenders(): Observable<Lender[]> {
    //const cache = sessionStorage.getItem('lenderAutoComplete');
    //if (cache) {
    //  return of(JSON.parse(cache));
    //} else {
    return this.lendersService.getLenders().pipe(
      map((lenders: Lender[]) => {
        // Only show the lenders that are 'connected' with Unite
        // lenders = lenders.filter(lender => lender.number === 1 || lender.number === 2 || lender.number === 3 || lender.number === 5);
        // sessionStorage.setItem('lenderAutoComplete', JSON.stringify(lenders));
        return lenders;
      })
    );
    //}
  }

}
