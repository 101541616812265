import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { StingrayAuthenticationService } from '../authentication/stingray-authentication.service';
import { ToasterService } from '../toaster/toaster.service';
import { GoogleAnalyticsService } from '../analytics/google-analytics.service';

/**
 * Adds a default error handler to all requests.
 */
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  excludePaths = ['version.json'];

  constructor(
    private translateService: TranslateService,
    private toasterService: ToasterService,
    private googleAnalyticsService: GoogleAnalyticsService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(error => this.errorHandler(request, error)));
  }

  /**
   * Handle the given http error
   * @param response error response
   */
  private errorHandler(request: HttpRequest<any>, response: HttpErrorResponse): Observable<HttpEvent<any>> {

    let isExcludedPath = false;
    this.excludePaths.forEach(path => {
      if( request.url.indexOf(path) > -1) {
        isExcludedPath = true;
      }
    });


    if(!isExcludedPath) {
      if (response.status === 400 && (request.method === 'POST' || request.method === 'PUT' || request.method === 'PATCH')) {
        this.toasterService.error(this.translateService.instant('No valid input'));
      } else if (response.status === 401) {
        this.toasterService.error(this.translateService.instant('The user is not authorized'));
      } else if (response.status === 403) {
        this.toasterService.error(this.translateService.instant('The user has not sufficient permissions for this action'));
      } else if (response.status === 503) {
        this.toasterService.error(this.translateService.instant('The server is unreachable. Please try again in a few minutes'));
      } else {
        this.toasterService.error(this.translateService.instant('Something went wrong'));
      }

      // Exception tracking
      if (!environment.production ) {
        console.log(response);
      } else {
        let apiName  = 'Unknown';
        if (request.url.includes(environment.stingrayApiEndpoint)) {
          apiName = 'Stingray';
        }
        if (request.url.includes(environment.octopusApiEndpoint)) {
          apiName = 'Octopus';
        }

        this.googleAnalyticsService.trackException(`${apiName} API - ${response.status}`);
      }
    }

    return throwError(response);
  }
}
