/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Lender } from '../models/lender';
import { Notary } from '../models/notary';
import { RateOptions } from '../models/rate-options';
import { Valuator } from '../models/valuator';

@Injectable({
  providedIn: 'root',
})
export class LendersService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getLenders
   */
  static readonly GetLendersPath = '/api/v1/Lenders';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLenders$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLenders$Plain$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Lender>>> {

    const rb = new RequestBuilder(this.rootUrl, LendersService.GetLendersPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Lender>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLenders$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLenders$Plain(params?: {
  },
  context?: HttpContext

): Observable<Array<Lender>> {

    return this.getLenders$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Lender>>) => r.body as Array<Lender>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLenders$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLenders$Json$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Lender>>> {

    const rb = new RequestBuilder(this.rootUrl, LendersService.GetLendersPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Lender>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLenders$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLenders$Json(params?: {
  },
  context?: HttpContext

): Observable<Array<Lender>> {

    return this.getLenders$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Lender>>) => r.body as Array<Lender>)
    );
  }

  /**
   * Path part for operation getLenderById
   */
  static readonly GetLenderByIdPath = '/api/v1/Lenders/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLenderById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLenderById$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Lender>> {

    const rb = new RequestBuilder(this.rootUrl, LendersService.GetLenderByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Lender>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLenderById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLenderById$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Lender> {

    return this.getLenderById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Lender>) => r.body as Lender)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLenderById$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLenderById$Json$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Lender>> {

    const rb = new RequestBuilder(this.rootUrl, LendersService.GetLenderByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Lender>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLenderById$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLenderById$Json(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Lender> {

    return this.getLenderById$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Lender>) => r.body as Lender)
    );
  }

  /**
   * Path part for operation getLenderNotaries
   */
  static readonly GetLenderNotariesPath = '/api/v1/Lenders/{id}/Notaries';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLenderNotaries$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLenderNotaries$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Notary>>> {

    const rb = new RequestBuilder(this.rootUrl, LendersService.GetLenderNotariesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Notary>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLenderNotaries$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLenderNotaries$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<Notary>> {

    return this.getLenderNotaries$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Notary>>) => r.body as Array<Notary>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLenderNotaries$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLenderNotaries$Json$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Notary>>> {

    const rb = new RequestBuilder(this.rootUrl, LendersService.GetLenderNotariesPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Notary>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLenderNotaries$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLenderNotaries$Json(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<Notary>> {

    return this.getLenderNotaries$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Notary>>) => r.body as Array<Notary>)
    );
  }

  /**
   * Path part for operation getLenderValuators
   */
  static readonly GetLenderValuatorsPath = '/api/v1/Lenders/{id}/Valuators';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLenderValuators$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLenderValuators$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Valuator>>> {

    const rb = new RequestBuilder(this.rootUrl, LendersService.GetLenderValuatorsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Valuator>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLenderValuators$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLenderValuators$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<Valuator>> {

    return this.getLenderValuators$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Valuator>>) => r.body as Array<Valuator>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLenderValuators$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLenderValuators$Json$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Valuator>>> {

    const rb = new RequestBuilder(this.rootUrl, LendersService.GetLenderValuatorsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Valuator>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getLenderValuators$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLenderValuators$Json(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<Valuator>> {

    return this.getLenderValuators$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Valuator>>) => r.body as Array<Valuator>)
    );
  }

  /**
   * Path part for operation getRates
   */
  static readonly GetRatesPath = '/api/v1/Rates';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRates$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRates$Plain$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<RateOptions>>> {

    const rb = new RequestBuilder(this.rootUrl, LendersService.GetRatesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RateOptions>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRates$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRates$Plain(params?: {
  },
  context?: HttpContext

): Observable<Array<RateOptions>> {

    return this.getRates$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<RateOptions>>) => r.body as Array<RateOptions>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getRates$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRates$Json$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<RateOptions>>> {

    const rb = new RequestBuilder(this.rootUrl, LendersService.GetRatesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RateOptions>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getRates$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getRates$Json(params?: {
  },
  context?: HttpContext

): Observable<Array<RateOptions>> {

    return this.getRates$Json$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<RateOptions>>) => r.body as Array<RateOptions>)
    );
  }

}
