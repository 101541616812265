import { Injectable } from '@angular/core';
import createAuth0Client, { Auth0Client, Auth0ClientOptions, GetTokenSilentlyVerboseResponse, GetUserOptions } from '@auth0/auth0-spa-js';
import { from, of, Observable, BehaviorSubject, combineLatest, throwError } from 'rxjs';
import { tap, catchError, concatMap, shareReplay } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { Organization, User } from '../stingray-api/models';
import { I18nService } from '../i18n.service';

@Injectable()
export class StingrayAuthenticationService {

  // User information
  user: User;
  userId: any = null;

  // Organization
  organization: Organization;
  organizationId: any = null;


  // Create an observable of Auth0 instance of client
  auth0Client$ = (from(
    createAuth0Client(<Auth0ClientOptions>{
      client_id: environment.stingrayAuthentication.clientID,
      domain: environment.stingrayAuthentication.domain,
      redirect_uri: environment.stingrayAuthentication.redirectUri,
      audience: environment.stingrayAuthentication.audience,
      scope: environment.stingrayAuthentication.scope
    })
  ) as Observable<Auth0Client>).pipe(
    shareReplay(1), // Every subscription receives the same shared value
    catchError((err) => throwError(err))
  );

  // Define observables for SDK methods that return promises by default
  // For each Auth0 SDK method, first ensure the client instance is ready
  // concatMap: Using the client instance, call SDK method; SDK returns a promise
  // from: Convert that resulting promise into an observable
  isAuthenticated$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.isAuthenticated())),
    tap(res => this.loggedIn = res)
  );
  handleRedirectCallback$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.handleRedirectCallback()))
  );

  // Create subject and public observable of user profile data
  private userProfileSubject$ = new BehaviorSubject<any>(null);
  userProfile$ = this.userProfileSubject$.asObservable();

  // Create a local property for login status
  loggedIn: boolean = null;

  constructor(private router: Router, private i18nService: I18nService) {
  }


  // When calling, options can be passed if desired
  // https://auth0.github.io/auth0-spa-js/classes/auth0client.html#getuser
  getUser$(options?: GetUserOptions): Observable<any> {
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.getUser(options))),
      tap(user => {
        this.setUserProfile(user);
        this.userProfileSubject$.next(user);
      })
    );
  }

  getTokenSilently$(options?: any): Observable<GetTokenSilentlyVerboseResponse> {
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.getTokenSilently(options)))
    );
  }

  localAuthSetup() {
    // This should only be called on app initialization
    // Set up local authentication streams
    const checkAuth$ = this.isAuthenticated$.pipe(
      concatMap((loggedIn: boolean) => {
        if (loggedIn) {
          // If authenticated, get user and set in app
          // NOTE: you could pass options here if needed
          return this.getUser$();
        }
        // If not authenticated, return stream that emits 'false'
        return of(loggedIn);
      })
    );
    checkAuth$.subscribe((response: { [key: string]: any } | boolean) => {
      // If authenticated, response will be user object
      // If not authenticated, response will be 'false'
      this.loggedIn = !!response;
    });
  }

  login(redirectPath: string = '/') {
    // A desired redirect path can be passed to login method
    // (e.g., from a route guard)
    // Ensure Auth0 client instance exists
    this.auth0Client$.subscribe((client: Auth0Client) => {
      // Call method to log in
      client.loginWithRedirect({
        redirect_uri: environment.stingrayAuthentication.redirectUri,
        appState: { target: redirectPath },
        ui_locales: this.i18nService.language.substring(0, 2),
      });
    });
  }

  handleAuthCallback() {
    // Only the callback component should call this method
    // Call when app reloads after user logs in with Auth0
    let targetRoute: string; // Path to redirect to after login processsed
    const authComplete$ = this.handleRedirectCallback$.pipe(
      // Have client, now call method to handle auth callback redirect
      tap(cbRes => {
        // Get and set target redirect route from callback results
        targetRoute = cbRes.appState && cbRes.appState.target ? cbRes.appState.target : '/';
      }),
      concatMap(() =>
        // Redirect callback complete; get user and login status
        combineLatest([
          this.getUser$(),
          this.isAuthenticated$
        ])
      )
    );
    // Subscribe to authentication completion observable
    // Response will be an array of user and login status
    authComplete$.subscribe(([user, loggedIn]) => {
      // Redirect to target route after callback processing
      this.router.navigateByUrl(targetRoute);
    });
  }

  logout() {
    // Ensure Auth0 client instance exists
    this.auth0Client$.subscribe((client: Auth0Client) => {
      // Call method to log out
      client.logout({
        client_id: environment.stingrayAuthentication.clientID,
        returnTo: environment.stingrayAuthentication.returnUri
      });
    });
  }

  isVirEnabledAccount(): boolean {
    if(!this.organization) {
      return false;
    }

    return this.organization.hasCadastralSettings;
  }



  isCombinedProposalsEnabledAccount(): boolean {
    if(!this.organization) {
      return false;
    }

    return this.organization.subscription === 'Professional' && ['peter@unite.nl', 'marc@unite.nl', 'rob@assets.nl', 'arthur@assets.nl', 'erik.devoogd@gmail.com'].indexOf(this.user.email) > 0;
  }


  /**
   * Set the user info from Auth0
   * @param user User info from Auth0
   */
  private setUserProfile(user: any) {
    if (user[environment.stingrayAuthentication.namespace] != null) {
      this.organizationId = String(user[environment.stingrayAuthentication.namespace].organizationId).toLowerCase();
      this.userId = String(user[environment.stingrayAuthentication.namespace].userId).toLowerCase();
    } else {
      console.error('Could not find the Auth0 namespace!');
      this.logout();
    }
  }
}
