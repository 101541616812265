import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'unite-loading-button',
  templateUrl: './loading-button.component.html',
  styleUrls: ['./loading-button.component.scss']
})
export class LoadingButtonComponent implements OnInit {

  @Input() loading = false;
  @Input() text = '';
  @Input() icon: string = null;
  @Input() disabled = false;
  @Input() tooltip: string = null;
  @Input() color = 'primary';

  @Input() matIconButton = false;
  @Input() matIconPosition: 'prefix' | 'suffix' = 'prefix';
  @Input() matStrokedButton = false;

  @Output() buttonClick = new EventEmitter();

  @Input() small = false;

  constructor() { }

  ngOnInit() {
  }

}
