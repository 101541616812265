/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Customer } from '../models/customer';
import { Document } from '../models/document';
import { JsonPatchDocument } from '../models/json-patch-document';
import { LoanPart } from '../models/loan-part';
import { LoanParts } from '../models/loan-parts';
import { Mortgage } from '../models/mortgage';
import { MortgageBuilding } from '../models/mortgage-building';
import { MortgageProgress } from '../models/mortgage-progress';
import { Mortgages } from '../models/mortgages';
import { NewMortgage } from '../models/new-mortgage';
import { PenaltyInterest } from '../models/penalty-interest';

@Injectable({
  providedIn: 'root',
})
export class MortgagesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getMortgages
   */
  static readonly GetMortgagesPath = '/api/v1/Mortgages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMortgages$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgages$Plain$Response(params?: {
    count?: number;
    page?: number;
    statuses?: Array<'Concept' | 'Active' | 'Repayed'>;
    sort?: string;
    order?: 'Asc' | 'Desc';
    onlyExpiring?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Mortgages>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.GetMortgagesPath, 'get');
    if (params) {
      rb.query('count', params.count, {});
      rb.query('page', params.page, {});
      rb.query('statuses', params.statuses, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
      rb.query('onlyExpiring', params.onlyExpiring, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Mortgages>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMortgages$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgages$Plain(params?: {
    count?: number;
    page?: number;
    statuses?: Array<'Concept' | 'Active' | 'Repayed'>;
    sort?: string;
    order?: 'Asc' | 'Desc';
    onlyExpiring?: boolean;
  },
  context?: HttpContext

): Observable<Mortgages> {

    return this.getMortgages$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Mortgages>) => r.body as Mortgages)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMortgages()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgages$Response(params?: {
    count?: number;
    page?: number;
    statuses?: Array<'Concept' | 'Active' | 'Repayed'>;
    sort?: string;
    order?: 'Asc' | 'Desc';
    onlyExpiring?: boolean;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Mortgages>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.GetMortgagesPath, 'get');
    if (params) {
      rb.query('count', params.count, {});
      rb.query('page', params.page, {});
      rb.query('statuses', params.statuses, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
      rb.query('onlyExpiring', params.onlyExpiring, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Mortgages>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMortgages$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgages(params?: {
    count?: number;
    page?: number;
    statuses?: Array<'Concept' | 'Active' | 'Repayed'>;
    sort?: string;
    order?: 'Asc' | 'Desc';
    onlyExpiring?: boolean;
  },
  context?: HttpContext

): Observable<Mortgages> {

    return this.getMortgages$Response(params,context).pipe(
      map((r: StrictHttpResponse<Mortgages>) => r.body as Mortgages)
    );
  }

  /**
   * Path part for operation addMortgage
   */
  static readonly AddMortgagePath = '/api/v1/Mortgages';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addMortgage$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addMortgage$Plain$Response(params?: {
    body?: NewMortgage
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Mortgage>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.AddMortgagePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Mortgage>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addMortgage$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addMortgage$Plain(params?: {
    body?: NewMortgage
  },
  context?: HttpContext

): Observable<Mortgage> {

    return this.addMortgage$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Mortgage>) => r.body as Mortgage)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addMortgage()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addMortgage$Response(params?: {
    body?: NewMortgage
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Mortgage>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.AddMortgagePath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Mortgage>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addMortgage$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addMortgage(params?: {
    body?: NewMortgage
  },
  context?: HttpContext

): Observable<Mortgage> {

    return this.addMortgage$Response(params,context).pipe(
      map((r: StrictHttpResponse<Mortgage>) => r.body as Mortgage)
    );
  }

  /**
   * Path part for operation getMortgageById
   */
  static readonly GetMortgageByIdPath = '/api/v1/Mortgages/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMortgageById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageById$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Mortgage>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.GetMortgageByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Mortgage>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMortgageById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageById$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Mortgage> {

    return this.getMortgageById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Mortgage>) => r.body as Mortgage)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMortgageById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageById$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Mortgage>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.GetMortgageByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Mortgage>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMortgageById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageById(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Mortgage> {

    return this.getMortgageById$Response(params,context).pipe(
      map((r: StrictHttpResponse<Mortgage>) => r.body as Mortgage)
    );
  }

  /**
   * Path part for operation updateMortgage
   */
  static readonly UpdateMortgagePath = '/api/v1/Mortgages/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateMortgage$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateMortgage$Plain$Response(params: {
    id: string;
    body?: Mortgage
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Mortgage>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.UpdateMortgagePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Mortgage>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateMortgage$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateMortgage$Plain(params: {
    id: string;
    body?: Mortgage
  },
  context?: HttpContext

): Observable<Mortgage> {

    return this.updateMortgage$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Mortgage>) => r.body as Mortgage)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateMortgage()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateMortgage$Response(params: {
    id: string;
    body?: Mortgage
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Mortgage>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.UpdateMortgagePath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Mortgage>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateMortgage$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateMortgage(params: {
    id: string;
    body?: Mortgage
  },
  context?: HttpContext

): Observable<Mortgage> {

    return this.updateMortgage$Response(params,context).pipe(
      map((r: StrictHttpResponse<Mortgage>) => r.body as Mortgage)
    );
  }

  /**
   * Path part for operation deleteMortgageById
   */
  static readonly DeleteMortgageByIdPath = '/api/v1/Mortgages/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteMortgageById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteMortgageById$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.DeleteMortgageByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteMortgageById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteMortgageById(params: {
    id: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.deleteMortgageById$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation patchMortgage
   */
  static readonly PatchMortgagePath = '/api/v1/Mortgages/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchMortgage$Plain()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchMortgage$Plain$Response(params: {
    id: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Mortgage>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.PatchMortgagePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json-patch+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Mortgage>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchMortgage$Plain$Response()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchMortgage$Plain(params: {
    id: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<Mortgage> {

    return this.patchMortgage$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Mortgage>) => r.body as Mortgage)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchMortgage()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchMortgage$Response(params: {
    id: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Mortgage>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.PatchMortgagePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json-patch+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Mortgage>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchMortgage$Response()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchMortgage(params: {
    id: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<Mortgage> {

    return this.patchMortgage$Response(params,context).pipe(
      map((r: StrictHttpResponse<Mortgage>) => r.body as Mortgage)
    );
  }

  /**
   * Path part for operation getMortgageBorrowersById
   */
  static readonly GetMortgageBorrowersByIdPath = '/api/v1/Mortgages/{id}/Borrowers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMortgageBorrowersById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageBorrowersById$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Customer>>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.GetMortgageBorrowersByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Customer>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMortgageBorrowersById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageBorrowersById$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<Customer>> {

    return this.getMortgageBorrowersById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Customer>>) => r.body as Array<Customer>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMortgageBorrowersById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageBorrowersById$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Customer>>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.GetMortgageBorrowersByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Customer>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMortgageBorrowersById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageBorrowersById(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<Customer>> {

    return this.getMortgageBorrowersById$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Customer>>) => r.body as Array<Customer>)
    );
  }

  /**
   * Path part for operation getMortgageBuildings
   */
  static readonly GetMortgageBuildingsPath = '/api/v1/Mortgages/{id}/Buildings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMortgageBuildings$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageBuildings$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<MortgageBuilding>>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.GetMortgageBuildingsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MortgageBuilding>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMortgageBuildings$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageBuildings$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<MortgageBuilding>> {

    return this.getMortgageBuildings$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<MortgageBuilding>>) => r.body as Array<MortgageBuilding>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMortgageBuildings()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageBuildings$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<MortgageBuilding>>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.GetMortgageBuildingsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MortgageBuilding>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMortgageBuildings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageBuildings(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<MortgageBuilding>> {

    return this.getMortgageBuildings$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<MortgageBuilding>>) => r.body as Array<MortgageBuilding>)
    );
  }

  /**
   * Path part for operation addMortgageBuildings
   */
  static readonly AddMortgageBuildingsPath = '/api/v1/Mortgages/{id}/Buildings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addMortgageBuildings()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addMortgageBuildings$Response(params: {
    id: string;
    body?: Array<MortgageBuilding>
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.AddMortgageBuildingsPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addMortgageBuildings$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addMortgageBuildings(params: {
    id: string;
    body?: Array<MortgageBuilding>
  },
  context?: HttpContext

): Observable<void> {

    return this.addMortgageBuildings$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation updateMortgageBuildingById
   */
  static readonly UpdateMortgageBuildingByIdPath = '/api/v1/Mortgages/{id}/Buildings/{buildingId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateMortgageBuildingById$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateMortgageBuildingById$Plain$Response(params: {
    id: string;
    buildingId: string;
    body?: MortgageBuilding
  },
  context?: HttpContext

): Observable<StrictHttpResponse<MortgageBuilding>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.UpdateMortgageBuildingByIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('buildingId', params.buildingId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MortgageBuilding>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateMortgageBuildingById$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateMortgageBuildingById$Plain(params: {
    id: string;
    buildingId: string;
    body?: MortgageBuilding
  },
  context?: HttpContext

): Observable<MortgageBuilding> {

    return this.updateMortgageBuildingById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<MortgageBuilding>) => r.body as MortgageBuilding)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateMortgageBuildingById()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateMortgageBuildingById$Response(params: {
    id: string;
    buildingId: string;
    body?: MortgageBuilding
  },
  context?: HttpContext

): Observable<StrictHttpResponse<MortgageBuilding>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.UpdateMortgageBuildingByIdPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('buildingId', params.buildingId, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MortgageBuilding>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateMortgageBuildingById$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  updateMortgageBuildingById(params: {
    id: string;
    buildingId: string;
    body?: MortgageBuilding
  },
  context?: HttpContext

): Observable<MortgageBuilding> {

    return this.updateMortgageBuildingById$Response(params,context).pipe(
      map((r: StrictHttpResponse<MortgageBuilding>) => r.body as MortgageBuilding)
    );
  }

  /**
   * Path part for operation deleteMortgageBuildingById
   */
  static readonly DeleteMortgageBuildingByIdPath = '/api/v1/Mortgages/{id}/Buildings/{buildingId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteMortgageBuildingById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteMortgageBuildingById$Response(params: {
    id: string;
    buildingId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.DeleteMortgageBuildingByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('buildingId', params.buildingId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteMortgageBuildingById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteMortgageBuildingById(params: {
    id: string;
    buildingId: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.deleteMortgageBuildingById$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getMortgageDocuments
   */
  static readonly GetMortgageDocumentsPath = '/api/v1/Mortgages/{id}/Documents';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMortgageDocuments$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageDocuments$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Document>>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.GetMortgageDocumentsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Document>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMortgageDocuments$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageDocuments$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<Document>> {

    return this.getMortgageDocuments$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Document>>) => r.body as Array<Document>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMortgageDocuments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageDocuments$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Document>>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.GetMortgageDocumentsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Document>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMortgageDocuments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageDocuments(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<Document>> {

    return this.getMortgageDocuments$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Document>>) => r.body as Array<Document>)
    );
  }

  /**
   * Path part for operation putMortgageDocument
   */
  static readonly PutMortgageDocumentPath = '/api/v1/Mortgages/{id}/Documents/{documentId}';

  /**
   * Associates a document with this mortgage.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putMortgageDocument$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  putMortgageDocument$Plain$Response(params: {
    id: string;
    documentId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Document>>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.PutMortgageDocumentPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('documentId', params.documentId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Document>>;
      })
    );
  }

  /**
   * Associates a document with this mortgage.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `putMortgageDocument$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  putMortgageDocument$Plain(params: {
    id: string;
    documentId: string;
  },
  context?: HttpContext

): Observable<Array<Document>> {

    return this.putMortgageDocument$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Document>>) => r.body as Array<Document>)
    );
  }

  /**
   * Associates a document with this mortgage.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `putMortgageDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  putMortgageDocument$Response(params: {
    id: string;
    documentId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Document>>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.PutMortgageDocumentPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('documentId', params.documentId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Document>>;
      })
    );
  }

  /**
   * Associates a document with this mortgage.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `putMortgageDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  putMortgageDocument(params: {
    id: string;
    documentId: string;
  },
  context?: HttpContext

): Observable<Array<Document>> {

    return this.putMortgageDocument$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Document>>) => r.body as Array<Document>)
    );
  }

  /**
   * Path part for operation deleteMortgageDocument
   */
  static readonly DeleteMortgageDocumentPath = '/api/v1/Mortgages/{id}/Documents/{documentId}';

  /**
   * Removes the assocation of a document with this mortgage.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteMortgageDocument$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteMortgageDocument$Plain$Response(params: {
    id: string;
    documentId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Document>>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.DeleteMortgageDocumentPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('documentId', params.documentId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Document>>;
      })
    );
  }

  /**
   * Removes the assocation of a document with this mortgage.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteMortgageDocument$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteMortgageDocument$Plain(params: {
    id: string;
    documentId: string;
  },
  context?: HttpContext

): Observable<Array<Document>> {

    return this.deleteMortgageDocument$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Document>>) => r.body as Array<Document>)
    );
  }

  /**
   * Removes the assocation of a document with this mortgage.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteMortgageDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteMortgageDocument$Response(params: {
    id: string;
    documentId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Document>>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.DeleteMortgageDocumentPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('documentId', params.documentId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Document>>;
      })
    );
  }

  /**
   * Removes the assocation of a document with this mortgage.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteMortgageDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteMortgageDocument(params: {
    id: string;
    documentId: string;
  },
  context?: HttpContext

): Observable<Array<Document>> {

    return this.deleteMortgageDocument$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Document>>) => r.body as Array<Document>)
    );
  }

  /**
   * Path part for operation getGuarantorsById
   */
  static readonly GetGuarantorsByIdPath = '/api/v1/Mortgages/{id}/Guarantors';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGuarantorsById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGuarantorsById$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Customer>>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.GetGuarantorsByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Customer>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getGuarantorsById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGuarantorsById$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<Customer>> {

    return this.getGuarantorsById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Customer>>) => r.body as Array<Customer>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getGuarantorsById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGuarantorsById$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Customer>>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.GetGuarantorsByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Customer>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getGuarantorsById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getGuarantorsById(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<Customer>> {

    return this.getGuarantorsById$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Customer>>) => r.body as Array<Customer>)
    );
  }

  /**
   * Path part for operation getMortgageLoanParts
   */
  static readonly GetMortgageLoanPartsPath = '/api/v1/Mortgages/{id}/LoanParts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMortgageLoanParts$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageLoanParts$Plain$Response(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<LoanParts>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.GetMortgageLoanPartsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoanParts>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMortgageLoanParts$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageLoanParts$Plain(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<LoanParts> {

    return this.getMortgageLoanParts$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<LoanParts>) => r.body as LoanParts)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMortgageLoanParts()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageLoanParts$Response(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<LoanParts>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.GetMortgageLoanPartsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoanParts>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMortgageLoanParts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageLoanParts(params: {
    id: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<LoanParts> {

    return this.getMortgageLoanParts$Response(params,context).pipe(
      map((r: StrictHttpResponse<LoanParts>) => r.body as LoanParts)
    );
  }

  /**
   * Path part for operation addMortgageLoanPart
   */
  static readonly AddMortgageLoanPartPath = '/api/v1/Mortgages/{id}/LoanParts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addMortgageLoanPart$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addMortgageLoanPart$Plain$Response(params: {
    id: string;
    body?: LoanPart
  },
  context?: HttpContext

): Observable<StrictHttpResponse<LoanPart>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.AddMortgageLoanPartPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoanPart>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addMortgageLoanPart$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addMortgageLoanPart$Plain(params: {
    id: string;
    body?: LoanPart
  },
  context?: HttpContext

): Observable<LoanPart> {

    return this.addMortgageLoanPart$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<LoanPart>) => r.body as LoanPart)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addMortgageLoanPart()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addMortgageLoanPart$Response(params: {
    id: string;
    body?: LoanPart
  },
  context?: HttpContext

): Observable<StrictHttpResponse<LoanPart>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.AddMortgageLoanPartPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoanPart>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addMortgageLoanPart$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  addMortgageLoanPart(params: {
    id: string;
    body?: LoanPart
  },
  context?: HttpContext

): Observable<LoanPart> {

    return this.addMortgageLoanPart$Response(params,context).pipe(
      map((r: StrictHttpResponse<LoanPart>) => r.body as LoanPart)
    );
  }

  /**
   * Path part for operation getMortgagePenaltyInterest
   */
  static readonly GetMortgagePenaltyInterestPath = '/api/v1/Mortgages/{id}/PenaltyInterest';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMortgagePenaltyInterest$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgagePenaltyInterest$Plain$Response(params: {
    id: string;
    newInterestRate?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PenaltyInterest>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.GetMortgagePenaltyInterestPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('newInterestRate', params.newInterestRate, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PenaltyInterest>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMortgagePenaltyInterest$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgagePenaltyInterest$Plain(params: {
    id: string;
    newInterestRate?: number;
  },
  context?: HttpContext

): Observable<PenaltyInterest> {

    return this.getMortgagePenaltyInterest$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<PenaltyInterest>) => r.body as PenaltyInterest)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMortgagePenaltyInterest()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgagePenaltyInterest$Response(params: {
    id: string;
    newInterestRate?: number;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PenaltyInterest>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.GetMortgagePenaltyInterestPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('newInterestRate', params.newInterestRate, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PenaltyInterest>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMortgagePenaltyInterest$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgagePenaltyInterest(params: {
    id: string;
    newInterestRate?: number;
  },
  context?: HttpContext

): Observable<PenaltyInterest> {

    return this.getMortgagePenaltyInterest$Response(params,context).pipe(
      map((r: StrictHttpResponse<PenaltyInterest>) => r.body as PenaltyInterest)
    );
  }

  /**
   * Path part for operation deleteLoanPartById
   */
  static readonly DeleteLoanPartByIdPath = '/api/v1/Mortgages/{id}/LoanParts/{loanPartId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteLoanPartById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteLoanPartById$Response(params: {
    id: string;
    loanPartId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.DeleteLoanPartByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('loanPartId', params.loanPartId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteLoanPartById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteLoanPartById(params: {
    id: string;
    loanPartId: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.deleteLoanPartById$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation patchLoanPart
   */
  static readonly PatchLoanPartPath = '/api/v1/Mortgages/{id}/LoanParts/{loanPartId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchLoanPart$Plain()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchLoanPart$Plain$Response(params: {
    id: string;
    loanPartId: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<LoanPart>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.PatchLoanPartPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('loanPartId', params.loanPartId, {});
      rb.body(params.body, 'application/json-patch+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoanPart>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchLoanPart$Plain$Response()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchLoanPart$Plain(params: {
    id: string;
    loanPartId: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<LoanPart> {

    return this.patchLoanPart$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<LoanPart>) => r.body as LoanPart)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchLoanPart()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchLoanPart$Response(params: {
    id: string;
    loanPartId: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<LoanPart>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.PatchLoanPartPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('loanPartId', params.loanPartId, {});
      rb.body(params.body, 'application/json-patch+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LoanPart>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchLoanPart$Response()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchLoanPart(params: {
    id: string;
    loanPartId: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<LoanPart> {

    return this.patchLoanPart$Response(params,context).pipe(
      map((r: StrictHttpResponse<LoanPart>) => r.body as LoanPart)
    );
  }

  /**
   * Path part for operation getMortgageProgressById
   */
  static readonly GetMortgageProgressByIdPath = '/api/v1/Mortgages/{id}/Progress';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMortgageProgressById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageProgressById$Plain$Response(params: {
    id: string;
    starDate?: string;
    endDate?: string;
    scale?: 'Month' | 'Year';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<MortgageProgress>>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.GetMortgageProgressByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('starDate', params.starDate, {});
      rb.query('endDate', params.endDate, {});
      rb.query('scale', params.scale, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MortgageProgress>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMortgageProgressById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageProgressById$Plain(params: {
    id: string;
    starDate?: string;
    endDate?: string;
    scale?: 'Month' | 'Year';
  },
  context?: HttpContext

): Observable<Array<MortgageProgress>> {

    return this.getMortgageProgressById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<MortgageProgress>>) => r.body as Array<MortgageProgress>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMortgageProgressById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageProgressById$Response(params: {
    id: string;
    starDate?: string;
    endDate?: string;
    scale?: 'Month' | 'Year';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<MortgageProgress>>> {

    const rb = new RequestBuilder(this.rootUrl, MortgagesService.GetMortgageProgressByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('starDate', params.starDate, {});
      rb.query('endDate', params.endDate, {});
      rb.query('scale', params.scale, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MortgageProgress>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMortgageProgressById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMortgageProgressById(params: {
    id: string;
    starDate?: string;
    endDate?: string;
    scale?: 'Month' | 'Year';
  },
  context?: HttpContext

): Observable<Array<MortgageProgress>> {

    return this.getMortgageProgressById$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<MortgageProgress>>) => r.body as Array<MortgageProgress>)
    );
  }

}
