import { Component, OnInit, Input } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { map, tap, debounceTime, distinctUntilChanged, switchMap, catchError } from 'rxjs/operators';
import { Customer } from '@app/core/stingray-api/models';
import { requireMatch } from '@app/validators/autocomplete-validator';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { of } from 'rxjs';
import { CustomersService } from '@app/core/stingray-api/services';

@UntilDestroy()
@Component({
  selector: 'unite-tax-partner-autocomplete',
  templateUrl: './tax-partner-autocomplete.component.html',
  styleUrls: ['./tax-partner-autocomplete.component.scss']
})
export class TaxPartnerAutocompleteComponent implements OnInit {

  @Input() control: FormControl;
  @Input() required = false;
  @Input() focus = false;
  @Input() label: string;

  taxPartners: Customer[] = [];
  taxPartnersFiltered: Customer[];

  isLoadingTaxPartners = false;

  constructor(
    private customersService: CustomersService) { }

  ngOnInit() {
    if (this.required === false) {
      this.control.setValidators([requireMatch]);
    } else {
      this.control.setValidators([Validators.required, requireMatch]);
    }

    this.control.valueChanges.pipe(
      tap(() => this.isLoadingTaxPartners = true),
      debounceTime(350),
      distinctUntilChanged(),
      untilDestroyed(this),
      switchMap((value: any) => {
        if (value && typeof value === 'string' && value.trim() !== '') {
          return this.customersService.getCustomers({
            name: value,
            types: ['Person']
          }).pipe(map(data => data.items), catchError(() => of(null)));
        } else {
          this.isLoadingTaxPartners = false;
          return of(null);
        }
      })).subscribe((taxPartnersFiltered: Customer[]) => {
        this.taxPartnersFiltered = taxPartnersFiltered;
        this.isLoadingTaxPartners = false;
      });
  }

  displayWithCustomer(taxPartner: Customer): string {
    if (taxPartner != null) {
      return taxPartner.name;
    } else {
      return '';
    }
  }

  isCustomer(value: any | Customer): value is Customer {
    return value != null && (<Customer>value).id !== undefined;
  }
}
