import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Country } from '@app/core/stingray-api/models';
import { finalize, map } from 'rxjs/operators';
import { CountryAutoCompleteService } from './country-autocomplete.service';
import { requireMatch } from '@app/validators/autocomplete-validator';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'unite-country-autocomplete',
  templateUrl: './country-autocomplete.component.html',
  styleUrls: ['./country-autocomplete.component.scss']
})
export class CountryAutocompleteComponent implements OnInit {

  @Input() appearance: string = 'outline';
  @Input() class: string = null;
  @Input() control: FormControl;
  @Input() required = false;
  @Input() focus = false;
  @Input() label: string;

  countries: Country[] = [];
  countriesFiltered: Country[];

  isLoadingCountries = false;

  constructor(
    private countryAutoCompleteService: CountryAutoCompleteService) { }

  ngOnInit() {
    if (this.required === false) {
      this.control.setValidators([requireMatch]);
    } else {
      this.control.setValidators([Validators.required, requireMatch]);
    }

    this.isLoadingCountries = true;
    this.countryAutoCompleteService.getCountries().pipe(
      finalize(() => {
        this.isLoadingCountries = false;
      })
    ).subscribe((customers: Country[]) => {
      this.countries = customers;
      this.countriesFiltered = customers;
    });

    this.control.valueChanges.pipe(
      map((value: string | Country) => {
        if (this.isCountry(value)) {
          return this.filterCountry(value.name);
        } else {
          return this.filterCountry(value || '');
        }
      }), untilDestroyed(this)
    ).subscribe((countries: Country[]) => {
      this.countriesFiltered = countries;
    });
  }

  displayWithCountry(country: Country): string {
    if (country != null) {
      return country.name;
    } else {
      return '';
    }
  }

  filterCountry(value: string): Country[] {
    const filterValue = value.toLowerCase();
    return this.countries.filter((country: Country) => {
      return this.displayWithCountry(country).toLowerCase().includes(filterValue) ||
        country.code.toLowerCase().includes(filterValue);
    });
  }

  isCountry(value: any | Country): value is Country {
    return value != null && (<Country>value).code !== undefined;
  }
}
