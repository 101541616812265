import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { OrganizationsService } from '@app/core/stingray-api/services';
import { User } from '@app/core/stingray-api/models';
import { StingrayAuthenticationService } from '@app/core/authentication/stingray-authentication.service';


@Injectable()
export class UserAutoCompleteService {

  constructor(
    private stingrayAuthenticationService: StingrayAuthenticationService,
    private organizationsService: OrganizationsService) { }

  getUsers(reload: boolean = false): Observable<User[]> {
    const cache = sessionStorage.getItem('userAutoComplete');
    if (reload === false && cache) {
      return of(JSON.parse(cache));
    } else {
      return this.organizationsService.getUsers({ id: this.stingrayAuthenticationService.organizationId }).pipe(
        map((users: User[]) => {
          sessionStorage.setItem('userAutoComplete', JSON.stringify(users));
          return users;
        }));
    }
  }

}
