import { Injectable } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Subject, Observable } from 'rxjs';

@Injectable()
export class ShellSidenavService {

  // Generald sidenav
  private generalSidenav: MatSidenav;

  // Customer sidenav
  private leftSidenav: MatSidenav = null;
  private hasLeftSidenav = new Subject<boolean>();

  getGeneralSidenav(): MatSidenav {
    return this.generalSidenav;
  }

  setGeneralSidenav(generalSidenav: MatSidenav): void {
    this.generalSidenav = generalSidenav;
  }

  toggleGeneralSidenav(): void {
    if (this.generalSidenav) {

      // Close the customer sidenav if it is open
      if (this.leftSidenav && this.leftSidenav.opened) {
        this.leftSidenav.close();
      }

      this.generalSidenav.toggle();
    }
  }

  getLeftSidenav(): MatSidenav {
    return this.leftSidenav;
  }

  getHasLeftSidenav(): Observable<boolean> {
    return this.hasLeftSidenav.asObservable();
  }

  setLeftSidenav(leftSidenav: MatSidenav): void {
    this.leftSidenav = leftSidenav;
    this.hasLeftSidenav.next(leftSidenav !== null);
  }

  closeGeneralSidenav(): void {
    if (this.generalSidenav) {
      this.generalSidenav.close();
    }
  }

  openLeftSidenav(): void {
    if (this.leftSidenav) {

      // Close the general sidenav if it is open
      if (this.generalSidenav && this.generalSidenav.opened) {
        this.generalSidenav.close();
      }

      this.leftSidenav.open();

    }
  }

  closeLeftSidenav(): void {
    if (this.leftSidenav) {
      this.leftSidenav.close();
    }
  }

  toggleLeftSidenav(): void {
    if (this.leftSidenav) {

      // Close the general sidenav if it is open
      if (this.generalSidenav && this.generalSidenav.opened) {
        this.generalSidenav.close();
      }

      this.leftSidenav.toggle();
    }
  }

  closeAllSidenavs(): void {
    if (this.generalSidenav) {
      this.generalSidenav.close();
    }

    if (this.leftSidenav) {
      this.leftSidenav.close();
    }
  }
}
