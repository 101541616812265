import { Component, EventEmitter, OnInit, Output, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { GuidId, Person } from '@app/core/stingray-api/models';
import { PersonsService } from '@app/core/stingray-api/services';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators';
import { GoogleAnalyticsService } from '@app/core/analytics/google-analytics.service';
import { StingrayAuthenticationService } from '@app/core/authentication/stingray-authentication.service';
import { createAddressFormGroup } from '@app/shared/address-input-v2/address-input-v2-tools';

@Component({
  selector: 'unite-create-person',
  templateUrl: './create-person.component.html',
  styleUrls: ['./create-person.component.scss']
})
export class CreatePersonComponent implements OnInit {

  @Output() person = new EventEmitter<Person>();
  personGroup: UntypedFormGroup;

  isLoadingCreate = false;
  supportsConsumerRates = false;

  constructor(
    private el: ElementRef,
    private googleAnalyticsService: GoogleAnalyticsService,
    private stingrayAuthenticationService: StingrayAuthenticationService,
    private formBuilder: UntypedFormBuilder,
    private personsService: PersonsService) {
    this.personGroup = this.formBuilder.group({
      gender: ['Male', [Validators.required]],
      initials: [, [Validators.required]],
      firstName: [, [Validators.required]],
      givenNames: [],
      middleName: [],
      surname: [, [Validators.required]],
      birthdate: [],
      phone: [],
      mobile: [],
      email: [],
      iban: [],
      businessunit: [],
      address: createAddressFormGroup(this.formBuilder),
      nationality: [],
      maritalStatus: [],
      taxPartner: [],
      adviser: [],
      isConsumer: [],
      existing: [false],
      incomeSources: [],
    });
  }

  ngOnInit(): void {
    this.supportsConsumerRates = this.stingrayAuthenticationService.organization.supportsConsumerRates;

    if (!this.stingrayAuthenticationService.organization.supportsConsumerRates) {
      this.personGroup.get('isConsumer').setValue(false);
      this.personGroup.get('isConsumer').disable();
    }
  }

  create(): void {
    this.personGroup.markAllAsTouched();

    if (this.personGroup.valid) {
      this.googleAnalyticsService.trackEvent('customer', 'add', 'person');
      this.isLoadingCreate = true;
      const person = <Person>this.personGroup.value;
      if (person.birthdate) {
        person.birthdate = (<moment.Moment>this.personGroup.value.birthdate).locale('en').format('L');
      }

      this.personsService.addPerson({ body: person }).pipe(finalize(() => {
        this.isLoadingCreate = false;
      })).subscribe((response: GuidId) => {
        person.id = response.id;
        person.name = `${this.personGroup.get('initials').value} ${this.personGroup.get('surname').value}`;
        this.person.emit(person);
      });
    } else {
      this.scrollToFirstInvalidControl();
    }
  }

  private scrollToFirstInvalidControl(): void {
    const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector('form .ng-invalid');
    firstInvalidControl.scrollIntoView();
  }
}
