/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { PublicAccess } from '../models/public-access';

@Injectable({
  providedIn: 'root',
})
export class PublicAccessService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getPublicAccessForOrganizationLogoUpload
   */
  static readonly GetPublicAccessForOrganizationLogoUploadPath = '/api/v1/PublicAccess/OrganizationLogos/Upload';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPublicAccessForOrganizationLogoUpload$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicAccessForOrganizationLogoUpload$Plain$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PublicAccess>> {

    const rb = new RequestBuilder(this.rootUrl, PublicAccessService.GetPublicAccessForOrganizationLogoUploadPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PublicAccess>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPublicAccessForOrganizationLogoUpload$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicAccessForOrganizationLogoUpload$Plain(params?: {
  },
  context?: HttpContext

): Observable<PublicAccess> {

    return this.getPublicAccessForOrganizationLogoUpload$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<PublicAccess>) => r.body as PublicAccess)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPublicAccessForOrganizationLogoUpload()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicAccessForOrganizationLogoUpload$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PublicAccess>> {

    const rb = new RequestBuilder(this.rootUrl, PublicAccessService.GetPublicAccessForOrganizationLogoUploadPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PublicAccess>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPublicAccessForOrganizationLogoUpload$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicAccessForOrganizationLogoUpload(params?: {
  },
  context?: HttpContext

): Observable<PublicAccess> {

    return this.getPublicAccessForOrganizationLogoUpload$Response(params,context).pipe(
      map((r: StrictHttpResponse<PublicAccess>) => r.body as PublicAccess)
    );
  }

  /**
   * Path part for operation getPublicAccessForDocumentUpload
   */
  static readonly GetPublicAccessForDocumentUploadPath = '/api/v1/PublicAccess/Documents/Upload';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPublicAccessForDocumentUpload$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicAccessForDocumentUpload$Plain$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PublicAccess>> {

    const rb = new RequestBuilder(this.rootUrl, PublicAccessService.GetPublicAccessForDocumentUploadPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PublicAccess>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPublicAccessForDocumentUpload$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicAccessForDocumentUpload$Plain(params?: {
  },
  context?: HttpContext

): Observable<PublicAccess> {

    return this.getPublicAccessForDocumentUpload$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<PublicAccess>) => r.body as PublicAccess)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPublicAccessForDocumentUpload()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicAccessForDocumentUpload$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PublicAccess>> {

    const rb = new RequestBuilder(this.rootUrl, PublicAccessService.GetPublicAccessForDocumentUploadPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PublicAccess>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPublicAccessForDocumentUpload$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicAccessForDocumentUpload(params?: {
  },
  context?: HttpContext

): Observable<PublicAccess> {

    return this.getPublicAccessForDocumentUpload$Response(params,context).pipe(
      map((r: StrictHttpResponse<PublicAccess>) => r.body as PublicAccess)
    );
  }

  /**
   * Path part for operation getPublicAccessForDocumentDownload
   */
  static readonly GetPublicAccessForDocumentDownloadPath = '/api/v1/PublicAccess/Documents/Download';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPublicAccessForDocumentDownload$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicAccessForDocumentDownload$Plain$Response(params?: {
    storageFileName?: string;
    originalFileName?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PublicAccess>> {

    const rb = new RequestBuilder(this.rootUrl, PublicAccessService.GetPublicAccessForDocumentDownloadPath, 'post');
    if (params) {
      rb.query('storageFileName', params.storageFileName, {});
      rb.query('originalFileName', params.originalFileName, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PublicAccess>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPublicAccessForDocumentDownload$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicAccessForDocumentDownload$Plain(params?: {
    storageFileName?: string;
    originalFileName?: string;
  },
  context?: HttpContext

): Observable<PublicAccess> {

    return this.getPublicAccessForDocumentDownload$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<PublicAccess>) => r.body as PublicAccess)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPublicAccessForDocumentDownload()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicAccessForDocumentDownload$Response(params?: {
    storageFileName?: string;
    originalFileName?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PublicAccess>> {

    const rb = new RequestBuilder(this.rootUrl, PublicAccessService.GetPublicAccessForDocumentDownloadPath, 'post');
    if (params) {
      rb.query('storageFileName', params.storageFileName, {});
      rb.query('originalFileName', params.originalFileName, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PublicAccess>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPublicAccessForDocumentDownload$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicAccessForDocumentDownload(params?: {
    storageFileName?: string;
    originalFileName?: string;
  },
  context?: HttpContext

): Observable<PublicAccess> {

    return this.getPublicAccessForDocumentDownload$Response(params,context).pipe(
      map((r: StrictHttpResponse<PublicAccess>) => r.body as PublicAccess)
    );
  }

  /**
   * Path part for operation getPublicAccessForDocumentOpen
   */
  static readonly GetPublicAccessForDocumentOpenPath = '/api/v1/PublicAccess/Documents/Open';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPublicAccessForDocumentOpen$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicAccessForDocumentOpen$Plain$Response(params?: {
    storageFileName?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PublicAccess>> {

    const rb = new RequestBuilder(this.rootUrl, PublicAccessService.GetPublicAccessForDocumentOpenPath, 'post');
    if (params) {
      rb.query('storageFileName', params.storageFileName, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PublicAccess>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPublicAccessForDocumentOpen$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicAccessForDocumentOpen$Plain(params?: {
    storageFileName?: string;
  },
  context?: HttpContext

): Observable<PublicAccess> {

    return this.getPublicAccessForDocumentOpen$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<PublicAccess>) => r.body as PublicAccess)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPublicAccessForDocumentOpen()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicAccessForDocumentOpen$Response(params?: {
    storageFileName?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PublicAccess>> {

    const rb = new RequestBuilder(this.rootUrl, PublicAccessService.GetPublicAccessForDocumentOpenPath, 'post');
    if (params) {
      rb.query('storageFileName', params.storageFileName, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PublicAccess>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPublicAccessForDocumentOpen$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicAccessForDocumentOpen(params?: {
    storageFileName?: string;
  },
  context?: HttpContext

): Observable<PublicAccess> {

    return this.getPublicAccessForDocumentOpen$Response(params,context).pipe(
      map((r: StrictHttpResponse<PublicAccess>) => r.body as PublicAccess)
    );
  }

  /**
   * Path part for operation getPublicAccessForDocumentZip
   */
  static readonly GetPublicAccessForDocumentZipPath = '/api/v1/PublicAccess/Documents/Zip';

  /**
   * Downloads the files as a zip. The files within the zip will have the original file names. Currently supports a maximum of 20 files.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPublicAccessForDocumentZip$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicAccessForDocumentZip$Plain$Response(params?: {

    /**
     * The file names under which the file was stored in the storage container
     */
    storageFileNames?: Array<string>;

    /**
     * The file name of the zip. Should end with .zip . If ommitted will use download.zip as default
     */
    downloadFileName?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PublicAccess>> {

    const rb = new RequestBuilder(this.rootUrl, PublicAccessService.GetPublicAccessForDocumentZipPath, 'get');
    if (params) {
      rb.query('storageFileNames', params.storageFileNames, {});
      rb.query('downloadFileName', params.downloadFileName, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PublicAccess>;
      })
    );
  }

  /**
   * Downloads the files as a zip. The files within the zip will have the original file names. Currently supports a maximum of 20 files.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPublicAccessForDocumentZip$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicAccessForDocumentZip$Plain(params?: {

    /**
     * The file names under which the file was stored in the storage container
     */
    storageFileNames?: Array<string>;

    /**
     * The file name of the zip. Should end with .zip . If ommitted will use download.zip as default
     */
    downloadFileName?: string;
  },
  context?: HttpContext

): Observable<PublicAccess> {

    return this.getPublicAccessForDocumentZip$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<PublicAccess>) => r.body as PublicAccess)
    );
  }

  /**
   * Downloads the files as a zip. The files within the zip will have the original file names. Currently supports a maximum of 20 files.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPublicAccessForDocumentZip()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicAccessForDocumentZip$Response(params?: {

    /**
     * The file names under which the file was stored in the storage container
     */
    storageFileNames?: Array<string>;

    /**
     * The file name of the zip. Should end with .zip . If ommitted will use download.zip as default
     */
    downloadFileName?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PublicAccess>> {

    const rb = new RequestBuilder(this.rootUrl, PublicAccessService.GetPublicAccessForDocumentZipPath, 'get');
    if (params) {
      rb.query('storageFileNames', params.storageFileNames, {});
      rb.query('downloadFileName', params.downloadFileName, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PublicAccess>;
      })
    );
  }

  /**
   * Downloads the files as a zip. The files within the zip will have the original file names. Currently supports a maximum of 20 files.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPublicAccessForDocumentZip$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicAccessForDocumentZip(params?: {

    /**
     * The file names under which the file was stored in the storage container
     */
    storageFileNames?: Array<string>;

    /**
     * The file name of the zip. Should end with .zip . If ommitted will use download.zip as default
     */
    downloadFileName?: string;
  },
  context?: HttpContext

): Observable<PublicAccess> {

    return this.getPublicAccessForDocumentZip$Response(params,context).pipe(
      map((r: StrictHttpResponse<PublicAccess>) => r.body as PublicAccess)
    );
  }

}
