<mat-form-field appearance="outline" class="small-field">
  <mat-label translate>Legal form</mat-label>
  <mat-select  [formControl]="legalFormControl">
    <mat-option [value]="null"><span translate>None</span></mat-option>
    <mat-option [value]="'SoleProprietorship'"><span translate>SoleProprietorship</span></mat-option>
    <mat-option [value]="'PrivateCompany'"><span translate>PrivateCompany</span></mat-option>
    <mat-option [value]="'LimitedCompany'"><span translate>LimitedCompany</span></mat-option>
    <mat-option [value]="'LimitedPartnership'"><span translate>LimitedPartnership</span></mat-option>
    <mat-option [value]="'PartnershipUnderFirm'"><span translate>PartnershipUnderFirm</span></mat-option>
    <mat-option [value]="'Partnership'"><span translate>Partnership</span></mat-option>
    <mat-option [value]="'Cooperation'"><span translate>Cooperation</span></mat-option>
    <mat-option [value]="'Association'"><span translate>Association</span></mat-option>
    <mat-option [value]="'Foundation'"><span translate>Foundation</span></mat-option>
  </mat-select>
</mat-form-field>
