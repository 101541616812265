import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { OctopusApiAuthenticationService } from '../authentication/octopus-api-authentication.service';

@Injectable()
export class OctopusApiTokenInterceptor implements HttpInterceptor {

  constructor(private octopusApiAuthenticationService: OctopusApiAuthenticationService) {

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // Only add the authentication token if it is an API call
    if (request.url.includes(environment.octopusApiEndpoint)) {
      request = request.clone({
        setHeaders: {
          'Authorization': `Bearer ${this.octopusApiAuthenticationService.getToken()}`
        }
      });
    }

    return next.handle(request);
  }
}
