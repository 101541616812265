import { Component, OnInit, Input } from '@angular/core';
import { UserAutoCompleteService } from './user-autocomplete.service';
import { FormControl, Validators } from '@angular/forms';
import { finalize, map } from 'rxjs/operators';
import { User } from '@app/core/stingray-api/models';
import { requireMatch } from '@app/validators/autocomplete-validator';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'unite-user-autocomplete',
  templateUrl: './user-autocomplete.component.html',
  styleUrls: ['./user-autocomplete.component.scss']
})
export class UserAutocompleteComponent implements OnInit {

  @Input() control: FormControl;
  @Input() required = false;
  @Input() focus = false;
  @Input() label: string;

  users: User[] = [];
  usersFiltered: User[];

  isLoadingUsers = false;

  constructor(
    private userAutoCompleteService: UserAutoCompleteService) { }

  ngOnInit() {
    if (this.required === false) {
      this.control.setValidators([requireMatch]);
    } else {
      this.control.setValidators([Validators.required, requireMatch]);
    }

    this.isLoadingUsers = true;
    this.userAutoCompleteService.getUsers(false).pipe(
      finalize(() => {
        this.isLoadingUsers = false;
      })
    ).subscribe((users: User[]) => {
      this.users = users;
      this.usersFiltered = users;
    });

    this.control.valueChanges.pipe(
      untilDestroyed(this),
      map((value: string | User) => {
        if (this.isUser(value)) {
          return this.filterUser(this.getUserName(value));
        } else {
          return this.filterUser(value || '');
        }
      })
    ).subscribe((users: User[]) => {
      this.usersFiltered = users;
    });
  }

  refreshUsers() {
    this.isLoadingUsers = true;
    this.userAutoCompleteService.getUsers(true).pipe(
      finalize(() => {
        this.isLoadingUsers = false;
      })
    ).subscribe((users: User[]) => {
      this.users = users;
      this.usersFiltered = users;
    });
  }

  displayWithUser(user: User): string {
    if (user != null) {
      if (user.middleName !== null && user.middleName.trim().length > 0) {
        return user.initials + ' ' + user.middleName + ' ' + user.surname;
      } else {
        return user.initials + ' ' + user.surname;
      }
    } else {
      return '';
    }
  }

  filterUser(value: string): User[] {
    const filterValue = value.toLowerCase();
    return this.users.filter((user: User) => {
      return this.displayWithUser(user).toLowerCase().includes(filterValue) ||
        user.email.toLowerCase().includes(filterValue) ||
        user.firstName.toLowerCase().includes(filterValue) ||
        user.surname.toLowerCase().includes(filterValue);
    });
  }

  isUser(value: any | User): value is User {
    return value != null && (<User>value).id !== undefined;
  }

  getUserName(user: User): string {
    if (user.middleName !== null && user.middleName.trim().length > 0) {
      return user.initials + ' ' + user.middleName + ' ' + user.surname;
    } else {
      return user.initials + ' ' + user.surname;
    }
  }
}
