/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { GenerateAnalysisReportRequest } from '../models/generate-analysis-report-request';
import { PublicAccess } from '../models/public-access';
import { Report } from '../models/report';
import { ReportEstimatedCosts } from '../models/report-estimated-costs';
import { ReportSettings } from '../models/report-settings';
import { Reports } from '../models/reports';

@Injectable({
  providedIn: 'root',
})
export class ReportsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getReportById
   */
  static readonly GetReportByIdPath = '/api/v1/Reports/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getReportById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReportById$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Report>> {

    const rb = new RequestBuilder(this.rootUrl, ReportsService.GetReportByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Report>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getReportById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReportById$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Report> {

    return this.getReportById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Report>) => r.body as Report)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getReportById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReportById$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Report>> {

    const rb = new RequestBuilder(this.rootUrl, ReportsService.GetReportByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Report>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getReportById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReportById(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Report> {

    return this.getReportById$Response(params,context).pipe(
      map((r: StrictHttpResponse<Report>) => r.body as Report)
    );
  }

  /**
   * Path part for operation deleteReportById
   */
  static readonly DeleteReportByIdPath = '/api/v1/Reports/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteReportById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteReportById$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ReportsService.DeleteReportByIdPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteReportById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteReportById(params: {
    id: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.deleteReportById$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation downloadBuildingList
   */
  static readonly DownloadBuildingListPath = '/api/v1/Reports/downloadBuildingList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadBuildingList$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadBuildingList$Plain$Response(params?: {
    reportType?: 'Box3Analysis' | 'MidRentAnalysis' | 'RefinancingAnalysis' | 'PurchaseAnalysis' | 'BusinessPlanAnalysis' | 'StructuralRiskAnalysis';
    customerId?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PublicAccess>> {

    const rb = new RequestBuilder(this.rootUrl, ReportsService.DownloadBuildingListPath, 'post');
    if (params) {
      rb.query('reportType', params.reportType, {});
      rb.query('customerId', params.customerId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PublicAccess>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `downloadBuildingList$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadBuildingList$Plain(params?: {
    reportType?: 'Box3Analysis' | 'MidRentAnalysis' | 'RefinancingAnalysis' | 'PurchaseAnalysis' | 'BusinessPlanAnalysis' | 'StructuralRiskAnalysis';
    customerId?: string;
  },
  context?: HttpContext

): Observable<PublicAccess> {

    return this.downloadBuildingList$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<PublicAccess>) => r.body as PublicAccess)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `downloadBuildingList()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadBuildingList$Response(params?: {
    reportType?: 'Box3Analysis' | 'MidRentAnalysis' | 'RefinancingAnalysis' | 'PurchaseAnalysis' | 'BusinessPlanAnalysis' | 'StructuralRiskAnalysis';
    customerId?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PublicAccess>> {

    const rb = new RequestBuilder(this.rootUrl, ReportsService.DownloadBuildingListPath, 'post');
    if (params) {
      rb.query('reportType', params.reportType, {});
      rb.query('customerId', params.customerId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PublicAccess>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `downloadBuildingList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  downloadBuildingList(params?: {
    reportType?: 'Box3Analysis' | 'MidRentAnalysis' | 'RefinancingAnalysis' | 'PurchaseAnalysis' | 'BusinessPlanAnalysis' | 'StructuralRiskAnalysis';
    customerId?: string;
  },
  context?: HttpContext

): Observable<PublicAccess> {

    return this.downloadBuildingList$Response(params,context).pipe(
      map((r: StrictHttpResponse<PublicAccess>) => r.body as PublicAccess)
    );
  }

  /**
   * Path part for operation generateAnalysisReport
   */
  static readonly GenerateAnalysisReportPath = '/api/v1/Reports/generateAnalysisReport';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `generateAnalysisReport$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  generateAnalysisReport$Plain$Response(params?: {
    body?: GenerateAnalysisReportRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PublicAccess>> {

    const rb = new RequestBuilder(this.rootUrl, ReportsService.GenerateAnalysisReportPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PublicAccess>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `generateAnalysisReport$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  generateAnalysisReport$Plain(params?: {
    body?: GenerateAnalysisReportRequest
  },
  context?: HttpContext

): Observable<PublicAccess> {

    return this.generateAnalysisReport$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<PublicAccess>) => r.body as PublicAccess)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `generateAnalysisReport()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  generateAnalysisReport$Response(params?: {
    body?: GenerateAnalysisReportRequest
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PublicAccess>> {

    const rb = new RequestBuilder(this.rootUrl, ReportsService.GenerateAnalysisReportPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PublicAccess>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `generateAnalysisReport$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  generateAnalysisReport(params?: {
    body?: GenerateAnalysisReportRequest
  },
  context?: HttpContext

): Observable<PublicAccess> {

    return this.generateAnalysisReport$Response(params,context).pipe(
      map((r: StrictHttpResponse<PublicAccess>) => r.body as PublicAccess)
    );
  }

  /**
   * Path part for operation getEstimatedReportCosts
   */
  static readonly GetEstimatedReportCostsPath = '/api/v1/Reports/costs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEstimatedReportCosts$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEstimatedReportCosts$Plain$Response(params?: {
    reportType?: 'Box3Analysis' | 'MidRentAnalysis' | 'RefinancingAnalysis' | 'PurchaseAnalysis' | 'BusinessPlanAnalysis' | 'StructuralRiskAnalysis';
    cadastralId?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ReportEstimatedCosts>> {

    const rb = new RequestBuilder(this.rootUrl, ReportsService.GetEstimatedReportCostsPath, 'get');
    if (params) {
      rb.query('reportType', params.reportType, {});
      rb.query('cadastralId', params.cadastralId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportEstimatedCosts>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEstimatedReportCosts$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEstimatedReportCosts$Plain(params?: {
    reportType?: 'Box3Analysis' | 'MidRentAnalysis' | 'RefinancingAnalysis' | 'PurchaseAnalysis' | 'BusinessPlanAnalysis' | 'StructuralRiskAnalysis';
    cadastralId?: string;
  },
  context?: HttpContext

): Observable<ReportEstimatedCosts> {

    return this.getEstimatedReportCosts$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<ReportEstimatedCosts>) => r.body as ReportEstimatedCosts)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getEstimatedReportCosts()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEstimatedReportCosts$Response(params?: {
    reportType?: 'Box3Analysis' | 'MidRentAnalysis' | 'RefinancingAnalysis' | 'PurchaseAnalysis' | 'BusinessPlanAnalysis' | 'StructuralRiskAnalysis';
    cadastralId?: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<ReportEstimatedCosts>> {

    const rb = new RequestBuilder(this.rootUrl, ReportsService.GetEstimatedReportCostsPath, 'get');
    if (params) {
      rb.query('reportType', params.reportType, {});
      rb.query('cadastralId', params.cadastralId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ReportEstimatedCosts>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getEstimatedReportCosts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getEstimatedReportCosts(params?: {
    reportType?: 'Box3Analysis' | 'MidRentAnalysis' | 'RefinancingAnalysis' | 'PurchaseAnalysis' | 'BusinessPlanAnalysis' | 'StructuralRiskAnalysis';
    cadastralId?: string;
  },
  context?: HttpContext

): Observable<ReportEstimatedCosts> {

    return this.getEstimatedReportCosts$Response(params,context).pipe(
      map((r: StrictHttpResponse<ReportEstimatedCosts>) => r.body as ReportEstimatedCosts)
    );
  }

  /**
   * Path part for operation getPublicAccessForReportDownload
   */
  static readonly GetPublicAccessForReportDownloadPath = '/api/v1/Reports/{id}/download';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPublicAccessForReportDownload$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicAccessForReportDownload$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PublicAccess>> {

    const rb = new RequestBuilder(this.rootUrl, ReportsService.GetPublicAccessForReportDownloadPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PublicAccess>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPublicAccessForReportDownload$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicAccessForReportDownload$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<PublicAccess> {

    return this.getPublicAccessForReportDownload$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<PublicAccess>) => r.body as PublicAccess)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPublicAccessForReportDownload()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicAccessForReportDownload$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<PublicAccess>> {

    const rb = new RequestBuilder(this.rootUrl, ReportsService.GetPublicAccessForReportDownloadPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PublicAccess>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPublicAccessForReportDownload$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicAccessForReportDownload(params: {
    id: string;
  },
  context?: HttpContext

): Observable<PublicAccess> {

    return this.getPublicAccessForReportDownload$Response(params,context).pipe(
      map((r: StrictHttpResponse<PublicAccess>) => r.body as PublicAccess)
    );
  }

  /**
   * Path part for operation getReports
   */
  static readonly GetReportsPath = '/api/v1/Reports';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getReports$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReports$Plain$Response(params?: {
    count?: number;
    page?: number;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Reports>> {

    const rb = new RequestBuilder(this.rootUrl, ReportsService.GetReportsPath, 'get');
    if (params) {
      rb.query('count', params.count, {});
      rb.query('page', params.page, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Reports>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getReports$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReports$Plain(params?: {
    count?: number;
    page?: number;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Reports> {

    return this.getReports$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Reports>) => r.body as Reports)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getReports()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReports$Response(params?: {
    count?: number;
    page?: number;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Reports>> {

    const rb = new RequestBuilder(this.rootUrl, ReportsService.GetReportsPath, 'get');
    if (params) {
      rb.query('count', params.count, {});
      rb.query('page', params.page, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Reports>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getReports$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReports(params?: {
    count?: number;
    page?: number;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Reports> {

    return this.getReports$Response(params,context).pipe(
      map((r: StrictHttpResponse<Reports>) => r.body as Reports)
    );
  }

  /**
   * Path part for operation getReportSettings
   */
  static readonly GetReportSettingsPath = '/api/v1/Reports/settings';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getReportSettings$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReportSettings$Plain$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ReportSettings>>> {

    const rb = new RequestBuilder(this.rootUrl, ReportsService.GetReportSettingsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ReportSettings>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getReportSettings$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReportSettings$Plain(params?: {
  },
  context?: HttpContext

): Observable<Array<ReportSettings>> {

    return this.getReportSettings$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ReportSettings>>) => r.body as Array<ReportSettings>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getReportSettings()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReportSettings$Response(params?: {
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<ReportSettings>>> {

    const rb = new RequestBuilder(this.rootUrl, ReportsService.GetReportSettingsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ReportSettings>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getReportSettings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getReportSettings(params?: {
  },
  context?: HttpContext

): Observable<Array<ReportSettings>> {

    return this.getReportSettings$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<ReportSettings>>) => r.body as Array<ReportSettings>)
    );
  }

}
