import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Nationality } from '@app/core/stingray-api/models';
import { finalize, map } from 'rxjs/operators';
import { requireMatch } from '@app/validators/autocomplete-validator';
import { NationalityAutoCompleteService } from './nationality-autocomplete.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'unite-nationality-autocomplete',
  templateUrl: './nationality-autocomplete.component.html',
  styleUrls: ['./nationality-autocomplete.component.scss'],
  encapsulation : ViewEncapsulation.None
})
export class NationalityAutocompleteComponent implements OnInit {

  @Input() control: FormControl;
  @Input() focus = false;
  @Input() label: string;
  @Input() appearance: string = 'outline';
  @Input() class: string = '';
  @Input() showPlaceholder = false;

  nationalities: Nationality[] = [];
  nationalitiesFiltered: Nationality[];

  isLoadingNationalities = false;

  constructor(
    private nationalityAutoCompleteService: NationalityAutoCompleteService) { }

  ngOnInit() {
    if (!this.control.hasValidator(Validators.required)) {
      this.control.setValidators([requireMatch]);
    } else {
      this.control.setValidators([Validators.required, requireMatch]);
    }

    this.isLoadingNationalities = true;
    this.nationalityAutoCompleteService.getNationalities().pipe(
      finalize(() => {
        this.isLoadingNationalities = false;
      })
    ).subscribe((customers: Nationality[]) => {
      this.nationalities = customers;
      this.nationalitiesFiltered = customers;
    });

    this.control.valueChanges.pipe(
      untilDestroyed(this),
      map((value: string | Nationality) => {
        if (this.isNationality(value)) {
          return this.filterNationality(value.name);
        } else {
          return this.filterNationality(value || '');
        }
      })
    ).subscribe((nationalities: Nationality[]) => {
      this.nationalitiesFiltered = nationalities;
    });
  }

  showValidationError(): boolean {
    return this.control.touched && this.control.valid === false;
  }

  displayWithNationality(nationality: Nationality): string {
    if (nationality != null) {
      return nationality.name;
    } else {
      return '';
    }
  }

  filterNationality(value: string): Nationality[] {
    const filterValue = value.toLowerCase();
    return this.nationalities.filter((nationality: Nationality) => {
      return this.displayWithNationality(nationality).toLowerCase().includes(filterValue) ||
      nationality.code.toLowerCase().includes(filterValue);
    });
  }

  isNationality(value: any | Nationality): value is Nationality {
    return value != null && (<Nationality>value).code !== undefined;
  }
}
