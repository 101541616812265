import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';
import { Injectable } from '@angular/core';

/**
 * A route strategy allowing for explicit route reuse.
 */
@Injectable()
export class RouteReusableStrategy extends RouteReuseStrategy {
  public shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  public store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle | null): void { }

  public shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  public retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    return null;
  }

  public shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    // reload the components if the customersId changes (occurs when searching with the search-bar)
    if (future.params.customerId !== curr.params.customerId) {
      return false;
    }

    // reload the components if the buildingsId changes (occurs when searching with the search-bar)
    if (future.params.buildingId !== curr.params.buildingId) {
      return false;
    }

    // reload the components if the mortgageId changes (occurs when searching with the search-bar)
    if (future.params.mortgageId !== curr.params.mortgageId) {
      return false;
    }

    // reload the components if the mortgageId changes (occurs when searching with the search-bar)
    if (future.params.financingId !== curr.params.financingId) {
      return false;
    }

    // reload the components if the mortgageId changes (occurs when searching with the search-bar)
    if (future.params.applicationId !== curr.params.applicationId) {
      return false;
    }

    // Don't reuse the routes for Auth0 callbacks
    if((curr.queryParamMap.has('code') && curr.queryParamMap.has('state'))) {
      return false;
    }

    return future.routeConfig === curr.routeConfig || future.data.reuse;
  }
}
