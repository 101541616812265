/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Customer } from '../models/customer';
import { GuidId } from '../models/guid-id';
import { JsonPatchDocument } from '../models/json-patch-document';
import { Prospect } from '../models/prospect';

@Injectable({
  providedIn: 'root',
})
export class ProspectsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation createProspect
   */
  static readonly CreateProspectPath = '/api/v1/Prospects';

  /**
   * Creates a new prospect.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createProspect$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createProspect$Plain$Response(params?: {
    body?: Prospect
  },
  context?: HttpContext

): Observable<StrictHttpResponse<GuidId>> {

    const rb = new RequestBuilder(this.rootUrl, ProspectsService.CreateProspectPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GuidId>;
      })
    );
  }

  /**
   * Creates a new prospect.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createProspect$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createProspect$Plain(params?: {
    body?: Prospect
  },
  context?: HttpContext

): Observable<GuidId> {

    return this.createProspect$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<GuidId>) => r.body as GuidId)
    );
  }

  /**
   * Creates a new prospect.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createProspect()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createProspect$Response(params?: {
    body?: Prospect
  },
  context?: HttpContext

): Observable<StrictHttpResponse<GuidId>> {

    const rb = new RequestBuilder(this.rootUrl, ProspectsService.CreateProspectPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<GuidId>;
      })
    );
  }

  /**
   * Creates a new prospect.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createProspect$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createProspect(params?: {
    body?: Prospect
  },
  context?: HttpContext

): Observable<GuidId> {

    return this.createProspect$Response(params,context).pipe(
      map((r: StrictHttpResponse<GuidId>) => r.body as GuidId)
    );
  }

  /**
   * Path part for operation getProspectById
   */
  static readonly GetProspectByIdPath = '/api/v1/Prospects/{id}';

  /**
   * Retrieves a prospect.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProspectById$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProspectById$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Prospect>> {

    const rb = new RequestBuilder(this.rootUrl, ProspectsService.GetProspectByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Prospect>;
      })
    );
  }

  /**
   * Retrieves a prospect.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProspectById$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProspectById$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Prospect> {

    return this.getProspectById$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Prospect>) => r.body as Prospect)
    );
  }

  /**
   * Retrieves a prospect.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getProspectById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProspectById$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Prospect>> {

    const rb = new RequestBuilder(this.rootUrl, ProspectsService.GetProspectByIdPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Prospect>;
      })
    );
  }

  /**
   * Retrieves a prospect.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getProspectById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getProspectById(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Prospect> {

    return this.getProspectById$Response(params,context).pipe(
      map((r: StrictHttpResponse<Prospect>) => r.body as Prospect)
    );
  }

  /**
   * Path part for operation patchProspect
   */
  static readonly PatchProspectPath = '/api/v1/Prospects/{id}';

  /**
   * Patches a prospect.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchProspect$Plain()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchProspect$Plain$Response(params: {
    id: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Prospect>> {

    const rb = new RequestBuilder(this.rootUrl, ProspectsService.PatchProspectPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json-patch+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Prospect>;
      })
    );
  }

  /**
   * Patches a prospect.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchProspect$Plain$Response()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchProspect$Plain(params: {
    id: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<Prospect> {

    return this.patchProspect$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Prospect>) => r.body as Prospect)
    );
  }

  /**
   * Patches a prospect.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchProspect()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchProspect$Response(params: {
    id: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Prospect>> {

    const rb = new RequestBuilder(this.rootUrl, ProspectsService.PatchProspectPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json-patch+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Prospect>;
      })
    );
  }

  /**
   * Patches a prospect.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchProspect$Response()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchProspect(params: {
    id: string;
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<Prospect> {

    return this.patchProspect$Response(params,context).pipe(
      map((r: StrictHttpResponse<Prospect>) => r.body as Prospect)
    );
  }

  /**
   * Path part for operation promoteProspectToCustomer
   */
  static readonly PromoteProspectToCustomerPath = '/api/v1/Prospects/{id}/promote/{customerId}';

  /**
   * Promote prospect to a company.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `promoteProspectToCustomer$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  promoteProspectToCustomer$Plain$Response(params: {
    id: string;
    customerId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Customer>> {

    const rb = new RequestBuilder(this.rootUrl, ProspectsService.PromoteProspectToCustomerPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('customerId', params.customerId, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Customer>;
      })
    );
  }

  /**
   * Promote prospect to a company.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `promoteProspectToCustomer$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  promoteProspectToCustomer$Plain(params: {
    id: string;
    customerId: string;
  },
  context?: HttpContext

): Observable<Customer> {

    return this.promoteProspectToCustomer$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Customer>) => r.body as Customer)
    );
  }

  /**
   * Promote prospect to a company.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `promoteProspectToCustomer()` instead.
   *
   * This method doesn't expect any request body.
   */
  promoteProspectToCustomer$Response(params: {
    id: string;
    customerId: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Customer>> {

    const rb = new RequestBuilder(this.rootUrl, ProspectsService.PromoteProspectToCustomerPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('customerId', params.customerId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Customer>;
      })
    );
  }

  /**
   * Promote prospect to a company.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `promoteProspectToCustomer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  promoteProspectToCustomer(params: {
    id: string;
    customerId: string;
  },
  context?: HttpContext

): Observable<Customer> {

    return this.promoteProspectToCustomer$Response(params,context).pipe(
      map((r: StrictHttpResponse<Customer>) => r.body as Customer)
    );
  }

}
