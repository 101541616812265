import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Nationality } from '@app/core/stingray-api/models';
import { NationalitiesService } from '@app/core/stingray-api/services';


@Injectable()
export class NationalityAutoCompleteService {

  constructor(private nationalitiesService: NationalitiesService) { }

  getNationalities(): Observable<Nationality[]> {
    const cache = sessionStorage.getItem('nationalityAutoComplete');
    if (cache) {
      return of(JSON.parse(cache));
    } else {
      return this.nationalitiesService.getNationalities().pipe(map((nationalities: Nationality[]) => {
        sessionStorage.setItem('nationalityAutoComplete', JSON.stringify(nationalities));
        return nationalities;
      }));
    }
  }

}
