/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Document } from '../models/document';
import { JsonPatchDocument } from '../models/json-patch-document';
import { NewTask } from '../models/new-task';
import { Task } from '../models/task';
import { Tasks } from '../models/tasks';

@Injectable({
  providedIn: 'root',
})
export class TasksService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getTask
   */
  static readonly GetTaskPath = '/api/v1/Tasks/{id}';

  /**
   * Returns a single task.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTask$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTask$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Task>> {

    const rb = new RequestBuilder(this.rootUrl, TasksService.GetTaskPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Task>;
      })
    );
  }

  /**
   * Returns a single task.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTask$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTask$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Task> {

    return this.getTask$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Task>) => r.body as Task)
    );
  }

  /**
   * Returns a single task.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTask()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTask$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Task>> {

    const rb = new RequestBuilder(this.rootUrl, TasksService.GetTaskPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Task>;
      })
    );
  }

  /**
   * Returns a single task.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTask$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTask(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Task> {

    return this.getTask$Response(params,context).pipe(
      map((r: StrictHttpResponse<Task>) => r.body as Task)
    );
  }

  /**
   * Path part for operation deleteTask
   */
  static readonly DeleteTaskPath = '/api/v1/Tasks/{id}';

  /**
   * Deletes a document from the organizations collection.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteTask()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTask$Response(params: {

    /**
     * the id of the document
     */
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, TasksService.DeleteTaskPath, 'delete');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Deletes a document from the organizations collection.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteTask$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTask(params: {

    /**
     * the id of the document
     */
    id: string;
  },
  context?: HttpContext

): Observable<void> {

    return this.deleteTask$Response(params,context).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation patchTask
   */
  static readonly PatchTaskPath = '/api/v1/Tasks/{id}';

  /**
   * Patch a document to change basic properties.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchTask$Plain()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchTask$Plain$Response(params: {

    /**
     * The id of the document to patch
     */
    id: string;

    /**
     * Details of the operation in JSON patch format
     */
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Task>> {

    const rb = new RequestBuilder(this.rootUrl, TasksService.PatchTaskPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json-patch+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Task>;
      })
    );
  }

  /**
   * Patch a document to change basic properties.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchTask$Plain$Response()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchTask$Plain(params: {

    /**
     * The id of the document to patch
     */
    id: string;

    /**
     * Details of the operation in JSON patch format
     */
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<Task> {

    return this.patchTask$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Task>) => r.body as Task)
    );
  }

  /**
   * Patch a document to change basic properties.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchTask()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchTask$Response(params: {

    /**
     * The id of the document to patch
     */
    id: string;

    /**
     * Details of the operation in JSON patch format
     */
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Task>> {

    const rb = new RequestBuilder(this.rootUrl, TasksService.PatchTaskPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json-patch+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Task>;
      })
    );
  }

  /**
   * Patch a document to change basic properties.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchTask$Response()` instead.
   *
   * This method sends `application/json-patch+json` and handles request body of type `application/json-patch+json`.
   */
  patchTask(params: {

    /**
     * The id of the document to patch
     */
    id: string;

    /**
     * Details of the operation in JSON patch format
     */
    body?: JsonPatchDocument
  },
  context?: HttpContext

): Observable<Task> {

    return this.patchTask$Response(params,context).pipe(
      map((r: StrictHttpResponse<Task>) => r.body as Task)
    );
  }

  /**
   * Path part for operation getTasks
   */
  static readonly GetTasksPath = '/api/v1/Tasks';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTasks$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTasks$Plain$Response(params?: {
    count?: number;
    page?: number;
    statuses?: Array<'New' | 'InProgress' | 'Completed'>;
    userId?: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Tasks>> {

    const rb = new RequestBuilder(this.rootUrl, TasksService.GetTasksPath, 'get');
    if (params) {
      rb.query('count', params.count, {});
      rb.query('page', params.page, {});
      rb.query('statuses', params.statuses, {});
      rb.query('userId', params.userId, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Tasks>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTasks$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTasks$Plain(params?: {
    count?: number;
    page?: number;
    statuses?: Array<'New' | 'InProgress' | 'Completed'>;
    userId?: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Tasks> {

    return this.getTasks$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Tasks>) => r.body as Tasks)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTasks()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTasks$Response(params?: {
    count?: number;
    page?: number;
    statuses?: Array<'New' | 'InProgress' | 'Completed'>;
    userId?: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Tasks>> {

    const rb = new RequestBuilder(this.rootUrl, TasksService.GetTasksPath, 'get');
    if (params) {
      rb.query('count', params.count, {});
      rb.query('page', params.page, {});
      rb.query('statuses', params.statuses, {});
      rb.query('userId', params.userId, {});
      rb.query('sort', params.sort, {});
      rb.query('order', params.order, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Tasks>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTasks$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTasks(params?: {
    count?: number;
    page?: number;
    statuses?: Array<'New' | 'InProgress' | 'Completed'>;
    userId?: string;
    sort?: string;
    order?: 'Asc' | 'Desc';
  },
  context?: HttpContext

): Observable<Tasks> {

    return this.getTasks$Response(params,context).pipe(
      map((r: StrictHttpResponse<Tasks>) => r.body as Tasks)
    );
  }

  /**
   * Path part for operation createTask
   */
  static readonly CreateTaskPath = '/api/v1/Tasks';

  /**
   * Creates a new task.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTask$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createTask$Plain$Response(params?: {
    body?: NewTask
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Task>> {

    const rb = new RequestBuilder(this.rootUrl, TasksService.CreateTaskPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Task>;
      })
    );
  }

  /**
   * Creates a new task.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createTask$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createTask$Plain(params?: {
    body?: NewTask
  },
  context?: HttpContext

): Observable<Task> {

    return this.createTask$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Task>) => r.body as Task)
    );
  }

  /**
   * Creates a new task.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTask()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createTask$Response(params?: {
    body?: NewTask
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Task>> {

    const rb = new RequestBuilder(this.rootUrl, TasksService.CreateTaskPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Task>;
      })
    );
  }

  /**
   * Creates a new task.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createTask$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  createTask(params?: {
    body?: NewTask
  },
  context?: HttpContext

): Observable<Task> {

    return this.createTask$Response(params,context).pipe(
      map((r: StrictHttpResponse<Task>) => r.body as Task)
    );
  }

  /**
   * Path part for operation openTask
   */
  static readonly OpenTaskPath = '/api/v1/Tasks/{id}/Open';

  /**
   * Opens and assigns a task to the current user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `openTask$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  openTask$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Task>> {

    const rb = new RequestBuilder(this.rootUrl, TasksService.OpenTaskPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Task>;
      })
    );
  }

  /**
   * Opens and assigns a task to the current user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `openTask$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  openTask$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Task> {

    return this.openTask$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Task>) => r.body as Task)
    );
  }

  /**
   * Opens and assigns a task to the current user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `openTask()` instead.
   *
   * This method doesn't expect any request body.
   */
  openTask$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Task>> {

    const rb = new RequestBuilder(this.rootUrl, TasksService.OpenTaskPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Task>;
      })
    );
  }

  /**
   * Opens and assigns a task to the current user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `openTask$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  openTask(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Task> {

    return this.openTask$Response(params,context).pipe(
      map((r: StrictHttpResponse<Task>) => r.body as Task)
    );
  }

  /**
   * Path part for operation closeTask
   */
  static readonly CloseTaskPath = '/api/v1/Tasks/{id}/Close';

  /**
   * Closes a task.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `closeTask$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  closeTask$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Task>> {

    const rb = new RequestBuilder(this.rootUrl, TasksService.CloseTaskPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Task>;
      })
    );
  }

  /**
   * Closes a task.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `closeTask$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  closeTask$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Task> {

    return this.closeTask$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Task>) => r.body as Task)
    );
  }

  /**
   * Closes a task.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `closeTask()` instead.
   *
   * This method doesn't expect any request body.
   */
  closeTask$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Task>> {

    const rb = new RequestBuilder(this.rootUrl, TasksService.CloseTaskPath, 'put');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Task>;
      })
    );
  }

  /**
   * Closes a task.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `closeTask$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  closeTask(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Task> {

    return this.closeTask$Response(params,context).pipe(
      map((r: StrictHttpResponse<Task>) => r.body as Task)
    );
  }

  /**
   * Path part for operation getTaskDocuments
   */
  static readonly GetTaskDocumentsPath = '/api/v1/Tasks/{id}/Documents';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTaskDocuments$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTaskDocuments$Plain$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Document>>> {

    const rb = new RequestBuilder(this.rootUrl, TasksService.GetTaskDocumentsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Document>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTaskDocuments$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTaskDocuments$Plain(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<Document>> {

    return this.getTaskDocuments$Plain$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Document>>) => r.body as Array<Document>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getTaskDocuments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTaskDocuments$Response(params: {
    id: string;
  },
  context?: HttpContext

): Observable<StrictHttpResponse<Array<Document>>> {

    const rb = new RequestBuilder(this.rootUrl, TasksService.GetTaskDocumentsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Document>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getTaskDocuments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getTaskDocuments(params: {
    id: string;
  },
  context?: HttpContext

): Observable<Array<Document>> {

    return this.getTaskDocuments$Response(params,context).pipe(
      map((r: StrictHttpResponse<Array<Document>>) => r.body as Array<Document>)
    );
  }

}
