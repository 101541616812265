import { Route, Routes } from '@angular/router';
import { ShellComponent } from './shell.component';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { Observable } from 'rxjs';
import { ComponentType } from '@angular/cdk/portal';
import { AuthenticationGuard } from '@app/core/authentication/guards/authentication.guard';
import { ShellGuard } from './shell.guard';
import { AuditLogDetailData } from '@app/shared/audit-log-detail/audit-log-detail.component';
import { ConfirmDialogData } from '@app/shared/confirm-dialog/confirm-dialog.component';

/**
 * Provides helper methods to create routes and dialogs.
 */
export class Shell {

  /**
   * Creates routes using the shell component and authentication.
   * @param routes The routes to add.
   * @return The new route using shell as the base.
   */
  static childRoutes(routes: Routes): Route {
    return {
      path: '',
      component: ShellComponent,
      children: routes,
      canActivate: [AuthenticationGuard, ShellGuard],
      // Reuse ShellComponent instance when navigating between child views
      data: { reuse: true }
    };
  }

  static addDialog<Dialog, Model, Return>(
    matDialog: MatDialog,
    component: ComponentType<Dialog>,
    data: Model): Observable<Return> {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.hasBackdrop = true;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    if (data != null) {
      dialogConfig.data = data;
    }
    dialogConfig.panelClass = 'add-dialog';

    return matDialog.open(component, dialogConfig).afterClosed();
  }

  static editDialog<Dialog, Model>(
    matDialog: MatDialog,
    component: ComponentType<Dialog>,
    data: Model): Observable<Model> {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.hasBackdrop = true;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = data;
    dialogConfig.panelClass = 'edit-dialog';

    return matDialog.open(component, dialogConfig).afterClosed();
  }

  static informationDialog<Dialog, Model>(
    matDialog: MatDialog,
    component: ComponentType<Dialog>,
    data: Model): Observable<Model> {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.hasBackdrop = true;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.data = data;
    dialogConfig.panelClass = 'information-dialog';

    return matDialog.open(component, dialogConfig).afterClosed();
  }

  static confirmationDialog<Dialog>(
    matDialog: MatDialog,
    component: ComponentType<Dialog>,
    name: string | ConfirmDialogData): Observable<boolean> {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.hasBackdrop = true;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = name;
    dialogConfig.panelClass = 'confirmation-dialog';

    return matDialog.open(component, dialogConfig).afterClosed();
  }

  static auditDialog<Dialog>(
    matDialog: MatDialog,
    component: ComponentType<Dialog>,
    data: AuditLogDetailData): Observable<void> {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.hasBackdrop = true;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.data = data;
    dialogConfig.panelClass = 'audit-dialog';

    return matDialog.open(component, dialogConfig).afterClosed();
  }
}
